import { Injectable } from '@angular/core';
import { UtilError } from '@formbird/shared';
import { isArray, isDate, isEmpty, isEqual, isError, isObject, isString } from 'lodash';
import { MandatoryValueProcessor } from '../../utils/MandatoryValueProcessor';
import { ModifiedFieldService } from './modified-field.service';

@Injectable()
export class MandatoryFieldService {

  constructor(
    private modifiedFieldService: ModifiedFieldService
  ) {
    this.checkMandatoryFields = this.checkMandatoryFields.bind(this);
  }

  public mandatoryFields = { components: [] };

  /**
   * @param fieldInput the input field
   * @param fld field
   * @returns the label that will be shown for mandatory fields that are missing
   */
  async checkMandatoryValues(fieldInput, fld, isValidationArray) {

    let label = fld.label;
    if (!fld.label || fld.label === '') {
      label = fld.name;
    }

    if (!fieldInput && fieldInput !== 0) {
      return label;

    } else if (isArray(fieldInput) && isEqual([], fieldInput)) {
      return label;

    } else if (isObject(fieldInput) && !isDate(fieldInput)) {

      if (isEqual({}, fieldInput)) {
        return label;

      } else {

        const valuesArray = isValidationArray ? fld.validationsArray : fld.mandatoryValuesArray;
        if (valuesArray) {

          const mandatoryValuesLabel = await MandatoryValueProcessor.processMandatoryValues(valuesArray, fieldInput, label);

          if (mandatoryValuesLabel) {
            // there are some conditions that do not match the mandatory requirements, so do not do any further
            // processing of the save
            return mandatoryValuesLabel;
          }
        }
      }
    }

    return null;
  }

  /**
   * @param fieldInput the input field
   * @param fld field
   * @returns the label that will be shown for mandatory fields that are missing
   */
  checkMandatoryFields(fieldInput, fld) {

    let label = fld.label;
    if (!fld.label || fld.label === '') {
      label = fld.name;
    }

    if (!fieldInput && fieldInput !== 0) {
      return label;

    } else if (isArray(fieldInput) && isEqual([], fieldInput)) {
      return label;

    } else if (isObject(fieldInput) && !isDate(fieldInput)) {

      if (isEqual({}, fieldInput)) {
        return label;

      } else {

        const mandatoryFields = fld.mandatoryFields || [];
        if (!mandatoryFields.length) {
          if (fld.mandatoryValues) {
            const keys = Object.keys(fld.mandatoryValues) || [];
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              if (fld.mandatoryValues[key]) {
                mandatoryFields.push(key);
              }
            }
          }
        }

        if (mandatoryFields && mandatoryFields.length) {
          const keys = Object.keys(fieldInput);

          for (let id = 0; id < keys.length; id++) {

            const key = keys[id];
            const obj = fieldInput[key];

            if (isString(obj)) {

              if (mandatoryFields) {

                for (let m = 0, len = mandatoryFields.length; m < len; m++) {

                  if (mandatoryFields[m] === key) {

                    if (obj === undefined || obj === '') {
                      return label + ' ➡ ' + mandatoryFields[m];
                    }
                  }
                }

              } else {

                if (obj === undefined || obj === '') {
                  return label;
                }
              }

            } else if (isObject(obj)) {

              if (this.checkMandatoryFields(obj, fld)) {
                return label;
              }
            }
          }
        }
      }

    }

    return null;
  }

  async isMandatoryAnswered(unsavedDocumentDatas) {

    if (!unsavedDocumentDatas) {
      return Promise.reject(new Error('Unsaved document data list not provided in mandatory field check'));

    }
    
    const modifiedFieldService = this.modifiedFieldService;
    const checkMandatoryValues = this.checkMandatoryValues;
    const checkMandatoryFields = this.checkMandatoryFields;

    let unansweredMandatoryFieldMsg = 'Mandatory item(s) not completed: ';
    let hasUnansweredMandatoryFields = false;

    const doCheckMandatory = async function (unsavedDocumentData) {

      const bean = unsavedDocumentData.document;
      const tpl = unsavedDocumentData.template;

      if (modifiedFieldService.hasChanged(bean.documentId)) {

        try {

          for (let t = 0, len = tpl.components.length; t < len; t++) {

            const fld = tpl.components[t];
            const value = bean[fld.name];

            // add the mandatory fields to a message that can be output once all mandatory fields
            // are checked. This way all mandatory fields can be displayed in the message
            let processMandatoryFields = null;
            let isValidationArray = false;

            if (fld.mandatoryValuesArray && fld.mandatory) {
              processMandatoryFields = checkMandatoryValues;
            } else if (fld.mandatory) {
              processMandatoryFields = checkMandatoryFields;

            } else if (fld.validationsArray && !isEmpty(value)) {

              processMandatoryFields = checkMandatoryFields;
              isValidationArray = true;
            }

            if (processMandatoryFields) {

              const missingField = await processMandatoryFields(value, fld, isValidationArray);
              if (isError(missingField)) {
                return Promise.reject(missingField);

              }
              
              if (missingField) {
                if(window.innerWidth > 400){
                  unansweredMandatoryFieldMsg += '<br>'
                }
                if (fld.mandatoryMessage) {
                  unansweredMandatoryFieldMsg += `<span class='mandatory-item'>${fld.mandatoryMessage}</span>`;

                } else {
                  unansweredMandatoryFieldMsg += `<span class='mandatory-item'>${missingField}</span>`;
                }

                hasUnansweredMandatoryFields = true;
              }
            }
          }

        } catch (err) {

          let msg;
          if (err.name === UtilError.NOT_FOUND_ERROR) {
            msg = 'Template not found';
          } else {
            msg = 'Error getting template to determine mandatory field: ' + err.message;
          }
          return Promise.reject(new Error(msg));
        }

      } else {

        return Promise.resolve();
      }

    };

    for (let i = 0; i < unsavedDocumentDatas.length; i++) {
      await doCheckMandatory(unsavedDocumentDatas[i]);
    };

    if (hasUnansweredMandatoryFields) {
      // reject with the the mandatory message so it can be output by the caller
      return Promise.reject(new Error(unansweredMandatoryFieldMsg));
    } else {
      return Promise.resolve(true);
    }
  }

}
