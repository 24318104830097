import { CurrentDocumentService } from './current-document.service';
import { Injectable } from '@angular/core';
import { SharedConstants, User } from '@formbird/types';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { ClientConstants } from '../../constants/ClientConstants';
import { select } from '../../redux/decorators/select';
import { ClientAccessService } from '../access/client-access.service';
import { BroadcastService } from '../broadcast/broadcast.service';
import { DocumentService } from './document.service';
import { UnsavedDocumentService } from './unsaved-document.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class ModifiedFieldService {

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;

  // list of modified fields in a document
  public modifiedFields = {};

  // store the changed data while the document is pushing into server
  public savingChangedData = {};

  // controls the dirty checking
  public formDirty = {
    showSpinner: false,
    isSaving: false,
    dirty: false,
    updateAllowed: false,
    validating: null
  };

  constructor(
    private documentService: DocumentService,
    private clientAccessService: ClientAccessService,
    private unsavedDocumentService: UnsavedDocumentService,
    private currentDocumentService: CurrentDocumentService,
    private broadcastService: BroadcastService
  ) {
    this.user$.subscribe((user: User) => {
      this.user = user;
    });
  }

  private canUpdateDoc(targetDocument) {

    if (!targetDocument) {
      return false;
    }

    // if the document is new
    if (targetDocument.systemHeader && !targetDocument.systemHeader.createdDate) {
      // check if user can create
      return this.clientAccessService.checkAccess(targetDocument, SharedConstants.OPERATION_TYPE_CREATE);

    } else {

      // check if user can update
      return this.clientAccessService.checkAccess(targetDocument, SharedConstants.OPERATION_TYPE_UPDATE);
    }
  }

  /**
   * Check if the current form is dirty, that make the save button appear and blink or display unsaved changes dialog
   */
  isDirty() {
    return this.formDirty.dirty;
  }

  shouldApplyFlags(documentListId) {
    // Should apply flag on the document list that was attached to command bar
    return !documentListId || documentListId === this.currentDocumentService.getMainDocumentListId();
  }

  /**
   * Set current form to be dirty, that make the save button appear and blink
   * @param isDirty is dirty
   * @param documentId the document id
   */
  setDirty(isDirty, documentId?, unsavedDocumentListId?) {

    if (!this.shouldApplyFlags(unsavedDocumentListId)) {
      return;
    }

    if (isDirty !== this.formDirty.dirty) {

      // don't set the dirty value if save is in progress. Any change in a presave server rule will go into the save. The
      // isSaving flag should have been cleared by the time of a postSave rule so that the save button will appear if the
      // document is changed in the postSave rule
      if (isDirty) {

        if (!this.formDirty.isSaving) {

          if (this.formDirty.updateAllowed) {

            this.formDirty.dirty = isDirty;

          } else {

            const targetDoc = this.documentService.getExistingDocument(documentId);

            if (this.canUpdateDoc(targetDoc)) {

              this.formDirty.dirty = isDirty;
            }
          }
        }

      } else {

        this.formDirty.dirty = false;

      }
    }

    this.broadcastService.broadcast(ClientConstants.FORM_DIRTY_UPDATED, this.formDirty);
  }

  setShowSpinner(showSpinner, unsavedDocumentListId?) {

    if (!this.shouldApplyFlags(unsavedDocumentListId)) {
      return;
    }

    this.formDirty.showSpinner = showSpinner;

    this.broadcastService.broadcast(ClientConstants.SPINNER_UPDATED, showSpinner);

  }

  /**
   * Allow this form to be updatable.
   * @param isAllowedUpdate is allowed update
   */
  setUpdateAllowed(isAllowedUpdate) {

    this.formDirty.updateAllowed = isAllowedUpdate;
  }

  /**
   * Reset the current form to its un-interacted state.
   */
  resetFormDirty() {
    this.setDirty(false);
  }

  /**
   * Sets the current form to be updatable depending on the current user's privileges.
   * @param docData the doc data object
   */
  setFormAccess(docData) {

    this.setUpdateAllowed(false); // default to false

    const userAccount = this.user ? this.user.account : null;

    // do not allow update
    if (!docData || !userAccount) {
      return;
    }

    if (this.canUpdateDoc(docData)) {
      this.setUpdateAllowed(true);
    }
  }

  isSaving() {
    return this.formDirty.isSaving;
  }

  setSaveFlag(isSaving, unsavedDocumentListId?) {

    if (!this.shouldApplyFlags(unsavedDocumentListId)) {
      return;
    }
    
    this.formDirty.isSaving = isSaving;
  }

  /*
   * This method is used to add modified field by document id.
   * Before adding should check whether the version of the document has changed from the original because
   * there is a case reset current document from the one returned from server once it is saved.
   * @returns: true if adding successfully, otherwise: false
   */
  addModifiedField(documentId, changedFieldName, documentListId, shouldCompareDocuments?, overrideTemplateId?) {

    let result = false;

    if (!documentListId) {
      logger.error('Document list id not provided in addModifiedField');

    } else if (!documentId) {
      logger.error('Document id not provided in addModifiedField');

    } else if (!changedFieldName) {
      logger.error('Changed field name not provided in addModifiedField');

    } else if (!this.unsavedDocumentService.isDocumentsDefined(documentListId)) {
      logger.info('Unsaved Document List is not found');

    } else {

      let shouldAdd = false;

      const isPushing = this.isPushing(documentId);
      if (isPushing === false && (shouldCompareDocuments === undefined || shouldCompareDocuments === true)) {

        shouldAdd = this.isChangedField(documentId, changedFieldName, documentListId, overrideTemplateId);

      } else {

        shouldAdd = true;
      }

      if (shouldAdd) {

        if (isPushing) {

          if (!this.savingChangedData[documentId]) {
            this.savingChangedData[documentId] = {};
          }

          if (!this.savingChangedData[documentId].data) {
            this.savingChangedData[documentId].data = {};
          }

          this.savingChangedData[documentId][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY] = documentListId;
          this.savingChangedData[documentId].data[changedFieldName] = this.unsavedDocumentService.getDocumentOnly(documentId)[changedFieldName];
          
          logger.info('Document is pushing into server. Record the modified field: ', this.savingChangedData);
          return;
        }

        if (!this.modifiedFields[documentId]) {
          this.modifiedFields[documentId] = {};
        }

        this.modifiedFields[documentId][changedFieldName] = true;
        this.modifiedFields[documentId][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY] = documentListId;

        this.setDirty(true, documentId, documentListId);

        logger.info('Adding new modified field. Doc: ' + documentId + ' - field: ' + changedFieldName);

        result = true;
      }
    }

    return result;
  }

  resetModifiedFields() {

    logger.info('Resetting all modified fields...');

    this.modifiedFields = {};
    this.broadcastService.broadcast(ClientConstants.FORM_RESET);
  }

  // Remove all changed fields of a document
  removeModifiedDocumentFields(documentId) {

    logger.info('Resetting all modified fields of a document...');

    delete this.modifiedFields[documentId];

    if (!this.hasAnyChanged()) {
      this.setDirty(false);
    }
  }

  isPushing(documentId) {
    return this.savingChangedData[documentId] && this.savingChangedData[documentId].isPushing;
  }

  setPushingData(isPushing, documentId) {

    if (!this.savingChangedData[documentId]) {
      this.savingChangedData[documentId] = {};
    }

    this.savingChangedData[documentId].isPushing = isPushing;
  }

  resetPushingData(documentId) {

    if (this.savingChangedData[documentId]) {
      
      this.savingChangedData[documentId].isPushing = false;
    }
  }

  removeAllSavingChangedDocumentFields() {

    logger.info('Resetting all saving changed fields...');

    this.savingChangedData = { };

  }

  assignChangedDocumentFields(document) {
  
    const documentId = document.documentId;
  
    this.modifiedFields[documentId] = {};
    this.modifiedFields[documentId][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY] = this.savingChangedData[documentId][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY];
  
    const fields = Object.keys(this.savingChangedData[documentId].data);
    fields?.forEach(field => {
  
      const changedFieldValue = this.savingChangedData[documentId].data[field];
      const fieldValue = document[field];
  
      if (!isEqual(changedFieldValue, fieldValue)) {
  
        this.modifiedFields[documentId][field] = true;
  
      } else {
  
        delete this.savingChangedData[documentId].data[field];//remove the stored saving changed field if it is the same as the field of saved document
  
      }
  
    });
  }

  // Remove all changed fields of a template
  removeTemplateFields(templateId) {

    logger.info('Removing changed fields of template: ' + templateId);

    delete this.modifiedFields[templateId];
  }

  removeChangedField(documentId, changedFieldName) {
    logger.warn('DEPRECATED: Please call ModifiedFieldService.removeModifiedField ' +
      'instead of ModifiedFieldService.removeChangedField');

    this.removeModifiedField(documentId, changedFieldName);
  }

  removeModifiedField(documentId, changedFieldName) {

    if (this.modifiedFields[documentId]) {

      logger.info('Removing changed field: ' + changedFieldName + ' of document: ' + documentId);

      delete this.modifiedFields[documentId][changedFieldName];
    }

    if (!this.hasAnyChanged()) {
      this.setDirty(false);
    }
  }

  isChangedField(documentId, changedFieldName, documentListId, overrideTemplateId?) {

    try {

      const currentDoc = this.unsavedDocumentService.getDocumentOnly(documentId);
      const currentOriginalDoc = this.documentService.getExistingDocument(documentId);

      if (currentDoc && !currentOriginalDoc ||    // create doc mode
        (currentDoc && currentOriginalDoc &&    // modify doc && component editor mode
          !isEqual(currentDoc[changedFieldName], currentOriginalDoc[changedFieldName])) ||
        (overrideTemplateId && !isEqual(currentDoc, currentOriginalDoc))) {   // override template

        return true;
      }

    } catch (e) {
    }

    return false;
  }

  /**
   * whether the document has unsaved changes
   * @param documentId the document id
   * @returns the result
   */
  hasChanged(documentId, unsavedDocumentListId?) {

    logger.info('Checking changed document. Stored modified fields: ' + JSON.stringify(this.modifiedFields));

    const changes = this.getModifiedFieldsInDoc(documentId);

    if (!unsavedDocumentListId) {
      return changes.length > 0;
    }

    return unsavedDocumentListId === changes[ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY];
  }

  /**
   * whether any documents have been changed
   * @returns the result
   */
  hasAnyChanged() {

    const documentIds = Object.keys(this.modifiedFields);

    if (documentIds) {

      for (let id = 0; id < documentIds.length; id++) {

        const infos = Object.keys(this.modifiedFields[documentIds[id]]);

        for (let i = 0; i < infos.length; i++) {

          const key = infos[i];
          if (key !== ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY) {

            return true;
          }

        }
      }
    }

    return false;
  }

  hasSavingChanged(documentId) {
    console.log('Saving changed data: ', this.savingChangedData[documentId]?.data);
    return this.savingChangedData[documentId] &&
      this.savingChangedData[documentId].data && 
      Object.keys(this.savingChangedData[documentId].data);
  }

  hasAnySavingChanged() {
    
    const documentIds = Object.keys(this.savingChangedData);
    
    if (documentIds?.length) {
    
      for (let id = 0; id < documentIds.length; id++) {
    
        const documentId = documentIds[id];
        const data = this.savingChangedData[documentId]?.data;
        if (data) {
          const fields = Object.keys(data);
          if (fields?.length) {
            return true;
          }
        }
        
      }
    }

    return false;
  }

  extractSavingChangedAsDocument(documentId) {
    return this.savingChangedData[documentId].data;
  }

  /**
   * get the unsaved documents for the unsaved document list. This needs to be separate from UnsavedDocumentService to avoid
   * a circular dependency of Angular service injections
   * @param unsavedDocumentListId - the id of the list to get the changed docs for
   */
  getModifiedDocuments(unsavedDocumentListId) {
  
    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not specified when getting loaded documents');
    }

    const self = this;

    const loadedDocs = this.unsavedDocumentService.getUnsavedDocuments(unsavedDocumentListId);

    // only return documents that have unsaved changes. Load the modified docs into unsavedDocs
    const unsavedDocs = [];

    if (loadedDocs) {


      loadedDocs.forEach(function (loadedDoc) {

        if (self.hasChanged(loadedDoc?.documentId)) {

          unsavedDocs.push(loadedDoc);

        }

      });
    }

    return unsavedDocs;
  }

  getChangedDocumentListId(documentId) {

    return this.modifiedFields[documentId] ?
      this.modifiedFields[documentId][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY] : null;
  }

  getAllChangedDocumentListIds() {

    const res = [];
    const documentIds = Object.keys(this.modifiedFields);

    for (let i = 0; i < documentIds.length; i++) {

      const unsavedDocumentListId = this.modifiedFields[documentIds[i]][ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY];
      if (unsavedDocumentListId) {

        res.push(unsavedDocumentListId);

      }
    }

    return res;
  }

  /**
   * Get all the changed fields of the provided document.
   * @param documentId 
   * @returns 
   */
  getModifiedFieldsInDoc(documentId) {
    const changedFieldNames = [];

    const info = this.modifiedFields[documentId];

    if (info) {

      const keys = Object.keys(info);

      for (let i = 0; i < keys.length; i++) {

        const key = keys[i];

        if (key !== ClientConstants.CHANGED_DOCUMENT_LIST_ID_KEY) {

          changedFieldNames.push(key);
        }
      }
    }

    return changedFieldNames;
  }

}
