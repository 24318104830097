import { Injectable } from '@angular/core';
import { SharedUrlRoutes } from '@formbird/shared';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';

const serverRoutes = SharedUrlRoutes.serverRoutes;

@Injectable({
  providedIn: 'root'
})
export class DynamicMetaTagService {

  constructor(
    private localStorageService: LocalStorageService
  ) {
  }


  private metaDefaultTitle = 'Formbird Version ' + this.localStorageService.getItem(LocalStorageKeys.APP_VERSION);
  private metaDefaultDesc = 'Copyright ' + new Date().getFullYear() + ' Formbird Pty Ltd. All rights reserved.';
  private metaDefaultImg = '../../../../../images/formbird-logo.png';
  private metaTitleData;
  private metaDescData;
  private metaImgData;

  /**
   * set the meta tags in the html head of the page. This can be used by other applications to see details about the current
   * document in the page, eg. with document unfurling in Skype or Slack
   */
  set(title, description, img) {
    this.metaTitleData = title ? title : this.metaDefaultTitle;
    this.metaDescData = description ? description : this.metaDefaultDesc;
    this.metaImgData = img ? (serverRoutes.loadFile + '/' + img) : this.metaDefaultImg;

    const metaTitleElm = document.getElementById('metaTitle');
    if (metaTitleElm) {
      metaTitleElm.setAttribute('content', this.metaTitleData);
    }

    const metaDescElm = document.getElementById('metaDesc');
    if (metaDescElm) {
      metaDescElm.setAttribute('content', this.metaDescData);
    }

    const metaImgElm = document.getElementById('metaImg');
    if (metaImgElm) {
      metaImgElm.setAttribute('content', this.metaImgData);
    }
  }

  title() {
    return this.metaTitleData;
  }

  description() {
    return this.metaDescData;
  }

  image() {
    return this.metaImgData;
  }

}
