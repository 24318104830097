import { UtilArray, UtilDocument } from '@formbird/shared';
import { SharedConstants } from '@formbird/types';
import { formSetComponentFlagTemplate, formSetListComponentsFlagTemplate, 
  formSetComponentPropsTemplate } from '../../redux/actions';

const logger = console;

export class RuleContext {

  private currentEventName;
  private currentDocument;
  private currentTemplate;
  private currentDocumentContext;
  private affectedDoc;
  private affectedTpl;
  private superObj;

  constructor(superObj, eventName, processDoc, documentContext) {

    this.superObj = superObj;
    this.currentEventName = eventName;
    this.currentDocument = processDoc;
    this.currentTemplate = documentContext.template;
    this.currentDocumentContext = documentContext;

  }


  getComponentDefinition(field) {
    const examDoc = this.determineAffectedDoc(field);
    return this.loadTplItem(examDoc.docField);
  }

  isVisible(field) {
    return this.getComponentDefinition(field)?.visible !== false;
  }

  showField(field, flag) {

    this.setFieldFlag(field, 'visible', flag);

  }

  showFields(fields, flag) {
    this.setFieldFlags(fields, 'visible', flag);
  }

  enableField(field, flag) {

    this.setFieldFlag(field, 'enabled', flag);

  }

  isEnabled(field) {
    return this.getComponentDefinition(field)?.enabled !== false;
  }

  enableFields(fields, flag) {
    this.setFieldFlags(fields, 'enabled', flag);
  }

  filterAndSetAffectedDoc(fields) {
    const res = [];

    for (let i = 0; i < fields.length; i++) {
    
      let examDoc = this.determineAffectedDoc(fields[i]);
      
      if (this.affectedDoc) {
    
        const field = examDoc.docField;
    
        let tplItem = this.loadTplItem(field);
    
        if (!tplItem) {
          logger.warn('Template does not contain field: ' + field + ' to mandate.');
    
        } else {
    
          res.push(field);
    
        }
      }
    }

    return res;
  }

  isMandatory(field) {
    return this.getComponentDefinition(field)?.mandatory === true;
  }

  mandateField(field, flag) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {

      if (flag !== undefined) {
        const tplItem = this.loadTplItem(examDoc.docField);

        if (!tplItem) {
          logger.warn('Template does not contain field: ' + field + ' to mandate.');
          return;
        }

        const result = (flag === 'yes' || flag === 'true' || flag === true);

        this.superObj.dispatchStoreAction(formSetComponentFlagTemplate(this.affectedTpl.documentId, examDoc.docField, 'mandatory', result));

        if (!result) {
          this.superObj.setValidationValue(this.affectedDoc, examDoc.docField, 'required', false);
        }

      }
    }
  }

  mandateFields(fields, flag) {

    const resFields = this.filterAndSetAffectedDoc(fields);

    if (resFields.length) {

      const result = (flag === 'yes' || flag === 'true' || flag === true);

      this.superObj.dispatchStoreAction(formSetListComponentsFlagTemplate(this.affectedTpl.documentId, resFields, 'mandatory', result));

      if (!result) {
        for (let i = 0; i < resFields.length; i++) {
          this.superObj.setValidationValue(this.affectedDoc, resFields[i], 'required', false);
        }
      }

    }

  }

  setFieldValue(field, value, tmp) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {
      if (tmp) {

        logger.warn('Please use function setFieldValue(field, value) instead of setFieldValue(doc, field, value)');

        this.affectedDoc[value] = tmp;

      } else {

        this.affectedDoc[examDoc.docField] = value;

      }
    }
  }

  getFieldValue(field, tmp) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {

      if (tmp) {

        logger.warn('Please use function getFieldValue(field) instead of getFieldValue(doc, field)');
        return this.affectedDoc[tmp];

      } else {

        return this.affectedDoc[examDoc.docField];

      }
    }
  }

  checkChecklistItem(field, optionName, checked) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {

      let fieldValues = this.affectedDoc[examDoc.docField];
      if (fieldValues) {

        const matchIndex = UtilArray.containsIndex(fieldValues, optionName);

        if (checked === 'yes' || checked === true) {

          if (matchIndex === -1) {
            fieldValues.push(optionName);
          }

        } else {

          if (matchIndex > -1) {
            fieldValues.splice(matchIndex, 1);
          }

        }
      } else {

        if (checked === 'yes' || checked === true) {
          fieldValues = [];
          fieldValues.push(optionName);
        }

      }

      this.affectedDoc[examDoc.docField] = fieldValues;

      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME] = {};
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_RESULT_FLAG] = true;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_FIELD_NAME] = examDoc.docField;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_TEMPLATE_ID] = this.affectedTpl.documentId;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_DOCUMENT_ID] = this.affectedDoc.documentId;

      this.superObj.broadcastEvent(SharedConstants.EVENT_LIST_NAME, this.superObj.eventList);
    }
  }

  getFilter(field) {

    return this.getGridFilter(field);

  }

  getGridFilter(field) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {

      const tplItem = this.loadTplItem(examDoc.docField);

      if (tplItem) {
        return tplItem.filter;
      }
    }
  }

  setGridFilter(field, newFilter) {

    this.setFilter(field, newFilter);

  }

  setFilter(field, newFilter) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {

      const tplItem = this.loadTplItem(examDoc.docField);

      if (!tplItem) {
        logger.warn('Template does not contain field: ' + field + ' to set filter.');
        return;
      }

      this.superObj.dispatchStoreAction(formSetComponentFlagTemplate(this.affectedTpl.documentId, examDoc.docField, 'filter', newFilter));

      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME] = {};
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_NEW_FILTER] = newFilter;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_FIELD_NAME] = examDoc.docField;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_TEMPLATE_ID] = this.affectedTpl.documentId;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_AFFECTED_DOCUMENT_ID] = this.affectedDoc.documentId;
      this.superObj.eventList[SharedConstants.ON_CHANGE_FILTER_EVENT_NAME]
      [SharedConstants.RULESET_EVENT_NAME] = this.currentEventName;

      this.superObj.broadcastEvent(SharedConstants.EVENT_LIST_NAME, this.superObj.eventList);
    }
  }

  openNewDocument(templateId, initialData, newWindow, callback) {

    this.superObj.tabService.openNewDocument(templateId, initialData, newWindow, callback);

  }

  markDocumentChanged() {

    this.superObj.modifiedFieldService.setDirty(true);

  }

  isNew(doc) {

    return UtilDocument.isNew(doc ? doc : this.affectedDoc);

  }

  determineAffectedDoc(field) {

    const result = {} as any;

    if (field && field.indexOf('.') !== -1) {

      const temp = field.split('.');
      result.docPrefix = temp[0]; // Must be 'parentDoc' OR 'childDoc'
      result.docField = temp[1];

    } else {
      result.docField = field;
    }

    this.setAffectedItems(result.docPrefix);

    return result;
  }

  /**
   * Set affected items: document, templateId, template item
   */
  setAffectedItems(docPrefix) {

    if (docPrefix === SharedConstants.RULESET_PARENT_DOCUMENT) {
      this.affectedDoc = this.superObj.getDocumentFromStore(this.currentDocumentContext.parentDocument.documentId);
      this.affectedTpl = this.superObj.getTemplateFromStore(this.currentDocumentContext.parentTemplate.documentId);

    } else if (docPrefix === SharedConstants.RULESET_CHILD_DOCUMENT) {
      this.affectedDoc = this.superObj.getDocumentFromStore(this.currentDocumentContext.childDocument.documentId);
      this.affectedTpl = this.superObj.getTemplateFromStore(this.currentDocumentContext.childTemplate.documentId);

    } else {
      this.affectedDoc = this.superObj.getDocumentFromStore(this.currentDocument.documentId);
      this.affectedTpl = this.superObj.getTemplateFromStore(this.currentTemplate.documentId);
    }
  }


  setComponentProps(field, componentName, propertyValue) {
    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {
      const tplItem = this.loadTplItem(examDoc.docField);
      
      if (!tplItem) {
        logger.warn('Template does not contain field: ' + field + ' to show/enable.');
        return;
      }

      this.superObj.dispatchStoreAction(formSetComponentPropsTemplate(
        this.affectedTpl.documentId, examDoc.docField, componentName, propertyValue));
    }
    
    return false;
  }

  setFieldFlag(field, fieldFlag, flag) {

    const examDoc = this.determineAffectedDoc(field);

    if (this.affectedDoc) {
      if ('enabled' === fieldFlag) {

        if (!this.superObj.clientAccessService.hasPermission(this.affectedDoc, 'Update,Create')) {
          return;
        }

      }

      if (flag !== undefined) {
        const tplItem = this.loadTplItem(examDoc.docField);

        if (!tplItem) {
          logger.warn('Template does not contain field: ' + field + ' to show/enable.');
          return;
        }

        const result = (flag === 'yes' || flag === 'true' || flag === true);
        this.superObj.dispatchStoreAction(formSetComponentFlagTemplate(this.affectedTpl.documentId, examDoc.docField, fieldFlag, result));

        return result;
      }
    }

    return false;
  }

  setFieldFlags(fields, fieldFlagName, flag) {

    const resFields = this.filterAndSetAffectedDoc(fields);

    if (resFields.length) {

      if (flag !== undefined) {

        const result = (flag === 'yes' || flag === 'true' || flag === true);
        
        this.superObj.dispatchStoreAction(formSetListComponentsFlagTemplate(this.affectedTpl.documentId, resFields, fieldFlagName, result));

        return result;
      }
    }

    return false;
  }

  loadTplItem(fieldName) {

    if (this.affectedTpl && this.affectedTpl.components) {

      for (let i = 0; i < this.affectedTpl.components.length; i++) {

        const tplItem = this.affectedTpl.components[i];

        if (tplItem.name === fieldName) {
          return tplItem;
        }
      }
    }

    return null;
  }

  refreshComponentData(fieldName) {
    const examDoc = this.determineAffectedDoc(fieldName);
    if (this.affectedDoc) {
      this.superObj.broadcastEvent(SharedConstants.EVENT_REFRESH_COMPONENT_NAME, {
        fieldName: examDoc.docField,
        documentId: this.affectedDoc.documentId
      });
    }
  }
}
