/* eslint-disable no-console */
import { SharedUrlRoutes } from '@formbird/shared';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { UrlBasePathUtil } from '../../utils/UrlBasePathUtil';

async function getServerVersion(): Promise<string> {
  const resp = await fetch(SharedUrlRoutes.serverRoutes.appVersion);
  const json = await resp.json();
  return json.version;
}

export class VersionService {
    static compareVersions(version1: string, version2: string): number {
        const parseVersion = (version) => {
            const [numPart, tagPart] = version.split('-');
            const numbers = numPart.split('.').map(Number);
            const tagNumbers = tagPart ? tagPart.split('.').map(Number) : [];
            return { numbers, tagNumbers, hasTag: !!tagPart };
        };

        const v1 = parseVersion(version1);
        const v2 = parseVersion(version2);

        for (let i = 0; i < Math.max(v1.numbers.length, v2.numbers.length); i++) {
            if ((v1.numbers[i] || 0) < (v2.numbers[i] || 0)) return -1;
            if ((v1.numbers[i] || 0) > (v2.numbers[i] || 0)) return 1;
        }

        if (v1.hasTag && !v2.hasTag) return -1;
        if (!v1.hasTag && v2.hasTag) return 1;

        for (let i = 0; i < Math.max(v1.tagNumbers.length, v2.tagNumbers.length); i++) {
            if ((v1.tagNumbers[i] || 0) < (v2.tagNumbers[i] || 0)) return -1;
            if ((v1.tagNumbers[i] || 0) > (v2.tagNumbers[i] || 0)) return 1;
        }

        return 0;
    }

    // Checks for breaking API changes in the serviceWorker configuration
    static hasBreakingApiChanges(): boolean {
        const { serviceWorker } = globalThis.ftClientConfig || {};
        return serviceWorker?.breakingApiChanges === true;
    }

    // Determines if an update is necessary based on the current app version
    static needsUpdate(appVersion: string): boolean {
        const { serviceWorker } = globalThis.ftClientConfig || {};

        return this.hasBreakingApiChanges()
            && serviceWorker?.minPreviousVersion
            && this.compareVersions(serviceWorker.minPreviousVersion, appVersion) === -1;
    }

    static async checkServerVersion() {
        try {
            console.log('Checking server version.');
            // needs to be inlined here from localStorageService because this is not an angular service
            const appVersion = localStorage.getItem(UrlBasePathUtil.basePath + LocalStorageKeys.APP_VERSION);
            const serverAppVersion = await getServerVersion();
            if (appVersion !== serverAppVersion) {
                if ('serviceWorker' in navigator) { // invalidate service worker
                    try {
                        const registrations = await navigator.serviceWorker.getRegistrations();
                        for (let registration of registrations) {
                            await registration.unregister();
                        }
                        console.log("Service worker unregistered.");

                        if (this.needsUpdate(serverAppVersion)) {
                            console.log("Reloading...");
                            window.location.reload();
                        }
                    } catch (err) {
                        console.error('Error during service worker unregister:', err);
                    }
                }
            }
        } catch (err) {
            console.error("Unable to retrieve server version: " + err.message);
        }
    }
}
