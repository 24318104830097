import { NewDocumentService } from './../../services/document/new-document.service';
import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService, ModifiedFieldService, select, ClientConstants } from '@formbird/services';
import { FormParameters, User } from '@formbird/types';
import { DocumentListService } from '../../services/document/document-list.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'ft-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss']
})
export class MainTemplateComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() params;

  @select(['userState', 'user']) user$: Observable<User>;

  @ViewChild('ngMainCont', { read: ViewContainerRef, static: false }) private viewContainerRef;
  @ViewChild('ngMainTpl', { read: TemplateRef, static: false }) private ngMainTpl: TemplateRef<any>;

  formParameters: FormParameters;
  user: any;
  subs: Subscription = new Subscription();
  queue = new Subject<any>();
  previousUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private documentListService: DocumentListService,
    private modifiedFieldService: ModifiedFieldService,
    private broadcastService: BroadcastService,
    private newDocumentService: NewDocumentService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
  }
  ngAfterViewInit(): void {
    this.renderForm();
  }

  ngOnInit() {

    const subUser = this.user$.subscribe(user => {
      this.user = user;
    });

    const urlClickedSub = this.broadcastService.on(ClientConstants.URL_CLICKED).subscribe((url: string) => {
      if (url && this.previousUrl !== url) {
        this.previousUrl = url;
        this.ngZone.run(() => this.router.navigateByUrl(url));
      }
      this.detectChanges();
    });

    const renewDocSub = this.broadcastService.on(ClientConstants.DOCUMENT_RENEW).subscribe((data: any) => {
      if (this.newDocumentService.isNeedReloadForm()) {
        this.params = {
          id: data?.templateId
        };
        this.renderForm();
        
        this.newDocumentService.resetRenewFlag();
      }
    });

    this.subs.add(subUser);
    this.subs.add(urlClickedSub);
    this.subs.add(renewDocSub);
  }

  renderForm() {
    this.clearViewContainer();
    this.processTemplateViewData();
  }

  clearViewContainer() {
    this.viewContainerRef?.clear();
  }

  processTemplateViewData() {

    // clearing already loaded documents before loading new one to avoid hanging around in the client memory
    this.modifiedFieldService.resetFormDirty();
    this.modifiedFieldService.resetModifiedFields();

    const documentId = this.params?.id;
    if (documentId) {
      // create the unsaved document group. This will be what is used to group these documents together.
      // These documents will be saved together, so you can have different groups of unsaved documents
      // that are saved in different ways. Eg. there will be a document group that is saved on clicking
      // the save button on the navbar. If there is a dialog opened with a document that is saved, it will
      // be in a different document group so it can be saved on closing of the dialog, while still keeping
      // the unsaved changes on the main form
      const mainDocumentListId = this.documentListService.setupDocumentListId(true);

      // the override template id can be specified as the second parameter in the URL:
      // http://localhost/form/<documentId>/<templateId>. See the formTemplateOverride route in
      // SharedUrlRoutes for further details
      const overrideId = this.params?.templateId;
      this.formParameters = {
        overrideTemplateId: overrideId,
        selectedDocumentId: documentId,
        unsavedDocumentListId: mainDocumentListId,
        isMainDoc: true
      };
    }

    this.appendMainTemplate();

    this.resetScroll();
  }

  appendMainTemplate() {

    this.viewContainerRef?.createEmbeddedView(this.ngMainTpl);

    this.detectChanges();
  }

  detectChanges() {
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  resetScroll() {
    (<any>window).document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
  }

}
