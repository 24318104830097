const cleanRelatedDocs = function (docs) {

    // remove _uiSelectChoiceLocked field
    for (let i = 0; i < docs.length; i++) {

        if ('_uiSelectChoiceLocked' in docs[i]) {

            delete docs[i]['_uiSelectChoiceLocked'];
        }
    }
};

const formatValueInDocument = function (tplItem, document) {

    const field = tplItem.name;
    const relatedFields = tplItem.relatedFields;

    if (document[field]) {

        for (let i = 0; i < document[field].length; i++) {
            const value = document[field][i];

            // if the value is in ElasticSearch format, convert it to the required related document format
            if (value._source) {
                const formattedValue = {
                    documentId: value._source.documentId,
                    name: ''
                };

                if (value._source.systemHeader.summaryName) {
                    formattedValue.name = value._source.systemHeader.summaryName;
                }

                if (relatedFields) {

                  const keys = Object.keys(relatedFields);
                  for (let idx = 0; idx < keys.length; idx++) {

                    const key = keys[idx];
                    const val = relatedFields[key];

                    if (value._source[val]) {
                      formattedValue[key] = value._source[val];
                    }
                  }

                }

                document[field][i] = formattedValue;
            }
        }
    }
};

export const RelatedDocumentPreprocessor = {
    preProcess: function (field, document) {
        if (document[field.name]) {
            cleanRelatedDocs(document[field.name]);
            formatValueInDocument(field, document);
        }
    },

    formatValueInDocument: formatValueInDocument
};
