import { UtilArray, UtilHttpStatus } from '@formbird/shared';
import { SharedConstants }from '@formbird/types';
import { filter, chunk, nth } from 'lodash';
import { IndexedDBConstants } from '@formbird/indexed-db';

export const UtilSearch = {
  dexieSearchFunction: function(term, pageNumber?, numberPerPage?) {
      return {

        dexieSearchFunction: async function(db, callback) {
          try {
            
            const searchSummaryNameCriteria = {};
            searchSummaryNameCriteria[IndexedDBConstants.DOCUMENT_INDEX_SUMMARY_NAME] = term;
            searchSummaryNameCriteria[IndexedDBConstants.DOCUMENT_INDEX_] = true;
            
            const searchSummaryNameDescCriteria = {};
            searchSummaryNameDescCriteria[IndexedDBConstants.DOCUMENT_INDEX_SUMMARY_DESCRIPTION] = term;
            
            const filterSumaryNameDocs = await db.documents.where(searchSummaryNameCriteria).toArray();
            const filterSumaryNameDesDocs = await db.documents.where(searchSummaryNameDescCriteria).toArray();
            
            const filterDocs = UtilArray.union(filterSumaryNameDocs, filterSumaryNameDesDocs, function (doc1, doc2) {
              return doc1?.documentId === doc2?.documentId;
            });
            const count = filterDocs?.length;
            
            logger.info("No. of documents found : " + count);
            
            let docs = [];
            if (count){
              let size = numberPerPage || SharedConstants.DEFAULT_PAGE_SIZE;
              let page = pageNumber || SharedConstants.DEFAULT_PAGE;
              const docsArray = chunk(filterDocs, size);
              docs = nth(docsArray, page);
            }


            let results;
            results = {};
            results.total = count;
            results.statusCode = UtilHttpStatus.OK;
            results.data = docs;
            results.searchProvier = 'dexie';
            callback(null, results);
          } catch(err){
            logger.error(err);
            callback(err);
          }
        }


      }
  }

};
