import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';

import {
  DocumentUpdater,
  SharedUrlRoutes,
  UtilDocument,
  UtilDocumentId,
  UtilError,
  UtilHttpStatus,
  UtilType
} from '@formbird/shared';
import * as Handlebars from 'handlebars';

import { LoggedInUserService } from '../user/logged-in-user.service';
import { IndexedDBConnectorService } from '../indexeddb/indexed-dbconnector.service';
import { UtilService } from '../../utils/UtilService';
import { RestDataService } from './rest-data.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { IndexedDBService } from '../indexeddb/indexed-db.service';
import { ClientAccessService } from '../access/client-access.service';
import { Observable } from 'rxjs';
import { User, SharedConstants, FileReferenceDetails, FileReferenceDocument } from '@formbird/types';
import { select } from '../../redux/decorators/select';
import { DocumentSessionService } from '../document/document-session.service';
import { IndexedDBConstants } from '@formbird/indexed-db';
import { OpfsCacheService } from '../opfs/opfs-cache.service';

const serverRoutes = SharedUrlRoutes.serverRoutes;

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // the preferred service will be called before the secondary service. So if caching is enabled the preferred service will be
  // the IndexedDBService. If caching is not enabled the preferred service will be the rest data service
  preferredService: any;
  secondaryService: any;

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;
  isRunningPreviousSync: boolean;

  constructor(
    private indexedDBConnectorService: IndexedDBConnectorService,
    private loggedInUserService: LoggedInUserService,
    private restDataService: RestDataService,
    private offlineStatusService: OfflineStatusService,
    private indexedDBService: IndexedDBService,
    private clientAccessService: ClientAccessService,
    private documentSessionService: DocumentSessionService,
    private opfsCacheService: OpfsCacheService,
  ) {
    if (this.user$){
      this.user$.subscribe((data: User) => this.user = data);
    }
  }

  //
  // // the preferred service. This can be configured to either check the cache for data first or go to the
  // // network first. The service to call first will be set based on the ConfigService.clientConfig.preferCachedData value
  // var preferredService = null;
  //
  // // the secondaryService will be tried if the preferred service fails
  // var secondaryService = null;

  ////////////////////////////////////////
  // local service functions

  /**
   * cache a document for offline use
   * @param docInfo - the document used to load full document to cache
   * @param versions - the versions used to cache
   */
  cacheDocument(docInfo, versions): Promise<any> {

    const _self = this;

    if (_self.offlineStatusService.isCachingEnabled()) {

      if (!versions || !versions.length) {

        return _self.restDataService.loadVersions(docInfo.versionIds).then(
          (versions) => {

            return _self.indexedDBService.cachePushedDocument(docInfo, versions);

          },
          (error) => {

            return Promise.reject(error);

          }
        );
      }

      return _self.indexedDBService.cachePushedDocument(docInfo, versions);

    }

    return Promise.resolve(versions);
  }

  async documentExists(documentId: string, includeDeleted?: boolean): Promise<any> {

    if (!documentId) {
      throw new Error('No documentId specified for check exist!');
    }


    try {

      const onlineFunc: any = this.checkDocumentExistFromServer;
      const oflineFunc: any = this.checkDocumentExistsOfflineMode;

      return await  this.executeDataFunction(onlineFunc, oflineFunc, documentId, includeDeleted);

    } catch (err) {
      throw UtilError.createErrorObject(err);
    }
  }

  /**
   * get a document
   */
  async getDocument(id: string, expectedDocumentType? : string): Promise<any> {

    if (!id) {
      // old code reject this error as string
      // tslint:disable-next-line:no-string-throw
      throw 'No documentId specified for getDocument!';
    }

    try {

      const onlineFunc: any = this.getDocumentFromServer;
      const oflineFunc: any = this.getDocumentOfflineMode;

      const doc = await  this.executeDataFunction(onlineFunc, oflineFunc, id, expectedDocumentType);
      if (doc) {
        if (doc.systemHeader && doc.systemHeader.offlineDetails) {
          delete doc.systemHeader.offlineDetails;
        }
        return cloneDeep(doc);
      } else {
        raiseError(new Error("The device is offline and the document is not set up for offline access"));
      }

    } catch (err) {
      raiseError(err);
    }

    function raiseError(err) {
      let error;
      if (err.status === UtilHttpStatus.FORBIDDEN) {
        error = UtilError.createError(err.message, UtilError.FORBIDDEN_ERROR);
      } else if (err.status === UtilHttpStatus.NOT_FOUND) {
        error = UtilError.createError(err.message, UtilError.NOT_FOUND_ERROR);
      } else if (err.status === UtilHttpStatus.GONE) {
        error = UtilError.createError(err.message, UtilError.DELETED_ERROR);
      } else {
        error = UtilError.createErrorObject(err);
      }
      throw error;
    }
  }

  async getTemplateAndDocument(id: string, templateId?: string) {
    
    console.log(`Getting template and document from id: ${id} and templateId: ${templateId}`);

    try {
      const onlineFunc: any = this.restDataService.getTemplateAndDocument;
      const oflineFunc: any = this.getTemplateAndDocumentOfflineMode;

      return await  this.executeDataFunction(onlineFunc, oflineFunc, id, templateId);
    } catch (error) {
      raiseError(error)
    }

    function raiseError(error) {
      if (error.status <= 0) {
        error.message = `Caught error on Network Suspension: ${error.status}: ${error.statusText}.`;
      }
      throw error;
    }
  }

  /* if offline mode then retrieve the latest version of the document from offline db and name as cachedDoc
   * if the cachedDoc is not yet sent to server then return it;
   * else query the server for the latest versionId of the document
   * if versions don't match, use server's version and perform 'documentReset'
   *
   * DocumentReset will remove all versions of a document
   * and start from the beginning as a single version (that current version from the server)
   *
   * DocumentReset is there primarily there to simplify retrieving documents once there have
   * been version conflicts that have been resolved by the server: when two users modifies
   * a single document at the same time offline. If this do not occur, then documentReset will not happen.
   */
  getDocumentOfflineMode = (id, expectedDocumentType? : string): Promise<any> => {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.indexedDBService.getDocumentCurrentVersion(id,
        (cacheDoc) => { // latest version

          resolve(cacheDoc);

          // Do not retrieve the server's version if this document is still unsent.
          // var unsent = cacheDoc.offlineDetails &&
          //     cacheDoc.offlineDetails.status === IndexedDBConstants.OFFLINE_STATUS_PENDING;
          // if (unsent) {
          //     deferred.resolve(cachedObject);
          //     return;
          // }


          // //let's see if we have conflicts from the server:
          // getDocumentFromServer(id).then((doc) => {
          //     var identicalDocuments = doc.systemHeader.versionId === cachedDoc.systemHeader.versionId;
          //     if (!identicalDocuments) {//version conflict! see 10049 & 10539
          //         IndexedDBService.documentReset(doc.documentId);
          //     //doc here is the latest version from the document that have been resolved by the server from conflict
          //         cacheDocument(doc);
          //     }
          //
          //     deferred.resolve(doc);
          //
          // }, function err(statusCode){
          //     //we could not retrieve from the server due to some error, return the cachedDoc instead
          //     deferred.resolve(cachedDoc);
          //
          // });

        }
      );
    });
  }

   getDocumentFromServer = async (id: string, expectedDocumentType?: string): Promise<any>  => {
    try {
      return await this.restDataService.getDocument(id, expectedDocumentType);
    } catch (error) {
      const offlineMode = this.offlineStatusService.isOfflineMode();
      if (offlineMode && error && error.status === UtilHttpStatus.SERVER_UNRESPONSIVE) {
        error.status = UtilHttpStatus.NOT_FOUND;
      }

      throw error;
    }
  }

  private checkDocumentExistsOfflineMode = async (id: string, includeDeleted?: boolean) => {

    const _self = this;

    let found: boolean = await new Promise((resolve) => {

      _self.indexedDBService.getDocumentCurrentVersion(id, cacheDoc => {
        resolve(!!cacheDoc);
      });

    });

    try {
      if (!found) {
        found = !!(await _self.checkDocumentExistFromServer(id));
      }
    } catch (err) {
      if ([UtilHttpStatus.INTERNAL_SERVER_ERROR, UtilHttpStatus.SERVER_UNRESPONSIVE, UtilHttpStatus.NOT_FOUND].indexOf(err.status) !== -1 ){
        found = false;
      } else {
        throw err;
      }

    }

    return found;
  };

   private checkDocumentExistFromServer = async(id: string, includeDeleted?: boolean) => {

    const _self = this;

    try {
      return await _self.restDataService.checkDocumentExists(serverRoutes.documentExists, id, includeDeleted);
    } catch (error) {
      const offlineMode: boolean = _self.offlineStatusService.isCachingEnabled();

      if (offlineMode && error && error.status === UtilHttpStatus.SERVER_UNRESPONSIVE) {
        error.status = UtilHttpStatus.NOT_FOUND;
        error.message = 'Network connection lost. Could not retrieve.';
      }

      throw error;
    }
  };

  findByElastic(query, searchContext?): Promise<any> {
    const options = {
      filter: query
    };

    return this.search(options, searchContext);
  }

  generatePit() {
    return this.restDataService.generatePit();
  }

  aggregate(query) {
    return this.restDataService.aggregate(query);
  }

  search(options, searchContext): Promise<any> {

    const _self = this;

    return new Promise((resolve, reject) => {
      if (searchContext) {
        const keys = Object.keys(searchContext);
        if (keys && keys.length) {
          for (const key of keys) {
            options[key] = searchContext[key];
          }
        }
      }

      const offlineMode = _self.offlineStatusService.isOfflineMode();
      const isConnected = this.offlineStatusService.isConnected();

      const searchOffline = () => {
        _self.indexedDBService.search(options).then(
          (data2) => {
            resolve(data2);
          },
          (err) => {
            const msg = 'Search by indexedDB failed with error: ' + err;
            logger.error(msg);
            reject(new Error(msg));
          }
        );
      };

      if (options.text || options.filter) {
        if (options.document) {
          const doc = {
            document: options.document,
            account: _self.user.account
          };

          if (!UtilType.isString(options.filter)) {
            options.filter = JSON.stringify(options.filter);
          }

          if (!options?.disableHandlebars) {
            const hbTemplate = Handlebars.compile(options.filter);
            options.filter = hbTemplate(doc);
          }

          delete options.document;
        }

        if (options.searchOfflineOnly) {
          if (!offlineMode) {
            reject(new Error('Please enable caching before performing a search configured as offline only'));
          } else {
            searchOffline();
          }
        } else if (offlineMode && !isConnected) {
          searchOffline();
        } else {
          _self.restDataService.search(options).then(
            (data: any) => {

              if (!data.statusCode && data.statusCode !== UtilHttpStatus.OK && offlineMode) {
                searchOffline();
              } else {
                resolve(data);
              }

            }, (err) => {

              if (offlineMode) {
                searchOffline();
              } else {
                reject(err);
              }
            }
          );
        }

      } else if (options.searchOptions && options.searchOptions.dexieSearchFunction && offlineMode) {
        searchOffline();
      } else {

        const er = UtilError.createError('Undefined search text or query!');
        reject(er);

      }
    });
  }

  /** initialise the service passed in and return a promise for initialisation completion
   *
   * @param service - the service to initialise
   */
  initialiseService(service) {
    if (service && service.initialise) {
      return service.initialise();
    }

    // no initialise function in service
    return Promise.resolve();
  }

  terminateService(service): Promise<any> {
    if (service && service.terminateService) {
      return service.terminateService();
    }

    // no terminateService function in service
    return Promise.resolve();
  }

  /** set the versionId and previousVersionId fields */
  setVersionFields(document) {
    if (document && document.systemHeader) {
      if (document && document.systemHeader.versionId) {
        document.systemHeader.previousVersionId = document.systemHeader.versionId;

        document.systemHeader.versionId = UtilDocumentId.generateId();

        // update createdBy every time because when we save the doc it's a new version
        document.systemHeader.createdBy = this.user?.account?.documentId;
      }
    }
  }

  private hasOfflineAccess(document) {
    return this.clientAccessService.hasPermission(document, SharedConstants.OPERATION_TYPE_OFFLINE);
  }

  private isAccountDoc(doc) {
    return doc.systemHeader && (
      doc.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_ACCOUNT ||
      doc.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_ACCOUNT_SECURITY ||
      doc.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_ACCOUNT_CONTROL);
  }

  async jsonPatchUpdate(originalBean, newBean, updateOption?) {
    const patchedVersionId = originalBean.systemHeader?.versionId;
    // set the details like the versionId and previousVersionId
    this.setVersionFields(newBean);

    // set the systemHeader.createdDate in the new version. The systemHeader.createdDate is the created date of the
    // version, rather than the document, so each version needs to have a new createdDate. See Mantis 10816 for further
    // details
    newBean.systemHeader.createdDate = Date.now();

    // always use the Database Service if caching is enabled, so that the items will be placed in the
    // queue to be sent to the server, otherwise items can be saved in the wrong
    // order if there is anything already in the queue
    const updates = await DocumentUpdater.getUpdates(originalBean, newBean);
    const options: any = {
      updates: updates,
      isUpdateFromPreviousVersion: UtilDocument.isPreviousVersion(newBean),
      overrideTemplateId: updateOption ? updateOption.overrideTemplateId : null,
      template: updateOption ? updateOption.templateId : null,
      makeCurrentVersion: updateOption ? updateOption.makeCurrentVersion : null,
      importPreviousVersion: updateOption ? updateOption.importPreviousVersion : null,
      documentSession: this.documentSessionService.getDocumentSession(originalBean.documentId),
      patchedVersionId
    };

    const shouldSaveOffline  = await this.shouldSaveOffline(originalBean, false, newBean);
    if (shouldSaveOffline ) {
      const doc = this.indexedDBService.jsonPatchUpdate(newBean, originalBean, options);
      if (!this.hasOfflineAccess(newBean)) {
        this.indexedDBService.documentReset(originalBean.documentId);
      }
      return doc
    }

    return this.restDataService.jsonPatchUpdate(newBean, options);
  }


  /**
   * Called in CacheLoadService.enable cache to re-arrange primary and secondary data service
   */
  initialise() {

    const _self = this;

    const offlineMode = _self.offlineStatusService.isOfflineMode();
    _self.preferredService = offlineMode ? _self.indexedDBService : _self.restDataService;
    _self.secondaryService = offlineMode ? _self.restDataService : null;

    return _self.initialiseService(_self.preferredService).then(() => {
      return _self.initialiseService(_self.secondaryService);
    });
  }

  // terminateService DataServices on logout. See InitialisationService.terminateDefaultServices
  reset() {

    const _self = this;

    return _self.terminateService(_self.preferredService).then(() => {
      return _self.terminateService(_self.secondaryService);
    }).then(() => {
      // RestDataService is necessary for login.
      // Make RestDataService as the preferred service and reset.
      _self.preferredService = _self.restDataService;
      return _self.initialiseService(_self.preferredService);
    });
  }

  /*
  jsonPatchUpdate function
  parameters:
  bean - the bean to update
  cachingRequired - whether to save the record to the local database. Records that never need
  to be loaded in the app on their own, such as events, can have this set to false
  */
  deepDiffUpdate(originalBean, newBean, options?) {
    return this.jsonPatchUpdate(originalBean, newBean, options);
  }

  /*
   insert function
   parameters:
   bean - the bean to insert
   successFunction - a function to execute on success to do post save steps
   cachingRequired - whether to save the record to the local database. Records that never need
   to be loaded in the app on their own, such as events, can have this set to false
   */
  async insert(bean, cachingRequired?): Promise<any> {
    // set the details like the versionId and previousVersionId
    this.setVersionFields(bean);

    // a different version of the data service will be used depending on whether offline is enabled.
    // assign the service that is to be used rather than the function because assigning a function that is a member of
    // a class means the this object of the function will not be defined
    let dataSaveService;

    try {
      const shouldSaveOffline  = await this.shouldSaveOffline(bean, true);
      dataSaveService = shouldSaveOffline ? this.indexedDBService : this.restDataService;

      const fullUrl = UtilService.getDataUrl(serverRoutes.document);

      return await dataSaveService.insert(bean, fullUrl, cachingRequired);
    } catch (error) {
      throw error;
    }
  }

  async destroy(id, templateId?, versionId?): Promise<any> {

    const document = await this.getDocument(id);

    try {
     
      const shouldSaveOffline = await this.shouldSaveOffline(document, false);
      if (shouldSaveOffline) {
        return await this.indexedDBService.destroy(id);
      } else {
        return await this.restDataService.destroy(id, serverRoutes.document, null, templateId, versionId);
      }
    } catch (err) {
      const message = typeof err === 'string' ? err : err.message; 
      logger.log('Delete failed with error: ' + message);
      throw new Error(message);      
    }
  }

  /**
   * load unsynced and cache documents into indexedDB
   */
  workerLoadDataCache(): Promise<any> {

    const _self = this;
    if (!_self.offlineStatusService.isCachingEnabled() || this.isRunningPreviousSync) {
      return Promise.resolve();
    }

    let syncStartTime = _self.offlineStatusService.getLastSyncDate();
    if (!syncStartTime) {
      syncStartTime = 0;
      logger.log('Loading data cache from beginning of time');
    } else {
      logger.log('Loading data cache from time: ' + syncStartTime);
    }



    this.isRunningPreviousSync = true;
    _self.loggedInUserService.setUserConfigItem('initialCacheCompleted', false);

    this.offlineStatusService.offlineStatus.cacheLoading = true;

    return _self.restDataService.workerLoadDataCache(syncStartTime, true).then(async (results) => {
      logger.log('Done caching offline documents. Adding any search indexes from indexedDBIndexes.');
      await _self.loadFileAttachmentCache();
      _self.loggedInUserService.setUserConfigItem('ErrorCachingAttachedFiles',
        this.offlineStatusService.offlineStatus.errorCacheAttachedFile);
      _self.loggedInUserService.setUserConfigItem('initialCacheCompleted', true);

      // registers search indexes
      return _self.indexedDBConnectorService.upgradeIndexedDB().then(
        () => {
          return Promise.resolve(results);
        },
        (err) => {
          return Promise.reject(err);
        }
      );

    }, (err) => {

      logger.error('Error caching offline documents.');
      logger.error(err);

      this.isRunningPreviousSync = false;
      return Promise.reject(err);
    }).then((results) => {

      logger.log('Done registering search indexes.');

      this.isRunningPreviousSync = false;
      return Promise.resolve(results);

    }, (err) => {

      logger.error('Error in registering search indexes.');
      logger.error(err);

      this.isRunningPreviousSync = false;
      return Promise.reject(err);

    });
  }

  /**
   * load unsynced and cache documents into indexedDB
   * @param isInitialize - whether in the case the documents are cached in the first time
   */
  loadDataCache(isInitialize?): Promise<any> {

    const _self = this;

    if (!_self.loggedInUserService.getUserConfigItem('initialCacheCompleted')) {
      _self.offlineStatusService.setInitialCachingComplete(false);
      _self.workerLoadDataCache();
      return Promise.resolve();
    }

    if (!_self.offlineStatusService.isCachingEnabled() || this.isRunningPreviousSync) {
      return Promise.resolve();
    }

    let syncStartTime = _self.offlineStatusService.getLastSyncDate();
    if (!syncStartTime) {
      syncStartTime = 0;
      logger.log('Loading data cache from beginning of time');
    } else {
      logger.log('Loading data cache from time: ' + syncStartTime);
    }

    this.isRunningPreviousSync = true;

    return _self.restDataService.workerLoadDataCache(syncStartTime, isInitialize).then((results) => {
      logger.log('Done caching offline documents. Adding any search indexes from indexedDBIndexes.');

      // registers search indexes
      return _self.indexedDBConnectorService.upgradeIndexedDB().then(
        () => {
          return Promise.resolve(results);
        },
        (err) => {
          return Promise.reject(err);
        }
      );

    }, (err) => {

      logger.error('Error caching offline documents.');
      logger.error(err);

      this.isRunningPreviousSync = false;
      return Promise.reject(err);
    }).then((results) => {

      logger.log('Done registering search indexes.');

      this.isRunningPreviousSync = false;
      return Promise.resolve(results);

    }, (err) => {

      logger.error('Error in registering search indexes.');
      logger.error(err);

      this.isRunningPreviousSync = false;
      return Promise.reject(err);

    });
  }

  async clearData() {

    const _self = this;

    if (_self.offlineStatusService.isCachingEnabled()) {
        await _self.indexedDBService.clearData();
        await this.opfsCacheService.clearData();
        await _self.indexedDBConnectorService.closeDatabase();
        return Promise.resolve(true);
    } else {
      return Promise.reject(new Error('You did not enable caching.'));
    }
  }

  backupData(isFirstTime?) {

    const _self = this;

    return new Promise((resolve, reject) => {
      if (_self.offlineStatusService.isCachingEnabled()) {
        _self.indexedDBService.backupData().then(resolve, reject);
      } else {
        reject(new Error('You did not enable caching.'));
      }
    });
  }

  getIndexedDBIndexes() {
    return this.restDataService.getIndexedDBIndexes();
  }

  async undestroy(id: string): Promise<any> {

    if (this.offlineStatusService.isCachingEnabled()) {

      const errMsg = 'You need to be online to undelete a document. ';
      logger.error(errMsg);
      throw new Error(errMsg);

    } else {

      const url = 'api/undelete';
      try {
        return await this.restDataService.undestroy(id, url);
      } catch (err) {
        logger.error('Delete failed with error: ' + err);
        throw new Error(err);
      }
    }
  }

  async bulkUploadDocuments(documents): Promise<any> {

    if (this.offlineStatusService.isCachingEnabled()) {

      const errMsg = 'You need to be online to upload multiple documents. ';
      console.error(errMsg);

      throw new Error(errMsg);

    } else {

      const url = 'api/bulkUploadDocuments';

      try {

        return await this.restDataService.bulkUploadDocuments(documents, url);

      } catch (err) {

        console.error('Upload documents failed with error: ' + err);
        throw new Error(err);

      }
    }
  }

  executeComponentFunction(options): Promise<any> {
    const _self = this;

    return new Promise((resolve, reject) => {

      const offlineMode = _self.offlineStatusService.isOfflineMode();


      if (options.componentWebServiceName) {

        const searchOffline = () => {
          _self.indexedDBService.executeComponentFunction(options).then(
            (data2) => {
              resolve(data2);
            },
            (err) => {
              reject(err);
            }
          );
        };

        if (options.searchOfflineOnly) {
          if (!offlineMode) {
            reject(new Error('Please enable caching before performing a search configured as offline only'));
          } else {
            searchOffline();
          }
        } else {
          _self.restDataService.executeComponentFunction(options).then(
            (data: any) => {

              if (!data.statusCode && data.statusCode !== UtilHttpStatus.OK && offlineMode) {
                searchOffline();
              } else {
                resolve(data);
              }

            }, (err) => {

              if (offlineMode) {
                searchOffline();
              } else {
                reject(err);
              }
            }
          );
        }

      } else {

        const er = UtilError.createError('Undefined executeComponentFunction componentWebServiceName and functionParameters!');
        reject(er);

      }

    });
  }

  getTemplateAndDocumentOfflineMode = (id, templateId): Promise<any> => {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.indexedDBService.getTemplateAndDocument(id, templateId,
        async (err, cacheDoc) => { // latest version

          if (cacheDoc == null) { // not found in offline? retrieve from server
            try {
              const result = await this.restDataService.getTemplateAndDocument(id, templateId);
              resolve(result);
            } catch (error) {
              const offlineMode = _self.offlineStatusService.isOfflineMode();
              if (offlineMode && error && error.status === UtilHttpStatus.SERVER_UNRESPONSIVE && err) {
                error.status = UtilHttpStatus.NOT_FOUND;
                error.message = err.message;
              }
              reject(error);
            }
          } else {
            resolve(cacheDoc);
          }
        }
      );
    });
  }


  async loadFileAttachmentCache(){

    const tbl = IndexedDBConstants.DOCUMENT_TABLE_NAME;
    const index = IndexedDBConstants.DOCUMENT_INDEX_SYSTEM_TYPE;
    const searchValue = 'fileReference';

   return new Promise((resolve, reject) => {
      this.indexedDBService.getEntries(tbl, index, searchValue, async(documents) => {
        const promises = [];
        this.offlineStatusService.offlineStatus.maxCacheAttachedFileCount = documents.length;
        this.offlineStatusService.publishOfflineStatus();
        for(let doc of documents) {
          for (const details of doc.fileDetails) {
            promises.push(await this.cacheAttachedFilesOffline(details));
          }
          this.offlineStatusService.offlineStatus.currentCacheAttachedFileCount++;
          this.offlineStatusService.publishOfflineStatus();
        }
        resolve(await Promise.all(promises));
      });
    });

  }

  /**
   restFunc: will pass RestDataService function
   offlineFunc: will pass IndexedDBService function
   args: will pass the arguments object described at https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
   */
  executeDataFunction = async (onlineFunc, offlineFunc, ...args) => {
    const offlineMode = this.offlineStatusService.isOfflineMode();
    const isConnected = this.offlineStatusService.isConnected();

    try {
      if (offlineMode && !isConnected) {
        return await offlineFunc(...args);
      } else {
        const returnObj = await onlineFunc(...args);
        if (offlineMode && isConnected && returnObj) {
          // cache offline documents when they are fetched from server
          if (returnObj.document && this.hasOfflineAccess(returnObj.document)) {
            await this.indexedDBService.cacheDocument(returnObj.document, true);
          }
          if (returnObj.template && this.hasOfflineAccess(returnObj.template)) {
            await this.indexedDBService.cacheDocument(returnObj.template, true);
          }

          if (returnObj.documentId && this.hasOfflineAccess(returnObj)) {
            await this.indexedDBService.cacheDocument(returnObj, true);
          }
        }
        return returnObj;
      }
    } catch (err) {
      if (offlineMode) {
        return await offlineFunc(...args);
      } else {
        throw err;
      }
    }
  }

  async loadComponentDoc(directiveName) {
    
    try {

      return await this.restDataService.loadComponentDoc(directiveName);

    } catch (e) {

      const offlineMode = this.offlineStatusService.isOfflineMode();

      if (offlineMode) {

        return await this.indexedDBService.loadComponentDoc(directiveName);
      
      }
    
    }
  }

  private async cacheAttachedFilesOffline(file: FileReferenceDetails) {
    try {
      const blob = await this.restDataService.loadFile(file.fileNo);
      await this.opfsCacheService.cacheFile(file, blob);
      await this.indexedDBService.saveFile(file);
    } catch (e) {
      console.warn(`Can not cache file: ${file.fileName}. Error: `, e?.message);
    }

  }

  private shouldSaveOffline = (bean, isInsert?, newBean?) => {
      return new Promise(async (resolve, reject) => {

        let hasOfflineAccess = this.hasOfflineAccess(bean);
        if (isInsert && hasOfflineAccess && bean.systemHeader && bean.systemHeader.templateId) {
          const template = await this.getDocument(bean.systemHeader.templateId, SharedConstants.SYSTEM_TYPE_TEMPLATE);
          hasOfflineAccess = this.hasOfflineAccess(template);
        }

        if (this.offlineStatusService.isOfflineMode() && hasOfflineAccess && !this.isAccountDoc(newBean || bean)) {
            const pendingSyncCount: any = await this.indexedDBService.getUnprocessedDocsCount();
            if (pendingSyncCount < 1 && this.offlineStatusService.isConnected() ) {
              resolve(false);
            } else {
              resolve(true);
            }           
        } else {
          resolve(false);
        }
       
      });
  }

}
