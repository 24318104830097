<div class="not-found-wrap mat-body">
    <div class="main">
        <div class="error-template">
            <h1>Page Not Found</h1>
            <div class="error-details">
                <p>Sorry, an error has occured, Requested page not found!</p>
            </div>
            <div class="error-actions">
                <button type="button" (click)="changeState()"  mat-raised-button color="primary"><mat-icon aria-hidden="false" aria-label="home icon">home</mat-icon>
                    Take Me Home</button>                
               
            </div>
        </div>



    </div>
    
        
     
</div>