import { BroadcastEvent } from '@formbird/types';
import { Observable, ReplaySubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export class BroadcastService {
  private _eventBus: ReplaySubject<BroadcastEvent>;

  constructor() {
    this._eventBus = new ReplaySubject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({ key, data });
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus
      .pipe(filter(event => event.key === key))
      .pipe(map(event => <T>event.data));
  }
}
