import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, DocumentSaveService, BroadcastService, ModifiedFieldService, NavigationService, DocumentService } from '@formbird/services';
import { ModalService } from '@services/modal/modal.service';
import { Subscription } from 'rxjs';
import { UtilHttpStatus } from '@formbird/shared';


@Component({
  selector: 'ft-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TemplateViewComponent implements OnInit, OnDestroy {

  private subParam: Subscription;

  noNavBar = false;

  public params: any;
  public renderForm: boolean = false;
  public hideTopBar: boolean;
  public fullWindow: boolean;

  public initialized = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private modifiedFieldService: ModifiedFieldService,
    private documentSaveService: DocumentSaveService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {

    this.subParam = this.route.params.subscribe(async (params: any) => {
      try {

        this.renderForm = false;
        this.params = params;
        
        console.log('Route changes detected: ', params);

        const { id, templateId } = params;
        let {document, template, templateReturnStatus, templateError } = await this.documentService.getTemplateAndDocument(id, templateId);
        if ( templateReturnStatus !== UtilHttpStatus.OK ){
          console.error('Error getting template: ' + templateError.message ? templateError.message : templateError);
        } else if(template) {
          this.hideTopBar = template.hideTopBar;
          this.fullWindow = template.fullWindow;
          this.noNavBar = template.noNavBar;
          
          this.navigationService.setCurrentTemplate(template);

          if (document) {
            const documentId = document.documentId;
            const tplId = template.documentId;
            if (document.systemHeader.templateId !== documentId) {
              this.documentService.setAltTemplateInfo(documentId, tplId);
            }
          }
        }

        console.log('Data loaded from route changes.');

      } catch (error) {
        console.log(error);
      } finally {
        this.renderForm = true;
        this.initialized = true;
        this.detectChanges();
      }

    });
  }

  detectChanges() {
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subParam.unsubscribe();
  }

  canDeactivate(nextState) {
    if (this.modifiedFieldService.isDirty()) {
      const succFunc = async () => {
        const unsavedDocumentListIds = this.modifiedFieldService.getAllChangedDocumentListIds();
        const promises = [];
        for (let i = 0; i < unsavedDocumentListIds.length; i++) {
          promises.push(this.documentSaveService.save(unsavedDocumentListIds[i]));
        }

        try {
          await Promise.all(promises);
          this.router.navigate([nextState.url]);
        } catch (error) {
          console.log(error.message);
        }
      };

      const discardFunc = () => {
        this.modifiedFieldService.resetFormDirty();
        this.router.navigate([ nextState.url ]);
      };

      const cancelFunc = () => {
        console.log('Cancelling navigation due to unsaved changes.');
      };

      this.modalService.showDialog('Save Changes?', 'WARNING', succFunc, cancelFunc, discardFunc);

      return false;
    }

    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.modifiedFieldService.isDirty()) {
      $event.returnValue = true;
    }
  }

}
