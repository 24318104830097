
import { Injectable } from '@angular/core';
import { ModalService } from '@services/modal/modal.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class ListenServiceWorkerService {


  constructor(
    private modalService: ModalService
  ) {


  }

  listenServiceWorkerMessage() {

    if (navigator.serviceWorker) {

      document.addEventListener('service-worker-update-found', (evtData:any) => {

        this.modalService.showDialog('A new version of the Formbird application is available.  ' +
          'Do you wish to upgrade?', 'WARNING',
          () => {
            evtData.detail.newSW.postMessage('InstallingUpdatedServiceWorker');
          },
          (err) => {
            evtData.detail.newSW.postMessage('CancelUpdatedServiceWorker');
        });

      }, false);

    }

  }
}
