import { Injectable } from '@angular/core';
import { cloneDeep, extend } from 'lodash';
import { ModifiedFieldService } from './modified-field.service';
import { SharedConstants } from '@formbird/types';

@Injectable({
  providedIn: 'root'
})
export class FinaliseValueFieldService {

  private finaliseValueFunctions: any = {};

  constructor(
    private modifiedFieldService: ModifiedFieldService
  ) {
  }

  private ensureTemplateExist(templateId) {
    if (!this.finaliseValueFunctions[templateId]) {
      this.finaliseValueFunctions[templateId] = {};
    }
  }

  addFinaliseValueFunction(templateId, fieldName, func) {

    this.ensureTemplateExist(templateId);

    this.finaliseValueFunctions[templateId][fieldName] = func;
  }

  getFinaliseValueFunction(templateId, fieldName) {

    this.ensureTemplateExist(templateId);

    return this.finaliseValueFunctions[templateId][fieldName];
  }

  finaliseFieldValues(document, template) {

    if (document && this.modifiedFieldService.hasChanged(document.documentId)) {

      for (let t = 0, len = template.components.length; t < len; t++) {

        const fld = template.components[t];

        const finaliseFunc = this.getFinaliseValueFunction(template.documentId, fld.name);
        if (finaliseFunc) {
          finaliseFunc(document);
        }
      }
    }
  }
}
