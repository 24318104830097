import { Component, Inject, ViewEncapsulation, ApplicationRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ft-dialog-component',
  templateUrl: 'ft-dialog-component.html',
  styleUrls: ['ft-dialog-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent {
  color: string;
  ftbtnclass: string;
  inputValue: any;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    private app: ApplicationRef,
    @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    // confirm OK
    if (data.confirmButton && data.messageType === 'error') {
      this.color = 'warn';
    } else if (data.confirmButton && data.messageType === 'ft-about-us') {
      this.color = 'primary';
      this.ftbtnclass = 'ft-btn-about';
    } else if (data.confirmButton && data.messageType === 'success') {
      this.color = 'accent';
    } else if (data.confirmButton && data.messageType === 'warning') {
      this.color = 'warning';
    } else if (data.confirmButton && data.messageType === 'question') {
      this.color = 'question';
    }

    // success OK
    if (data.succFunc && data.messageType === 'warning') {
      this.color = 'warning';
    } else if (data.succFunc && data.messageType === 'null') {
      this.color = 'primary';
    }

    // cancel
    if (data.errorFunc && data.messageType === 'warning') {
      this.color = 'warning';
      this.ftbtnclass = 'ft-btn-cancel';
    }

    if (data.input) {
      this.inputValue = data.inputValue;
    }

    this.app.tick();
  }

  cancel() {
    if (this.data.errorFunc) {
      this.data.errorFunc();
    }

    this.close();
  }

  discard() {
    if (this.data.discardFunc) {
      this.data.discardFunc();
    }

    this.close();
  }

  async ok() {
    if (this.data.succFunc) {
      try {
        await this.data.succFunc();
      } catch (e) {
        console.error('Error calling success function: ', e);
      }
    }

    this.dialogRef.close(this.inputValue);
  }

  protected close(arg?) {
    this.dialogRef.close(arg);
    this.app.tick();
  }

  onEnter(event) {
    event.preventDefault();
    if (this.data.allowEnterKey !== false) { // default to true
      this.ok();
    }
  }

}
