import { Injectable } from '@angular/core';
import { createCustomElement } from '@angular/elements';

@Injectable({
  providedIn: 'root'
})

export class CustomElementService {

  constructor() {
  }

  convertToCustomElement = (component, selector, injector) => {
    if (!customElements.get(selector)) {
      const scComponent = createCustomElement(component, {  injector: injector });
      customElements.define(selector, scComponent);
    }
  };
}
