import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component, ElementRef, Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  ViewRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  AppStore,
  BroadcastService,
  ChangedDocumentService,
  ClientConstants,
  ComponentService,
  ConfigService,
  CurrentDocumentInfoService,
  CurrentDocumentService,
  DocumentService,
  DocumentSessionService,
  FormColorService,
  IApplicationState,
  LoggedInUserService,
  ModifiedCSSService,
  NavigationService,
  NotificationService,
  select,
  ValidationService,
  LocalStorageKeys,
  LocalStorageService,
  ModifiedFieldService,
  formSetDocument,
  DocumentSaveService,
  formDeleteDocument,
  DataService,
  OfflineStatusService
} from '@formbird/services';
import { ComponentDocument, DocumentData, RulesetDocumentContext, SharedConstants, User } from '@formbird/types';
import { ImageLinkService } from '@services/image-link.service';
import * as async from 'async';
import * as lodashCloneDeep from 'lodash.clonedeep';
import * as lodashArray from 'lodash/array';
import * as lodashString from 'lodash/string';
import { Observable, Subscription } from 'rxjs';
import { UtilType } from '@formbird/shared';
import { RouterService } from '../../../app-routing/router.service';
import { ModalService } from '@services/modal/modal.service';

const logger = console;

@Component({
  selector: 'ft-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      state('shown', style({ 'background-image': 'url("{{bgImageUrl}}")',
        'background-size' : 'auto 100%',
        'background-repeat': 'no-repeat',
        'padding-top': '3%',
        'padding-bottom': '3%',
        'width': '25vw',
        'height': '25vw',
        'background-position': 'center center',
        'margin': 'auto',
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'bottom': '0',
        'right': '0',


        opacity: 0.1}),{params: {bgImageUrl: '/images/loading.gif'}}
      ),state('disable', style({
        opacity: 1})
      ),
      transition('shown => hidden', [
        animate('0s', style({
          opacity: 1}))
      ]),

    ])
  ]
})
export class TemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formParameters;
  @Input() user: User;
  @ViewChild('ngCont', { read: ViewContainerRef }) viewContainerRef;
  @ViewChild('ngTpl', { read: TemplateRef }) ngTpl: TemplateRef<any>;

  componentIndex: number;
  documentData: DocumentData;
  responsiveLayouts: any;
  componentsRegistered = false;
  errorMsg: string;
  isDeleted: boolean = false;
  private subs: Subscription = new Subscription();
  components: any;
  isOffline = false;
  showNetworkMsg = false;
  state = 'shown';
  functionComponentData = {};
  animationBGImageUrl: any;
  @select(['formState', 'documents']) documents$: Observable<any>;
  @select(['formState', 'templates']) templates$: Observable<any>;
  @select(['formState', 'documentInfo']) documentInfo$: Observable<any>;
  @select(['formState', 'recordedComponentTemplateChanges']) recordedComponentTemplateChanges$: Observable<any>;

  constructor(
    private formColorService: FormColorService,
    private imageLinkService: ImageLinkService,
    private currentDocumentInfoService: CurrentDocumentInfoService,
    private currentDocumentService: CurrentDocumentService,
    private configService: ConfigService,
    private modifiedCSSService: ModifiedCSSService,
    private navigationService: NavigationService,
    private componentService: ComponentService,
    private documentService: DocumentService,
    private validationService: ValidationService,
    private titleService: Title,
    private broadcastService: BroadcastService,
    private loggedInUserService: LoggedInUserService,
    private appStore: AppStore<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private changedDocumentService: ChangedDocumentService,
    private documentSessionService: DocumentSessionService,
    private el: ElementRef,
    private notificationService: NotificationService,
    private modifiedFieldService: ModifiedFieldService,
    private localStorageService: LocalStorageService,
    private routerService: RouterService,
    private documentSaveService: DocumentSaveService,
    private modalService: ModalService,
    private dataService: DataService,
    private offlineStatusService: OfflineStatusService
  ) {
    this.componentIndex = 0;
  }

  async loadData() {
    this.componentsRegistered = false;
    this.errorMsg = null;

    if (this.loggedInUserService.getUserConfigItem('isRunningCacheClear')){
        await this.clearCache();
    }

    if (this.formParameters && this.formParameters.selectedDocumentId) {

      console.log('Loading html for document/template: ' + this.formParameters.selectedDocumentId);

      const unsavedDocumentListId = this.formParameters.unsavedDocumentListId;
      if (!unsavedDocumentListId) {
        this.errorMsg = 'An unsaved document group id must be passed to load data.';
        return;
      }


      this.documentData = this.currentDocumentInfoService.getDocumentData(unsavedDocumentListId, this.formParameters.selectedDocumentId, this.formParameters.overrideTemplateId);

      if (this.documentData && this.documentData.template && this.documentData.template.enableTemplateLoadAnimation) {
        this.state = !this.documentData.template.enableTemplateLoadAnimation ? 'disable' : 'shown';
      } else {
        this.state =  !this.configService.clientConfig().enableTemplateLoadAnimation ? 'disable' : 'shown';
      }

      if (this.documentData && this.formParameters.defaultFields) {
        this.documentData.document = lodashCloneDeep(this.formParameters.defaultFields);
      }

      // formParameters.overrideTemplateId will only be set if there is an override template id set in the URL
      this.documentService.getTemplateForDocument(this.formParameters, this.documentData).then(
          async (result) => {

          if (this.formParameters.isMainDoc) {

            this.currentDocumentService.setMainDocumentListId(unsavedDocumentListId, this.documentData.documentId);
          }

          this.isOffline = false;

          const isEnableAnimation = this.documentData.template.enableTemplateLoadAnimation;
          if (this.documentData.template && typeof(isEnableAnimation) !== 'undefined' && isEnableAnimation !== null ) {
            this.state = !isEnableAnimation ? 'disable' : 'shown';
          } else {
            this.state =  !this.configService.clientConfig().enableTemplateLoadAnimation ? 'disable' : 'shown';
          }
          this.animationBGImageUrl = this.documentData.template.formLoadingImageURL || this.getDefaultAnimationLoadImage();

          if (this.documentData.template && this.documentData.template.documentId) {

            this.addDisableSaveFieldValues();

            this.setDocumentTitle();

            this.setFormLayout();

            await this.componentService.loadTemplateVendorLibs(this.documentData.template);
            
            this.setWatchComponentTemplate();
            
            this.documentSessionService.initialize(this.documentData.document.documentId);
            
            await this.doPreRender();

            if (this.documentData.template.loadComponentDocumentDependencies === false){
              this.appendComponents();

            } else{
              await this.registerAndAppendComponents();
            }

            this.componentsRegistered = true;

            this.updateTemplateSettings();

            this.doOnLoad();

            this.restoreWIPDocument(result, unsavedDocumentListId);

          } else {
            const msg = 'Template not found: ' + this.formParameters.selectedDocumentId;
            this.updateError(msg);
          }
        },
        err => {
          if (err.status === 0) {
            this.isOffline = true;
          }

          this.handleLoadError(err);
        });

    } else {
      this.currentDocumentService.resetDocumentData(this.formParameters?.unsavedDocumentListId);
      this.updateError('No selected document to load.');
    }
  }

  ngOnInit() {
    if (!this.user) {
      this.user = this.loggedInUserService.getLoggedInUser();
    }

    this.loadData();

    const formChangeSub = this.broadcastService.on(ClientConstants.FORM_DETECT_CHANGES).subscribe(data => {
      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });

    const documentSub = this.documents$.subscribe(documents => {
      if (this.documentData) {
        const documentId = this.documentData.documentId;
        const document = documents[documentId];

        if (document) {
          this.documentData.document = lodashCloneDeep(document);
        }
      }
    });

    const templateSub = this.templates$.subscribe(templates => {
      if (this.documentData) {
        const templateId = this.documentData.templateId;
        const template = templates[templateId];

        if (template) {
          this.documentData.template = lodashCloneDeep(template);
        }
      }
    });

    this.subs.add(formChangeSub);
    this.subs.add(documentSub);
    this.subs.add(templateSub);

    (<any>window).addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.broadcastService.broadcast(ClientConstants.BEFORE_INSTALL_PROMPT, e);
    });


  }

  ngOnChanges(data) {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }

    // only reload component if there have changes from formParameters
    if (data?.formParameters?.previousValue) {
      this.loadData();
    }
  }

  private handleLoadError(err) {
    this.updateError(err);

    this.currentDocumentService.resetDocumentData(this.formParameters?.unsavedDocumentListId);
    this.broadcastService.broadcast(ClientConstants.DOCUMENT_LOAD_ERROR, true);
  }

  private updateError(err) {
    this.errorMsg = err?.message ? err.message : err;

    if (this.errorMsg?.includes('Unknown Error') || this.errorMsg?.includes('Network Error')) {
      this.errorMsg = '';
      this.showNetworkMsg = true;
    }

    logger.log(this.errorMsg);

    this.setFormLayout();
  }

  private setFormLayout() {
    if (this.formParameters.isMainDoc) {
      const tplData = this.documentData.template;
      const docData = this.documentData.document;

      this.formColorService.setColor(tplData?.formColor ? tplData?.formColor : '');

      if (tplData?.commandBarCSS) {
        this.modifiedCSSService.setCommandBarCSS(tplData?.documentId, tplData?.commandBarCSS);
      }

      if (docData && docData.systemHeader) {
        const systemHeader: any = docData.systemHeader;
        this.imageLinkService.setImageFileNo96(systemHeader.imageFileNo96);
      }
    }
  }

  /**
   * set the document title. This will display at the top of the tab or window
   */
  setDocumentTitle() {
    
    if (!this.formParameters.isMainDoc) {
      return;
    }

    const document = this.documentData.document;
    const template = this.documentData.template;
    const title = document.systemHeader.summaryName 
              || template.systemHeader.summaryName 
              || this.configService.clientConfig().browserDocumentTitle 
              || SharedConstants.APP_NAME;

    const prefix = this.localStorageService.getItem(LocalStorageKeys.TITLE_PREFIX) || '';
    this.localStorageService.removeItem(LocalStorageKeys.TITLE_PREFIX);

    this.titleService.setTitle(prefix + title);
  }

  private updateTemplateSettings() {
    if (this.formParameters.isMainDoc) {
      this.navigationService.setCurrentTemplate(this.documentData.template);
    }
  }

  private async doPreRender() {
    const docContext: RulesetDocumentContext = {
      documentData: this.documentData,
      template: this.documentData.template,
      templateId: this.documentData.template.documentId,
      formParameters: this.formParameters,
      isNew: this.documentData?.isNew
    };

    return new Promise((resolve, reject) => {
      this.validationService.preRenderDocument(this.documentData.document, docContext,
        (error, result) => {
          if (error) {
            reject(error);
          } else {
            this.validationService.broadcastValidationEvent();
            resolve(result);
          }
      });
    });
  }

  private doOnLoad() {

    const docContext: RulesetDocumentContext = {
      documentData: this.documentData,
      template: this.documentData.template,
      templateId: this.documentData.template.documentId,
      formParameters: this.formParameters,
      isNew: this.documentData?.isNew
    };

    this.validationService.onLoadDocument(this.documentData.document, docContext,
      (error, result) => {

        if (result) {

          this.validationService.broadcastValidationEvent();
          this.onLoadCb(null);

        } else {
          this.onLoadCb(error);
        }
        this.state = 'hidden';
      });
  }

  private onLoadCb(error?) {

    if (error) {
      this.updateTemplateSettings();
      const msg = error.message;
      this.handleLoadError(msg);

    } else {
      this.broadcastService.broadcast(ClientConstants.DOCUMENT_LOADED, {
        documentData: this.documentData,
        formParameters: this.formParameters
      });
      this.changedDocumentService.documentLoaded(this.documentData.documentId, this.formParameters.unsavedDocumentListId, true);
      
      this.onDocumentSaved();
      
    }

  }

  private onDocumentSaved() {
    const savedSub: Subscription = this.documentSaveService.onDocumentSaved().subscribe((documentIds) => {
      console.log('Saved documents: ', documentIds);
      if (documentIds.includes(this.documentData?.documentId)) {
        const systemHeader: any = this.documentData?.document?.systemHeader;
        if (systemHeader?.deleted) {
          this.updateError('Document has been deleted');
          this.isDeleted = true;
          this.changedDocumentService.dispatchAction(formDeleteDocument(this.documentData?.documentId));
          this.modifiedFieldService.resetFormDirty();
          this.cdr.detectChanges();
        }
      }
    });
    this.subs.add(savedSub);

    this.broadcastService.on(ClientConstants.DOCUMENT_DELETED).subscribe((documentId) => {
      if (documentId === this.documentData?.documentId) {
        this.updateError('Document has been deleted');
        this.isDeleted = true;
        this.changedDocumentService.dispatchAction(formDeleteDocument(documentId));
        this.modifiedFieldService.resetFormDirty();
        this.cdr.detectChanges();
      }
    });
  }

  setWatchComponentTemplate() {
    const _self = this;
    const compSub = this.changedDocumentService.watchComponentTemplate(this.documentData.template.documentId).subscribe(changes => {

      _self.addDisableSaveFieldValues();

      _self.components = changes;

      if (!(_self.cdr as ViewRef).destroyed) {
        _self.cdr.detectChanges();
      }

    });

    this.subs.add(compSub);

    this.watchComponentStateChange();

    this.watchNewDocument();

  }
  /**
   * function for improving the performance of the ngFor used to render the components in the html file for this component
   */
  trackByFn(index, item) {
    return index.name;
  }

  ngOnDestroy() {
    if (this.documentData?.document) {
      const documentId = this.documentData.document.documentId;
      const unsavedDocumentListId = this.formParameters.unsavedDocumentListId;
      this.broadcastService.broadcast(ClientConstants.DOCUMENT_DESTROYED, {
        documentId,
        unsavedDocumentListId
      });

      const curUrl = this.routerService.getCurrentUrl();
      if (this.documentData?.isNew && !curUrl?.endsWith(documentId) ) {
        setTimeout(() => {
          this.changedDocumentService.documentLoaded(documentId, unsavedDocumentListId, false);
        }, 500);
      } else {
        this.changedDocumentService.documentLoaded(documentId, unsavedDocumentListId, false);
      }

    }

    this.subs.unsubscribe();
  }

  async runComponentFunction(componentDef) {
    const anyWindow: any = window;
    const componentNameCamelCase = lodashString.camelCase(componentDef.componentName);


    if (anyWindow.FormbirdServiceInjector.componentFunctions
      && anyWindow.FormbirdServiceInjector.componentFunctions[componentNameCamelCase]
      && anyWindow.FormbirdServiceInjector.componentFunctions[componentNameCamelCase].process) {
      this.functionComponentData[componentDef.name] = {
        componentDef: componentDef,
        componentName: componentNameCamelCase
      };

      await anyWindow.FormbirdServiceInjector.componentFunctions[componentNameCamelCase].process(
        componentDef, this.documentData.document);
    }
  }

  registerAndAppendComponents() {
    const _self = this;
    return new Promise(function (resolve, reject) {

      let key = 0;
      let arrWrapActionOpen = [];
      let arrWrapActionClose = [];
      let notFoundPanelNames = [];

      _self.viewContainerRef.clear();

      if (!_self.components){
        _self.components = _self.documentData.template.components;
      }

      async.eachSeries(_self.documentData.template.components, async (componentDef, next) => {
        
        if (componentDef.skipRendering) {
          key++;
          next();
          return;
        }

        const componentName = componentDef.componentName;
        if (componentName && !lodashString.startsWith(componentName, 'ft-') && !lodashString.startsWith(componentName, 'cc-')) {
          const componentDoc: ComponentDocument = await _self.componentService.loadComponentDocAndVendorLibs(componentName);

          componentDef.key = key;

          await _self.runComponentFunction(componentDef);

          if (componentDoc?.componentType !== 'Function') {
            const isOpenPanel = componentDef.wrapAction === "open" && componentDef.wrapHtmlType === "wrap";
            const isClosePanel = componentDef.wrapAction === "close" && componentDef.wrapHtmlType === "wrap";
            if ((isOpenPanel || isClosePanel) && !componentDef.name) {
              notFoundPanelNames.push(key);
            }

            if (isOpenPanel) {
              arrWrapActionOpen.push(key);
            }
            if (arrWrapActionOpen.length > 0 && isClosePanel) {
              arrWrapActionClose.push(key);
            }
            if (arrWrapActionOpen.length > 0) {
              if (arrWrapActionClose.length > 0 && arrWrapActionOpen.length === arrWrapActionClose.length) {
                const item = { key: arrWrapActionOpen[0], endKey: key - 1 };

                arrWrapActionOpen = [];
                arrWrapActionClose = [];
                _self.viewContainerRef.createEmbeddedView(_self.ngTpl, { $implicit: item });
              }
            } else {
              _self.viewContainerRef.createEmbeddedView(_self.ngTpl, { $implicit: componentDef });
            }

          }

          key++;
          next();

        } else {
          key++;
          next();
        }
      }, function(errorMsg) {
        if (errorMsg) {
          logger.error(errorMsg);
          reject(new Error(errorMsg));
        } else {

          if (arrWrapActionOpen.length > 0) {

            let notFoundClosingPanelNames = [];

            for (let i = 0; i < arrWrapActionOpen.length; i++) {

              const key = arrWrapActionOpen[i];
              const panelName =  _self.components[key].name;

              const foundKey = lodashArray.findIndex(_self.components, {
                'name': panelName, 'wrapAction': 'close',
                'wrapHtmlType': 'wrap'});
              if (foundKey < 0){
                notFoundClosingPanelNames.push(panelName);
              }
            }

            _self.notificationService.error(`Closing Tag for panel:  ${notFoundClosingPanelNames.join(', ')} missing or incomplete`);

            const item = { key: arrWrapActionOpen[0], endKey: key - 1 };
            arrWrapActionOpen = [];
            arrWrapActionClose = [];
            _self.viewContainerRef.createEmbeddedView(_self.ngTpl, { $implicit: item });
          }
        }

        if (notFoundPanelNames.length) {
          _self.notificationService.error(`Panel(s) at position ${notFoundPanelNames.join(', ')} have no name field`);
        }

        resolve(true);
      });
    });
  }

  appendComponents() {
    this.components = this.documentData.template.components;
    this.documentData.template.components.forEach((componentDef: any, index) => {
      componentDef.key = index;
      this.viewContainerRef.createEmbeddedView(this.ngTpl, { $implicit: componentDef });

      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * @description
   * adding disable save field values which has been removed while saving
   */
  addDisableSaveFieldValues() {
    const document = this.documentData.document;
    const template = this.documentData.template;
    if (document && template) {
      this.documentService.setDisableSaveFieldDocument(document, template);
    }
  }

  isPanel(componentDef: any) {
    return componentDef?.wrapAction;
  }

  watchComponentStateChange() {

    const compTemplateSub = this.recordedComponentTemplateChanges$.subscribe(changes => {

      const templateId = this.documentData.templateId;
      if (changes && templateId) {
        const fieldNames = changes[templateId] ? Object.keys(changes[templateId]) : null;
        if (fieldNames && fieldNames.length) {
          fieldNames.forEach(fieldName => {
            const tplItemChanges = changes[templateId][fieldName];
            const key = tplItemChanges.key;
            const componentDef = changes[templateId][fieldName].componentDef;
            const isPanel = this.isPanel(componentDef);
            const divId = "divParent" + fieldName + key;

            const panelWrapperId = fieldName + this.documentData.documentId;
            let children = this.el.nativeElement?.parentNode?.querySelectorAll(`[id='${divId}'`);
            if (!children?.length) {
              children = this.el.nativeElement?.parentNode?.querySelectorAll(`[id='${panelWrapperId}'`);
            }
            children?.forEach(child => {
              child.style = `display: ${componentDef.visible !== false ? 'flex' : 'none'}`;

              const isMandatory = componentDef.mandatory === true;
              if (isMandatory) {
                isPanel ? child.classList.add('mandatory-panel') : child.classList.add('mandatory');
              } else {
                isPanel ? child.classList.remove('mandatory-panel') : child.classList.remove('mandatory');
              }

              const isEnabled = componentDef.enabled !== false;
              if (!isEnabled) {
                isPanel ? child.classList.add('disabled-panel', 'readonly-panel', 'fb-label-disabled') : child.classList.add('disabled', 'readonly', 'fb-label-disabled');
                isPanel ? child.classList.remove('fb-label-enabled') : child.classList.remove('fb-label-enabled');
              } else {
                isPanel ? child.classList.remove('disabled-panel', 'readonly-panel', 'fb-label-disabled') : child.classList.remove('disabled', 'readonly', 'fb-label-disabled');
                isPanel ? child.classList.add('fb-label-enabled') : child.classList.remove('fb-label-enabled');
              }
            });
          });
        }
      }

    });

    this.subs.add(compTemplateSub);

  }

  watchNewDocument() {

    const _self = this;

    const docCreatedSub = _self.broadcastService.on(ClientConstants.DOCUMENT_NEW).subscribe((documentInfo: any) => {

      const document = documentInfo?.document;

      if (document) {

        const unsavedDocumentListId = documentInfo?.unsavedDocumentListId;
        const documentId = document.documentId;

        if (unsavedDocumentListId === _self.formParameters.unsavedDocumentListId && document.documentId === documentId) {

          const anyWindow: any = window;
          const compFunc = anyWindow.FormbirdServiceInjector.componentFunctions;

          const fieldNames = Object.keys(_self.functionComponentData);
          fieldNames.forEach(fieldName => {

            const componentDef = _self.functionComponentData[fieldName].componentDef;
            const componentName = _self.functionComponentData[fieldName].componentName;

            if (compFunc && compFunc[componentName].process) {

              compFunc[componentName].process(componentDef, _self.documentData.document);

            }
          });
        }
      }
    });

    _self.subs.add(docCreatedSub);
  }

  getDefaultAnimationLoadImage() {
    return  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB4bWxuczpzdmc9" +
      "Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZ" +
      "y8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB4bWw6c3BhY2U9InByZXNlcn" +
      "ZlIj48Zz48cGF0aCBkPSJNNzEgMzkuMlYuNGE2My42IDYzLjYgMCAwIDEgMzMuOTYgMTQuNTdMNzcuNjggNDIuMjRhMjUuNTMgMjUuNTMgMCAwIDAtNi43LTMuMDN6" +
      "IiBmaWxsPSIjMDAwIi8+PHBhdGggZD0iTTcxIDM5LjJWLjRhNjMuNiA2My42IDAgMCAxIDMzLjk2IDE0LjU3TDc3LjY4IDQyLjI0YTI1LjUzIDI1LjUzIDAgMCAwLTY" +
      "uNy0zLjAzeiIgZmlsbD0iI2UxZTFlMSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNjQgNjQpIi8+PHBhdGggZD0iTTcxIDM5LjJWLjRhNjMuNiA2My42IDAgMCAxIDMz" +
      "Ljk2IDE0LjU3TDc3LjY4IDQyLjI0YTI1LjUzIDI1LjUzIDAgMCAwLTYuNy0zLjAzeiIgZmlsbD0iI2UxZTFlMSIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgNjQgNjQpI" +
      "i8+PHBhdGggZD0iTTcxIDM5LjJWLjRhNjMuNiA2My42IDAgMCAxIDMzLjk2IDE0LjU3TDc3LjY4IDQyLjI0YTI1LjUzIDI1LjUzIDAgMCAwLTYuNy0zLjAzeiIgZml" +
      "sbD0iI2UxZTFlMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTM1IDY0IDY0KSIvPjxwYXRoIGQ9Ik03MSAzOS4yVi40YTYzLjYgNjMuNiAwIDAgMSAzMy45NiAxNC41N0w3Ny" +
      "42OCA0Mi4yNGEyNS41MyAyNS41MyAwIDAgMC02LjctMy4wM3oiIGZpbGw9IiNiZWJlYmUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA2NCA2NCkiLz48cGF0aCBkPSJNNz" +
      "EgMzkuMlYuNGE2My42IDYzLjYgMCAwIDEgMzMuOTYgMTQuNTdMNzcuNjggNDIuMjRhMjUuNTMgMjUuNTMgMCAwIDAtNi43LTMuMDN6IiBmaWxsPSIjOTc5Nzk3IiB0cm" +
      "Fuc2Zvcm09InJvdGF0ZSgyMjUgNjQgNjQpIi8+PHBhdGggZD0iTTcxIDM5LjJWLjRhNjMuNiA2My42IDAgMCAxIDMzLjk2IDE0LjU3TDc3LjY4IDQyLjI0YTI1LjUzI" +
      "DI1LjUzIDAgMCAwLTYuNy0zLjAzeiIgZmlsbD0iIzZlNmU2ZSIgdHJhbnNmb3JtPSJyb3RhdGUoMjcwIDY0IDY0KSIvPjxwYXRoIGQ9Ik03MSAzOS4yVi40YTYzLjYgN" +
      "jMuNiAwIDAgMSAzMy45NiAxNC41N0w3Ny42OCA0Mi4yNGEyNS41MyAyNS41MyAwIDAgMC02LjctMy4wM3oiIGZpbGw9IiMzYzNjM2MiIHRyYW5zZm9ybT0icm90YXR" +
      "lKDMxNSA2NCA2NCkiLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgdmFsdWVzPSIwIDY0IDY0OzQ1IDY0I" +
      "DY0OzkwIDY0IDY0OzEzNSA2NCA2NDsxODAgNjQgNjQ7MjI1IDY0IDY0OzI3MCA2NCA2NDszMTUgNjQgNjQiIGNhbGNNb2RlPSJkaXNjcmV0ZSIgZHVyPSI3Mj" +
      "BtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2c+PGc+PGNpcmNsZSBmaWxsPSIjMDAwIiBjeD0iNjMuNjYiIGN5PSI2My" +
      "4xNiIgcj0iMTIiLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJvcGFjaXR5IiBkdXI9IjcyMG1zIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi" +
      "BrZXlUaW1lcz0iMDswLjU7MSIgdmFsdWVzPSIxOzA7MSIvPjwvZz48L3N2Zz4="
  }

  private restoreWIPDocument = (result, unsavedDocumentListId) => {
    const _self = this;
    const initialData = result ? result.restoreWIPDocument : null;
    if (initialData) {

      for (const key in initialData) {
        const value = initialData[key];

        if (UtilType.hasValue(value)) {
          _self.documentData.document[key] = value; 
        
          const compDef = this.documentData?.template?.components?.find(comp => comp.name === key);
          if (compDef && !compDef.disableSave && !compDef.disableDirty) {
            const docId = _self.documentData.document.documentId;
            _self.modifiedFieldService.addModifiedField(docId, key, unsavedDocumentListId, false) ;
          }
        }

      }

      this.appStore.dispatch(formSetDocument(this.documentData.document));
    }
  }

  private clearCache = () => {
    const _self = this;
    return new Promise(async function (resolve) {
      _self.modalService.showDialog('Clearing Cache. Please wait.', 'WARNING');
      const ok = await _self.dataService.clearData();
      _self.modalService.closeDialog();
      if (ok) {
        _self.notificationService.printMessage('Your cache is now cleared.', 'success');
        _self.offlineStatusService.offlineStatus.errorCacheAttachedFile = [];
        _self.loggedInUserService.setUserConfigItem('ErrorCachingAttachedFiles', _self.offlineStatusService.offlineStatus.errorCacheAttachedFile);
      } else {
        _self.notificationService.printMessage('Error on deleting cache.', 'error');
      }
      resolve(true);
    });

  }
}
