import {Component , OnInit} from '@angular/core';

@Component({
    selector: 'ft-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss']
    
})

export class PrintComponent implements OnInit {
    constructor(
    ) {

    }

    printPage() {
        window.print();
    }

    ngOnInit() {
    }
}
