import { Directive, HostBinding, ChangeDetectorRef } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { FormColorService } from '@formbird/services';

@Directive({
  selector: '[ftFormColor]'
})
export class FormColorDirective {

  @HostBinding('style.background-color') public background: SafeStyle;
  @HostBinding('class') class: String = 'background-color-holder';

  constructor(
    private formColorService: FormColorService,
    private cd: ChangeDetectorRef
  ) {
    const sub = this.formColorService.color$.subscribe(color => {
      this.background = color.background;
      this.cd.detectChanges();
    });
  }
}
