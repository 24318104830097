<div class="ft-menu-object">
    <div class="modal-dialog modal-notify modal-info">
            
            <div class="menu-object modal-header">
                <h2 class="heading" *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
            

                
                <button mat-icon-button aria-label="close dialog" 
                        class="menu-object-close"
                        data-dismiss="modal"
                        (click)="closeDialog()"
                        *ngIf="data.errorFunc || data.showCloseButton" mat-dialog-close>
                    <mat-icon>close</mat-icon>
                </button>
            
                    
                
            </div>
            <mat-dialog-content class="mat-typography">
                <div class="ft-menu-content f-flex f-flex-column">
                    <mat-form-field>
                        <input matInput placeholder="Name" id="name-tf" type="text" name="name" [(ngModel)]="data.name" />
                    </mat-form-field>
                    
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Description"
                            id="des-tf"
                            type="text"
                            name="description"
                            [(ngModel)]="data.description"
                        />
                    </mat-form-field>
                    
                    <mat-form-field>
                        <input matInput style="display:none;" />
                        <mat-checkbox id="isFtApp-tf" color="primary" type="checkbox" name="isFieldtecApp" [(ngModel)]="data.isFieldtecApp">
                            Is Application link?
                        </mat-checkbox>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <input matInput placeholder="Address" id="addr-tf" type="text" name="address" [(ngModel)]="data.address" />
                    </mat-form-field>
                    
                    <mat-form-field>
                        <input matInput placeholder="Icon" id="icon-tf" type="text" name="icon" [(ngModel)]="data.icon" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Target</mat-label>
                        <mat-select [(ngModel)]="data.target">
                            <mat-option value="_self">Current Window</mat-option>
                            <mat-option value="_blank">New Tab</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                
                    
                
                
            </mat-dialog-content>
            <mat-dialog-actions class="menu-object modal-footer justify-content-center" align="center">
                
                    <button type="button" mat-button *ngIf="data.confirmButton" mat-raised-button color="warn" mat-dialog-close>Ok</button>
                    <button type="button" mat-button *ngIf="data.errorFunc" (click)="cancel()" mat-raised-button cdkFocusInitial>Cancel</button>
                    <button type="button" mat-button *ngIf="data.discardFunc" (click)="discard()" color="warn" mat-raised-button>Discard</button>
                    <button type="button" mat-button *ngIf="data.succFunc" (click)="ok()" color="primary" mat-raised-button>Ok</button>
                
            </mat-dialog-actions>

    </div>
</div>
