import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ErrorComponentDefinition } from '@formbird/types';

@Component({
  selector: 'ft-component-error',
  templateUrl: './component-error.component.html',
  styleUrls: ['./component-error.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ComponentErrorComponent {
  @Input() componentDefinition: ErrorComponentDefinition;
}
