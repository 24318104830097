import { SharedConstants } from '@formbird/types';
import { UtilIncludeRuleSet } from '@formbird/shared';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { IncludeDocumentDataService } from './include-document-data.service';
import { NotificationService } from '../notification/notification.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class ClientIncludeRuleSetService {

  constructor(
    private configService: ConfigService,
    private includeDocumentDataService: IncludeDocumentDataService,
    private notificationService: NotificationService
  ) {
  }

  private loadIncludeDocs(includeNames, callback) {
    this.includeDocumentDataService.getIncludeDocuments(includeNames).then((results: any) => {
      let docs = results ? results : [];
      const tmpData = { data: docs, total: docs.length};
      callback(null, tmpData);
    }, (err) => {
      logger.warn(err);
      callback(err);
    });
  }

  /*
   * Including ruleset processing.
   * Include ruleset syntax: #include "rulesetName";
   */
  addIncludesToRuleset(ruleFlow, callback) {

    const includeNames = UtilIncludeRuleSet.getIncludeNames(ruleFlow);

    if (includeNames && includeNames.length) {

      const remainIncludeNames = [];

      // remove commented #include
      for (let i = 0; i < includeNames.length; i++) {

        const beforeLength = ruleFlow.length;
        const commentLineExp = '//[\t ]*#include[\t ]+[\'"]' + includeNames[i] + '[\'"][\t ]*.*';
        ruleFlow = ruleFlow.replace(new RegExp(commentLineExp, 'g'), '');

        if (beforeLength === ruleFlow.length) {
          // Keep not commented include name
          remainIncludeNames.push(includeNames[i]);
        }
      }

      if (remainIncludeNames.length) {

        this.loadIncludeDocs(remainIncludeNames, (error, result) => {

          if (error) {

            callback({
              status: SharedConstants.STATUS_ERROR,
              message: 'Error loading include ruleset: ' + error.message
            });

          } else if (result && result.total && result.total >= remainIncludeNames.length) {

            const duplicates = result.data.map(value => value.name).filter((value, index, self) => {
              return self.indexOf(value) !== index;
            });

            if (duplicates.length) this.notificationService.warning(`Duplicate ruleset include: ${duplicates}`);

            for (let id = 0; id < result.total; id++) {
              const includeDoc = result.data[id];

              // replace #include by script for JS
              const exp = '#include[\t ]+[\'"]' + includeDoc.name + '[\'"][\t ]*,';
              ruleFlow = ruleFlow.replace(new RegExp(exp, 'g'), includeDoc.ruleSetSnippet + ',');
            }

            callback({
              status: SharedConstants.STATUS_SUCCESS,
              ruleFlow: ruleFlow
            });

          } else {

            let missedIncludes;

            if (result && result.total) {

              missedIncludes = [];

              for (let i = 0; i < remainIncludeNames.length; i++) {

                let res = false;

                for (let j = 0; j < result.total; j++) {

                  if (result.data[j].name === remainIncludeNames[i]) {
                    res = true;
                    break;
                  }
                }

                if (!res) {
                  missedIncludes.push(remainIncludeNames[i]);
                }
              }

            } else {
              missedIncludes = remainIncludeNames;
            }

            logger.error('There are missing ruleset includes for this document: ' + missedIncludes +
              '. Please make sure the ruleset include documents exist and ' +
              'the correct access keys are configured for them.');

            let errMsg = 'Ruleset Include(s) not found: ';
            for (let i = 0; i < missedIncludes.length; i++) {
              errMsg += missedIncludes[i];
              if (i < missedIncludes.length - 1) {
                errMsg += ', ';
              }
            }

            callback({
              status: SharedConstants.STATUS_ERROR,
              message: errMsg
            });
          }
        });

      } else {
        callback({
          status: SharedConstants.STATUS_SUCCESS,
          ruleFlow: ruleFlow
        });
      }

    } else {

      callback({
        status: SharedConstants.STATUS_SUCCESS,
        ruleFlow: ruleFlow
      });
    }
  }
}
