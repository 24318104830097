import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
    selector: 'ft-menu-object',
    templateUrl: './menu-object.component.html',
    styleUrls: [ './menu-object.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class MenuObjectDialogComponent {

    constructor(public dialogRef: MatDialogRef<MenuObjectDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    closeDialog() {
        this.dialogRef.close();
    }

    cancel() {
        if (this.data.errorFunc) {
            this.data.errorFunc();
        }

        this.dialogRef.close();
    }

    discard() {
        if (this.data.discardFunc) {
            this.data.discardFunc();
        }

        this.dialogRef.close();
    }

    ok() {
        if (this.data.succFunc) {
            this.data.succFunc();
        }

        this.dialogRef.close();
    }
}
