export class SearchQueue {
    private queue: (() => Promise<any>)[] = [];
    private isProcessing: boolean = false;

    addToQueue(searchFunction: () => Promise<any>) {
        this.queue.push(searchFunction);
        this.processQueue();
    }

    async processQueue() {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;
        while (this.queue.length > 0) {
            const searchFunction = this.queue.shift();
            if (searchFunction) {
                try {
                    await searchFunction();
                } catch (error) {
                    console.error('Error during search:', error);
                }
            }
        }
        this.isProcessing = false;
    }
}