
<div class="modal-{{data.messageType}} modal-header">
    <h2 class="heading" *ngIf="data.title" mat-dialog-title [innerHTML]='data.title'></h2>   
    <button mat-icon-button aria-label="close dialog" (click)="cancel()" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
                      
</div>

    <mat-dialog-content class="mat-typography"> 
        <div *ngIf="data.imageUrl" style="padding: 10px">
            <img [src]="data.imageUrl" [alt]="data.imageAlt"/>
        </div>
        <mat-icon  class="f-flex f-flex-justify-center f-flex-align-items-center" *ngIf="data.messageType == 'error'" color="warn" aria-hidden="false"
            aria-label="Error">error_outline</mat-icon>
        <div class="ft-dialog-data mat-h3" [innerHTML]="data.html" ></div>
        <div *ngIf='data.input'>
            <div *ngIf="data.input !== 'radio' && data.input !== 'checkbox' && data.input !== 'range'">
                <mat-form-field>
                    <mat-label *ngIf="data.inputPlaceholder">{{ data.inputPlaceholder }}</mat-label>
                    <input *ngIf="data.input !== 'select' && data.input !== 'radio' && data.input !== 'range'" [type]='data.input' matInput [(ngModel)]="inputValue">
                    <mat-select *ngIf="data.input === 'select'" matInput [(ngModel)]="inputValue">
                        <mat-option *ngFor="let option of data.inputOptions | keyvalue" [value]="option.value">
                                {{ option.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-radio-group *ngIf="data.input === 'radio'" [(ngModel)]="inputValue">
                <mat-radio-button *ngFor="let option of data.inputOptions | keyvalue" [value]="option.value">
                    {{ option.value }}
                </mat-radio-button>
            </mat-radio-group>
            <mat-checkbox *ngIf="data.input === 'checkbox'" [(ngModel)]="inputValue">{{ data.inputPlaceholder }}</mat-checkbox>
            <div *ngIf="data.input === 'range'">
                <mat-slider style="width: 90%;"
                            [(ngModel)]="inputValue"
                            [min]="data.inputAttributes.min || 0"
                            [max]="data.inputAttributes.max || 1"
                            [step]="data.inputAttributes.step || 1"
                            [value]="data.inputValue || 0"></mat-slider> {{inputValue}}
            </div>
        </div>
    </mat-dialog-content>
                
    <mat-dialog-actions class="menu-object modal-footer justify-content-center" align="center">
        <button type="button" [style.background]="data.cancelButtonColor" (keydown.enter)="onEnter($event)" mat-button [ngClass]="ftbtnclass" *ngIf="data.errorFunc" (click)="cancel()" [color]="color" mat-raised-button cdkFocusInitial>{{ data.cancelButtonText || 'Cancel'}}</button>
        <button type="button" mat-button *ngIf="data.discardFunc" (keydown.enter)="onEnter($event)" (click)="discard()" color="warn" mat-raised-button >Discard</button>
        <button type="button" [style.background]="data.confirmButtonColor" mat-button type="button" (click)="ok()" [ngClass]="ftbtnclass" [mat-dialog-close]="data.inputValue" *ngIf="data.confirmButton"  mat-raised-button [color]="color">
            {{ data.confirmButtonText || 'Ok'}}</button>
        <button type="button" [style.background]="data.confirmButtonColor" (keydown.enter)="onEnter($event)" mat-button type="button" [ngClass]="ftbtnclass" *ngIf="data.succFunc && data.showConfirmButton !== false" mat-dialog-close (click)="ok()" [color]="color" mat-raised-button>{{ data.confirmButtonText || 'Ok'}}</button>
    </mat-dialog-actions>
                