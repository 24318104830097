import { combineReducers } from 'redux';
import { formDocumentReducer } from './form-document.reducer';
import { userReducer } from './user.reducer';
import { offlineReducer } from './offline.reducer';

const rootReducer = combineReducers({
  offlineReducer: offlineReducer,
  formState: formDocumentReducer,
  userState: userReducer
});

export default rootReducer;
