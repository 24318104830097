import { Injectable } from '@angular/core';
declare const angular: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  ajsService: any;

  constructor() {
    const element = document.querySelectorAll('div[ui-view]');
    const injector = angular.element(element).injector();

    this.ajsService = injector.get('ModalService');
  }

  showDialog(message, messageType?, succFunc?, errorFunc?, discardFunc?) {
    this.ajsService.showDialog(message, messageType, succFunc, errorFunc, discardFunc);
  }
}
