import { User } from '@formbird/types';

export interface IUserState {
  user?: User;
}

export function getDefaultUserState(): IUserState {
  return {
    user: {
      welcomeMsg: '',
      account: null,
      filteredAccount: null,
      menu: null,
      publicAccount: false,
      accountPublicURL: null,
      accountControlDocument: null,
      accountGroupConfigDocument: null
    }
  };
}
