import { Injectable } from "@angular/core";
// import { downgradeInjectable } from '@angular/upgrade/static';
import { Observable, Subject } from "rxjs";
export interface ImageLink  {
  imageFileNo96: string
}

@Injectable({
  providedIn: 'root'
})
export class ImageLinkService {

  imageLink : ImageLink;
  private imageLinkSubject: Subject<ImageLink>;
  imageLink$: Observable<ImageLink>;
  constructor(
  ) {

    this.imageLink = {
      imageFileNo96: null
    };
    this.imageLinkSubject = new Subject<ImageLink>();
    this.imageLink$ = this.imageLinkSubject.asObservable();
  }

  setImageFileNo96 = function (fileNo) {
    this.imageLink.imageFileNo96 = fileNo;
    this.imageLinkSubject.next(this.imageLink);
  };

}


// angular.module('ftApp')
//     .factory('ImageLinkService', downgradeInjectable(ImageLinkService));
