import { Injectable } from '@angular/core';
import { IndexedDBConstants } from '@formbird/indexed-db';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { IndexedDBService } from './indexed-db.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class IndexedDBPropertiesService {

  constructor(
    private loggedInUserService: LoggedInUserService,
    private indexedDBService: IndexedDBService,
    private offlineStatusService: OfflineStatusService
  ) { }

  setItem(key: string, value: any) {

    if (!this.offlineStatusService.isCachingEnabled()) {
      logger.error('Attempting to use IndexedDBPropertiesService while caching disabled.');
      return Promise.reject(new Error('Offline is disabled.'));
    }

    const _self = this;

    const record = {
      key: key,
      value: value
    };

    return new Promise((resolve, reject) => {
      _self.indexedDBService.saveEntry(IndexedDBConstants.FT_PROPERTIES_TABLE_NAME, record, function (err) {

        if (err) {
          if (err.name === "OpenFailedError") {
            reject(new Error('Offline is not supported in this browser. If using Private Mode, you may not be able to use it in this browser.'));
          } else {
            reject(new Error('Error in setting IDBProperties key ' + key));
          }
        } else {
          resolve(record);
        }
      });
    });

  }

  getItem(key: string) {

    const _self = this;

    if (!this.offlineStatusService.isCachingEnabled()) {
      logger.error('Attempting to use IndexedDBPropertiesService while caching disabled.');
      return Promise.reject('Offline is disabled.');
    }

    return new Promise((resolve, reject) => {

      const tbl = IndexedDBConstants.FT_PROPERTIES_TABLE_NAME;
      const index = IndexedDBConstants.FT_PROPERTIES_TABLE_INDEX_KEY;

      _self.indexedDBService.getEntries(tbl, index, key, function (entries) {

        if (entries.length > 0) {
          resolve(entries[0]); // get first item.

        } else {
          reject(new Error('Error in getting IDBProperties key ' + key));
        }

      });

    });

  }

  removeItem(key: string) {

    const _self = this;

    return new Promise((resolve, reject) => {

      const tbl = IndexedDBConstants.FT_PROPERTIES_TABLE_NAME;
      const index = IndexedDBConstants.FT_PROPERTIES_TABLE_INDEX_KEY;

      _self.indexedDBService.deleteEntries(tbl, index, key, function (err, count) {

        if (count === 0) {
          logger.info('No entry to remove for key: ' + key);
        }

        resolve(true);
      });

    });

  }
}
