import { Directive, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CurrentDocumentService } from '@formbird/services';
@Directive({
    selector: '[ftBackgroundImage]'
  })
export class BackgroundImageDirective {

    @HostBinding('style.background-image') public backgroundImage: SafeStyle;
    @HostBinding('class') class: String = 'background-image-holder';

    constructor(sanitizer: DomSanitizer, private currentDocumentService: CurrentDocumentService) {
        const setBackground = (bg) => {
            this.backgroundImage = sanitizer.bypassSecurityTrustStyle(`url("${bg}")`);
        }


        const config = (<any>window).ftClientConfig;
        if (config.backgroundImage) {
            setBackground(config.backgroundImage);
        }

        this.currentDocumentService.documentData$.subscribe((data: any) => {
            if (data.template?.backgroundImage) {
                setBackground(data.template.backgroundImage);
            }
        });
    }
}
