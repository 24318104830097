import { Injectable } from '@angular/core';
import { SharedUrlRoutes } from '@formbird/shared';
import { FtError, SharedConstants } from '@formbird/types';
import { IndexedDBConnectorService } from '../indexeddb/indexed-dbconnector.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data/data.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IncludeDocumentDataService {

  constructor(private indexedDBConnectorService: IndexedDBConnectorService,
    private dataService: DataService,
    private http: HttpClient) { }

  async getIncludeDocuments(includeNames: any): Promise<any> {
    const onlineFunc: any = async (includeNames) => {
      const url = SharedUrlRoutes.serverRoutes.loadIncludeDocs;
      const body = { includeNames };
      try {
        return await firstValueFrom(this.http.post<any>(url, body));
      } catch (err) {
        const msg = err ? err.message : 'Something went wrong when getting include documents.';
        const error = new FtError(msg);

        if (err) {
          error.status = err.status;
          error.statusText = err.statusText;
          error.message = err.error;
        }

        throw error;
      }
    };

    const oflineFunc: any = async (includeNames: any): Promise<any> => {
      const db = await this.indexedDBConnectorService.getDatabase();
      const result = db.documents.where('systemHeader.systemType').equals(SharedConstants.SYSTEM_TYPE_RULESET_INCLUDE);

      return new Promise<any>((resolve, reject) => {
        result.toArray((documents) => {
          try {
            const results = documents.filter(document =>
              document.systemHeader?.currentVersion === true && includeNames.includes(document.name));
            resolve(results);
          } catch (err) {
            reject(err);
          }
        });
      });
    };

    try {
      return await this.dataService.executeDataFunction(onlineFunc, oflineFunc, includeNames);
    } catch (err) {

      console.error('Get ruleset include documents failed with error: ' + err);
      throw new Error(err);
    }
  }

}
