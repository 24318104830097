import { Injectable } from '@angular/core';

const logger = console;
let pageVisibilityService$: any;

@Injectable({
  providedIn: 'root'
})
export class PageVisibilityService {

  // main snippet source: https://www.html5rocks.com/en/tutorials/pagevisibility/intro/
  private visibilityListeners = [];

  private evtname = null;

  constructor() {
    pageVisibilityService$ = this;
  }
  
  isHidden() {
    if (!document.hidden) {
      return false;
    }

    return document.hidden;
  }

  private visChange() {
    const hidden = pageVisibilityService$.isHidden();

    for (let x = 0; x < pageVisibilityService$.visibilityListeners.length; x++) {
      pageVisibilityService$.visibilityListeners[x](hidden);
    }

  }

  startListeningForPageVisibility() {
    if (this.evtname !== null) { // done init, skip.
      return;
    }

    if (document.hidden !== null) {
      this.evtname = 'visibilitychange';
      document.addEventListener(this.evtname, this.visChange);
    } else {
      logger.error('Visibility API not supported by browser.');
    }

  }

  stopListeningForPageVisibility() {
    document.removeEventListener(this.evtname, this.visChange);
    this.evtname = null; // reset so we can enable it back via startListening..
  }

  /**
   * Listeners should execute as fast as possible.
   *
   * It is the responsibility of the listener to implement its own debounce logic so to avoid unnecessary repetitive
   * execution.
   *
   * At the moment, debounce will not be implemented here to allow the listeners to react in the shortest
   * possible time.
   * @param listener the listener
   */

  registerVisibilityListener(listener: (isHidden) => void) {
    this.visibilityListeners.push(listener);
  }

  removeVisibilityListener(listener) {
    const index = this.visibilityListeners.indexOf(listener);
    if (index > -1) {
      this.visibilityListeners.splice(index, 1);
    }
  }

}
