import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, SessionTestService, LoggedInUserService } from '@formbird/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '@services/modal/modal.service';
import { NotificationService, OfflineStatusService } from '@formbird/services';

@Injectable()
export class GatewayErrorInterceptorService implements HttpInterceptor {

    intervalStarted = false;
    attempts = 0;
    dialogClosed = true;
    sessionTestRouteError = false;

    constructor(
        private configService: ConfigService,
        private modalService: ModalService,
        private loggedInUserService: LoggedInUserService,
        private offlineStatusService: OfflineStatusService,
        private sessionTestService: SessionTestService,
        private notificationService: NotificationService) { }

    doSessionTest() {
        this.sessionTestService.testSession().then(() => {
            console.log('Client is connected.');
        }).catch(err => {
            console.error('Error on session test: ' + err.message);
        });
    }

    startRetryInterval() {
        let retryInterval = 20000;
        let maxAttempts = 10;

        const clientConfig = this.configService.clientConfig();

        if (clientConfig && clientConfig.serverStart) {
            retryInterval = clientConfig.serverStart.retryInterval || retryInterval;
            maxAttempts = clientConfig.serverStart.maxAttempts || maxAttempts;
        }

        if (!this.intervalStarted) {
            this.intervalStarted = true;

            const interval = setInterval(() => {
                this.doSessionTest();
                if (++this.attempts >= maxAttempts) {
                    clearInterval(interval);
                }
            }, retryInterval);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isSessionTestRoute = req.url === 'api/sessionTest';

        // this only works for angular http requests
        // angularjs $http requests wont be intercepted here
        return next.handle(req).pipe(tap(event => {
            if (event instanceof HttpResponse 
                && this.intervalStarted 
                && this.sessionTestRouteError 
                && isSessionTestRoute) {
                window.location.reload(); // reload if connected
            }
        }, error => {
                if (error.status === 502 || error.status === 504) {
                    const cacheEnabled = this.offlineStatusService.isCachingEnabled();
                    if (!cacheEnabled && this.dialogClosed) {
                        this.dialogClosed = false;
                        if (isSessionTestRoute) {
                            this.sessionTestRouteError = true;
                            this.modalService.showDialog(
                                'Formbird Server is starting. Please Wait.',
                                'WARNING', () => {
                                    this.dialogClosed = true;
                                    this.doSessionTest();
                                });

                            this.startRetryInterval();
                        } else {
                            this.notificationService.error('A gateway error occurred. ' +
                                'Please ask an administrator to ensure all formbird services are running correctly.');
                        }
                    }
                } else if (this.intervalStarted) {
                    // if status returned other than 502 or 504, reload the page
                    window.location.reload();
                }
        }));
  }
}
