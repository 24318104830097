<div class="ft-delete-wrap f-flex f-vertical-align-middle">
    <div *ngIf="deleteOptions.showSpinner" class="spinner-delete-wrapper f-flex f-flex-align-items-center">
        <mat-spinner [diameter]="21"></mat-spinner>
    </div>
    <button type="button" mat-icon-button color="primary"
        (click)="deleteDoc();"
        class="commandbar-item material-icons hidden-print"
        tooltip-placement="bottom" tooltip="Delete" title="Delete document"
        *ngIf="!deleteOptions.showSpinner"
        aria-label="Delete Document"
        id="deleteButton">
        <mat-icon>delete</mat-icon>
    </button>
</div>
