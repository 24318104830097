import { Injectable } from '@angular/core';
import { AccessManager } from '@formbird/shared';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { User } from '@formbird/types';
import { Observable } from 'rxjs';
import { select } from '../../redux/decorators/select';

@Injectable({
  providedIn: 'root'
})
export class ClientAccessService {

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;

  constructor(
    private loggedInUserService: LoggedInUserService
  ) {
    this.user$.subscribe((user: User) => this.user = user);
  }

  checkAccess(targetDocument, operation) { // Implements 12125

    if (!targetDocument) {
      return true;
    }

    return AccessManager.checkAccess(targetDocument, this.user.accountControlDocument, operation);

  }

  hasPermission(targetDocument, operations) {
    if (!targetDocument) {
      return true;
    }

    return AccessManager.hasPermission(targetDocument, this.user.accountControlDocument, operations);
  }

  // Document access determined by comparing systemHeader.keyIds against the user's attachKeys.
  // A new document inherits keyIds from its template and from the user that created it.
  writeKeysToDocument(document, template) {
    const userAccountControlDocument = this.user.accountControlDocument;
    AccessManager.writeKeysToDocument(document, template, userAccountControlDocument);
  }

}
