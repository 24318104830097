import { DocumentChangedInfo, StateChangeInfo } from '@formbird/types';

export interface IFormNewState {
  storeSessionId: string,
  canUndo: boolean,
  canRedo: boolean,
  resetDocument: string,
  documents: any, // list of documents on forms
  templates: any, // list of templates on forms
  orgDocuments: any, // cached documents - they will be used as original ones
  documentInfo: any, // list of hierarchy documents
  documentChangedInfo: DocumentChangedInfo,
  recordedStateChanges: StateChangeInfo,
  recordedComponentTemplateChanges: any,
  recordedDisableSaveFieldValues: any,
  documentSession: any;
  rulesProcessingData: any;
  altTemplateInfo: any;
}

export function getDefaultFormNewState(): IFormNewState {
  return {
    storeSessionId: null,
    canUndo: false,
    canRedo: false,
    resetDocument: null,
    documents: {},
    templates: {},
    orgDocuments: {},
    documentInfo: {},
    documentChangedInfo: null,
    recordedStateChanges: null,
    recordedComponentTemplateChanges: {},
    recordedDisableSaveFieldValues: {},
    documentSession: {},
    rulesProcessingData: {},
    altTemplateInfo: {}
  };
}
