import { SharedConstants } from '@formbird/types';
import { Notyf } from 'notyf';

declare var $: any;

export class NotificationService {
  notyf: any;
  private queue = [];
  private currentMessage = {};
  private _scope = null;

  constructor() {
    this.notyf = new Notyf({
      ripple: false,
      types: [
        {
          type: 'warning',
          className: 'notyf-warning',
          icon: {
            className: 'material-icons',
            tagName: 'i',
            text: 'warning'
          }

        },
        {
          type: 'info',
          className: 'notyf-info',
          dismissible: true,
          icon: {
            className: 'material-icons',
            tagName: 'i',
            text: 'info'
          }
        }
      ]
    });
  }

  /**
   * position:
   *     { x: 'right', y: 'top' }
   *     { x: 'center', y: 'top' }
   *     { x: 'left', y: 'top' }
   *     { x: 'right', y: 'bottom' }
   *     { x: 'center', y: 'bottom' }
   *     { x: 'left', y: 'bottom' }
   *      /**
   *(e.g) position: { x: 'right', y: 'top' }
   *     notyf position options
   *     Param |       Type                | Details
   *     x     |  left ; center or right  | x-position
   *     y     | top  ; center or bottom  |  y-position
   */
  private printNotyfMessage(message, options, title, position, preventDuplicate, onClickFunction, timeOut, customClassName) {
    let messageType = options?.messageType || SharedConstants.STATUS_INFO;
    let onCloseClickFunction = () => {};

    if (typeof options !== 'object') {
      messageType = options;
    } else {
      if (typeof options.onCloseClickFunction === 'function') {
        onCloseClickFunction = options.onCloseClickFunction;
      }
    }

    if (!position || /^toast/.test(position)) {
      position = { x: 'right', y: 'top' };
    }

    preventDuplicate = preventDuplicate ? preventDuplicate : false;
    const notyfMessage = !(!title || title === '') ? '<i class="notyf-message-type">[' + messageType + ']</i><b>' +
      title + '</b> <br/>' + message : message;


    let lowerCaseMessageType = messageType.toLowerCase();
    lowerCaseMessageType = lowerCaseMessageType === 'forbidden' ? SharedConstants.STATUS_ERROR : lowerCaseMessageType;

    /** preventDuplicate condition
     * if value is true, do not display duplicate notification
     * if value is false, display duplicate notification
     **/
    if (preventDuplicate) {
      const notyfTextContent = this.notyf.view.container.textContent;
      let compareMessage = !(!title || title === '') ? '[' + messageType + ']' + title + ' ' + message : message;
      if (lowerCaseMessageType === 'warning') {
        compareMessage = 'warning' + compareMessage;
      } else if (lowerCaseMessageType === 'info') {
        compareMessage = 'info' + compareMessage;
      }
      /**The message is duplicate if notyfTextContent.split(compareMessage).length greater than 1
       * The message is duplicate if preventDuplicate value is true and
       * [title, message and messageType] are similar with new notification message
       * */
      if (notyfTextContent.split(compareMessage).length > 1) {
        if (lowerCaseMessageType === 'success') {
          console.log('%cDuplicate ' + lowerCaseMessageType + ' notification: ' + notyfMessage, `background: #3dc663;color:white`);
        } else if (lowerCaseMessageType === 'info') {
          console.log('%cDuplicate ' + lowerCaseMessageType + ' notification: ' + notyfMessage, `background: blue;color:white`);
        } else if (lowerCaseMessageType === 'warning') {
          console.log('%cDuplicate ' + lowerCaseMessageType + ' notification: ' + notyfMessage, `background: orange;color:white`);
        } else if (lowerCaseMessageType === 'error') {
          console.log('%cDuplicate ' + lowerCaseMessageType + ' notification: ' + notyfMessage, `background: #ed3d3d;color:white`);
        } else if (lowerCaseMessageType === 'forbidden') {
          console.log('%cDuplicate ' + lowerCaseMessageType + ' notification: ' + notyfMessage, `background: #ed3d3d;color:white`);
        }
        return;
      }
    }
    
    if(customClassName){
      this.notyf.options.types = this.notyf.options.types.map(obj => {
        return { ...obj, className: obj.className + ' ' + customClassName };
      });
    }

    let notyfOptions = { type: lowerCaseMessageType, message: notyfMessage, duration: timeOut, dismissible: true, position: position };
    
    if (options && typeof options === 'object') {
      notyfOptions = { ...notyfOptions, ...(options.notyfOptions || {}) };
    }

    const notification = this.notyf.open(notyfOptions);
    notification.on('click', ({target, event}) => {
      onClickFunction();
    });

    notification.on('dismiss', ({target, event}) => {
      onCloseClickFunction();
    });

    return notification;
  }

  /**
   * position:
   *     { x: 'right', y: 'top' }
   *     { x: 'center', y: 'top' }
   *     { x: 'left', y: 'top' }
   *     { x: 'right', y: 'bottom' }
   *     { x: 'center', y: 'bottom' }
   *     { x: 'left', y: 'bottom' }
   *      /**
   *(e.g) position: { x: 'right', y: 'top' }
   *     notyf position options
   *     Param |       Type                | Details
   *     x     |  left ; center or right  | x-position
   *     y     | top  ; center or bottom  |  y-position
   */
  private printHTML5Notification(message, messageType, title, position, preventDuplicate, onClickFunction, onCloseClickFunction, timeOut) {
    const doPrintHTML5Notif = () => {
      let icon;
      const lowerCaseMessageType = messageType.toLowerCase();

      switch (lowerCaseMessageType) {
        case SharedConstants.STATUS_SUCCESS:
          icon = './images/notifications/success.png';
          break;
        case SharedConstants.STATUS_INFO:
          icon = './images/notifications/info.png';
          break;
        case SharedConstants.STATUS_WARNING:
          icon = './images/notifications/warning.png';
          break;
        case SharedConstants.STATUS_ERROR:
          icon = './images/notifications/error.png';
          break;
        case SharedConstants.STATUS_FORBIDDEN:
          icon = './images/notifications/warning.png';
          break;

      }

      const notification = new (<any>window).Notification('Formbird', {
        body: message,
        icon: icon,
        dir: 'auto'
      });

      notification.onclick = function() {
        onClickFunction();
      };

      notification.onclose = function() {
        onCloseClickFunction();
      };

      if (timeOut) {
        setTimeout(function() {
          notification.close();
        }, timeOut);
      }

      return notification;
    };

    if (Notification.permission === 'granted') {
      return doPrintHTML5Notif();
    } else {
      (<any>window).Notification.requestPermission(function(permission) {
        doPrintHTML5Notif();
      });
    }
  }

  /**
   * Display both messages
   */
  public printMessage(message, options?, title?, position?, preventDuplicate?, displayDesktopNotification?,
                      onClickFunction?, timeOut?, isPermanent?, customClassName?) {
    if (typeof options === 'object') {
      timeOut = options.timeOut || timeOut;
      displayDesktopNotification = options.displayDesktopNotification || displayDesktopNotification;
      isPermanent = options.isPermanent;
      title = options.title || title;
      position = options.position || position;
      preventDuplicate = options.preventDuplicate || preventDuplicate;
      onClickFunction = options.onClickFunction || onClickFunction;
      customClassName = options.customClassName || customClassName;
    }                    

    if (isPermanent) {
      timeOut = 0;
    } else if (!timeOut) {
      timeOut = 3000;
    }

    if (!onClickFunction) {
      onClickFunction = function() {};
    }

    const notification: any = {};

    if (displayDesktopNotification) {
      let messageType = (typeof options === 'string' ? options : options.messageType) || SharedConstants.STATUS_INFO;
      let onCloseClickFunction = options.onCloseClickFunction || (() => {});
      notification.desktopNotification = this.printHTML5Notification(message, messageType, title, position, preventDuplicate, 
        onClickFunction, onCloseClickFunction, timeOut);
    }

    notification.notyfNotification = this.printNotyfMessage(message, options, title, position, preventDuplicate, onClickFunction, timeOut, customClassName);

    return notification;
  }

  public dismiss(notification) {
    if (notification.desktopNotification) {
      notification.desktopNotification.close();
    }

    this.notyf.dismiss(notification?.notyfNotification);
  }

  public set(message) {
    const msg = message;
    this.queue.push(msg);
  }

  public get() {
    return this.currentMessage;
  }

  public error(message, title?, displayDesktopNotification?) {

    const messageType = SharedConstants.STATUS_ERROR;
    const position = '';
    const preventDuplicate = false;
    const onClickFunction = null; // leave this undefined so the default will be used
    const timeOut = 0;
    const isPermanent = true; // make errors permanent so the user will see them if they miss the temporary popup

    this.printMessage(message, messageType, title, position, preventDuplicate, displayDesktopNotification,
      onClickFunction, timeOut, isPermanent);
  }

  public forbidden(message, title?, displayDesktopNotification?) {
    if (!title) {
      title = 'Forbidden';
    }

    this.error(message, title, displayDesktopNotification);
  }

  public info(message, title?, displayDesktopNotification?) {
    this.printMessage(message, SharedConstants.STATUS_INFO, title, '', undefined, displayDesktopNotification);
  }

  public success(message, title?, displayDesktopNotification?) {
    this.printMessage(message, SharedConstants.STATUS_SUCCESS, title, '', undefined, displayDesktopNotification);
  }

  public warning(message, title?, displayDesktopNotification?) {
    this.printMessage(message, SharedConstants.STATUS_WARNING, title, '', undefined, displayDesktopNotification);
  }

  public setupAlert(scope, maxAlertMessages) {
    this._scope = scope;
    this._scope.MaxAlertMessages = [];
    if (!maxAlertMessages) {
      maxAlertMessages = 5;
    }
    for (let i = 0; i < maxAlertMessages; i++) {
      const alertPlaceholderId = 'alert_placeholder_' + i;
      this._scope.MaxAlertMessages.push(alertPlaceholderId);
    }

  }

  public reverseSetupAlert() {
    if (this._scope != null && this._scope.MaxAlertMessages != null) {
      delete this._scope.MaxAlertMessages;
    }
  }
}
