import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientConstants, UrlService } from '@formbird/services';

@Component({
  selector: 'ft-external-view',
  templateUrl: './external-view.component.html',
  styleUrls: ['./external-view.component.scss']
})
export class ExternalViewComponent implements OnInit, OnDestroy {
  sub: any;
  url = null;

  constructor(
    private route: ActivatedRoute,
    private urlService: UrlService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      const externalLink = params.p || this.urlService.urls.external;
      const url = externalLink.replace(ClientConstants.URL_EQUAL_REPLACEMENT_STRING, '=');
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
