import { Injectable, OnDestroy } from '@angular/core';
import {
  CurrentDocumentService,
  DocumentSaveService,
  UnsavedDocumentService,
  DocumentService
} from '@formbird/services';
import { Subscription } from 'rxjs';
import { RouterService } from '../../../app-routing/router.service';
import { ModalService } from './../modal/modal.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DocumentListService implements OnDestroy {


  private sub: Subscription;

  private mainDocumentListId: string;

  /*
   documentInfo = {
    'documentListId-1': {
      'document-id': {
        'isMainDoc': true,  // It is the document that is attached to the navbar/save button. It should be destroyed if a new one is setting up because the whole screen is changed.
        'documentId': 'main-document-id-1',
        'templateId': 'main-template-id-1',
        'hierarchyInfo': {
          'parentDocumentId': null,
          'childDocumentId': 'child-document-id-1'
        }
      },
      'child-document-id-1': {
        'isMainDoc': false,
        'documentId': 'child-document-id-1',
        'templateId': 'child-template-id-1',
        'hierarchyInfo': {
          'parentDocumentId': 'document-id',
          'childDocumentId': 'child-document-id-2'
        }
      },
      'child-document-id-2': {
        'isMainDoc': false,
        'documentId': 'child-document-id-2',
        'templateId': 'child-template-id-2',
        'hierarchyInfo': {
          'parentDocumentId': 'child-document-id-1',
          'childDocumentId': null
        }
      }
    },
    'documentListId-2': {
      'document-id': {
        'isMainDoc': false, //It could be another document list in modal
        'documentId': 'main-document-id-1',
        'templateId': 'main-template-id-1',
        'hierarchyInfo': {
          'parentDocumentId': null,
          'childDocumentId': 'child-document-id-1'
        }
      },
      'child-document-id-1': {
        'isMainDoc': false,
        'documentId': 'child-document-id-1',
        'templateId': 'child-template-id-1',
        'hierarchyInfo': {
          'parentDocumentId': 'document-id'',
          'childDocumentId': 'child-document-id-2'
        }
      },
      'child-document-id-2': {
        'isMainDoc': false,
        'documentId': 'child-document-id-2',
        'templateId': 'child-template-id-2',
        'hierarchyInfo': {
          'parentDocumentId': 'child-document-id-1',
          'childDocumentId': null
        }
      }
    }
  };*/

  documentData: any;

  constructor(
    private documentSaveService: DocumentSaveService,
    private unsavedDocumentService: UnsavedDocumentService,
    private currentDocumentService: CurrentDocumentService,
    private modalService: ModalService,
    private routerService: RouterService,
    private documentService: DocumentService
  ) {
    this.sub = this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * get the main document info
   */
  getMainDocumentListId() {
    return this.mainDocumentListId;
  }

  /**
   * setup the document list id
   */
  setupDocumentListId(isNewMainDocList?) {

    const docListId = this.unsavedDocumentService.createUnsavedDocumentList(isNewMainDocList);

    if (isNewMainDocList) {
      this.mainDocumentListId = docListId;
    }

    logger.info(`Setting up document list Id: ${docListId}`);

    return docListId;
  }

  getDocumentInfo(documentListId) {
    return this.unsavedDocumentService.getDocumentInfo(documentListId);
  }

  /**
   * Get document info of each document inside a document list
   * @param documentListId 
   * @param documentId 
   * @returns 
   */
  getDocumentInfoItem(documentListId, documentId) {
    return this.unsavedDocumentService.getDocumentInfoItem(documentListId, documentId);
  }

  navigateDocument(documentId, overrideTemplateId?) {

    const snapshot = this.routerService.getActivatedRouteSnapshot();
    const routeParams = [];

    if (snapshot.url[0].path === 'formNoNavBar') {
      routeParams.push('/formNoNavBar');
    } else {
      routeParams.push('/form');
    }
    routeParams.push(documentId);
    if (overrideTemplateId) {
      routeParams.push(overrideTemplateId);
    }

    this.routerService.navigate(routeParams);
  }

  /**
   * perform save of the main document list
   */
  async save(documentListId?: string) {
    
    // save the provided document list or the current attached document list - i.e the main loaded document on form
    const targetDocumentListId = documentListId ? documentListId : this.currentDocumentService.getMainDocumentListId();

    const result = await this.documentSaveService.saveDocumentLists([targetDocumentListId]);

    if (!result.error) {

      logger.info('Document(s) saved.');

      const documentData = this.documentData;
      const mainDocument = documentData?.document;
      let savedMainDoc = null;

      const data = result.data;
      if (!data) {
        return;
      }

      data.forEach(documentObj => {
        if (documentObj?.documentId === mainDocument.documentId) {
          savedMainDoc = documentObj;
        }
      });

      if (savedMainDoc) {
        let overrideTemplateId = '';

        if (documentData.overrideTemplateId && !documentData.showFormAfterCreated &&
          documentData.document.documentId === savedMainDoc.documentId) {
          overrideTemplateId = documentData.overrideTemplateId;
        }

        if (!overrideTemplateId && documentData.document &&
          documentData.template.documentId !== documentData.document.systemHeader.templateId &&
          documentData.document.documentId === savedMainDoc.documentId) {
          
          if (!savedMainDoc.systemHeader.previousVersionId) {// No previousVersionId means this is creation mode
            this.navigateDocument(savedMainDoc.documentId);
          } else { // Edit mode to reload the form for another template
            window.location.reload();
          }

        } else {

          if (documentData.overrideTemplateId && !documentData.showFormAfterCreated &&
            documentData.document.documentId === savedMainDoc.documentId) {
            overrideTemplateId = documentData.overrideTemplateId;
          }
          
          // removed to solve issue - 18919: fleet-upgrade > mandated field state not kept after save
          // this.documentService.resetLoadedTemplate(savedMainDoc.systemHeader.templateId);
          this.navigateDocument(savedMainDoc.documentId, overrideTemplateId);

        }
      }

    } else {

      console.error(result.error);
      if (result && result.error && result.error.message) {
        let message = result.error.message;
        if (message.includes('Network Error.') || message.includes('0 Unknown Error')) {
          message = "Could not save due to lost network connection before save.";
        }

        this.modalService.showDialog(message, 'ERROR');
      }


    }
  }

}
