import { Injectable } from '@angular/core';
import { IApplicationState } from '../../redux/state/application.state';
import { SharedConstants, DocumentInfo, FormParameters } from '@formbird/types';
import { UtilDocumentId, UtilDocument, UtilError, UtilType, UtilHttpStatus } from '@formbird/shared';
import { extend, cloneDeep, isFunction } from 'lodash';
import { ClientAccessService } from '../access/client-access.service';
import { DataService } from '../data/data.service';
import { UnsavedDocumentService } from './unsaved-document.service';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { KeyValueStorageService } from '../key-value-storage/key-value-storage.service';

import {
  formClearDocuments,
  formSetDocument,
  formSetDocumentInfo,
  formSetOrgDocument,
  formSetDisableSaveFieldDocument,
  formSetTemplate,
  formSetAltTemplateInfo
} from '../../redux/actions/form-new.actions';
import { AppStore } from '../../redux/store/app.store';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private restoreWIPDocument;
  constructor(
    private dataService: DataService,
    private clientAccessService: ClientAccessService,
    private unsavedDocumentService: UnsavedDocumentService,
    private localStorageService: LocalStorageService,
    private appStore: AppStore<IApplicationState>,
    private keyValueStorageService: KeyValueStorageService
  ) {
  }

  /**
   * Return the cached document that can be the document getting from DB or successful created document on form
   * @param documentId the document Id
   * @returns the existing document
   */
  getExistingDocument(documentId) {

    return cloneDeep(this.appStore.getState().formState.orgDocuments[documentId]);
  }

  private callbackResult(callback, result) {
    if (typeof callback === 'function') {
      callback(result);
    }
  }

  private async execFindDocument(document, callback): Promise<any> {

    let hasAccess = this.clientAccessService.checkAccess(document, SharedConstants.OPERATION_TYPE_READ);
    if (hasAccess) {

      // return the cached document in a callback and promise
      this.callbackResult(callback, document);

      return document;

    } else {

      if (document.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE
        && document.altTemplateIds && document.altTemplateIds.length !== 0) {

        const docIds = [];
        for (let i = 0; i < document.altTemplateIds.length; i++) {
          docIds.push(document.altTemplateIds[i]);
        }

        const query = {
          'query': {
            'bool': {
              'must': [
                { 'terms': { 'documentId': docIds } }
              ]
            }
          }
        };

        const results = await this.dataService.findByElastic(query);

        let accessibleDocument;
        const templates = results.data.hits.hits;

        for (let i = 0; i < templates.length; i++) {
          const result = templates[i]._source;

          hasAccess = this.clientAccessService.checkAccess(result, SharedConstants.OPERATION_TYPE_READ);

          if (hasAccess) {

            logger.info('Returning Alt Template');

            accessibleDocument = result;

            break;
          }
        }

        if (accessibleDocument) {

          this.callbackResult(callback, accessibleDocument);

          return Promise.resolve(accessibleDocument);

        }

      }

      return Promise.reject(UtilError.createForbiddenError(document, SharedConstants.OPERATION_TYPE_READ));

    }
  }

  /**
   * return the document using a promise. This will be used to load documents into the data that is bound to,
   * before determining whether the document is a template or other document
   * @param documentId
   * @param options
   * @param callback
   */
  async getDocumentOnly(documentId, options?, callback?): Promise<any> {

    // if the document is already in the cache, return the document from the cache, otherwise get it from the
    // data service
    const document = this.getExistingDocument(documentId);

    if (document) {

      if (document.systemHeader && document.systemHeader.deleted) {

        const deletedMsg = 'Document has been deleted';
        return Promise.reject(UtilError.createError(deletedMsg, UtilError.DELETED_ERROR));

      } else {

        return await this.execFindDocument(document, callback);

      }

    } else {

      const data = await this.dataService.getDocument(documentId);

      if (data.systemHeader && data.systemHeader.deleted) {

        const deletedMsg = 'Document has been deleted';
        return Promise.reject(UtilError.createError(deletedMsg, UtilError.DELETED_ERROR));

      } else {

        this.appStore.dispatch(formSetOrgDocument(data));

        return await this.execFindDocument(data, callback);
      }
    }

  }

  async getTemplateAndDocument(id: string, templateId?: string) {
    const result = await this.dataService.getTemplateAndDocument(id, templateId);
    const { document, template } = result;

    if (document) {
      let versionId = null;
      if (document.systemHeader.currentVersion === false || (id !== document.documentId && id === document.systemHeader.versionId)) {
        versionId = document.systemHeader.versionId;
      }

      this.setLoadedDocumentOnly(result.document, versionId);
    }

    if (template) {
      this.setLoadedTemplateOnly(template);
    }

    return result;
  }

  getLoadedDocument(documentId: string) {
    return cloneDeep(this.appStore.getState().formState.documents[documentId]);
  }

  getLoadedTemplate(templateId: string) {
    return cloneDeep(this.appStore.getState().formState.templates[templateId]);
  }

  getAltTemplate(documentId: string) {
    const templateId = this.appStore.getState().formState.altTemplateInfo[documentId];
    return this.getLoadedTemplate(templateId);
  }

  getloadedTemplateForDoc(document, formParameters) {

    const state = this.appStore.getState().formState;
    const documentId = document.documentId;
    let templateId;

    if (formParameters && formParameters.overrideTemplateId) {
      templateId = formParameters.overrideTemplateId;

    } else {
      const documentInfoItem = state.documentInfo[formParameters.unsavedDocumentListId];
      const docInfo = documentInfoItem[documentId];
      templateId = docInfo?.templateId;
    }

    if (!templateId) {
      templateId = document.systemHeader.templateId;
    }

    if (templateId) {
      return cloneDeep(state.templates[templateId]);
    }

  }

  /**
   * get the document
   * @param documentId the document Id
   * @param documentData - the result. The result will be put under a data element in documentData so it can
   * be bound to
   * @returns promise
   */
  async getDocument(documentId: string, documentData: any): Promise<any> {

    if (!documentData || typeof documentData !== 'object') {
      const msg = 'The documentData needs to be an object so data can be set under it, which allows it to be bound to';
      throw UtilError.createError(msg);
    }

    await this.getDocumentOnly(documentId);

    let document = this.getExistingDocument(documentId);

    if (document) {
      documentData.document = document;

    } else {

      document = await this.dataService.getDocument(documentId);

      this.appStore.dispatch(formSetDocument(document));

      documentData.document = document;
    }

    return document;

  }

  private setInitialValues(document, template) {
    const _self  = this;
    let hasInitialized = false;

    if (template && template.components && template.components.length) {
      if (!document) {
        document = {};
      }

      const documentId = UtilDocument.isNew(document) ? template.documentId : document.documentId;
      const initialDataKey = 'initialData:' + documentId;
      let initialData = null;
      try {

        initialData = JSON.parse(this.localStorageService.getItem(initialDataKey));
        if (initialData && initialData.documentId && UtilDocument.isNew(document)) {
          document.documentId = initialData.documentId;
        }
        _self.restoreWIPDocument = initialData;
      } catch (e) {
      }

      localStorage.removeItem(initialDataKey);

      template.components.forEach(function(component) {
        
        if (!component.name) {
          return;
        }

        if (!UtilType.hasValue(document[component.name])) {

          if (UtilType.hasValue(component.defaultValue)) {
            document[component.name] = component.defaultValue;
          }
        }
      });

    }

    return hasInitialized;
  }

  /**
   * initialise a new document. The documentId will be set straight away so it can be passed through to child
   * docs without waiting for the main document to be saved. This allows the save to be performed in one operation
   * and reduces the chance of the child doc being saved without having the parent id set
   * @param documentData the result inside documentData object
   */
  private initialiseNewDocument(documentData, template, formParameters) {

    if (documentData && template) {

      // set the isNew value to indicate that the document is a new document being created
      documentData.isNew = true;

      const newDocument = this.setInitialDocument(template);
      if (!newDocument.documentId){
        newDocument.documentId = UtilDocumentId.generateId();
      }

      if (documentData.document) {
        extend(newDocument, documentData.document);
      }

      this.updateDocumentData(documentData, newDocument, template, formParameters);

      logger.info('Initialised new document: ' + newDocument.documentId);

    } else {

      logger.error('Template not provided when initialising new document');
    }
  }

  /**
   * set the document in unsaved loaded docs and set the document to documentData.document. This will be used
   * to check for modifications and on saving the documents
   * @param document the data
   * @param unsavedDocumentListId the unsaved document list Id
   */
  private setInLoadedDocs(document, unsavedDocumentListId) {

    if (document && document.documentId) {

      // copy the document and set to original documents list so that the loaded doc on form isn't a reference to the original
      this.unsavedDocumentService.addDocumentToUnsavedList(document, unsavedDocumentListId);
      this.setOriginalDocumentOnly(cloneDeep(document));

    } else {

      logger.error('Attempting to add null or undefined document to loaded docs');
    }
  }

  /**
   * set the template in unsaved loaded templates and set the template to documentData.template.This will be
   * used to store modified version of the template by rules
   * associate to its document.
   * @param template the data
   * @param unsavedDocumentListId the unsaved document list Id
   * @param orgTemplateId the org template
   */
  private setInLoadedTemplates(document, template, unsavedDocumentListId, orgTemplateId?) {

    if (template && template.documentId) {

      // copy the template and set to original documents list so that the loaded template isn't a reference to the original
      this.unsavedDocumentService.addTemplateToUnsavedList(document, template, unsavedDocumentListId, orgTemplateId);

    } else {

      logger.error('Attempting to add null or undefined template to loaded templates');
    }
  }

  private updateDocumentData(documentData, document, template, formParameters) {

    documentData.template = cloneDeep(template);
    documentData.templateId = template.documentId;
    if (formParameters.overrideTemplateId) {
      // set the override template id in the current document if specified, so that the override
      // template will be used in the save
      documentData.overrideTemplateId = formParameters.overrideTemplateId;
    }

    documentData.document = cloneDeep(document);
    documentData.documentId = document.documentId;

  }

  private updateDocumentInfoProperties(unsavedDocumentListId, documentData, formParameters) {

    const docInfo: DocumentInfo = {
      documentListId: unsavedDocumentListId,
      documentId: documentData.document.documentId,
      isMainDoc: formParameters.isMainDoc,
      isNew: documentData.isNew
    };

    // set the override template id in the current document if specified, so that the override
    // template will be used in the save
    if (formParameters.overrideTemplateId) {
      docInfo.overrideTemplateId = formParameters.overrideTemplateId;
    }

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  }

  private getTemplateForDocumentErrorDetails(templateReturnStatus,templateId, templateError ) {
      if (templateReturnStatus === UtilHttpStatus.NOT_FOUND) {
        return UtilError.createError('Template for document not found: ' + templateId);
      } else if (templateReturnStatus === UtilHttpStatus.FORBIDDEN) {
        return UtilError.createError(templateError.message);
      } else if (templateError?.name === UtilError.DELETED_ERROR || templateReturnStatus === UtilHttpStatus.GONE || templateError?.type === SharedConstants.ERROR_TYPE_GONE) {
        return UtilError.createError('Template for document has been deleted: ' + templateId);
      } else if (templateError) {
        return UtilError.createError('Error getting template: ' + templateError.message ? templateError.message : templateError);
      } else {
        return UtilError.createError('Error getting template for document : ' + templateId);
      }

  }

  // get the template for the document. If it is a template, return the document itself, otherwise return the
  // template matching the systemHeader.templateId of the document
  async getTemplateForDocument(formParameters: FormParameters, documentData): Promise<any> {

    const documentId = formParameters.selectedDocumentId;
    const unsavedDocumentListId = formParameters.unsavedDocumentListId;
    const overrideTemplateId = formParameters.overrideTemplateId

    try {
      this.restoreWIPDocument = null;
      if (!documentData || typeof documentData !== 'object') {

        const msg = 'documentData needs to be an object so data can be set under it, which allows it to be bound to';
        logger.error(msg);

        throw UtilError.createError(msg);

      }

      let document  = this.getLoadedDocument(documentId);

      let templateId = overrideTemplateId;
      if (!templateId && document && document.systemHeader && document.systemHeader.templateId) {
        templateId = document.systemHeader.templateId;
      } else if (!templateId) {
        templateId = documentId;
      }

      let template = this.getLoadedTemplate(templateId);
      if (!template) {
        template = this.getAltTemplate(documentId);
      }

      if (!template) {
        const returnObj = await this.getTemplateAndDocument(documentId, overrideTemplateId);
        document = returnObj.document;
        template = returnObj.template;
        if (returnObj.templateReturnStatus !== UtilHttpStatus.OK) {
          let templateId = overrideTemplateId;
          if (!templateId && document && document.systemHeader && document.systemHeader.templateId) {
            templateId = document.systemHeader.templateId;
          }
          throw this.getTemplateForDocumentErrorDetails(returnObj.templateReturnStatus, templateId, returnObj.templateError);
        }
      }

      if (!overrideTemplateId && document?.systemHeader?.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE) {
        template = document;
        document = null;
      }

      if (document) {
        this.appStore.dispatch(formSetOrgDocument(document));
      }
      if (template) {
        this.appStore.dispatch(formSetOrgDocument(template));
      }
      template = this.updateDisableSaveTemplate(documentData.isNew, template, document);

      if (!document) {

        documentData.isNew = true;

        // initialise the document as a new document and set into loaded docs
        // Set document into documentData as well
        this.initialiseNewDocument(documentData, template, formParameters);
        // add the document to loaded docs in store, so it will be saved and have dirty checking applied
        this.setInLoadedDocs(cloneDeep(documentData.document), unsavedDocumentListId);
        // add the template to loaded docs into store, so it will be modified and have field mandatory/visible/disable checking applied
        this.setInLoadedTemplates(documentData.document, documentData.template, unsavedDocumentListId);

      } else {

        documentData.isNew = false;

        document = cloneDeep(document);

        // now set template into loaded templates and assign the its data to the documentData.template
        this.setInLoadedTemplates(document, template, unsavedDocumentListId);
        // update static values from template into existing document
        this.setInitialValues(document, template);
        // add the document to the unsaved loaded docs so that it can be compared with the
        // original in this DocumentService.document.docs when saving
        this.setInLoadedDocs(document, unsavedDocumentListId);
        this.updateDocumentData(documentData, document, template, formParameters);
      }

      this.updateDocumentInfoProperties(unsavedDocumentListId, documentData, formParameters);
      return Promise.resolve({"restoreWIPDocument" : this.restoreWIPDocument});
    } catch (err) {
      let errorMsg;
      if (err.name === UtilError.NOT_FOUND_ERROR) {
        errorMsg = 'Document not found: ' + documentId;
      } else if (err.name === UtilError.FORBIDDEN_ERROR) {
        errorMsg = err.message;
      } else if (err.name === UtilError.DELETED_ERROR) {
        errorMsg = 'Document was deleted: ' + documentId;
      } else {
        errorMsg = 'Error getting template or document: ' + err.message ? err.message : err;
      }

      logger.error(errorMsg);

      return Promise.reject(UtilError.createError(errorMsg, err.name));

      // throw new Error("Error getting template and document: " + err.message);
    }
  }

  async getTemplateForDocument_OLD(formParameters: FormParameters, documentData): Promise<any> {

    const targetDocumentId = formParameters.selectedDocumentId;
    const unsavedDocumentListId = formParameters.unsavedDocumentListId;
    const overrideTemplateId = formParameters.overrideTemplateId
    const _self = this;

    if (!documentData || typeof documentData !== 'object') {

      const msg = 'documentData needs to be an object so data can be set under it, which allows it to be bound to';
      logger.error(msg);

      return Promise.reject(UtilError.createError(msg));

    } else {

      try {
        const options = {};
        const data = cloneDeep(await _self.getDocumentOnly(targetDocumentId, options));

        if (data) {

          if (data.systemHeader && data.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE && !overrideTemplateId) {

            documentData.isNew = true;

            const template = _self.updateDisableSaveTemplate(documentData.isNew, data, documentData.document);

            // initialise the document as a new document and set into loaded docs
            // Set document into documentData as well
            _self.initialiseNewDocument(documentData, template, formParameters);

            // add the document to loaded docs in store, so it will be saved and have dirty checking applied
            _self.setInLoadedDocs(cloneDeep(documentData.document), unsavedDocumentListId);

            // add the template to loaded docs into store, so it will be modified and have field mandatory/visible/disable checking applied
            _self.setInLoadedTemplates(documentData.document, documentData.template, unsavedDocumentListId);

            _self.updateDocumentInfoProperties(unsavedDocumentListId, documentData, formParameters);

            return Promise.resolve();

          } else {

            // use the override template id if specified, otherwise get the templateId from the document systemHeader
            let templateId = overrideTemplateId;

            if (!templateId && data.systemHeader && data.systemHeader.templateId) {
              templateId = data.systemHeader.templateId;
            }

            if (templateId) {

              try {

                documentData.isNew = false;

                // get the template for the document
                let template = await _self.getDocumentOnly(templateId, options);

                template = _self.updateDisableSaveTemplate(documentData.isNew, template, data);

                const document = data;

                // update static values from template into existing document
                _self.setInitialValues(document, template);

                // add the document to the unsaved loaded docs so that it can be compared with the
                // original in this DocumentService.document.docs when saving
                _self.setInLoadedDocs(document, unsavedDocumentListId);

                // now set template into loaded templates and assign the its data to the documentData.template
                _self.setInLoadedTemplates(document, template, unsavedDocumentListId, templateId);

                _self.updateDocumentData(documentData, document, template, formParameters);

                _self.updateDocumentInfoProperties(unsavedDocumentListId, documentData, formParameters);

                return Promise.resolve();

              } catch (err) {

                let errorMsg;
                if (err.name === UtilError.NOT_FOUND_ERROR) {
                  errorMsg = 'Template for document not found: ' + templateId;
                } else if (err.name === UtilError.FORBIDDEN_ERROR) {
                  errorMsg = err.message;
                } else if (err.name === UtilError.DELETED_ERROR) {
                  errorMsg = 'Template for document has been deleted: ' + templateId;
                } else {
                  errorMsg = 'Error getting template: ' + err.message ? err.message : err;
                }

                logger.error(errorMsg);

                return Promise.reject(UtilError.createError(errorMsg, err.name));
              }

            } else {

              const errorMsg = 'No Template for document found by override template id or ' +
                'systemHeader.templateId: ' + targetDocumentId;
              logger.error(errorMsg);

              return Promise.reject(UtilError.createError(errorMsg));
            }
          }

        } else {

          const message = 'Document not found: ' + targetDocumentId;
          logger.error(message);
          return Promise.reject(UtilError.createError(message));
        }

      } catch (err) {
        let error;

        if (err.name === UtilError.NOT_FOUND_ERROR) {
          error = UtilError.createError('Document not found: ' + targetDocumentId, err.name);
        } else if (err.name === UtilError.FORBIDDEN_ERROR) {
          error = err;
        } else if (err.name === UtilError.DELETED_ERROR) {
          error = UtilError.createError('Document was deleted: ' + targetDocumentId, err.name);
        } else {
          error = UtilError.createError(
            'Error getting document to determine template: ' +
            err.message ? err.message : err, err.name);

          if (err.statusText) {
            error.status = err.status;
            error.statusText = err.statusText;
          }

        }

        return Promise.reject(error);
      }
    }
  }

  private updateDisableSaveTemplate(isNew, template, document?) {

    const canCreate = this.clientAccessService.checkAccess(template, SharedConstants.OPERATION_TYPE_CREATE);
    const canUpdate = this.clientAccessService.checkAccess(document, SharedConstants.OPERATION_TYPE_UPDATE);

    template = cloneDeep(template);

    if ((isNew && !canCreate) || (!isNew && !canUpdate)) {

      if (template.components) {

        for (let i = 0; i < template.components.length; i++) {

          if (!template.components[i].disableSave) {

            template.components[i].enabled = false;

          }
        }

      }
    }

    this.setLoadedTemplateOnly(template);

    return template;
  }

  /**
   * return the template for the document passed in
   * @param doc - the document to get the template for
   * @returns the template object
   */
  getTemplateFromDoc(doc) {

    if (doc && doc.systemHeader && doc.systemHeader.templateId) {

      return this.getLoadedTemplate(doc.systemHeader.templateId);

    } else {

      logger.warn('No template specified for document in systemHeader');
    }

    return null;
  }

  setLoadedTemplateOnly(tpl, versionId?) {
    this.appStore.dispatch(formSetTemplate(tpl, versionId));
  }

  setAltTemplateInfo(documentId, templateId) {
    this.appStore.dispatch(formSetAltTemplateInfo(documentId, templateId));
  }

  setLoadedDocumentOnly(doc, versionId?) {
    this.appStore.dispatch(formSetDocument(doc, versionId));
  }

  setOriginalDocumentOnly(doc, versionId?) {
    this.appStore.dispatch(formSetOrgDocument(doc, versionId));
  }

  /**
   * reset original doc to their clean state, with the latest copy of the original doc, so that any changes in
   * updates can be detected, also put new document after created successful on form.
   * @param doc - the doc to reset
   */
  resetOriginalDocument(doc) {

    if (doc) {

      this.setLoadedDocumentOnly(doc);
      this.setOriginalDocumentOnly(doc);
    }
  }

  resetLoadedTemplate(templateId) {
    const template = this.appStore.getState().formState.orgDocuments[templateId];
    if (template) {
      this.setLoadedTemplateOnly(template);
    }
  }

  /**
   * reset original version to their clean state, with the latest copy of the original doc, so that any changes in
   * updates can be detected, also put new document after created successful on form.
   * @param version - the version to reset
   */
  resetOriginalVersion(version) {

    if (version) {

      this.setLoadedDocumentOnly(version, version.systemHeader.versionId);
      this.setOriginalDocumentOnly(version, version.systemHeader.versionId);
    }
  }

  clearDocuments() {

    this.appStore.dispatch(formClearDocuments());

  }

  allDocuments() {

    const storedDocuments = this.appStore.getState().formState.documents;
    const documents = [];
    const keys = Object.keys(storedDocuments);

    keys.forEach(key => {
      documents.push(cloneDeep(storedDocuments[key]));
    });

    return documents;
  }

  getDocuments() {

    return this.allDocuments();
  }

  getOrgDocuments() {
    return this.appStore.getState().formState.orgDocuments;
  }

  setInitialDocument(template) {
    const systemType = template.systemTypeOverride ? template.systemTypeOverride : SharedConstants.SYSTEM_TYPE_DOCUMENT;

    const newDocument: any = {
      systemHeader: {
        templateId: template.documentId,
        systemType: systemType
      }
    };

    this.setInitialValues(newDocument, template);

    return newDocument;
  }

  setDisableSaveFieldDocument(document, template) {
    const key = document.documentId;
    const value = {};
    if (template && template.components && template.components.length) {
      template.components.forEach(tplItem => {
        if (tplItem.disableSave === true) {
          value[tplItem.name] = document[tplItem.name];
        }
      });
    }
    this.appStore.dispatch(formSetDisableSaveFieldDocument(key, value));
  }

  getDisableSaveFieldDocument(documentId: string) {
    const disabledSaveFields = this.appStore.getState().formState.recordedDisableSaveFieldValues[documentId];
    return disabledSaveFields ? cloneDeep(disabledSaveFields) : {};
  }

  resetDisableSaveFieldToDoc(doc) {

    const disabledSaveFields = this.getDisableSaveFieldDocument(doc.documentId);
    const fieldNames = Object.keys(disabledSaveFields);
    fieldNames.forEach(fieldName => {
      doc[fieldName] = disabledSaveFields[fieldName];
    });
  }
}
