import { Injectable } from '@angular/core';
import { IndexedDBConnectorService } from './indexed-dbconnector.service';
import { UtilHttpStatus } from '@formbird/shared';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DexieSearchService {

  constructor(
    private indexedDBConnectorService: IndexedDBConnectorService
  ) {
  }

  private getDatabase() {
    return this.indexedDBConnectorService.getDatabase();
  }

  public search(query) {

    const _self = this;

    return new Promise((resolve, reject) => {

      logger.debug('Doing search in DexieSearchService...');

      if (query.dexieSearchFunction) {

        _self.getDatabase().then(function(db) {

          query.dexieSearchFunction(db, function(err, data) {

            if (err) {

              const message = 'Error in Dexie: ' + err;
              reject(new Error(message));

            } else {
              let results;
              results = {};
              results.total = data.length;
              results.statusCode = UtilHttpStatus.OK;
              results.data = data;
              results.searchProvier = 'dexie';

              resolve(results);
            }
          });
        },(error) => {
          reject(error);
        });

      } else {

        const msg = 'Dexie search not specified for offline querying';
        reject(new Error(msg));
      }

    });
  }
}
