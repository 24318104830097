import { ClientConstants } from './../../constants/ClientConstants';
import { Injectable } from '@angular/core';
import { ChangeSourceType, DocumentChangedInfo, DocumentInfo, FormComponent, StateChangeInfo } from '@formbird/types';
import { Action } from 'redux';
import { Observable, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { formDocumentChanged, formResetDocumentChanged, formSetDocument, formSetDocumentInfo, formSetOrgDocument, formUpdateComponentDefinition, formUpdateInitDocumentData, formSetComponentFlagTemplate, formDocumentLoaded } from '../../redux/actions';
import { select } from '../../redux/decorators/select';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { ValidationService } from '../validation/validation.service';
import { LatestDocumentService } from './latest-document.service';

@Injectable({
  providedIn: 'root'
})
export class ChangedDocumentService {

  @select(['formState', 'resetDocument']) resetDocument$: Observable<any>;

  @select(['formState', 'documents']) documents$: Observable<any>;

  @select(['formState', 'recordedStateChanges']) recordedStateChanges$: Observable<any>;

  @select(['formState', 'recordedComponentTemplateChanges']) recordedComponentTemplateChanges$: Observable<any>;

  @select(['formState', 'rulesProcessingData']) rulesProcessingData$: Observable<any>;

  private latestDocumentService: LatestDocumentService;

  constructor(
    private appStore: AppStore<IApplicationState>,
    private validationService: ValidationService
  ) {
    this.latestDocumentService = LatestDocumentService.getInstance();
  }

  dispatchAction(action: Action) {
    this.appStore.dispatch(action);
  }

  /**
   * Provide the change information for the specified document field that the component binds to
   * @param component the component binds to the field
   * @param newValue the changed/new value
   * @param controlName the registered control manages the field value
   * @param options the options
   */
  valueChanged(component: FormComponent, newValue: any, controlName?: string, options?: any) {

    const fieldName = component?.fieldName;
    if (!fieldName) {
      const msg = `Name not specified in component definition of component ${component?.componentDefinition?.componentName} for value: ${JSON.stringify(newValue)}. Please ensure the components entry has a name in the component definition.`;
      console.error(msg);
      throw new Error(msg);
    }

    const documentChangedInfo: DocumentChangedInfo = {
      documentId: component.document.documentId,
      fieldName: fieldName,
      newValue: newValue,
      formParameters: component.formParameters,
      // default source value change is made from the component
      sourceType: options?.sourceType ? options?.sourceType : ChangeSourceType.COMPONENT,
      controlName: controlName
    };

    this.appStore.dispatch(formDocumentChanged(documentChangedInfo));
  }

  executeOnFieldChangeWithNoChanges(component: FormComponent) {
    const documentChangedInfo: DocumentChangedInfo = {
      documentId: component.document.documentId,
      fieldName: component.fieldName,
      newValue: null,
      formParameters: component.formParameters,
      // default source value change is made from the component
      sourceType: ChangeSourceType.COMPONENT,
      shouldCompareValues: false
    };
    this.validationService.executeOnFieldChangeWithNoChanges(documentChangedInfo);
  }

  resetChangeDocument() {
    this.appStore.dispatch(formResetDocumentChanged());
  }

  notifyIntializedField(component: FormComponent, shouldDispatchEmptyFieldValue?: boolean) {
    // const fieldName = component?.fieldName;
    // if (!fieldName) {
    //   return;
    // }
    // const unsavedDocumentListId = component?.formParameters?.unsavedDocumentListId;
    // const documentId = component?.document?.documentId;
    // const fieldValue = shouldDispatchEmptyFieldValue !== false ? component?.fieldValue : undefined;
    // this.appStore.dispatch(formUpdateInitDocumentData(unsavedDocumentListId, documentId, fieldName, fieldValue));
  }

  private isChangedFromTheSameSource(stateChanges: StateChangeInfo, fieldName: string, documentId, controlName?: string) {
    const changedSourceInfo = stateChanges?.changedSourceInfo;
    return changedSourceInfo
      && changedSourceInfo.type === ChangeSourceType.COMPONENT
      && changedSourceInfo.fieldName === fieldName
      && changedSourceInfo.documentId === documentId
      && changedSourceInfo.controlName === controlName;
  }

  /**
   * Listen value changes on a specified document field
   * @param fieldName the field name of document
   * @param documentId the document id
   * @param controlName the registered control that holds the field value in this component. It is used to check on the source of change
   * to avoid applying changes itself and also applying the value from the components that share the field name.
   * @param options provides more information for the watch.
   */
  watch(fieldName, documentId, controlName?: string, options?: any) {
    return this.recordedStateChanges$.pipe(
      filter((stateChanges: StateChangeInfo) => {
        const docExists = this.appStore.getState().formState.documents[documentId] && stateChanges?.changedSourceInfo?.documentId === documentId;
        const con1 = stateChanges?.changedSourceInfo?.resetDocument;
        const con2 = fieldName === stateChanges?.changedSourceInfo.fieldName
          && (!this.isChangedFromTheSameSource(stateChanges, fieldName, documentId) || options?.forceWatch);
        return docExists && (con1 || con2);
      }),
      map((stateChanges: StateChangeInfo) => {      
        return this.appStore.getState().formState.documents[documentId][fieldName];
      })
    );
  }

  watchComponentTemplate(templateId) {

    return this.rulesProcessingData$.pipe(
      filter((rulesProcessingData) => {
        return this.appStore.getState().formState.templates[templateId] &&
          rulesProcessingData[templateId] && rulesProcessingData[templateId].status === ClientConstants.RULES_STATUS_DONE;
      }),
      map((rulesProcessingData) => {
        return this.appStore.getState().formState.templates[templateId].components;
      })
    );
  }

  watchComponentDef(fieldName, templateId, componentName?) {
    return this.recordedComponentTemplateChanges$.pipe(
      filter((componentDefChanges: any) => {
        const hasChangedFieldName = componentDefChanges[templateId] && componentDefChanges[templateId][fieldName];
        return !!componentName ? componentDefChanges[templateId][fieldName].componentName === componentName : hasChangedFieldName;
      }),
      map((componentDefChanges: any) => {
        const components = this.appStore.getState().formState.templates[templateId].components;
        for (let i = 0; i < components.length; i++) {
          if (components[i].name === fieldName && (!componentName || componentName === components[i].componentName)) {
            return components[i];
          }
        }
      })
    );
  }

  watchDocument(documentId) {
    return this.recordedStateChanges$.pipe(
      filter((stateChanges: StateChangeInfo) => {
        return this.appStore.getState().formState.documents[documentId] &&
          stateChanges?.changedSourceInfo.documentId === documentId;
      }),
      map((stateChanges: StateChangeInfo) => {
        const document = this.appStore.getState().formState.documents[documentId];
        if (document?.documentId) {
          this.latestDocumentService.latestDocuments[document.documentId] = document;
        }

        return document;
      })
    );

  }

  watchStoreChanges() {
    return this.recordedStateChanges$;
  }

  watchComponentStateChanges(templateId) {
    return this.rulesProcessingData$.pipe(
      filter((rulesProcessingData) => {
        return rulesProcessingData[templateId] && rulesProcessingData[templateId].status === ClientConstants.RULES_STATUS_DONE;
      }),
      map((rulesProcessingData) => {
        return this.appStore.getState().formState.recordedComponentTemplateChanges;
      })
    );
  }

  notifyDocumentChanged(documentChangedInfo: DocumentChangedInfo) {
    this.dispatchAction(formDocumentChanged(documentChangedInfo));
  }

  notifyComponentDefinitionUpdated(templateId, fieldName, key, componentDefinition) {
    this.dispatchAction(formUpdateComponentDefinition(templateId, fieldName, key, componentDefinition));
  }

  setOrgDocument(document) {
    this.dispatchAction(formSetOrgDocument(document));
  }

  setDocumentInfo(unsavedDocumentListId, docInfo: DocumentInfo) {
    this.dispatchAction(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  }

  setDocument(document) {
    this.dispatchAction(formSetDocument(document));
  }

  setComponentFlagTemplate(templateId, fieldName, flagName, flagValue) {
    this.dispatchAction(formSetComponentFlagTemplate(templateId, fieldName, flagName, flagValue));
  }

  documentLoaded(documentId, unsavedDocumentListId, isLoaded) {
    this.dispatchAction(formDocumentLoaded(documentId, unsavedDocumentListId, isLoaded));
  }

  isLoadedDocument(documentId, unsavedDocumentListId) {
    const docInfo = this.appStore.getState().formState?.documentInfo[unsavedDocumentListId];
    return docInfo && docInfo[documentId] && docInfo[documentId].loaded;
  }
}
