'use strict';

/**
 * A constants object for constants that are used on the client-side offline for service worker
 */
export const OfflineConstants = {

  LOGGED_IN_USER_GROUP_CONFIG: 'LoggedInUserGroupConfig',
  DEFAULT_MAX_UPLOAD_SIZE: 50, //size in megabytes
  DOCUMENT_FIELD_NAME_UPLOAD_FILE: 'imageFileNo96',
  OFFLINE_PENDING_DOCUMENT_COUNT: 'offlinePendingDocumentsCount'
};
