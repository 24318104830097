import { Action } from 'redux';
import { OfflineStatus } from '@formbird/types';

const logger = console;

export const OFFLINE_SET_OFFLINE_STATUS = 'OFFLINE_SET_OFFLINE_STATUS';


export interface IOfflineSetOfflineStatusAction extends Action {
  payload: {
    offlineStatus: OfflineStatus;
  };
}

export function offlineSetOfflineStatus(offlineStatus: OfflineStatus): IOfflineSetOfflineStatusAction {
  return {
    type: OFFLINE_SET_OFFLINE_STATUS,
    payload: {
      offlineStatus
    }
  };
}
