import { Injectable } from '@angular/core';

export interface RulesetCacheInfo {
  rulesetId: string;
  rulesetScriptFunc: Function;
}

export interface RulesetCache {
  // key will be the documentId of the ruleset or rulesetInclude document that is cached
  [key: string]: RulesetCacheInfo;
}

@Injectable({
  providedIn: 'root'
})
export class RuleCacheService {
  loadedRulesets: RulesetCache = {
  }

  // loadedRulesetIncludes: RulesetCache = {
  // }

  constructor() { }

  setRuleset(documentId: string, rulesetScript: Function) {
    if (documentId && rulesetScript) {
      let rulesetInfo: RulesetCacheInfo = {
        rulesetId: documentId,
        rulesetScriptFunc: rulesetScript
      };

      this.loadedRulesets[documentId] = rulesetInfo;
    }
  }

  // setRulesetInclude(documentId: string, rulesetIncludeScript: string) {
  //   if (documentId && rulesetScript) {
  //     let rulesetInfo: RulesetCacheInfo = {
  //       rulesetScript: rulesetIncludeScript
  //     };

  //     this.loadedRulesetIncludes[documentId] = rulesetInfo;
  //   }
  // }

  getRuleset(documentId: string): RulesetCacheInfo {
    return documentId ? this.loadedRulesets[documentId] : null;
  }

  // getRulesetInclude(documentId: string): RulesetCacheInfo {
  //   return documentId ? loadedRulesetIncludes[documentId] : null;
  // }
}
