import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { User } from '@formbird/types';
import { DocumentUpdater, ESQueryUtils, RulesetError, SearchResultsProcessor, SharedUrlRoutes, UtilDocumentId } from '@formbird/shared';
import { cloneDeep, isFunction, isEmpty, isObject } from 'lodash';
import { NotificationService } from '../notification/notification.service';
import { ClientIncludeRuleSetService } from './client-include-rule-set.service';
import { Observable } from 'rxjs';
import { DataService } from '../data/data.service';
import { LazyScriptLoaderService } from '../lazyload/lazy-script-loader.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { DocumentService } from '../document/document.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { SearchService } from '../search/search.service';
import { ModifiedFieldService } from '../document/modified-field.service';
import { DomSanitizer } from '@angular/platform-browser';
import { select } from '../../redux/decorators/select';
import { RulesetContext } from './client-rule-set-context';
import { SharedConstants } from '@formbird/types';
import { ClientAccessService } from '../access/client-access.service';
import { SearchClientService} from '../search/search-client.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class ClientRulesService {

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;
  modalService: any;

  constructor(
    private dataService: DataService,
    private loggedInUserService: LoggedInUserService,
    private clientIncludeRuleSetService: ClientIncludeRuleSetService,
    private documentService: DocumentService,
    private offlineStatusService: OfflineStatusService,
    private http: HttpClient,
    private lazyScriptLoaderService: LazyScriptLoaderService,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private modifiedFieldService: ModifiedFieldService,
    private clientAccessService: ClientAccessService,
    private sanitizer: DomSanitizer,
    private searchClientService: SearchClientService,

  ) {
    this.user$.subscribe((data: User) => this.user = data);
  }

  private sharedValues = {} as any;

  async expose() {
    const rulesetContext = new RulesetContext();
    rulesetContext.user = this.user;

    rulesetContext.sharedValues = this.sharedValues;

    rulesetContext.modalService = this.modalService;

    rulesetContext.dataService = this.dataService;

    rulesetContext.DataService = this.dataService;  // used in rules for the old code convention

    rulesetContext.ModifiedFieldService = this.modifiedFieldService;

    rulesetContext.lazyLoader = this.lazyScriptLoaderService;

    rulesetContext.loggedInUserService = this.loggedInUserService;

    rulesetContext.clientIncludeRuleSetService = this.clientIncludeRuleSetService;

    rulesetContext.documentService = this.documentService;

    rulesetContext.offlineStatusService = this.offlineStatusService;

    rulesetContext.http = this.http;

    rulesetContext.searchService = this.searchService;

    rulesetContext.notificationService = this.notificationService;

    rulesetContext.q = Promise;

    rulesetContext.logger = logger;

    rulesetContext.ModalService = this.modalService;

    rulesetContext.hasDuplicate = this.hasDuplicate;

    rulesetContext.showNotification = this.showNotification;

    /**
     * returns a boolean indicating whether the device is connected
     */
     rulesetContext.isOnline = this.offlineStatusService.isConnected;

     rulesetContext.updateDocument = this.updateDocument;

     rulesetContext.deleteDocument = this.deleteDocument;

     rulesetContext.findDocuments = this.findDocuments;

     rulesetContext.findDocumentsByElastic = this.findDocumentsByElastic;

     rulesetContext.findDocumentsBySearch = this.findDocumentsBySearch;

    /*
        * Including ruleset processing.
        * Include ruleset syntax = #include 'rulesetName';
        */
    rulesetContext.addIncludesToRuleset = this.addIncludesToRuleset;

    rulesetContext.createDocumentFromTemplate = this.createDocumentFromTemplate;

    rulesetContext.setSharedValue = this.setSharedValue;

    rulesetContext.getSharedValue = this.getSharedValue;

    rulesetContext.processSearchResults = SearchResultsProcessor.processSearchResults;

    rulesetContext.showDialog = this.showDialog;

    rulesetContext.sanitizer = this.sanitizer;

    rulesetContext.RulesetError = RulesetError;

    rulesetContext.clientAccessService = this.clientAccessService;

    rulesetContext.searchClientService = this.searchClientService;

    rulesetContext.aggregate = this.aggregate;

    return rulesetContext;
  }

  private hasDuplicate(templateId, duplicateCondition, IdToExclude) {
    const _self = this;

    const query = ESQueryUtils.prepareESQuery(duplicateCondition);
    query.query.bool.must.push({ 'systemHeader.templateId': templateId });

    // Execute query
    return new Promise((resolve, reject) => {

      _self.dataService.findByElastic(query).then(
        function successFunc(results) {

          // Check excluded ID
          let result = false;

          for (let i = 0; i < results.data.hits.total; i++) {

            if (results.data.hits.hits[i]._source.documentId !== IdToExclude) {
              result = true;
              break;
            }

          }

          resolve(result);
        },

        function errorFunc(err) {
          reject(err);
        }
      );

    });

  }

  private showNotification(message, status, options?) {
    options = options || {};
    let messageType = 'info';

    // 18587#c82372 - allow options to be the second parameter
    if (typeof status === 'object') {
      options = status;
      messageType = options.messageType || messageType; 
    } else {
      messageType = status || messageType;
    }

    options.messageType = messageType.toLowerCase();
    this.notificationService.printMessage(message, options, options?.title, { x: 'right', y: 'top' });
  }

  private updateDocument(id, change, userId, options, callback) {
    // backward compatibility
    if (typeof options === 'function') {
      callback = options;
    }

    const _self = this;

    function convertToErrorObject(error){
      let err = error;
      if (typeof err === 'string' ){
        err = new Error(err);
      }

      return err;
    }

    async function checkAndDoPreSaveOffline(document, callback) {
      const shouldRulPreSaveOffline = !_self.offlineStatusService.isConnected() 
        && _self.offlineStatusService.isOfflineMode();

      const templateId = document?.systemHeader?.templateId;
      if (shouldRulPreSaveOffline && templateId) {

        const unsavedDocumentService = (<any>window).FormbirdServiceInjector.UnsavedDocumentService;
        const validationService = (<any>window).FormbirdServiceInjector.ValidationService;

        try {
          const documentContext = {} as any;
          documentContext.template = await _self.dataService.getDocument(templateId);
          documentContext.templateId = templateId;

          documentContext.unsavedDocumentListId = unsavedDocumentService.createUnsavedDocumentList();

          if (!documentContext.formParameters) {
            const unsavedDocumentListId = documentContext.unsavedDocumentListId;
            const hierarchyInfo = unsavedDocumentService.getHierarchyInfo(unsavedDocumentListId, document.documentId);
            documentContext.formParameters = {};
            documentContext.formParameters.hierarchyInfo = hierarchyInfo;
            documentContext.formParameters.unsavedDocumentListId = unsavedDocumentListId;
          }

          const rulesData: any = {
            eventName: SharedConstants.RULESET_RELATIONSHIPS.PRESAVE_OFFLINE_EVENT_NAME.name,
            document,
            documentContext,
            callback,
            shouldRun: true // bypass loadedDocument check
          };

          await validationService.executeDocumentEventPromise(rulesData);
          callback();
        } catch (error) {
          const errObj = convertToErrorObject(error);
          callback(errObj);
        } finally {
          validationService.setRuleStatusDone(templateId);
        }
      } else {
        callback();
      }
    }

    function doUpdateDoc(orgDoc) {
      try {
          const clonedDoc = cloneDeep(orgDoc);

          DocumentUpdater.applyUpdatesSimple(clonedDoc, change);

          checkAndDoPreSaveOffline(clonedDoc, (err) => {
            if (err) {
                const errObj = convertToErrorObject(err);
                callback(errObj);
            } else {
              _self.dataService.jsonPatchUpdate(orgDoc, clonedDoc).then(
                function successFunc(doc) {
                  callback(null, doc);
                },
                function errorFunc(err) {
                  const errObj = convertToErrorObject(err);
                  callback(errObj);
                }
              );
            }
          });
      } catch (error){
        const errObj = convertToErrorObject(error);
        callback(errObj);
      }
    }

    const originalDoc = _self.documentService.getExistingDocument(id);
    if (!originalDoc) {

      _self.dataService.getDocument(id).then(
        function successFunc(orgDoc) {
          doUpdateDoc(orgDoc);
        },
        function errorFunc(err) {
          callback(err);
        }
      );

    } else {
      doUpdateDoc(originalDoc);
    }

  }

  private deleteDocument(id, options, callback) {

    const _self = this;

    if (isFunction(options)) {
      callback = options;
      options = null;
    }

    if (id) {

      _self.dataService.destroy(id).then(
        function successFunc(doc) {
          callback();
        },
        function errorFunc(err) {
          callback(err);
        }
      );
    }
  }

  private findDocuments(query, userId, callback) {
    logger.warn('===== Deprecated function: findDocuments. Use findDocumentsByElastic instead. =====');
    logger.warn('Passing userId to findDocuments is ignored. The logged in user will be used');

    const queryString = '?q=' + JSON.stringify(query);
    const url = SharedUrlRoutes.serverRoutes.loadDocsForRulesBaseUrl + '/' + userId + queryString;

    this.http.get(url).subscribe(
      (response) => {
        callback(null, response);

      },
      (err) => {
        callback(err);
      });
  }

  addIncludesToRuleset(ruleFlow, callback) {
    this.clientIncludeRuleSetService.addIncludesToRuleset(ruleFlow, callback);
  }

  private createDocumentFromTemplate(docInfo, templateId, userId, callback) {
    const _self = this;
    const loggedInUserId = this.user.account.documentId;

    if (userId && userId !== loggedInUserId) {
      logger.warn('Logged in user id will be used rather than the user id parameter ' +
        'passed to createDocumentFromTemplate');
    }

    if (!docInfo.documentId) {
      docInfo.documentId = UtilDocumentId.generateId();
    }

    if (!docInfo.systemHeader) {
      docInfo.systemHeader = {};
    }
    if (!docInfo.systemHeader.systemType) {
      docInfo.systemHeader.systemType = SharedConstants.SYSTEM_TYPE_DOCUMENT;
    }
    docInfo.systemHeader.templateId = templateId;

    this.dataService.getDocument(templateId, SharedConstants.SYSTEM_TYPE_TEMPLATE).then(
      function successFunc(template) {
        _self.clientAccessService.writeKeysToDocument(docInfo, template);
        _self.dataService.insert(docInfo).then(
          function successFunc(result) {
            callback(null, result);
          },
          function errorFunc(err) {
            callback(err);
          }
        );
      },
      function errorFunc(err) {
        callback(err);
      }
    );


  }

  private aggregate(query) {
    return this.dataService.aggregate(query);
  }

  public setSharedValue(key, value) {
    this.sharedValues[key] = value;
  }

  public getSharedValue(key) {
    return this.sharedValues[key];
  }

  findDocumentsBySearch(query, userId, searchContext, options, callback) {

    const _self = this;

    if (isFunction(searchContext)) {
      callback = searchContext;
      searchContext = {};
    }
    if (isFunction(options)) {
      callback = options;
      options = {};
    }

    if (userId) {
      logger.warn('Passing userId to findDocumentsBySearch is ignored. The logged in user will be used');
    }

    options = isObject(options) ? options : {};
    options.filter = query;

    _self.searchClientService.search(options, searchContext).then(
      function successFunc(data) {
        callback(null, data);
      },
      function errorFunc(err) {
        _self.notificationService.printMessage(err.message, 'error');
        callback(err);
      }
    );

  }

  findDocumentsByElastic(query, userId, searchContext, options, callback) {
    this.findDocumentsBySearch(query, userId, searchContext, options, callback);
  }

  showDialog(message, errorType, succFunc, errorFunc, discardFunc) {

    this.modalService.showDialog(message, errorType, succFunc, errorFunc, discardFunc);

  }

  /** set the modal service. This is set from the application importing the @formbird/services
   *  library so that a native app can set its own implementation of ModalService
   */
  setModalService(modalService: any) {
    this.modalService = modalService;
  }
  
}
