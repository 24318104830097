import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ConfigService,
  DataService,
  IndexedDBPropertiesService,
  LocalStorageKeys,
  LoggedInUserService,
  OfflineStatusService,
  SearchService,
  ClientAccessService,
  ClientResourceService,
  VendorLibraryService,
  SessionTestService,
  select,
  OfflineUtilService,
  NotificationService
} from '@formbird/services';
import { SharedConstants, User, OfflineStatus } from '@formbird/types';
import {
  SharedUrlRoutes,
  UtilArray,
  UtilError,
  AccessManager,
} from '@formbird/shared';
import { UtilSequenceProcess } from '@formbird/shared';
import { Observable } from 'rxjs';
import { CacheMapTilesService } from './tileserver-gl/cache-map-tiles.service';
import { RouterService } from '../../app-routing/router.service';

const serverRoutes = SharedUrlRoutes.serverRoutes;
const strLastSyncStaticResourceCount = "lastSyncStaticResourceCount";
const strLastSyncComponentCount = "lastSyncComponentCount";
const logger = console;

@Injectable({
  providedIn: 'root'
})
export class CacheLoadService {

  @select(['userState', 'user']) user$: Observable<User>;
  @select(['offlineReducer', 'offlineStatus']) offlineStatus$: Observable<OfflineStatus>;

  user: User;
  connected: boolean;

  constructor(
    private dataService: DataService,
    private offlineStatusService: OfflineStatusService,
    private loggedInUserService: LoggedInUserService,
    private indexedDBPropertiesService: IndexedDBPropertiesService,
    private configService: ConfigService,
    private routingService: RouterService,
    private http: HttpClient,
    private clientResourceService: ClientResourceService,
    private searchService: SearchService,
    private clientAccessService: ClientAccessService,
    private cacheMapTilesService: CacheMapTilesService,
    private vendorLibraryService: VendorLibraryService,
    private sessionTestService: SessionTestService,
    private notificationService: NotificationService,
    private offlineUtilService: OfflineUtilService
  ) {
    this.user$.subscribe((user: User) => this.user = user);

    this.connected = true;
    this.offlineStatusService.offlineStatus$.subscribe((offlineStatusData: any) => {
      this.connected = offlineStatusData.connected;
    });
  }

  private checkStorageQuota() {
    const minimumQuotaMegabytes = this.configService.clientConfig().minimumQuotaMegabytes || 2048;
    return new Promise(resolve => {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            navigator.storage.estimate().then(estimate => {
                // Convert the quota from bytes to megabytes
                const quotaInMegabytes = estimate.quota / 1024 / 1024;
                resolve(quotaInMegabytes >= minimumQuotaMegabytes);
            });
        } else {
            // Resolve as true if storage estimation is not supported
            logger.warn("Storage quota cannot be estimated in this browser. " +
              "Please consider using a browser that supports navigator.storage.estimate");
            resolve(true);
        }
    })
  }

  /**
   * enable caching
   */
  async enableCaching() {
    const isQuotaSufficient = await this.checkStorageQuota();
    if (!isQuotaSufficient) {
      throw new Error('There is not enough space to enable offline.');
    }

    if (this.offlineStatusService.isCachingEnabled()) {
      return Promise.resolve('Caching is already enabled');
    }

    // https://developers.google.com/web/tools/workbox/
    // guides/storage-quota#special_chrome_incognito_considerations
    const requiredBytes = 124000000;

    const browserQuota = await this.getStorageQuota();

    // allow offline if cant detect browser quota
    if (browserQuota !== 0 && browserQuota < requiredBytes) {
      throw new Error('Offline mode is disabled in private window.');
    }

    this.offlineStatusService.setCachingEnabledStatus(true);

    // At this point, caching hasn't been enabled yet.
    this.offlineStatusService.setCacheLoadingStatus(true); //isCacheLoading = true
    this.offlineStatusService.setCachingAppStatus(true);
    this.offlineStatusService.setInitialCachingComplete(false);
    this.loggedInUserService.setUserConfigItem('lastSyncTileServerCacheCount', 0);
    this.loggedInUserService.setUserConfigItem(strLastSyncStaticResourceCount, 0)
    this.loggedInUserService.setUserConfigItem(strLastSyncComponentCount, 0)

    let cacheTemplate = null;
    const cachingEnableTemplate = this.configService.clientConfig().cachingEnableTemplate;
    if (cachingEnableTemplate) {
      if (cachingEnableTemplate.length) {
        cacheTemplate = cachingEnableTemplate[0].documentId;
      }
    }

 
    try {
      await this.sessionTestService.testSession();
      await this.loadClientConfig();
      await this.loadIndexedDBIndexes();
      await this.dataService.initialise();

      if (cacheTemplate) {
        this.asyncChangeState(cacheTemplate);
      }

      await this.storeOfflineUserCredentials();
      await this.cacheVendorLibs();
      await Promise.all([this.cacheStaticAssets(), this.loadDataCache(), this.cacheMapTilesService.loadDataCache()]);
      this.offlineUtilService.startOfflinePoller();
      await this.succLoadingCache();
    } catch (err) {
      return await this.errLoadingCache(err);
    }
  }

  private loadClientConfig(): Promise<void> {
    const _self = this;

    return new Promise((resolve, reject) => {

      return _self.http.get(serverRoutes.loadClientConfig, {responseType: 'text'}).subscribe(
        res => {
          eval(res);
          resolve();
        },
        err => {
          reject(err);
        }
      );

    });

  }

  async cacheVendorLibs() {
    if (typeof caches === "undefined") {
      this.notificationService.printMessage('Caching cannot be enabled in a non-secure context.'
        + 'Please ask an administrator to make sure the app is running in HTTPS.', 'error');
      return;
    }

    const accountControlDocument = this.user.accountControlDocument;
    const offlineAccess = AccessManager.getUserAccessKeysForOperation(accountControlDocument,
        SharedConstants.OPERATION_TYPE_OFFLINE);

    if (!offlineAccess || !offlineAccess.length) {
        return;
    }

    const results: any = await this.vendorLibraryService.getVendorLibs();

    const cacheName = "formbird-vendor-libs";
    const cacheStorage = await caches.open(cacheName);

    const cachedLibraries = [];
    results.filter(vendorLib => {
      return this.clientAccessService.hasPermission(vendorLib, SharedConstants.OPERATION_TYPE_OFFLINE);
    }).forEach(async vendorLib => {
      this.addCacheStorage(vendorLib.fileName,cacheStorage, cachedLibraries);

      if (vendorLib.fileNames) {
        for (const fileName of vendorLib.fileNames) {
          this.addCacheStorage(fileName,cacheStorage, cachedLibraries);
        }
      }

      if (vendorLib.vendorLibrariesRel) {
        for (const vendorLibRel of vendorLib.vendorLibrariesRel) {
          this.addCacheStorage(vendorLibRel.fileName,cacheStorage, cachedLibraries);
        }
      }
    });
  }

  private addCacheStorage(fileName, cacheStorage, cachedLibraries) {
    if (!fileName) {
      return;
    }

    const vendorLibraryBasePath = this.configService.clientConfig().vendorLibraryBasePath || '';
    fileName = fileName.replace('${vendorLibraryBasePath}', vendorLibraryBasePath);

    if (fileName && !cachedLibraries.includes(fileName)) {
      cacheStorage.add(fileName);
      cachedLibraries.push(fileName);
    }
  }

  private loadIndexedDBIndexes() {

    const _self = this;

    return _self.dataService.getIndexedDBIndexes().then(
      function(templateIndexes) {
        let indexes = _self.loggedInUserService.getUserConfigItem('searchIndexes');
        indexes = UtilArray.union(indexes, templateIndexes);
        _self.loggedInUserService.setUserConfigItem('searchIndexes', indexes);

        return Promise.resolve();
      },
      function(err) {
        logger.warn('Could not get indexedDBIndexes. If offline, new IndexedDB indexes cannot be created');
        return Promise.reject(err);
      });
  }

  private async getStorageQuota() {
    const nav: any = navigator;
    if (nav?.storage?.estimate) {
        const estimate = await nav.storage.estimate();
        return estimate.quota;
    }

    return 0; // cant read storage quota
  }

  private asyncChangeState(cacheTemplate) {
    this.routingService.navigate(['form', cacheTemplate]);
  }

  private succLoadingCache() {

    this.offlineStatusService.setCacheLoadingStatus(false);
    this.offlineStatusService.setInitialCachingComplete(true);
    this.offlineStatusService.offlineStatus.currentCacheAttachedFileCount = 0;
    const errorCacheCount = this.offlineStatusService.getErrorCacheCount();
    if (!errorCacheCount) {
      return Promise.resolve();
    } else {
      const msg = 'There are ' + errorCacheCount + ' offline documents that were not cached successfully.';
      logger.error(msg);
      return Promise.reject(new Error(msg));
    }

  }

  private async errLoadingCache(err) {
    await this.dataService.clearData();
    this.offlineStatusService.setCacheLoadingStatus(false);
    this.offlineStatusService.setCachingEnabledStatus(false);
    this.offlineStatusService.setInitialCachingComplete(true);
    logger.error('Error on enabling cache. See trace below:');
    logger.error(err);
    if (err.name === UtilError.SERVER_UNRESPONSE_ERROR) {
      return Promise.reject(UtilError.createError('User is offline. Can not enable caching.'));
    }

    return Promise.reject(err);
  }

  private storeOfflineUserCredentials() { //see restoreOfflineUserCredentials

    const _self = this;

    const loggedUserKey = LocalStorageKeys.LOGGED_IN_USER;
    const loggedUser = _self.user.account;

    return _self.indexedDBPropertiesService.setItem(loggedUserKey, loggedUser).then(function() {

      const controlDocumentKey = LocalStorageKeys.LOGGED_IN_ACCOUNT_CONTROL_DOCUMENT;
      const controlDocument = _self.user.accountControlDocument;

      return _self.indexedDBPropertiesService.setItem(controlDocumentKey, controlDocument);

    });
  }

  restoreOfflineUserCredentials() {

    const _self = this;

    const loggedAccount = _self.user.account;
    if (loggedAccount != null) { // no need for restoration
      return Promise.resolve();
    }

    const loggedUserKey = LocalStorageKeys.LOGGED_IN_USER;

    return _self.indexedDBPropertiesService.getItem(loggedUserKey).then(function(result: any) {

      if (result && result.value) {
        _self.loggedInUserService.setUser(result.value);
      } else {
        throw new Error('Failed to retrieve logged user info from IndexedDBPropertiesService');
      }

      const controlDocumentKey = LocalStorageKeys.LOGGED_IN_ACCOUNT_CONTROL_DOCUMENT;

      return _self.indexedDBPropertiesService.getItem(controlDocumentKey);

    }).then(function(result: any) {

      if (result && result.value) {
        _self.loggedInUserService.setUserControlDocument(result.value);
      } else {
        throw new Error('Failed to retrieve logged user control documents from IndexedDBPropertiesService');
      }

    });

  }

  private fetchLiveClientResources() {

    const clientResourcesReq = new Request(serverRoutes.clientResources, { credentials: 'include' });

    return fetch(clientResourcesReq).then(onResourcesFetched).then(addRootFolder);

    function onResourcesFetched(response) {

      if (response.ok) {
        return response.json();
      }

      throw new Error('Error fetching client resources.');
    }

    function addRootFolder(clientResources) {
      //caching root is necessary in order for offline to work.
      clientResources.resources.push('/');

      return clientResources;
    }
  }


  private setStaticResourceStatus() {
    const _self = this;
    if (_self.offlineStatusService.offlineStatus.currentStaticResourceCount
      >= _self.offlineStatusService.offlineStatus.totalStaticResourceCount
      && _self.offlineStatusService.offlineStatus.totalStaticResourceCount !== 0) {
      _self.offlineStatusService.setCachingAppStatus(false);
    } else{
      _self.offlineStatusService.setOfflineStatusToLocalStorage();
    }

  }

  private cacheComponents(clientResources, componentCount) {
    const listUrls = [];
    function forEachComponent(url) {
      listUrls.push(url);
    }

    clientResources.componentResources.map(forEachComponent);

    const job = async (url) => {
      if (!this.offlineStatusService.offlineStatus.cacheLoading) {
        const errMsg = "Caching components terminated due to cache loading cancelled";
        throw new Error(errMsg);
      }

      const compReq = new Request(url, { credentials: 'include' });

      try {
        await fetch(compReq);
      } catch (err) {
        console.log(err);
      } finally {
        this.offlineStatusService.offlineStatus.currentStaticResourceCount++;
        componentCount++;
        this.loggedInUserService.setUserConfigItem(strLastSyncComponentCount, componentCount);
        this.setStaticResourceStatus();
      }
    }
    if (componentCount > 0) {
      listUrls.splice(0, componentCount)
    }
    return UtilSequenceProcess.processList(listUrls, job);
  }

  private cacheResources(clientResources, lastSyncResourceCount) {
    const listUrls = [];
    function forEachResource(url) {
      listUrls.push(url);
    }
    
    clientResources.resources.map(forEachResource);

    const job = async (url) => {
      if (!this.offlineStatusService.offlineStatus.cacheLoading) {
        const errMsg = "Caching components terminated due to cache loading cancelled";
        throw new Error(errMsg);
      }

      var resUrl = url.startsWith('/') ? url : '/' + url;
      var resReq = new Request(resUrl);

      try {
        await fetch(resReq);
      } catch (err) {
        console.error(err);
      } finally {
        this.offlineStatusService.offlineStatus.currentStaticResourceCount++;
        lastSyncResourceCount++;
        this.loggedInUserService.setUserConfigItem(strLastSyncStaticResourceCount, lastSyncResourceCount);
        this.setStaticResourceStatus();
        return null;
      }
    }

    if (lastSyncResourceCount > 0) {
      listUrls.splice(0, lastSyncResourceCount)
    }
    return UtilSequenceProcess.processList(listUrls, job);
  }

  /**
   * cache the static assets that make up the web app, such as html, css, images - so that the static app can be accessed
   * offline. Making a request to each asset is enough to cache it for offline use. The core static assets are cached as part
   * of the workbox service worker built when a new release is built, but components and the libraries that they load are not
   * part of the build because they can't be determined at build time
   */
  async cacheStaticAssets() {
      let clientResources: any = await this.clientResourceService.getClientResources();
      this.offlineStatusService.setTotalStaticResourceCount(clientResources.componentResources?.length + clientResources.resources?.length);

      const lastSyncResourceCount  = this.loggedInUserService.getUserConfigItem(strLastSyncStaticResourceCount) || 0;
      const lastSyncCompCount  = this.loggedInUserService.getUserConfigItem(strLastSyncComponentCount) || 0;
      this.offlineStatusService.offlineStatus.currentStaticResourceCount = lastSyncResourceCount + lastSyncCompCount;

      clientResources = await this.fetchLiveClientResources();
      await this.cacheComponents(clientResources, lastSyncCompCount);
      await this.cacheResources(clientResources, lastSyncResourceCount);
      this.offlineStatusService.offlineStatus.currentStaticResourceCount = 0;
      return true;
  }

  loadDataCache(){
    const self = this;
    let loopCount = 0;
    let lastErr;
    const confOffline = this.configService.clientConfig().offline;
    let retryInterval = confOffline && confOffline.cacheRetryInterval > 0 ? confOffline.cacheRetryInterval : 10000; //default to 10seconds

    function processDataCache(resolve, reject){
      if(navigator.onLine && self.connected) {
        self.offlineStatusService.offlineStatus.errorCacheAttachedFile = [];
        self.offlineStatusService.offlineStatus.currentCacheCount = 0;
        self.offlineStatusService.offlineStatus.maxCacheCount = 0;
        self.offlineStatusService.offlineStatus.currentCacheAttachedFileCount = 0;
        self.offlineStatusService.offlineStatus.maxCacheAttachedFileCount = 0;
        self.loggedInUserService.setUserConfigItem('currentCacheCount', 0);
        self.loggedInUserService.setUserConfigItem('cachingEnableDate', new Date().valueOf());
        self.dataService.workerLoadDataCache().then(async function success(results) {
          resolve(results);
        }, function error(err) {
          lastErr = err;
          logger.error("Error on load data cache : " + err.message);
          if(!navigator.onLine || !self.connected){
            processDataCache(resolve, reject);
          } else {
            reject(err);
          }
        });
      } else {
        loopCount++;
        // We could retry less frequently after 60 seconds, based on cacheRetryInterval config.
        // We want it to keep trying until the connection is regained
        const interval = loopCount < 60 ? 1000 : retryInterval;
        setTimeout(function() {
          if (lastErr) {
            logger.error("Error on load data cache : " + lastErr.message);
          }
          processDataCache(resolve, reject);
        },interval);

      }

    }


    return new Promise((resolve, reject) => {
      processDataCache(resolve, reject);
    });


  }

}
