import { UtilType } from '@formbird/shared';

export class UtilService {

  public static getDataUrl(url, id?) {
    let dataUrl = url;

    if (UtilType.hasValue(id)) {
      dataUrl += '/' + id;
    }

    return dataUrl;
  }

  public static hasValue(val) {
    return UtilType.hasValue(val);
  }

  public static isDefined(val) {
    return UtilType.isDefined(val);
  }

}
