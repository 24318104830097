import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  public urls: any;

  constructor() {
    this.urls = {
      external: null,
      lastEntered: null, // last entered before login
      lastVisited: null, // last visited within the app
      current: null // current viewed url
    };
  }

  getUrls() {
    return this.urls;
  }

}
