import { Injectable } from '@angular/core';
import { DocumentInfo } from '@formbird/types';
import { formResetDocumentInfo, formSetDocumentInfo, formSetupDocumentList } from '../../redux/actions';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentChildDocumentService {

  constructor(
    private appStore: AppStore<IApplicationState>,
    private documentService: DocumentService
  ) {
  }

  // setTemplate(unsavedDocumentListId, template) {

  //   this.documentService.setLoadedDocumentOnly(template);

  //   const docInfo: DocumentInfo = {
  //     templateId: template.documentId
  //   };

  //   this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  // }

  // setDocument(unsavedDocumentListId, document) {

  //   this.documentService.setLoadedDocumentOnly(document);

  //   const docInfo: DocumentInfo = {
  //     documentId: document.documentId
  //   };

  //   this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  // }

  // setDocumentData(unsavedDocumentListId, documentData) {
  //   this.documentService.setLoadedDocumentOnly(documentData.document);
  //   this.documentService.setLoadedDocumentOnly(documentData.template);

  //   const docInfo: DocumentInfo = {
  //     documentId: documentData.document.documentId,
  //     templateId: documentData.template.documentId
  //   };
  //   this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  // }

  // resetDocumentData(unsavedDocumentList) {
  //   this.appStore.dispatch(formResetDocumentInfo(unsavedDocumentList));
  // }

  // setupDocumentData(unsavedDocumentListId) {
  //   this.appStore.dispatch(formSetupDocumentList(false, unsavedDocumentListId));
  // }

}
