import { Injectable } from '@angular/core';
import { ConfigService } from '@formbird/services';
@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  constructor(private configService: ConfigService) {}

  private isIE() {
    if (typeof navigator !== 'undefined' &&
      /MSIE [1-11]\./.test(navigator.userAgent)) {
      return true;
    }

    return false;
  }

  dataURLtoFile(dataurl, filename) {
    if (dataurl.startsWith("data:image/webp")) {
      filename += ".webp"
    }

    const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime});
  }

  resizeImage(file, maxHeight) {
    let { imageViewer } = this.configService.clientConfig();
    let format = imageViewer?.imageCompression?.format || "png";
    let encoderOptions = imageViewer?.imageCompression?.encoderOptions || 0.9;
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const dataUrl = reader.result;
        if (imageViewer.disableCompression ){
          resolve(dataUrl);
          return;
        }

        if (this.isIE()) {
          // just return ta dataUrl, do not resize since it canvas wont work in IE
          resolve(dataUrl);
          return;
        }

        const img = new Image();

        img.onload = () => {
          const { w, h } = this.getResizedDimensions(img, maxHeight);
          if (img.width !== w || img.height !== h) { // if size updated
            var canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, w, h);
            resolve(canvas.toDataURL('image/' + format, encoderOptions));
          } else {
            resolve(img.src);
          }
        }

        img.src = dataUrl.toString();
      }, false);

      reader.readAsDataURL(file);
    });
  }

  determineDimension (width, height, maxImageHeight) {
    let newHeight, newWidth, devisor;
    const result: any = {};
  
    if (height >= maxImageHeight) {
        devisor = height / maxImageHeight;
        newHeight = maxImageHeight;
        newWidth = width / devisor;
        result.image960 = {width: newWidth, height: newHeight};
    } else {
        result.image960 = {width: width, height: height};
    }
  
    if (height >= 96) {
        devisor = height / 96;
        newHeight = 96;
        newWidth = width / devisor;
        result.image96 = {width: newWidth, height: newHeight};
    } else {
        result.image96 = {width: width, height: height};
    }
  
    return result;
  }

  getResizedDimensions(image, maxHeight) {

    let newHeight, newWidth;
  
    // default os 960 if no configured size
    if (!maxHeight) {
        maxHeight = 960;
    } else if (typeof maxHeight === 'string') {
        // tslint:disable-next-line: radix
        maxHeight = parseInt(maxHeight);
    }
  
    const dimensions = this.determineDimension(image.width, image.height, maxHeight);
  
    if (maxHeight <= 96) { // This is used in top navbar
        newHeight = dimensions.image96.height;
        newWidth = dimensions.image96.width;
    } else {
        newHeight = dimensions.image960.height;
        newWidth = dimensions.image960.width;
    }
  
    return {
        w: newWidth,
        h: newHeight
    };
  }

}
