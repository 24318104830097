<div (appDropZone)="onFileSelect($event)" class="wrapper-uploader">

  <button type="button" mat-icon-button
          color="primary"
          *ngIf="!documentData?.isNew"
          style="margin-right: 10px;"
          (click)="fileInput.click()"
          class="navbar-uploader commandbar-item material-icons hidden-print"
          tooltip-placement="bottom" tooltip="File" title="Add photo to document"
          aria-label="Add photo to document"
          id="buttonCamera">
    <mat-icon>camera_alt</mat-icon>
  </button>

  <input id="inputFileUpload" #fileInput type="file" (change)="onFileInput($event)" style="display:none;"/>

</div>
