import { Action } from 'redux';
import { getDefaultOfflineState, IOfflineState } from '../state/offline.state';
import { OFFLINE_SET_OFFLINE_STATUS, IOfflineSetOfflineStatusAction } from '../actions/offline.actions';

export function offlineReducer(state: IOfflineState = getDefaultOfflineState(), action: Action): IOfflineState {
  switch (action.type) {
    case OFFLINE_SET_OFFLINE_STATUS: {
      const typedAction = <IOfflineSetOfflineStatusAction>action;
      return { ...state, offlineStatus: typedAction.payload.offlineStatus };
    }

    default: {
      return state;
    }
  }
}
