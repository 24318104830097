import { DocumentListService } from './document/document-list.service';
import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { KeyboardConstants } from '../utils/KeyboardConstants';
import { DocumentSaveService, BroadcastService, ClientConstants } from '@formbird/services';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutService {

  private initDone = false;
  private subscriptions: Subscription = new Subscription();
  isDirty = false;
  isSaving = false;

  constructor(
    private documentListService: DocumentListService,
    private documentSaveService: DocumentSaveService,
    private broadcastService: BroadcastService
  ) {
  }

  initialise() {

    //this service should only get initialised once. We could also perform an unbind function first then bind it again
    //but let's use this flag in order to not call any unnecessary actions.
    if (this.initDone) {
      return;
    }

    this.initDone = true;

    const savingSub: Subscription = this.documentSaveService.onDocumentSaving().subscribe((isDocSaving: boolean) => {
      this.isSaving = isDocSaving;
    });
    const formDirtySub: Subscription = this.broadcastService.on(ClientConstants.FORM_DIRTY_UPDATED).subscribe((formDirty: any) => {
      if (formDirty) {
        this.isDirty = formDirty.dirty;
      }
    });

    this.subscriptions.add(savingSub);
    this.subscriptions.add(formDirtySub);

    // create observable that emits click events
    const source = fromEvent<KeyboardEvent>(window, 'keydown');
    source.subscribe(event => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === KeyboardConstants.KEY_S) {
        logger.log("Meta key S : " + event);
        event.preventDefault();
        
        if (this.isDirty && !this.isSaving) {
          this.documentListService.save();
        }
      }

    });
  }
}
