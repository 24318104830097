import { Injectable } from '@angular/core';
import { ClientConfig } from '@formbird/types';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  clientConfig(): ClientConfig {
    return window['ftClientConfig'];
  }
}
