import { ApplicationRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DocumentChangedInfo, User } from '@formbird/types';
import { Observable, Subscription } from 'rxjs';
import { select, UnsavedDocumentService, ModifiedFieldService } from '@formbird/services';
import * as lodashLang from 'lodash/lang';

@Component({
  selector: 'ft-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentDialogComponent extends DialogComponent implements OnInit, OnDestroy {
  @select(['userState', 'user']) user$: Observable<User>;
  @select(['formState', 'documentChangedInfo']) documentChangedInfo$: Observable<DocumentChangedInfo>;

  formGroup: UntypedFormGroup;
  user: any;
  subs: Subscription = new Subscription();
  dirtyClass: string;
  showSpinner: boolean;

  constructor(
    dialogRef: MatDialogRef<any>,
    private appRef: ApplicationRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private unsavedDocumentService: UnsavedDocumentService,
    private modifiedFieldService: ModifiedFieldService,
    protected cdr: ChangeDetectorRef
  ) {
    super(dialogRef, appRef, data);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({});

    const subUser = this.user$.subscribe(user => {
      this.user = user;
    });

    const documentChangedSub = this.documentChangedInfo$.subscribe((documentChangedData: DocumentChangedInfo) => {
      this.applyDirtyClass(documentChangedData);
    });

    this.subs.add(subUser);
    this.subs.add(documentChangedSub);
  }

  applyDirtyClass(documentChangedData: DocumentChangedInfo) {
    const unsavedDocumentListId = this.data?.formParameters?.unsavedDocumentListId;
    if (documentChangedData && documentChangedData.formParameters.unsavedDocumentListId === unsavedDocumentListId) {
      const docId = this.getUnsavedDocumentId(unsavedDocumentListId);
      const modFields = this.modifiedFieldService.getModifiedFieldsInDoc(docId);
      this.dirtyClass = (modFields && modFields.length > 0) ? 'show' : 'hide';
      this.cdr.detectChanges();
    }
  }

  private getUnsavedDocumentId(unsavedDocumentListId) {
    let docId;
    if (!lodashLang.isEmpty(unsavedDocumentListId)) {
      const unsavedDocuments = this.unsavedDocumentService.getUnsavedDocuments(unsavedDocumentListId);
      if (!lodashLang.isEmpty(unsavedDocuments) && unsavedDocuments[Object.keys(unsavedDocuments)[0]]) {
        docId = unsavedDocuments[Object.keys(unsavedDocuments)[0]].documentId;
      }
    }
    return docId;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  saveModal() {
    if (this.data.saveFunc) {
      this.data.saveFunc();
    }

    this.close();
  }

  async ok() {
    this.showSpinner = true;
    this.dirtyClass = 'hide';
    await super.ok();
  }
}
