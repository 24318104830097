<div keyboard-nav class="ft-user">
  <div style="display: flex; flex-direction: row; place-content: center; align-items: center;">
        <span class="mat-small" >{{user.welcomeMsg}} </span>
    <button mat-icon-button  [matMenuTriggerFor]="userMenu">
      <mat-icon
        id="user-menu-selector"
        [color]="connected ? 'success' : 'warn'"
        matBadge="{{ offlineStatus?.pendingDocumentCount + offlineStatus?.maxCacheCount - offlineStatus?.currentCacheCount }}" matBadgeColor="accent"
        [matBadgeHidden]="(offlineStatus?.pendingDocumentCount + offlineStatus?.maxCacheCount) < 1"
       >account_box</mat-icon>

      <span class="cdk-visually-hidden">
        Pending Document Count: {{ offlineStatus?.pendingDocumentCount + (offlineStatus?.maxCacheCount - offlineStatus?.currentCacheCount) }}
      </span>
    </button>
    </div>
    <mat-menu class="ft-mat-menu-dropdown" #userMenu="matMenu" xPosition="before" backdropClass="ft-mat-menu-dropdown">
        <button type="button" id="user-menu-about-button" mat-menu-item (click)="showAboutDialog()">
          <mat-icon>info</mat-icon>
          <span>About</span>
        </button>
        <button type="button" *ngIf="!user.publicAccount" mat-menu-item (click)="openAccount()">
            <mat-icon>person_pin</mat-icon>
            <span>Account</span>
        </button>
        <button type="button" *ngIf="deferredPrompt" mat-menu-item (click)="addToHomeScreen()">
            <mat-icon>extension</mat-icon>
            <span>Add to Home Screen</span>
        </button>
        <button type="button" id="user-cache-menu" *ngIf="!user.publicAccount && !isCachingNotAllowed" [matMenuTriggerFor]="cacheMenu" mat-menu-item>
            <mat-icon>save</mat-icon>
            <span>Cache</span>
        </button>
        <mat-divider></mat-divider>


        <button type="button" mat-menu-item class="sign-out-only-pc" (click)="logout()" [matMenuTriggerFor]="singOutAllDevice" mat-menu-item>
            <mat-icon>power_settings_new</mat-icon>
            <span>Sign {{user.publicAccount ? 'in' : 'out'}}</span>
        </button>


        <button type="button" mat-menu-item class="sign-out-only" (click)="logout()" mat-menu-item>
            <mat-icon>power_settings_new</mat-icon>
            <span>Sign {{user.publicAccount ? 'in' : 'out'}}</span>
        </button>

        <button type="button" mat-menu-item class="sign-out-all"  (click)="logoutAllDevices()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Sign {{user.publicAccount ? 'in' : 'out all devices'}}</span>
        </button>

        
    </mat-menu>

    <mat-menu class="ft-mat-menu-sub-dropdown" #cacheMenu="matMenu">
        <button type="button" id="user-cache-enable" mat-menu-item *ngIf="!cacheEnabled" (click)="enableCaching()">
            <mat-icon>cached</mat-icon>
            <span>{{enableCacheText}}</span>
        </button>
        <button type="button"  id="user-cache-clear" *ngIf="cacheEnabled" mat-menu-item (click)="clearData()">
            <mat-icon>delete</mat-icon>
            <span>Clear</span>
        </button>
        <button type="button" *ngIf="cacheEnabled" mat-menu-item (click)="backupData()" id="butIndexedDBBackup">
            <mat-icon>cloud_download</mat-icon>
            <span>Backup</span>
        </button>
    </mat-menu>

    <mat-menu #singOutAllDevice="matMenu">
        <button type="button" class="sub-logout" mat-menu-item (click)="logoutAllDevices()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Sign {{user.publicAccount ? 'in' : 'out all devices'}}</span>
        </button>
    </mat-menu>
</div>
