/**
 * constants for local storage keys
 */
export const LocalStorageKeys = {
  LOGGED_IN_USER_ID: 'LoggedInUserID', // Also used as offline database table name
  LOGGED_IN_USER: 'LoggedInUser',
  LOGGED_IN_ACCOUNT_CONTROL_DOCUMENT: 'LoggedInAccountControlDocument',
  USER_SETTINGS: 'UserSettings',
  COMMAND_BAR_CSS: 'commandBarCSS',
  APP_VERSION: 'AppVersion',
  CHECK_PUBLIC_USER: "CheckPublicUser",
  TITLE_PREFIX: "titlePrefix",
  SERVICE_WORKER_ACTIVATED: "service-worker-activated"
};
