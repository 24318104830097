import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  AppStore, BroadcastService,
  ClientAccessService,
  ClientConstants,
  CurrentDocumentService,
  formRedoDocument,
  formUndoDocument,
  IApplicationState,
  ModifiedFieldService,
  NotificationService,
  DocumentSaveService,
  select
} from '@formbird/services';
import { DocumentData, SharedConstants } from '@formbird/types';
import { DeletionService } from '@services/deletion/deletion.service';
import { DocumentListService } from '@services/document/document-list.service';
import { NewDocumentService } from '@services/document/new-document.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ft-command-bar',
  templateUrl: './command-bar.component.html',
  styleUrls: ['./command-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})

export class CommandBarComponent implements OnInit, OnDestroy {
  @select(['formState', 'canUndo']) canUndo$: Observable<boolean>;
  @select(['formState', 'canRedo']) canRedo$: Observable<boolean>;

  documentData: DocumentData;

  private subscriptions: Subscription = new Subscription();
  deleteOptions: any;

  isDirty = false;
  isSaving = false;
  showSpinner = false;
  showUndo = false;
  showRedo = false;

  displayState: any = {};

  summaryName = 'none';
  searchRoute = '/search/';

  constructor(
    private broadcastService: BroadcastService,
    private clientAccessService: ClientAccessService,
    private deletionService: DeletionService,
    private currentDocumentService: CurrentDocumentService,
    private documentListService: DocumentListService,
    private newDocumentService: NewDocumentService,
    private modifiedFieldService: ModifiedFieldService,
    private notificationService: NotificationService,
    private documentSaveService: DocumentSaveService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private appStore: AppStore<IApplicationState>,
    private renderer: Renderer2
  ) {

    this.deleteOptions = {};

    this.modifiedFieldService.setUpdateAllowed(false);
    this.initializeDisplayState();
  }

  ngOnInit() {
    const stateChangeStartSub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.modifiedFieldService.setFormAccess(null);
        this.initializeDisplayState();

        const searchInputEl = this.renderer.selectRootElement("#navigationSearch > ft-search-box input[type=text]");
        this.renderer.setAttribute(searchInputEl, 'value', '');
        this.cdr.markForCheck();
      }
    });

    const docDataSub: Subscription = this.currentDocumentService.documentData$.subscribe((data: DocumentData) => {

      this.documentData = data;

      if (this.documentData && this.documentData.document && this.documentData.document.systemHeader) {
        if (this.documentData.document.systemHeader.currentVersion === false) {
          this.popupVersionMessage(this.documentData.document);
        }
      }

      const document = this.documentData.document;
      const template = this.documentData.template;

      if (document) {
        this.modifiedFieldService.setFormAccess(document);
        this.deletionService.checkingDeleteDoc(document);
      }

      const canCreate = this.clientAccessService.checkAccess(this.documentData.template, SharedConstants.OPERATION_TYPE_CREATE);

      this.summaryName = document?.systemHeader?.summaryName || template?.systemHeader?.summaryName;

      this.displayState.showCommandBar = this.documentData?.template?.hideCommandBar !== true;
      this.displayState.showTemplateName = true;
      this.displayState.showSummaryName = true;
      this.displayState.showCreateButton = canCreate;
      this.displayState.showPrintButton = true;
      this.displayState.showCameraButton = !this.documentData.isNew;

      const formNavBar = this.documentData?.template?.formNavBar;
      if (formNavBar) {
        this.displayState.showPrintButton = formNavBar.hidePrintButton !== true;
        this.displayState.showCameraButton = !this.documentData.isNew && formNavBar.hideCameraButton !== true;
        this.displayState.showCreateButton = canCreate && formNavBar.hideCreateButton !== true;
      }

      this.cdr.detectChanges();

    });

    const delSub = this.deletionService.deletionService$.subscribe((deleteOptions: any) => {
      this.deleteOptions = deleteOptions;
      this.displayState.showDeleteButton = deleteOptions.isDeletable;
    });

    const docLoadErrorSub: Subscription = this.broadcastService.on(ClientConstants.DOCUMENT_LOAD_ERROR).subscribe(() => {
      this.modifiedFieldService.setFormAccess(null);
      this.initializeDisplayState();
    });

    const formDirtySub: Subscription = this.broadcastService.on(ClientConstants.FORM_DIRTY_UPDATED).subscribe((formDirty: any) => {
      if (formDirty) {
        this.isDirty = formDirty.dirty;
        this.cdr.detectChanges();
      }
    });

    const savingSub: Subscription = this.documentSaveService.onDocumentSaving().subscribe((isDocSaving: boolean) => {
      this.isSaving = isDocSaving;
      this.cdr.detectChanges();
    });

    const showSpinnerSub: Subscription = this.broadcastService.on(ClientConstants.SPINNER_UPDATED).subscribe((showSpinner: boolean) => {
      this.showSpinner = showSpinner;
    });

    const showUndoSub: Subscription = this.canUndo$.subscribe(canUndo => {
      setTimeout(() => {
        this.showUndo = canUndo;
        this.cdr.detectChanges();
      });
    });

    const showRedoSub: Subscription = this.canRedo$.subscribe(canRedo => {
      setTimeout(() => {
        this.showRedo = canRedo;
        this.cdr.detectChanges();
      });
    });

    this.subscriptions.add(stateChangeStartSub);
    this.subscriptions.add(docDataSub);
    this.subscriptions.add(delSub);
    this.subscriptions.add(docLoadErrorSub);
    this.subscriptions.add(formDirtySub);
    this.subscriptions.add(savingSub);
    this.subscriptions.add(showSpinnerSub);
    this.subscriptions.add(showUndoSub);
    this.subscriptions.add(showRedoSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initializeDisplayState() {
    this.displayState = {
      showCommandBar: false,
      showTemplateName: false,
      showSummaryName: false,
      showCameraButton: false,
      showPrintButton: false,
      showCreateButton: false,
      showDeleteButton: false
    };
  }

  save() {
    this.documentListService.save();
  }

  undo() {
    this.appStore.dispatch(formUndoDocument());
  }

  redo() {
    this.appStore.dispatch(formRedoDocument());
  }

  createNewDocument() {
    this.newDocumentService.createNewDocument();
    this.cdr.detectChanges();
  }

  popupVersionMessage(document) {
    const dateCreated = new Date(document.systemHeader.createdDate).toLocaleDateString('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });
    const timeCreated = new Date(document.systemHeader.createdDate).toLocaleTimeString();
    this.notificationService.printMessage('Non-current Version Date: ' + dateCreated + ' ' + timeCreated,
      'info', null, { x: 'right', y: 'top' }, true);
  }

  private isSearchRoute() {
    const url = decodeURI(this.router.url);
    return url.indexOf(this.searchRoute) !== -1;
  }

}
