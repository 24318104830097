import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '@formbird/services';

const logger = console;

@Injectable({
    providedIn: 'root'
})
export class CustomCSSService {

    constructor(
        private configService: ConfigService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public loadCustomCSS() {
        const defaultCSS = 'styles.css';
        const appendCSS = (path: string) => {
            const head = this.document.getElementsByTagName('head')[0];
            const style = this.document.createElement('link');
            style.rel = 'stylesheet';
            style.href = path;
            head.appendChild(style);
        };

        const config = this.configService.clientConfig();
        const cssPaths = config.css?.mainFilePaths || [];
        let cssPath = config.css?.mainFilePath;
        
        for (let path of cssPaths) {
            path = path === 'DEFAULT' ? defaultCSS : path;
            if (path === 'DEFAULT') {
                path = defaultCSS;
            }

            appendCSS(path);
        }

        if (cssPath) {
            const path = cssPath === 'DEFAULT' ? defaultCSS : cssPath;
            appendCSS(path);
        }
    }
}
