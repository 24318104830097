import { isArray, isObject } from 'lodash';

export class MandatoryValueProcessor {

  public static async processMandatoryValues(mandatoryValuesArray, fieldValue, label) {
    let mandatoryValuesLabel;

    // the mandatoryValuesArray can only be applied to an object
    if (!fieldValue) {
      return;
    } else if (!isObject(fieldValue)) {
      return;
    }

    // mandatoryValuesArray must be an array
    if (!mandatoryValuesArray) {
      return;
    } else if (!isArray(mandatoryValuesArray)) {
      return;
    }

    for (let i = 0; i < mandatoryValuesArray.length; i++) {
      try {
        const JSONPath = (<any> window).JSONPath;
        let matches = JSONPath.JSONPath({path: mandatoryValuesArray[i].jsonPathQuery, json: fieldValue});

        if (matches.length === 0) {
          // second validation if the first one failed
          matches = JSONPath.JSONPath({path: mandatoryValuesArray[i].jsonPathQuery, json: [fieldValue]});
          if (matches.length === 0) {
            if (!mandatoryValuesLabel) {
              mandatoryValuesLabel = label;
            }

            // the mandatoryValuesLabel is output to html so use, <br> for the break
            mandatoryValuesLabel += '<br> ➡ ' + mandatoryValuesArray[i].name;
          }          
        }
      } catch (err) {
        console.error(err);
        return err;
      }


    }

    return mandatoryValuesLabel;
  }

}
