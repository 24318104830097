import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  BroadcastService,
  ConfigService,
  DefaultStateService,
  LoggedInUserService,
  NotificationService,
  UrlService,
  ClientConstants,
  PageVisibilityService,
  SessionTestService,
  ValidationService,
  LocalStorageService,
  OfflineStatusService,
} from '@formbird/services';
import { SharedConstants } from '@formbird/types';
import { AccessManager, SharedUrlRoutes } from '@formbird/shared';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@services/modal/modal.service';

const serverRoutes = SharedUrlRoutes.serverRoutes;

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  loggedInUser = null;

  constructor(
    private location: Location,
    private configService: ConfigService,
    private loggedInUserService: LoggedInUserService,
    private notificationService: NotificationService,
    private urlService: UrlService,
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private defaultStateService: DefaultStateService,
    private router: Router,
    private modalService: ModalService,
    private pvs: PageVisibilityService,
    private sessionTestService: SessionTestService,
    private validationService: ValidationService,
    private localStorageService: LocalStorageService,
    private offlineStatusService: OfflineStatusService
  ) {

    this.broadcastService.on('event:auth-loginRequired').subscribe( () => {
        this.showLogin();
        // clear the login bean, otherwise they will be in the login view when the user logs out
    });

    this.pvs.registerVisibilityListener(async (isHidden) => {
      // test if user is still logged-in at server
      if (isHidden) {
        return;
      }
      try {
        await this.sessionTestService.testSession();
      } catch (err) {
        if (err.status === 401) {
          this.showLogin();
        }
      }
    })

  }

  logoutConfirmed() {
    this.loggedInUserService.logoutUser();
    const config = this.configService.clientConfig();
    if (config
      && config.authenticationProviders
      && config.authenticationProviders.saml
      && config.authenticationProviders.saml.signOutURL) {
      // redirect to SSO signOutURL
      window.location.href = config.authenticationProviders.saml.signOutURL;
    } else {
      // window.location.href reloads the page to clear rulesets intervals
      window.location.href = 'default';
    }
  }

  async handleLoginSuccess(user) {
    this.loggedInUser = user;

    await this.loggedInUserService.setUser(user);

    const accountControlDocument = this.loggedInUserService.getUserControlDocument();
    const offlineAccess = AccessManager.getUserAccessKeysForOperation(
      accountControlDocument,
      SharedConstants.OPERATION_TYPE_OFFLINE
    );

    await new Promise((resolve, reject) => {
      const docContext = { user,  document: user, accountControlDocument };
      this.validationService.doPostLoginClient(user, docContext, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });

    const cachingAutoEnabled = this.configService.clientConfig().cachingAutoEnabled;

    if (offlineAccess && offlineAccess.length > 0 && cachingAutoEnabled === true) {
      this.offlineStatusService.setCachingEnabledStatus(true);
    }

    if (this.location.path() === '/default' || this.location.path() === '/login') {
      this.defaultStateService.changeToDefaultState();
    } else {
      window.location.reload();
    }
  }

  async sendLogoutRequest(isLogoutAllDevices?) {
    logger.info('Sending logout request to server');

    try {
      const user = this.loggedInUserService.getLoggedInUser();
      const accountControlDocument = this.loggedInUserService.getUserControlDocument();
      await new Promise((resolve, reject) => {
        const docContext = { user,  document: user, accountControlDocument };
        this.validationService.doPreLogoutClient(user, docContext, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });

      let url = isLogoutAllDevices ? serverRoutes.logoutAllDevices: serverRoutes.logout;
      await this.http.post(url, {}).toPromise();
    } catch (err) {
      const msg = 'Error logging out: ' + err.error;
      logger.error(msg);
      this.notificationService.printMessage(msg, 'error');
    } finally {
      this.logoutConfirmed();
      this.localStorageService.removeItem(ClientConstants.OFFLINE_LOGOUT);
      logger.info('Logout Completed');
    }
  }

  login(email, pass) {
    return this.http.post(serverRoutes.login, { email, pass }).toPromise();
  }

  logout(allDevice = false) {
    const isOffline = !this.offlineStatusService.isConnected() || !navigator.onLine;
  
    if (isOffline) {
      this.modalService.showDialog(
        'Warning: You are offline. If you logout you will not be able to login again until you go online.',
        'WARNING',
        () => {
          this.localStorageService.setItem(ClientConstants.OFFLINE_LOGOUT, 
            JSON.stringify({ sendLogoutRequest: true, allDevice: !!allDevice }));
          this.showLogin();
        },
        () => {} // cancel button
      );
    } else {
      this.sendLogoutRequest(allDevice);
    }
  }
  
  logoutAllDevices() {
    this.logout(true);
  }

  setLoggedInUser(user) {
    if (this.loggedInUser === undefined || this.loggedInUser === null) {
      this.loggedInUser = user;
      this.loggedInUserService.setUser(user);
    }
  }

  setLoggedOut() {
    // mark the app as logged out if the session is no longer logged in due to expiry or backend restart.
    // This will be triggered when calls to the backend return a http status of 401
    this.logoutConfirmed();
  }

  isLoggedOutOffline() {
    return !!this.localStorageService.getItem(ClientConstants.OFFLINE_LOGOUT);
  }

  async showLogin() {
    document.title = this.configService.clientConfig().browserDocumentTitle || 'Formbird - Login';
    this.urlService.getUrls().lastEntered = this.location.path(true);
    const config = this.configService.clientConfig();
    this.localStorageService.setItem(ClientConstants.LAST_ENTERED_STATE_URL, this.location.path(true));

    // if saml is configured
    if (config
      && config.authenticationProviders
      && config.authenticationProviders.saml
      && config.authenticationProviders.saml.autoRedirectToSamlServer
      && config.authenticationProviders.saml.authenticationLink) {

      // redirect to saml server if autoRedirectToSamlServer = true
      window.location.href = config.authenticationProviders.saml.authenticationLink;
    } else {
      const navExtras = { skipLocationChange: true, replaceUrl: false };
      this.router.navigate(['auth/login'], navExtras);
    }
  }
}
