import { SharedConstants } from '@formbird/types';
import { UtilHttpStatus } from '@formbird/shared';
import { ConfigService } from '../config/config.service';
import { DataService } from '../data/data.service';
import { Injectable } from '@angular/core';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  results = { data: [] };

  constructor(
    private dataService: DataService,
    private configService: ConfigService
  ) { }

  getInstance() {
    return this;
  }

  /**
   * Do Elastic search on server side. If the browser is offline, do searching indexedDB.
   * @param options consists of text, filter query to search
   * @param searchContext an object consists of additional params
   * @returns Promise
   */
  search(options, searchContext?): Promise<any> {

    const _self = this;

    return new Promise((resolve, reject) => {

      const exceedsMaxQueryLength = query => {
        const maxSearchQueryLength = _self.configService.clientConfig().maxSearchQueryLength ||
          SharedConstants.DEFAULT_MAX_SEARCH_QUERY_LENGTH;

        if (!query) {
          return false;
        } else {
          return JSON.stringify(query).length > maxSearchQueryLength;
        }

      };

      if (exceedsMaxQueryLength(options.filter)) {

        const message = 'The maximum length of a search query has been exceeded.';
        logger.error(message);

        reject(new Error(message));
        return;
      }

      _self.dataService.search(options, searchContext).then(
        function successFunc(returnData) {

          if (returnData.statusCode !== UtilHttpStatus.OK) {

            let msg = 'Search failed with status - ' + UtilHttpStatus.getStatusText(returnData.errorCode);

            if (returnData.message.status === UtilHttpStatus.BAD_REQUEST + '') {
              msg = 'Search failed with status - ' + 'filter is incorrectly set up';
            }

            reject(msg);

          } else {

            _self.results = returnData;
            resolve(returnData);

          }

        },
        function errorFunc(err) {
          let message;

          if (err) {

            if (err.message) {
              message = 'Error in search: ' + err.message;

            } else {

              if (err.errorCode) {
                message = 'Search failed with error: ' + err.errorCode;
              } else {
                message = 'Error in search. Please see an administrator';
              }
            }

          } else {
            message = 'Error in search. Please see an administrator';
          }

          reject(new Error(message));
        }
      );

    });

  }

  generatePit() {
    return this.dataService.generatePit();
  }
}
