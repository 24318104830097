let basePath = '';

const baseTag = document.getElementsByTagName('base')[0];
if (baseTag) {
    basePath = baseTag.href.split('/')[3] || '';
}

export const UrlBasePathUtil = {
    basePath: basePath,
    basePathWithSlashPrefix: basePath !== '' ? '/' + basePath : ''
};

export class UrlBasePathService {
    getBasePath() {
        return UrlBasePathUtil.basePath;
    }
}
