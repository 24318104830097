import { Component, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { OfflineStatusService, LoggedInUserService } from '@formbird/services';
import { OfflineStatus } from '@formbird/types';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ft-offline-status',
    templateUrl: 'offline-status.component.html',
    styleUrls: ['./offline-status.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OfflineStatusComponent implements OnInit, OnDestroy {

    mode = 'determinate';

    private sub: Subscription;

    
    @Input() offlineStatus: OfflineStatus;
    appValue: any;
    dataValue: any;
    mapValue: any;
    isMenuOpened: false;
    isErrorSyncMenuOpened: false;
    fileValue: any;
    constructor(
        private offlineStatusService: OfflineStatusService,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.sub = this.offlineStatusService.offlineStatus$.subscribe((offlineStatusData) => {
            this.offlineStatus = offlineStatusData;

            this.appValue = (this.offlineStatus.currentStaticResourceCount / this.offlineStatus.totalStaticResourceCount ) * 100;
            this.dataValue = (this.offlineStatus.currentCacheCount / this.offlineStatus.maxCacheCount ) * 100;
            this.mapValue = (this.offlineStatus.tileServerCacheCount / this.offlineStatus.tileServerMaxCacheCount ) * 100;
        });
        

        
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    clearErrorCachingAttachedFiles(){
      this.offlineStatusService.offlineStatus.errorCacheAttachedFile = [];
      this.loggedInUserService.setUserConfigItem('ErrorCachingAttachedFiles', this.offlineStatusService.offlineStatus.errorCacheAttachedFile);
    }

    errorMenuClick(isOpen, flag? ){
      if (flag === 'syncDoc'){
        this.isErrorSyncMenuOpened = isOpen;
      } else {
        this.isMenuOpened = isOpen;
      }
    }

    closeProgress() {
      //hide with setting opacity to 0 instead of ng-show/ng-hide
      //so ServiceWorkerStart can still call setAppInstallPopup
      const divSWUpdateIndicator =  document.getElementById('swUpdateProgressIndicator');
      divSWUpdateIndicator.style.opacity='0';
    }

    clearErrorOfflineChangesSyncDocs(){
      this.offlineStatusService.offlineStatus.errorOfflineChangesSyncDocs = [];
      this.loggedInUserService.setUserConfigItem('ErrorOfflineChangesSyncDocs', this.offlineStatusService.offlineStatus.errorOfflineChangesSyncDocs);
    }
}
