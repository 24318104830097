import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@formbird/types';
import { SharedUrlRoutes, UtilHttpStatus } from '@formbird/shared';
import { select} from '../../redux/decorators/select';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { PageVisibilityService } from '../page-visibility.service';
import { Observable } from 'rxjs';
import { IApplicationState } from '../../redux/state/application.state';
import { userSetUser } from '../../redux/actions/user.actions';
import { KeyValueStorageService  } from '../key-value-storage/key-value-storage.service';
import { OfflineConstants } from '../../constants/OfflineConstants';
import { cloneDeep } from 'lodash';
import { AppStore } from '../../redux/store/app.store';

const serverRoutes = SharedUrlRoutes.serverRoutes;
const logger = console;
let LoggedInUserService$: any;

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {

  @select(['userState', 'user']) user$: Observable<User>;

  private user: User = {
    welcomeMsg: '',
    account: null,
    filteredAccount: null,
    menu: null,
    publicAccount: false,
    accountPublicURL: null,
    accountControlDocument: null,
    accountGroupConfigDocument: null
  };

  private userConfigItemListeners = []; // listeners for 'set' events in the user config.

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private pageVisibilityService: PageVisibilityService,
    private appStore: AppStore<IApplicationState>,
    private keyValueStorageService: KeyValueStorageService
  ) {
    LoggedInUserService$ = this;
    this.pageVisibilityService.registerVisibilityListener((isPageHidden) => {
      if (isPageHidden) {
        return;
      }

      const trueUserId = this.localStorageService.getItem(LocalStorageKeys.LOGGED_IN_USER_ID);
      const registeredUserId = this.user.account == null ? null : this.user.account.documentId;

      if (trueUserId !== registeredUserId) { // user changed
        window.location.reload(); // just reload the page so that everything is checked from its initial state.
      }

    });

    this.user$.subscribe((user: User) => this.user = user);
  }

  /**
   * get the id of the logged in user
   */
  getUserId() {
    return this.user.account != null ? this.user.account.documentId : this.localStorageService.getItem(LocalStorageKeys.LOGGED_IN_USER_ID);
  }

  /**
   * set the user on login
   * @param account - the account to set
   * @param setAsOfflineUser - whether to set this user as the offline user
   */
  setUser(account): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (account) {
        this.user.filteredAccount = this.filterAcct(cloneDeep(account));
        this.user.account = account;
        this.user.menu = account.menu;

        if (account.systemHeader) {
          this.user.welcomeMsg = account.systemHeader.summaryName;
        }

        this.localStorageService.setItem(LocalStorageKeys.LOGGED_IN_USER_ID, account.documentId);
        await this.keyValueStorageService.setItem(LocalStorageKeys.LOGGED_IN_USER_ID, account.documentId);
        try{
          const accountGroupConfigDocument = await this.loggedUserAccountGroupConfigDocument();
          this.setUserAccountGroupConfigDocument(accountGroupConfigDocument);
        }catch(err){
          logger.error("Error on getting accountGroupConfig document " + err.message);
        }
        this.loggedUserAccountControlDocument().subscribe(resp => {
          this.setUserControlDocument(resp);

          resolve();
        });

      } else {
        resolve();
      }
    });
  }

  setUserControlDocument(controlDocument) {
    if (controlDocument) {
      this.user.accountControlDocument = controlDocument;

      if (controlDocument.publicAccount) {
        this.user.publicAccount = controlDocument.publicAccount;
        this.user.accountPublicURL = controlDocument.accountPublicURL;
      }
    }

    this.appStore.dispatch(userSetUser(this.user));
  }

  getUserControlDocument() {
    return this.user.accountControlDocument;
  }

  loggedUserAccountControlDocument() {
    const url = serverRoutes.loggedUserAccountControlDocument;
    return this.http.get(url);
  }

  setUserAccountGroupConfigDocument(groupConfigDocument) {
    if (groupConfigDocument) {
      this.user.accountGroupConfigDocument = groupConfigDocument;
    }

    this.appStore.dispatch(userSetUser(this.user));
  }

  loggedUserAccountGroupConfigDocument() {
    const url = serverRoutes.loggedUserAccountGroupConfigDocument;
    return this.http.get(url);
  }

  getUserAccountGroupConfigDocument() {
    return this.user.accountGroupConfigDocument;
  }

  /**
   * remove the user on logout
   * @param account the account
   */
  logoutUser() {
    this.resetUser();
    localStorage.removeItem(LocalStorageKeys.LOGGED_IN_USER_ID);
    this.userConfigItemListeners = [];
  }

  private filterAcct(account) {

    const fieldsToRemove = [
      'accessKeys',
      'attachKeys',
      'components',
      'menu',
      'provider'
    ];

    for (let i = 0; i < fieldsToRemove.length; i++) {
      delete account[fieldsToRemove[i]];
    }

    return account;
  }

  resetUser() {
    // reset only values. See http://stackoverflow.com/questions/19744462/update-scope-value-when-service-data-is-changed
    this.user.welcomeMsg = '';
    this.user.account = null;
    this.user.filteredAccount = null;
    this.user.menu = null;

  }

  /**
   * Custom user configuration is saved in local storage so that they survive across logins.
   * To that end, they should only contain flags or basic values keeping in mind the
   * limited storage capacity of local storage.
   */
  getUserConfig() {
    const userId = this.getUserId();

    if (userId == null) {
      logger.error('Requesting for user config with no user logged in.');
      return;
    }

    let userConfig: any;
    userConfig = this.localStorageService.getItem(userId);

    if (userConfig == null) {
      // add default user config here
      userConfig = {
        cachingEnabled: false,
        idbCurrentVersion: 0,
        searchIndexes: [], // pending
        registeredSearchIndexes: [],
        lastSyncDate: 0 // last time we synched offline
      };
      this.localStorageService.setItem(userId, JSON.stringify(userConfig));
      this.keyValueStorageService.setItem(userId, JSON.stringify(userConfig));
    } else {
      userConfig = JSON.parse(userConfig);
    }

    return userConfig;
  }

  public getUserConfigItem(key) {
    if (key == null) {
      logger.error('Missing key on requesting user config.');
      return;
    }

    const userConfig = this.getUserConfig();

    if (userConfig == null) {
      return;
    }

    return userConfig[key];
  }

  setUserConfigItem(key, value) {

    if (key == null) {
      logger.error('Missing key on setting user config.');
      return;
    }

    let userConfig: any;
    userConfig = this.getUserConfig();
    if (userConfig == null) {
      return;
    }

    userConfig[key] = value;
    this.localStorageService.setItem(this.getUserId(), JSON.stringify(userConfig));
    this.keyValueStorageService.setItem(this.getUserId(), JSON.stringify(userConfig));
    for (let x = 0; x < this.userConfigItemListeners.length; x++) {
      const listener = this.userConfigItemListeners[x];
      if (listener.key === key) {
        listener.listener(value); // informs the listener of the new value
      }
    }

  }

  /**
   * Custom implementation for listening to changes in user config items. Originally this should
   * have been done via using StorageEventListener but unfortunately it fires events only if other
   * tabs had modified the offline storage.
   * @param key the key
   * @param listener the listener
   */
  addUserConfigItemListener(key, listener) {
    if (key == null || listener == null) {
      return;
    }

    this.userConfigItemListeners.push({ key: key, listener: listener });
  }

  isUserOfflineMode() {
    return this.getUserConfigItem('cachingEnabled') === true;
  }

  getLoggedInUser() {
    return this.user.account;
  }

  loadLoggedUser() {

    const _self = this;

    const url = serverRoutes.loggedUserData;

    return new Promise((resolve, reject) => {
      _self.http.get<any>(url).subscribe(
        response => {
          const userBean = response;
          _self.setUserControlDocument(response.controlDoc);
          _self.setUserAccountGroupConfigDocument(response.groupConfigDoc);
          _self.appStore.dispatch(userSetUser(_self.user));

          return resolve(userBean);
        },

        error => {

          if (_self.isUserOfflineMode() && error.status === UtilHttpStatus.SERVER_UNRESPONSIVE) { // account will be retrieved
            resolve({ user: _self.user.account });

          } else {
            reject(error);
          }
        });
    });
  }

  setLoggedUser() {

    const _self = this;

    const url = serverRoutes.loggedUserData;

    return new Promise((resolve, reject) => {
      _self.http.get(url).subscribe(
        async (response: any) => {
          const userBean = response;
          const account = userBean.user;

          _self.user.filteredAccount = _self.filterAcct(cloneDeep(account));
          _self.user.account = account;
          _self.user.menu = account.menu;

          if (account.systemHeader) {
            _self.user.welcomeMsg = account.systemHeader.summaryName;
          }

          _self.localStorageService.setItem(LocalStorageKeys.LOGGED_IN_USER_ID, account.documentId);
          await this.keyValueStorageService.setItem(LocalStorageKeys.LOGGED_IN_USER_ID, account.documentId);
          _self.setUserControlDocument(userBean.controlDoc);
          _self.setUserAccountGroupConfigDocument(userBean.groupConfigDoc);

          await this.keyValueStorageService.setItem(OfflineConstants.LOGGED_IN_USER_GROUP_CONFIG, userBean.groupConfigDoc );
          resolve(userBean);
        },
        error => {

          if (_self.isUserOfflineMode() && error.status === UtilHttpStatus.SERVER_UNRESPONSIVE) { // item will be retried
            resolve({ user: _self.user.account });

          } else {
            reject(error);
          }
        });
    });
  }

  observableUser() {
    return this.user$;
  }
}
