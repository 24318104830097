import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }


  private fillSettingsObject(userSettings, templateId, name, settings, value) {

    if (!userSettings[templateId]) {
      userSettings[templateId] = {};
    }

    if (!userSettings[templateId][name]) {
      userSettings[templateId][name] = {};
    }

    userSettings[templateId][name][settings] = value;

    return userSettings;

  }

  saveSettings(templateId, name, settings, value) {

    let userSettings: any = this.localStorageService.getItem(LocalStorageKeys.USER_SETTINGS);

    if (userSettings) {

      try {
        userSettings = JSON.parse(userSettings);
      } catch (e) {
        userSettings = {};
      } finally {
        userSettings = this.fillSettingsObject(userSettings, templateId, name, settings, value);
      }

    } else {
      userSettings = {};
      userSettings = this.fillSettingsObject(userSettings, templateId, name, settings, value);
    }

    this.localStorageService.setItem(LocalStorageKeys.USER_SETTINGS, JSON.stringify(userSettings));

  }

  getSettings(templateId, name, settings) {

    let userSettings = this.localStorageService.getItem(LocalStorageKeys.USER_SETTINGS);

    if (userSettings) {

      try {

        userSettings = JSON.parse(userSettings);

        if (userSettings[templateId]
          && userSettings[templateId][name]
          && userSettings[templateId][name][settings]) {

          return userSettings[templateId][name][settings];

        }

      } catch (e) { }
    }

    return null;
  }

}
