import { Injectable } from '@angular/core';
import { RuleHelper } from '@formbird/shared';
import { ChangeSourceType, DocumentChangedInfo, SharedConstants } from '@formbird/types';
import { cloneDeep } from 'lodash';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { formDocumentChanged } from '../../redux/actions';
import { ReduxStoreService } from '../../redux/store/redux-store.service';
import { ConfigService } from '../config/config.service';
import { DocumentSessionService } from '../document/document-session.service';
import { ModifiedFieldService } from '../document/modified-field.service';
import { UnsavedDocumentService } from '../document/unsaved-document.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { ClientRulesService } from './client-rules.service';
import { ProxyEngineService } from './proxy-engine.service';
import { RuleCacheService } from './rule-cache.service';
import { getStore } from '../../redux/store/store';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class RuleEngineService {

  proxyEngineService: ProxyEngineService;

  constructor(
    private clientRulesService: ClientRulesService,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private documentSessionService: DocumentSessionService,
    private reduxStoreService: ReduxStoreService,
    private ruleCacheService: RuleCacheService,
    private offlineStatusService: OfflineStatusService,
    private loggedInUserService: LoggedInUserService,
    private modifiedFieldService: ModifiedFieldService,
    private unsavedDocumentService: UnsavedDocumentService
  ) {
    this.proxyEngineService = ProxyEngineService.getInstance();
  }

  /*
   * Process Rule flow string
   * @document - document object
   * @documentContext - additional objects will be used in rules
   * @ruleFlowInfo - rules flow as an object contains rulesetId and its script
   * @scope - additional scope objects will be plugged in to rules scope
   */

  async processRuleFlow(document, documentContext, ruleFlowInfo, ruleScope, callback) {

    const _self = this;

    const thisScope = await this.clientRulesService.expose();

    if (ruleScope) {

      Object.keys(thisScope).forEach(function (key) {
        ruleScope[key] = thisScope[key];
      });

      if (!documentContext) {
        documentContext = {};
      }

      const documentId = document.documentId;
      documentContext.logger = logger;
      documentContext.appVersion = _self.localStorageService.getItem(LocalStorageKeys.APP_VERSION);
      documentContext.databaseMode = _self.configService.clientConfig().defaultSearchProvider;
      documentContext.documentSession = this.documentSessionService.getDocumentSession(documentId);
      documentContext.isOnline = () => { return _self.offlineStatusService.offlineStatus.connected; };
      documentContext.isCachingEnabled = () => { return _self.offlineStatusService.isCachingEnabled(); };
      documentContext.isModal = documentContext.formParameters?.isModal ? true : false;

      function process(rulesetScriptFunc) {
        if (rulesetScriptFunc) {

          //defining proxy for document to get the changed fields from the ruleset.
          const proxyDocument = _self.proxyEngineService.setProxyForDocument(document, documentContext, _self);

          RuleHelper.processJsonRuleset(proxyDocument, documentContext, rulesetScriptFunc, ruleScope,
            function (result) {
              _self.documentSessionService.setDocumentSession(documentId, documentContext.documentSession);
              callback(result);
            });
        }
      }

      let rulesetCacheInfo = this.ruleCacheService.getRuleset(ruleFlowInfo.rulesetId);
      if (!rulesetCacheInfo || !rulesetCacheInfo?.rulesetScriptFunc) {

        _self.clientRulesService.addIncludesToRuleset(ruleFlowInfo.ruleSetDSL, function (processedRuleFlowResult) {

          if (processedRuleFlowResult.status === SharedConstants.STATUS_ERROR) {

            callback(processedRuleFlowResult);

          } else {

            const processedRuleFlow = processedRuleFlowResult.ruleFlow;
            if (processedRuleFlow) {
              // Parse the rulesetScript into a JSON object of functions
              const ruleset = Function('return (' + processedRuleFlow + ')')();

              _self.ruleCacheService.setRuleset(ruleFlowInfo.rulesetId, ruleset);

              process(ruleset);

            } else {
              callback();
            }
          }
        });

      } else {

        process(rulesetCacheInfo.rulesetScriptFunc);

      }

    }
  }

  dispatchChangedAction(dispatchedChangedActions) {
    const fieldNames = Object.keys(dispatchedChangedActions);
    if (fieldNames.length) {
      fieldNames.forEach(fieldName => {
        const action = dispatchedChangedActions[fieldName];
        console.log('Dispatching onfieldchange for field: ' + fieldName + ' - value: ' + action.payload.documentChangedInfo.newValue);
        this.reduxStoreService.getStore().dispatch(action);
      });
    }
  }

  // attachChangedField is passed to setProxyForDocument
  attachChangedField(document, fldName, newValue, oldValue, documentContext) {

    const documentId = document.documentId;

    let clonedNewVal = newValue;
    let clonedOldVal = oldValue;
    if (['[object Object]', '[object Array]'].indexOf(Object.prototype.toString.call(newValue)) > -1) {
      clonedNewVal = cloneDeep(newValue);
    }
    if (['[object Object]', '[object Array]'].indexOf(Object.prototype.toString.call(oldValue)) > -1) {
      clonedOldVal = cloneDeep(oldValue);
    }

    if (this.isChangedTemplateFieldValue(fldName, documentContext.template )) {

      const currentChangedFields = documentContext.changedFields;
      const changedFields = documentContext.changedFields ? cloneDeep(currentChangedFields) : [];
      const sourceType = documentContext.changeSourceType;

      let shouldTrackChangeDoc = true;  // 15618: change to false in order to change value in store without firing onfieldchange when setting document values from non-onfieldchange rules
      const isDocLoaded = this.unsavedDocumentService.documentIsLoaded(documentId);
      if (!isDocLoaded) {
        shouldTrackChangeDoc = false;
      } else {
        const eventName = documentContext.eventName;
        const onPreRenderName = SharedConstants.RULESET_RELATIONSHIPS.PRERENDER_EVENT_NAME.name;
        const onLoadName = SharedConstants.RULESET_RELATIONSHIPS.ONLOAD_EVENT_NAME.name;
        const onFieldChangeName = SharedConstants.RULESET_RELATIONSHIPS.ON_FIELD_CHANGE_EVENT_NAME.name;

        const isFromOnFieldChange = eventName === onFieldChangeName && documentContext.fieldChanged;
        const isFromHTMLEventListener = !sourceType && changedFields.length === 0 && (eventName === onPreRenderName || eventName === onLoadName);
        if (!isFromHTMLEventListener && !isFromOnFieldChange) {
          shouldTrackChangeDoc = false;
        }
      }

      if (sourceType === ChangeSourceType.RULESET && (changedFields.includes(fldName) || documentContext.fieldChanged === fldName)) {
        //Stop the looping OnFieldChange on the current field
        return;
      }

      if (sourceType === ChangeSourceType.COMPONENT) {
        changedFields.push(documentContext.fieldChanged);
      }
      changedFields.push(fldName);

      console.log('Dispatching onfieldchange for field: ' + fldName + ' - old value: ' + clonedOldVal + ' - new value: ' + clonedNewVal);

      // The sourceType value always be set when a change was originated from component or push. If not defined, it should be from ruleset. 
      // It was tested by making a change from a timeout in OnLoad
      const tartgetChangeSourceEvent = sourceType !== undefined ? documentContext.changeSourceEvent : ChangeSourceType.RULESET;

      const documentChangedInfo: DocumentChangedInfo = {
        documentId,
        fieldName: fldName,
        newValue: clonedNewVal,
        oldValue: clonedOldVal,
        isInitValue: !shouldTrackChangeDoc,
        formParameters: documentContext.formParameters,
        sourceType: ChangeSourceType.RULESET,
        sourceEvent: tartgetChangeSourceEvent,
        changedFields: changedFields
      };
      this.reduxStoreService.getStore().dispatch(formDocumentChanged(documentChangedInfo));

    } else {
      const documentChangedInfo: DocumentChangedInfo = {
        documentId,
        fieldName: fldName,
        newValue: clonedNewVal,
        isInitValue: true,
        formParameters: documentContext.formParameters,
        sourceType: ChangeSourceType.RULESET,
        changedFields: documentContext.changedFields
      };
      this.reduxStoreService.getStore().dispatch(formDocumentChanged(documentChangedInfo));

    }

    if (this.unsavedDocumentService.documentIsLoaded(documentId)) {
      const component = documentContext.template?.components?.filter(comp => comp?.name === fldName)[0] || {};
      const unsavedDocumentListId = documentContext.formParameters.unsavedDocumentListId;

      if (Object.keys(component).length && !component.disableSave && !component.disableDirty && documentContext.changeSourceEvent !== ChangeSourceType.DOCUMENT_PUSH) {
        this.modifiedFieldService.addModifiedField(documentId, fldName, unsavedDocumentListId);
      }
    }

  }

  private isChangedTemplateFieldValue(field, template) {
    let flag = false;
    for (let i = 0; i < template.components.length; i++) {
      if (template.components[i].name === field) {
        flag = true;
        break;
      }
    }

    return flag;
  }

}
