import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ComponentService, NotificationService } from '@formbird/services';
import { DocumentData, User } from '@formbird/types';
import { Subscription } from 'rxjs';
import * as lodashString from 'lodash/string';

@Component({
  selector: '[ft-dynamic-container]',
  template: '',
  styleUrls: ['./dynamic-container.component.scss']
})
export class DynamicContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formParameters;
  @Input() isMainDocument;
  @Input() tplItem;
  @Input() documentData: DocumentData;
  @Input() key: number;
  @Input() user: User;
  @Input() endKey: number;
  @Input() components;
  @ViewChild('divFlex', {static: false}) divFlex: ElementRef;

  // will be used for subscribing to changes for OnFieldChange an document dirtying
  changeSubscription: Subscription;
  skipKey: number;

  constructor(
    private componentService: ComponentService,
    private renderer: Renderer2,
    private el: ElementRef,
    private notificationService: NotificationService,
  ) {

  }

  ngOnInit() {

  }
  async ngAfterViewInit() {

    // add the component to the view
    const customElement = await this.getCustomElement(this.key);
    // insertBefore element to container
    this.renderer.insertBefore(this.el.nativeElement, customElement, this.el.nativeElement.firstChild);

    if (this.endKey > this.key){
      this.skipKey = -1;
      const panelBodyElement = this.getPanelBodyElement(customElement);
      this.setPanelCollapse(customElement, panelBodyElement);
      this.createComponentsUnderRecursivePanel(customElement, this.key+1, panelBodyElement);
    }
  }

  ngOnDestroy() {
    // unsubscribe from change detection to prevent a memory leak
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

  isPanel(componentDef) {
    return componentDef?.wrapAction;
  }

  async createComponentsUnderRecursivePanel(parentElement, startKey, panelBodyElement){

    for (let i=startKey; i<=this.endKey; i++){
      if (this.skipKey >  i){
        i=this.skipKey
      }

      let components = this.components;
      const tplItem =  components[i];
      const componentName = tplItem.componentName;
      
      if (tplItem.skipRendering) {
        continue;
      }

      if (componentName && !lodashString.startsWith(componentName, 'ft-') && !lodashString.startsWith(componentName, 'cc-')) {

        const childElem = await this.getCustomElement(i);
        const isPanel = this.isPanel(childElem.componentDefinition);

        const divElem = this.renderer.createElement('div');
        if (childElem.componentDefinition.wrapAction !== "close") {
          this.renderer.setAttribute(divElem, 'id', 'divParent' + childElem.componentDefinition.name + i);
          this.renderer.appendChild(panelBodyElement, divElem);
          this.renderer.appendChild(divElem, childElem);

          if (childElem.componentDefinition.display === 'none' || childElem.componentDefinition.visible === false) {
            this.renderer.setStyle(divElem, 'display', 'none');
          } else {
            this.renderer.setStyle(divElem, 'display', 'flex');
            this.renderer.setStyle(divElem, 'width', '100%');
          }
          this.renderer.addClass(divElem, 'ft-component-wrap');
          if (childElem.componentDefinition.enabled === false) {
            this.renderer.addClass(divElem, isPanel ? 'disabled-panel' : 'disabled');
            this.renderer.addClass(divElem, isPanel ? 'readonly-panel' : 'readonly');
            this.renderer.addClass(divElem, 'fb-label-disabled'); // add fb-label-disabled class
          } else {
            this.renderer.addClass(divElem, 'fb-label-enabled'); // add fb-label-enabled class
          }
          if (childElem.componentDefinition.mandatory === true) {
            this.renderer.addClass(divElem, isPanel ? 'mandatory-panel' : 'mandatory');
          }
          this.renderer.addClass(divElem, childElem.componentDefinition.fullWidth ? 'ft-dynamic-container-full-width' : 'ft-dynamic-container');

        }

        this.skipKey = i + 1;
        if (childElem.componentDefinition.wrapAction === "open") {
          const recPanelBodyElement = this.getPanelBodyElement(childElem);
          this.setPanelCollapse(childElem, recPanelBodyElement);
          await this.createComponentsUnderRecursivePanel(childElem, i + 1, recPanelBodyElement)
        } else if (childElem.componentDefinition.wrapAction === "close") {
          break;
        }
      }
    }
  }

  async getCustomElement(key) {
    return await this.componentService.createComponent(
      this.documentData.document,
      this.documentData.template,
      this.formParameters,
      this.user.filteredAccount,
      this.components[key],
      key,
      true
    );
  }

  getPanelBodyElement(elem) {
    return document.getElementById(elem.componentDefinition.name + elem.document.documentId + 'collapsable');
  }

  setPanelCollapse(elem, panelBodyElem) {
    const tplItem = elem.componentDefinition;
    if (tplItem.wrapPanelCollapsed === true) {

      const panelOpenElement = document.getElementById( elem.componentDefinition.name + elem.document.documentId + 'head');
      this.renderer.setStyle( panelBodyElem,
        'display', 'none'
      );
      this.renderer.addClass(panelOpenElement,'panel-collapsed');
    }
  }

}

