import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TopBarComponent } from '@components/top-bar/top-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchBoxComponent } from '@components/search/search-box.component';
import { OfflineStatusComponent } from '@components/offline-status/offline-status.component';
import { UserComponent } from '@components/user/user.component';
import { CommandBarComponent } from '@components/commandbar/command-bar.component';
import { CommandBarUploaderComponent } from '@components/commandbar/command-bar-uploader.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormbirdCommonModule } from '../common/formbird-common.module';
import { MenuObjectDialogComponent } from '@components/menu-object/menu-object.component';
import { DeleteComponent } from '@components/delete/delete.component';
import { MenuComponent } from '@components/menu/menu.component';
import { MatIconModule } from '@angular/material/icon';
import { PrintComponent } from '@components/print/print.component';
import { AboutDialogService } from '@services/modal/about-dialog.service';
import { DeletionService } from '@services/deletion/deletion.service';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgSelectModule } from '@ng-select/ng-select';
import { FtOptionHighlightDirective } from '../../directives/option-highlight.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { LoadingComponent } from '@components/loading/loading.component';
import { TemplateNameComponent } from '@components/template-name/template-name.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSliderModule,
        MatIconModule,
        MatTreeModule,
        MatToolbarModule,
        MatMenuModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatBadgeModule,
        NgSelectModule,
        MatSidenavModule,
        MatDividerModule,
        MatChipsModule,
        DragDropModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        FormbirdCommonModule,
        MatProgressBarModule,
        MatSelectModule
    ],
    exports: [
        TopBarComponent
    ],
    declarations: [
        TopBarComponent,
        CommandBarComponent,
        CommandBarUploaderComponent,
        OfflineStatusComponent,
        DeleteComponent,
        PrintComponent,
        SearchBoxComponent,
        MenuComponent,
        UserComponent,
        MenuObjectDialogComponent,
        LoadingComponent,
        TemplateNameComponent,
        FtOptionHighlightDirective,
    ],
    providers: [
        AboutDialogService,
        DeletionService
    ]
})
export class TopBarModule { }
