// sets up the logging object using the loglevel library. The log can be output to a file in chrome by specifying the
// following when starting:
//
// --enable-logging --v=1
//
// the output will go to the "Google\Chrome\User Data\chrome_debug.log" file under the user's
// profile

// just return console as the logger. We don't call console directly because of differences in client and server logging. If we call
// console functions on the server the log statements don't go to the log file and can't be used to diagnose issues. We have some
// code in the shared folder that needs to call logger and it can be a different logger depending on whether it is called from
// client or server
export const logger = console;
