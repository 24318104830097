import { UrlBasePathService } from '../../utils/UrlBasePathUtil';

export abstract class SynchronousStorageService {
  protected storage!: Storage;
  protected urlBasePathService: UrlBasePathService;

  protected constructor() {
    this.urlBasePathService = new UrlBasePathService();
  }

  setItem(key: string, value: string) {
    key = this.urlBasePathService.getBasePath() + key;
    return this.storage.setItem(key, value);
  }

  getItem(key: string) {
    key = this.urlBasePathService.getBasePath() + key;
    return this.storage.getItem(key);
  }

  removeItem(key: string) {
    key = this.urlBasePathService.getBasePath() + key;
    return this.storage.removeItem(key);
  }
}
