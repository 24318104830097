import { Injectable, SecurityContext } from '@angular/core';
import { RulesetError} from '@formbird/shared';


const logger = console;

@Injectable({
    providedIn: 'root'
})
export class RulesetContext {

  user;
  sharedValues;
  modalService;
  dataService;
  DataService;  // used in rules for the old code convention
  q;
  logger;
  ModalService;
  hasDuplicate;
  showNotification;
  /**
   * returns a boolean indicating whether the device is connected
   */
  isOnline;
  updateDocument;
  deleteDocument;
  findDocuments;
  findDocumentsByElastic;
  findDocumentsBySearch;
  /*
   * Including ruleset processing.
   * Include ruleset syntax: #include 'rulesetName';
   */
  addIncludesToRuleset;
  createDocumentFromTemplate;
  setSharedValue;
  getSharedValue;
  RulesetError;
  processSearchResults;
  showDialog;
  sanitizer;

  // service
  ModifiedFieldService;
  lazyLoader;
  loggedInUserService;
  clientIncludeRuleSetService;
  documentService;
  offlineStatusService;
  http;
  searchService;
  notificationService;
  clientAccessService;
  searchClientService;
  aggregate;
  constructor() {
  }

  loadRecords(collectionName, callback) {
    logger.error('LoadRecords regards collectionName is not valid function.');
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }
}
