import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SharedConstants, User } from '@formbird/types';
import {
  SharedUrlRoutes as SharedUrls,
  UtilArray,
  UtilDocumentId as IdGenerator,
  VersionManager,
  UtilHttpStatus
} from '@formbird/shared';
import { ClientConstants } from '../../constants/ClientConstants';
import { ClientAccessService } from '../access/client-access.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { DexieSearchService } from './dexie-search.service';
import { IndexedDBConnectorService } from './indexed-dbconnector.service';
import { BroadcastService } from '../broadcast/broadcast.service';
import { Observable, timeout, Subscription, catchError, from, of, switchMap, throwError } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { FtError } from '@formbird/types';
import { KeyValueStorageService } from '../key-value-storage/key-value-storage.service';

import {cloneDeep, toLower, isEmpty} from 'lodash';
import { DexieSharedWorkerService } from './dexie-shared-worker.service';
import { SearchQueue } from './search-queue';
import { OpfsCacheService } from '../opfs/opfs-cache.service';
import { ConfigService } from '../config/config.service';
import * as uuid from 'uuid';
import { IndexedDB, IndexedDBConstants } from '@formbird/indexed-db';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService extends IndexedDB implements OnDestroy {
  user: User;
  userSub: Subscription;
  searchQueue = new SearchQueue();
  subscriptionMap: Map<string, Subscription> = new Map();

  constructor(
    private http: HttpClient,
    private indexedDBConnectorService: IndexedDBConnectorService,
    public offlineStatusService: OfflineStatusService,
    public loggedInUserService: LoggedInUserService,
    private dexieSearchService: DexieSearchService,
    private clientAccessService: ClientAccessService,
    private broadcastService: BroadcastService,
    public notificationService: NotificationService,
    private keyValueStorageService: KeyValueStorageService,
    private dexieSharedWorkerService: DexieSharedWorkerService,
    private opfsCacheService: OpfsCacheService,
    private configService: ConfigService
  ) {
    super(indexedDBConnectorService, keyValueStorageService,
      offlineStatusService, notificationService, loggedInUserService);
    this.onDocumentUpdated();
    this.userSub = this.loggedInUserService.observableUser().subscribe((user: User) => this.user = user);

  }

  public constants = IndexedDBConstants;

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.terminateService();
  }

  public initialise() {
    this.dexieSharedWorkerService.initWorker();
    return this.getDatabase();
  }

  public terminateService() {
    return this.indexedDBConnectorService.closeDatabase();
  }

  public async search(options: any) {
    if (options.searchOptions && options.searchOptions.dexieSearchFunction) {
      return this.dexieSearchService.search(options.searchOptions);
    } else {
      const searchId = uuid.v1();
      const searchKey = `${window.location.href}-${JSON.stringify(options.filter)}`;
      
      if (this.subscriptionMap.has(searchKey)) {
        this.subscriptionMap.get(searchKey).unsubscribe();
        logger.info(`*** Previous search task cancelled for searchId = ${searchId}
          ,\t searchKey: ${searchKey}`);
      }

      const clientConfig: any = this.configService?.clientConfig();
      const searchTimeout = clientConfig.offline?.searchTimeout || 20000;

      return new Promise((resolve, reject) => {
        const searchObservable = this._search(options, searchId).pipe(
          timeout(searchTimeout),
          switchMap(result => {
            this.subscriptionMap.delete(searchKey);
            return of(result);
          })
        );

        const newSubscription = searchObservable.subscribe({
          next: (result) => {
            logger.info('*** Search result for searchId ' + searchId + ', =', result);
            resolve(result);
          },
          error: (error) => {
            logger.error('*** Search error for searchId ' + searchId + ', =', error);
            reject(error);
          },
          complete: () => {
            logger.info('*** Search complete for searchId ' + searchId);
            this.subscriptionMap.delete(searchKey);
          }
        });

        this.subscriptionMap.set(searchKey, newSubscription);

      });
    }
  }

  private _search(options: any, searchId: string): Observable<any> {

    const clientConfig: any = this.configService?.clientConfig();
    Object.assign(options, clientConfig.offlineSearchOptions);

    if (options.filter) {
      let queryObject: any;
      if (typeof options.filter === 'string') {
        queryObject = options.filter.replace(/'/g, '"');
        queryObject = typeof queryObject === 'string' ? JSON.parse(queryObject) : queryObject;
      } else {
        queryObject = options.filter;
      }
      if (queryObject && queryObject.sort) {
        options.sort = queryObject.sort;
      }
    }

    return new Observable<any>(observer => {
      this.dexieSharedWorkerService.search(
        this.indexedDBConnectorService.idbName,
        this.indexedDBConnectorService.currentVersion,
        this.indexedDBConnectorService.documentIndexes,
        searchId,
        options
      ).subscribe({
        next: (result) => {
          console.log(`*** DONE [Search ID: ${searchId}] get data from dexie worker, data = `, result);
          observer.next(result);
          observer.complete();
        },
        error: (error) => {
          console.error(`Error [Search ID: ${searchId}] fetching data from dexie worker:`, error);
          observer.error(error);
        }
      });
    });
  }

  public insert(bean) {

    if (!bean.systemHeader) {
      bean.systemHeader = {};
    }

    // set versionId
    bean.systemHeader.versionId = IdGenerator.generateId();

    // set currentVersion for new document
    bean.systemHeader.currentVersion = true;

    // set created date for version
    bean.systemHeader.createdDate = new Date();

    const userId = this.user ? this.user.account.documentId : null;
    if (userId) {
      bean.systemHeader.createdBy = userId;
    }

    if (!bean.systemHeader.documentCreatedDate) {
      bean.systemHeader.documentCreatedDate = new Date();
    }
    if (userId && !bean.systemHeader.documentCreatedBy) {
      bean.systemHeader.documentCreatedBy = userId;
    }

    return this.persist(bean, ClientConstants.OPERATION_TYPE_POST, null);
  }

  public destroy(id) {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.getDocumentCurrentVersion(id, function(originalRecord) {

        const originalBean = originalRecord;

        // save version as an insert
        const versionDoc = VersionManager.createVersion(originalBean);

        _self.cacheDocument(versionDoc, true).then(
          function successFunc(record) {

            const newBean = cloneDeep(originalBean);

            newBean.systemHeader.deleted = true;

            // perform newBean update
            // Aside from the differences in data between originalBean and newbean, newBean will always
            // have its previousVersionId = originalBean's versionId. newBean will also be set as the
            // current version with a new versionId
            if (newBean.systemHeader.versionId) {
              newBean.systemHeader.previousVersionId = originalBean.systemHeader.versionId;
            }

            // update createdBy every time because when we update the doc it's a new version
            newBean.systemHeader.createdBy = _self.user ?
              _self.user.account.documentId : null;

            newBean.systemHeader.createdDate = new Date();

            newBean.systemHeader.versionId = IdGenerator.generateId();

            _self.persist(newBean, ClientConstants.OPERATION_TYPE_DELETE).then(
              function sucF(rec) {

                resolve(rec);

              },

              function errF(error) {

                reject(error);

              }
            );
          },

          function errorFunc(err) {

            reject(err);

          }
        );
      });
    });

  }

  public getDocument(id) {

    const _self = this;

    return new Promise(function(resolve, reject) {

      _self.getDocumentCurrentVersion(id, function(cacheObject) {

        if (cacheObject == null) {

          reject('Document not available in offline database.');

        } else {

          resolve(cacheObject);

        }

      });
    });
  }

  public closeDatabase() {
    this.getDatabase().then(function(db: any) {
      db.close();
    },(error) => {
      logger.error(error);
    });
  }

  public backupData(): Promise<any> {

    const _self = this;

    return new Promise((resolve, reject) => {
      _self.getDatabase().then(function(db) {

        var tblName = IndexedDBConstants.DOCUMENT_TABLE_NAME;
        db.transaction('rw', db[tblName], (result) => {
          db[tblName].toArray().then((array) => {
            resolve(array);
          }).catch(error => resolve(error));
        }).catch(e => {
          logger.error('An error occured while clearing cached documents.');
          logger.error(e.stack || e);
          reject(e);
        });
      },(error) => {
        reject(error);
      });
    });
  }

  public clearData(): Promise<any> {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.getDatabase().then(function(db) {

        const tblName = IndexedDBConstants.DOCUMENT_TABLE_NAME;

        db.transaction('rw', db[tblName], (result) => {
          db[tblName].clear().then(async () => {

            _self.offlineStatusService.setCachingEnabledStatus(false);
            _self.loggedInUserService.setUserConfigItem('isRunningCacheClear', false);
            _self.loggedInUserService.setUserConfigItem('lastSyncDate', 0);
            _self.loggedInUserService.setUserConfigItem('idbCurrentVersion', 0);
            _self.loggedInUserService.setUserConfigItem('searchIndexes', []);
            await _self.keyValueStorageService.removeItem('searchIndexes');
            await _self.keyValueStorageService.removeItem('idbCurrentVersion');
            await _self.keyValueStorageService.removeItem('MainOfflinePoller');

            _self.offlineStatusService.offlineStatus.errorCacheAttachedFile = [];
            _self.offlineStatusService.offlineStatus.maxCacheAttachedFileCount = 0;
            _self.offlineStatusService.offlineStatus.totalStaticResourceCount = 0;
            
            await _self.offlineStatusService.setOfflineStatusToLocalStorage();

            resolve(true);

          }, (e) => {

            logger.error('An error occured while clearing cached documents.');
            logger.error(e.stack || e);

            resolve(false);

          });
        }).catch(e => {

          logger.error('An error occured while clearing cached documents.');
          logger.error(e.stack || e);

          resolve(false);

        });
      },(error) => {
        logger.error('An error occured while clearing cached documents.');
        logger.error(error.stack || error);
        resolve(false);
      });

    });
  }


  /**
   * cache a document
   * @param document the document
   * @returns PromiseLike<any> the Promise
   */
  public doCacheDocument(document, updateOfflineStatus): Promise<any> {

    const _self = this;

    return new Promise<any>((resolve, reject) => {

      if (!document.documentId) {

        _self.offlineStatusService.ignoreCacheDocument(document);

        const errMsg = "Document has no documentId " + JSON.stringify(document);
        logger.error(errMsg);

        reject(new Error(errMsg));

        return;
      }

      _self.setIndexedDBIndexes(document);

      _self.cacheDocument(document, false).then(
        function successFunc(cacheObject) {

          const doc = cacheObject;

          // update the last sync time in local storage with the time of this record if
          // it is later than the stored value
          if (updateOfflineStatus !== false) {
            _self.offlineStatusService.initialCacheQuerySuccess(doc);
          }

          resolve(doc);

        },
        function errorFunc(err) {
          const errorMsg = 'Error caching document: ' + document.documentId + ': ' + err;
          logger.error(errorMsg);

          if (updateOfflineStatus !== false) {
            _self.offlineStatusService.ignoreCacheDocument(document);
          }
          reject(new Error(errorMsg));
        }
      );
    });

  }




  private loadDocsById(docId, callback) {

    if (docId === undefined || docId === null) {
      callback(null);
      return;
    }

    const tblName = IndexedDBConstants.DOCUMENT_TABLE_NAME;
    const indexName = IndexedDBConstants.DOCUMENT_INDEX_DOCUMENT_ID;
    const indexValue = docId;

    this.getEntries(tblName, indexName, indexValue, function(cachedObjects) {

      callback(cachedObjects);

    });
  }

  /*
   offlineDetails

   Once offline, if a document has this object under it, it means that it is subject to an operation yet
   to be submitted to the server.

   This object will have the following attributes {type, status, lastRequestStatus, tries}

   type - From ClientConstants: OPERATION_TYPE_DELETE, OPERATION_TYPE_PUT, OPERATION_TYPE_POST
   status - IndexedDBConstants: (1)OFFLINE_STATUS_UPLOADED, (2)OFFLINE_STATUS_PENDING,
   (3)OFFLINE_STATUS_UPLOADING (4)OFFLINE_STATUS_FAILED

   lastRequestStatus - http status of last attempt to upload this operation
   tries - integer count of the number of times this document's pending operation has been uploaded to the server

   Should not be used to cache documents. Use cacheDocument function instead.
   Used for saving documents that needs to be sent to and processed by the server.

   */
  private persist(cacheObject, operation?, options?) {

    const self = this;

    cacheObject.systemHeader.offlineDetails = {
      type: operation,
      status: IndexedDBConstants.OFFLINE_STATUS_PENDING,
      lastRequestStatus: '',
      tries: 0,
      deleteOnComplete: false, // if true, the cache object is removed from offline db after completing the operation
      updates: options ? options.updates : null,
      isUpdateFromPreviousVersion: options ? options.isUpdateFromPreviousVersion : false,
      createdDate: new Date(),
      makeCurrentVersion: options ? options.makeCurrentVersion : false,
      importPreviousVersion: options ? options.importPreviousVersion : false,
      patchedVersionId: options?.patchedVersionId
    };

    return new Promise((resolve, reject) => {

      self.saveCacheObject(cacheObject, function onCacheObjectSaved(err, cacheObj) {

        if (err) {

          reject(err);

        } else {

          self.offlineStatusService.incPendingDocumentCount();
          resolve(cacheObj);

        }

      });

    });

  }

  /**
   * Cache the document in IndexedDB for offline use
   * @param document - the document to cache
   */
  public cacheDocument(document, force) {

    const _self = this;

    return new Promise(function (resolve, reject) {
      
      if (!document) {
        resolve(document);
        return;
      }

      _self.loadDocByVersion(document.systemHeader.versionId, docWithVersionFound);

      function docWithVersionFound(err, cacheObject) {

        // Do not cache the document twice unless forced to (when saving a version document. see jsonPatchUpdate)
        if (!force && cacheObject != null) {
          resolve(document);
          return;
        }

        _self.saveCacheObject(document, onCacheObjectSaved);

        function onCacheObjectSaved(error, doc) {

          if (error) {
            reject(error);
          } else {
            resolve(doc);
          }

        }
      }
    });
  }

  /**
   * cache document received from websocket
   * @param docInfo - the info used to load full document and changed versions
   * @param versions - the versions of document
   * @param updateOfflineStatus - flag indicates that should update offline caching status
   * @returns Promise
   */
  public cachePushedDocument(docInfo, versions, updateOfflineStatus?) {

    const _self = this;

    let currentVersion = null;
    versions.forEach(function(version) {

      if (version && version.systemHeader && version.systemHeader.currentVersion) {
        currentVersion = version;
      }

    });

    function cacheVersion(version) {

      return new Promise((resolve, reject) => {

        _self.saveCacheObject(version, function(err, savedVersion) {

          if (err) {
            reject(err);
          } else {
            resolve(savedVersion);
          }
        });
      });
    }

    function cacheExistingCurrentDoc() {

      // If the currentVersion in indexedDB is before the first prev version in above list so it would not
      // be set to non-currentVersion. This step set the value to false.
      _self.getDocumentCurrentVersion(docInfo.documentId, function(doc) {

        if (doc && currentVersion && doc.systemHeader.versionId !== currentVersion.systemHeader.versionId) {

          doc.systemHeader.currentVersion = false;

          _self.saveCacheObject(doc, cacheDone);
        }
      });
    }

    function cacheDone(err, version) {
      if (err) {
        logger.error('Caching version error: ' + err);
      } else {
        logger.info('Caching version done: ' + version.systemHeader.versionId);
      }
    }

    return new Promise((resolve, reject) => {

      const hasOfflineAccess = _self.clientAccessService.checkAccess(currentVersion, SharedConstants.OPERATION_TYPE_OFFLINE);
      if (hasOfflineAccess) {

        _self.setIndexedDBIndexes(currentVersion);

        if (docInfo.versionIds && docInfo.versionIds.length) { // in case of updating versions from pushed document

          const promises = [];
          versions.forEach(function(version) {
            promises.push(cacheVersion(version));
          });

          Promise.all(promises).then(
            function ok(versions2) {
              cacheExistingCurrentDoc();

              if (updateOfflineStatus !== false) {
                _self.offlineStatusService.initialCacheQuerySuccess(currentVersion);
              }

              resolve(versions2);
            },
            function fail(error) {
              const msg = 'Error in caching version of document: ' + docInfo.documentId;
              logger.error(msg);
              reject(new Error(msg));
            }
          );

        } else {    // in case of updating each current version from websocket reconnect event or browser reloading

          cacheVersion(currentVersion).then(cacheExistingCurrentDoc).then(function() {

            if (updateOfflineStatus !== false) {
              _self.offlineStatusService.initialCacheQuerySuccess(currentVersion);
            }

            resolve(currentVersion);

          });
        }
      }
    });
  }

  private updateOfflineStatusForCachingAttachedFiles(updateOfflineStatus, isMaxCount) {
    const _self = this;

    if (updateOfflineStatus !== false) {
      if (isMaxCount){
        this.offlineStatusService.offlineStatus.maxCacheAttachedFileCount++;
      } else {
        this.offlineStatusService.offlineStatus.currentCacheAttachedFileCount++;
      }
    }
  }
  public async cacheAttachedFilesOffline(document, updateOfflineStatus?) {
    // Cache file attachments sequence here
    // Searches for a file attachment in the document
    // Just visit every array property and if it contains an item with a property
    // named fileNo then load that file from the server into the cache system
    return new Promise(async (resolve, reject) => {
      const properties = Object.keys(document);
      const promises = [];

      for(let item of properties) {
        const pValue = document[item];
        if (pValue instanceof Array) {
          for(let value of pValue) {
            const specimen = value;
              if (specimen && specimen.fileNo && specimen.fileType) { // this is a file attachment by ftUploader
                try {
                  this.updateOfflineStatusForCachingAttachedFiles(updateOfflineStatus, true);
                  await this.loadFileAsync(specimen.fileNo);
                  this.updateOfflineStatusForCachingAttachedFiles(updateOfflineStatus, false);
                  promises.push(Promise.resolve(true));
                } catch (err) {
                  const msg = 'Error getting file ' + err.status + " : " + err.message;
                  if (err.status !== UtilHttpStatus.NOT_FOUND) {
                    logger.error(msg);
                    logger.error(err);
                  }
                  this.updateOfflineStatusForCachingAttachedFiles(updateOfflineStatus, false);
                  this.offlineStatusService.offlineStatus.errorCacheAttachedFile.push(msg);
                  promises.push(Promise.resolve(true));
                }
              } else {
                promises.push(Promise.resolve(true));
              }

          }
        }
      }
      resolve(await Promise.all(promises));
    });
  }

  public toBase64Str(blob) {
    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.onloadend = function() {
        resolve(reader.result);
      };

      reader.onerror = function() {
        reject('Error converting to base64str blob.');
      };

      reader.readAsDataURL(blob);
    });
  }

  // caches file blob into indexeddb
  public loadFileAsync(fileNo) {

    const _self = this;

    return new Promise((resolve, reject) => {

      const url = SharedUrls.serverRoutes.loadFile + '/' + fileNo;

      _self.http.get(url, {responseType: 'blob'}).toPromise().then(
        async response => {
          const data = response;
          const fileType = data.type;

          if (data) {

            const offlineFileRecord = {
              fileNo: fileNo,
              status: IndexedDBConstants.OFFLINE_STATUS_UPLOADED,
              mimeDetailsObj: { contentType: fileType }
            };

            await _self.opfsCacheService.cacheFile({
              fileNo: fileNo,
            }, data)
            await _self.saveFile(offlineFileRecord);

            resolve(true)

          } else {
            logger.warn('No data from remote file.');
            resolve(true);
          }

        }).catch(err => {
          reject(err);
        });
    });

  }


  public async saveCacheObject(offlineCacheObject, callback) {

    const _self = this;
    const tbl = IndexedDBConstants.DOCUMENT_TABLE_NAME;

    // versionId is the primary key, hence, redundancy is avoided in cache see IndexedDBConnectorService
  
    await _self.saveFlattendValues(offlineCacheObject);            

    this.saveEntry(tbl, offlineCacheObject, function(err) {

      if (!err) {
        _self.broadcastService.broadcast(ClientConstants.CHECK_UNSYNCED_DOCUMENTS, offlineCacheObject);
        callback(null, offlineCacheObject);
      } else {
        callback(err);
      }
    });

    // if there are blobs, then save them into cache too
    if (offlineCacheObject && offlineCacheObject.systemHeader && !offlineCacheObject.systemHeader.offlineDetails){
      this.cacheAttachedFilesOffline(offlineCacheObject);
    }


  }

  private onDocumentUpdated() {
    this.broadcastService.on(ClientConstants.DOCUMENT_UPDATED).subscribe(data => {
      if (data) {
        const record = data[0];
        if (record && record.systemHeader && record.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_OFFLINE_INDEX) {
          const previous = this.loggedInUserService.getUserConfigItem('searchIndexes');
          const indexes = record.indexedDBIndexes || [];
          const needtoUpgraded = previous.some(indexName => !indexes.includes(indexName))
            || indexes.some(indexName => !previous.includes(indexName));
          if (needtoUpgraded) {
            this.indexedDBConnectorService.upgradeOfflineDatabase(indexes);
          }
        }
      }
    });
  }

  async jsonPatchUpdate(newBean, originalBean, options) {

    // Use the doc saved in indexeddb because original bean has been mutated
    // to not have offlineDetails. See https://mantis.formbird.com/view.php?id=14351
    const orig = await this.loadDocByVersion(originalBean.systemHeader.versionId);
    const versionDoc = VersionManager.createVersion(orig);

    await this.cacheDocument(versionDoc, true);

    // perform newBean update
    // Aside from the differences in data between originalBean and newbean, newBean will always have
    // its previousVersionId = originalBean's versionId. newBean will also be set as the current version
    // with a new versionId

    newBean.systemHeader.previousVersionId = versionDoc.systemHeader.versionId;
    // if versionId not yet generated
    if (newBean.systemHeader.versionId === versionDoc.systemHeader.versionId) {
      newBean.systemHeader.versionId = IdGenerator.generateId();
    }
    // update createdBy every time because when we update the doc it's a new version
    newBean.systemHeader.createdBy = this.user ?
      this.user.account.documentId : null;

    newBean.systemHeader.createdDate = new Date();

    const savedDocument = await this.persist(newBean, ClientConstants.OPERATION_TYPE_PUT, options);
    return savedDocument;

  }

  public deepDiffUpdate(newBean, originalBean, options) {
    return this.jsonPatchUpdate(newBean, originalBean, options);
  }

  public executeComponentFunction(options) {

    logger.info('Executing component function in indexed-db Service');

    const _self = this;

    return new Promise((resolve, reject) => {

      this.indexedDBConnectorService.getDatabase().then(function(db) {
        try {
          db.documents.where('systemHeader.systemType').equals(SharedConstants.SYSTEM_TYPE_QUERY).toArray( async function(documents) {
            let queryDocument;
            const results = await documents.filter( function(document) {
              return document.name === options.offlineComponentLibraryName && document.systemHeader.currentVersion === true &&
                toLower(document.searchProvider) === 'dexie';
            });

            if (results && results.length > 0) {
              queryDocument = results[0];
              if (queryDocument && !isEmpty(queryDocument.filter)) {
                try {
                  const fun = new Function(queryDocument.filter);
                  fun(db, function(err, data) {

                    if (err) {

                      const message = 'Error in Dexie: ' + err;
                      reject(new Error(message));

                    } else {
                      resolve(data);
                    }
                  });
                } catch (err) {
                  reject(err);
                }

              }

            } else {
              reject(new Error("Offline component Library document not found."));
            }


          });
        } catch (err) {
          reject(err);
        }
      },(error) => {
        reject(error);
      });
    });
  }

  async getTemplateAndDocument(id: string, templateId, callBack) {
       const _self = this;

      _self.getDocumentCurrentVersion(id, function(document) {
        let template;
        if (!templateId && document && document.systemHeader &&
          document.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE
        ) {
          template = document;
          document = null;
        }

        if (document) {
          const tplId = templateId || document.systemHeader.templateId;
          if (tplId) {
            _self.getDocumentCurrentVersion(tplId, function(template) {

              if (template == null) {
                const error = new FtError(`Template for document not found: ${tplId}`);
                callBack(error, null);
              } else {
                callBack(null, {template, document, templateReturnStatus: UtilHttpStatus.OK} );
              }
            });
          }
        } else if (template) {
          callBack(null, {template, document, templateReturnStatus: UtilHttpStatus.OK });
        }
        else {
          const error = new FtError(`Template or document: ${id} not found`);
          callBack(error, null);
        }

      });

  }

  async loadComponentDoc(componentName) {

    const _self = this;

    const tbl = IndexedDBConstants.DOCUMENT_TABLE_NAME;
    const index = IndexedDBConstants.DOCUMENT_INDEX_NAME;

    return new Promise((resolve, reject) => {
      _self.getEntries(tbl, index, componentName, async (documents) => {
        
        for (let doc of documents) {
          if (doc?.systemHeader?.systemType === 'component' && componentName && doc?.systemHeader?.currentVersion) {
            resolve(doc);
            return;
          }
        }

        const error = new FtError(`Component: ${componentName} not found`);
        reject(error);
      });
    });
  }

}
