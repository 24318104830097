import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { DefaultStateService } from './default-state.service';
import { User } from '@formbird/types';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { select } from '../../redux/decorators/select';

export interface NavMenu {
  template: string;
  user: any;
  sideMenuOpen: boolean;
}

export interface NavImage {
  homeButtonImage: any;
  homeButtonImages: any[];
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnInit, OnDestroy {

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;

  private menus: NavMenu;
  private menusSubject: Subject<NavMenu>;
  menus$: Observable<NavMenu>;

  private images: NavImage;
  private imagesSubject: Subject<NavImage>;
  images$: Observable<NavImage>;

  subs: Subscription = new Subscription();

  constructor(
    private loggedInUserService: LoggedInUserService,
    private defaultStateService: DefaultStateService
  ) {
    this.menusSubject = new Subject<NavMenu>();
    this.menus$ = this.menusSubject.asObservable();

    this.imagesSubject = new Subject<NavImage>();
    this.images$ = this.imagesSubject.asObservable();

    this.reset();

    const sub = this.user$.subscribe((user: User) => this.user = user);
    this.subs.add(sub);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reset() {
    this.menus = {
      template: null,
      user: null,
      sideMenuOpen: false
    };

    this.images = {
      homeButtonImage: null,
      homeButtonImages: null
    };

    this.menusSubject.next(this.menus);
    this.imagesSubject.next(this.images);
  }

  setCurrentTemplate(template) {
    if (template) {

      // setting home Button Image
      if (template.homeButtonImage) {
        this.images.homeButtonImage = template.homeButtonImage;
      }

      if (template.homeButtonImages) {
        this.images.homeButtonImages = template.homeButtonImages;
      }

      // setting menu
      this.menus.user = this.user.menu;

      // assign template menu if default path regardless of undefined or not
      if (template.menu || this.defaultStateService.isPathAccountDefault()) {
        this.menus.template = template.menu;
      }

      this.menusSubject.next(this.menus);
      this.imagesSubject.next(this.images);
    }
  }
}
