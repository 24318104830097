import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {
  ConfigService,
  BroadcastService,
  DataService,
  LoggedInUserService,
  NotificationService,
  OfflineStatusService,
  select,
  ClientConstants,
  WebSocketService
} from '@formbird/services';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Router } from '@angular/router';
import { AboutDialogService } from '@services/modal/about-dialog.service';
import { InitialisationService } from '@services/initialisation.service';
import { Observable, Subscription } from 'rxjs';
import { User, OfflineStatus, SharedConstants } from '@formbird/types';
import { Input } from '@angular/core';
import { ModalService } from '@services/modal/modal.service';
const logger = console;

@Component({
    selector: 'ft-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit, OnDestroy {

    @select(['offlineReducer', 'offlineStatus']) offlineStatus$: Observable<OfflineStatus>;
    @select(['userState', 'user']) user$: Observable<User>;

    private subs: Subscription =  new Subscription();

    user: any;
    connected: boolean;
    isCachingNotAllowed: boolean;
    cacheEnabled: boolean;

    enableCacheText = 'Enable';
    isFirstTime = true;
    deferredPrompt: any;
    @Input() offlineStatus: OfflineStatus;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private initialisationService: InitialisationService,
        private authenticationService: AuthenticationService,
        private aboutDialogService: AboutDialogService,
        private broadcastService: BroadcastService,
        private router: Router,
        private offlineStatusService: OfflineStatusService,
        private cd: ChangeDetectorRef,
        private webSocketService: WebSocketService,
        private modalService: ModalService
    ) {
        // initialise the connected status to true to make it so that the online indicator will start as green and
        // change to red if disconnected
        this.connected = true;

        this.cacheEnabled = this.offlineStatusService.isCachingEnabled();
        this.isCachingNotAllowed = this.configService.clientConfig().cachingNotAllowed;
    }

    ngOnInit() {
        const subUser = this.user$.subscribe((user: User) => this.user = user);

        const subOfflineStatus = this.offlineStatusService.offlineStatus$.subscribe((offlineStatusData) => {
            this.connected = offlineStatusData.connected;
            this.cd.detectChanges();
            this.offlineStatus = offlineStatusData;
        });

        const subOfflineRedux = this.offlineStatus$.subscribe((offlineStatusData) => {
            this.connected = offlineStatusData.connected;
            this.cd.detectChanges();
            this.offlineStatus = offlineStatusData;
        });

        this.loggedInUserService.addUserConfigItemListener('cachingEnabled', newValue => {
            this.cacheEnabled = newValue;
        });

        const subInstall = this.broadcastService.on(ClientConstants.BEFORE_INSTALL_PROMPT).subscribe((deferredPrompt: any) => {
            this.deferredPrompt = deferredPrompt;
            this.cd.detectChanges();
        });

        this.subs.add(subUser);
        this.subs.add(subOfflineStatus);
        this.subs.add(subOfflineRedux);
        this.subs.add(subInstall);

    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    /**
    * @ngdoc method
    * @methodOf ft.core:ft-user
    * @name clearData
    * @description clears data
    * @function
    */
    async clearData() {
      if (!this.offlineStatusService.isConnected()) {
        this.notificationService.printMessage('Unable to clear the cache. Device is offline.', 'error');
      } else if (!this.cacheEnabled) {
        this.notificationService.printMessage('You did not enable caching.', 'error');
      } else {

        const succFunc = async () => {
          this.loggedInUserService.setUserConfigItem('isRunningCacheClear', true);
          this.modalService.showDialog('Clearing Cache. Please do not refresh or leave the page.', 'WARNING');
          const ok = await this.dataService.clearData();
          this.modalService.closeDialog();
          if (ok) {
            this.notificationService.printMessage('Your cache is now cleared.', 'success');
            this.offlineStatusService.offlineStatus.errorCacheAttachedFile = [];
            this.offlineStatusService.offlineStatus.pendingDocumentCount = 0;
            this.loggedInUserService.setUserConfigItem('ErrorCachingAttachedFiles', this.offlineStatusService.offlineStatus.errorCacheAttachedFile);
          } else {
            this.notificationService.printMessage('Error on deleting cache.', 'error');
          }
        };

        const errFunc = () => {
          logger.info('Clearing cache is cancelled.');
        };

        if (this.offlineStatusService.offlineStatus.pendingDocumentCount > 0){
          this.modalService.showDialog('Offline data has been saved and not sent to the server. ' +
            'Are you sure you wish to clear the cache?', 'WARNING', succFunc, errFunc);
        } else {
          succFunc();
        }


      }

    }

    async backupData() {
        if (this.cacheEnabled) {
          this.dataService.backupData().then(function successFunc(data) {
            var stringData = JSON.stringify(data, null, 4); //format data to make it readable in backup file
            var FileSaver = require('file-saver');
            var blob = new Blob([stringData], {type: "application/json;charset=utf-8"});
            FileSaver.saveAs(blob, "backup.json");
          });
        } else {
            this.notificationService.printMessage('You did not enable caching.', 'error');
        }
    }

    async enableCaching() {
        // TODO: reinstate when initialisationService fully converted
        if (typeof SharedWorker !== undefined){
          try {
            const msg = await this.initialisationService.enableCaching();
            this.notificationService.info(msg);
          } catch (error) {
            this.notificationService.error(error.message);
          }
        } else {
          this.notificationService.error('Cannot enable caching. Please upgrade your browser.');
        }

    }

    showAboutDialog() {
        this.aboutDialogService.showAboutDialog();
    }

    openAccount() {
        if (this.user
            && this.user.account
            && this.user.account.documentId) {
            const paramQuery = this.user.account.documentId;
            logger.info('Navigating to logged in account document '
                + paramQuery);
                this.router.navigate(['/form', paramQuery]);
        } else {
            logger.warn('Account could not be found to navigate to');
            this.notificationService.error('Account could not be found to navigate to');
        }
    }

    async addToHomeScreen() {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                this.notificationService.printMessage('Added to Home Screen.', 'success');
            }
        });
    }

    logout() {
        this.authenticationService.logout();
    }

    logoutAllDevices() {
      this.authenticationService.logoutAllDevices();
    }
}
