import { UtilDocumentId } from '@formbird/shared';
import { Injectable } from '@angular/core';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { formInitAppStoreSession } from '../../redux/actions';

@Injectable({
  providedIn: 'root'
})
export class AppStoreSessionService {
  
  constructor(private appStore: AppStore<IApplicationState>) {
  }

  initialize() {
    const appStoreId = UtilDocumentId.generateId();
    this.appStore.dispatch(formInitAppStoreSession(appStoreId));
  }

  getAppStoreSessionId() {
    return this.appStore.getState().formState.storeSessionId;
  }

  isSameAppStoreSession(sessionId) {
    return sessionId === this.getAppStoreSessionId();
  }

}
