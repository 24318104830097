'use strict';

/**
 * A constants object for constants that are used on the client-side only
 */
export const ClientConstants = {
  // Used for the search filter
  FILTER: 'filter',
  FILTERED_QUERY: 'queryToBeFiltered',

  HTTP_STATUS_LOGIN_REJECTED: 401,

  OPERATION_TYPE_POST: 'POST',        // HTTP Post (insert) operation
  OPERATION_TYPE_PUT: 'PUT',          // HTTP Put (update) operation
  OPERATION_TYPE_DELETE: 'DELETE',    // HTTP Delete operation

  LAST_ENTERED_STATE_URL: 'LastEnteredStateUrl',
  OFFLINE_LOGOUT: 'offlineLogout',
  URL_EQUAL_REPLACEMENT_STRING: 'fttf',

  // element ids
  TOGGLE_ID_LEFT: 'left_close',

  // broadcasts
  DOCUMENT_NEW: 'document:new',
  DOCUMENT_RENEW: 'document:renew',
  DOCUMENT_SAVING: 'document:saving',
  DOCUMENT_SAVED: 'document:saved',
  DOCUMENT_CHANGED: 'document:changed',
  DOCUMENT_DESTROYED: 'document:destroyed',
  // DOCUMENT_RECEIVED: 'document:received',
  DOCUMENT_UPDATED: 'document:updated',
  DOCUMENT_DELETED: 'document:deleted',
  DOCUMENT_LOADED: 'document:loaded',
  DOCUMENT_LOAD_ERROR: 'document:loadError',
  VALIDATE_FORM_FIELDS: 'document:validateFormFields',
  DOCUMENT_FORM_UPDATED: 'document:formUpdated',
  PRESAVE_DOCUMENT_FORM_RESET: 'document:preSaveDocumentReset',

  FORM_DETECT_CHANGES: 'form:detectChanges',

  URL_CLICKED: 'url:clicked',

  FORM_DIRTY_UPDATED: 'formDirty',
  SPINNER_UPDATED: 'showSpinner',
  FORM_RESET: 'formReset',

  RULES_STATUS_STARTED: 'rules_status_started',
  RULES_STATUS_DONE: 'rules_status_done',

  // validation
  VALIDATION_INTERVAL_TIME: 500, // 500 milliseconds
  VALIDATION_REQUIRED_KEY: 'required',
  VALIDATION_REQUIRED_MESSAGE: 'This field is required.',
  VALIDATION_MIN_LENGTH_KEY: 'hasMinLength',
  VALIDATION_MIN_LENGTH_MESSAGE: 'This field is required at least %s characters long.',
  VALIDATION_MAX_LENGTH_KEY: 'hasMaxLength',
  VALIDATION_MAX_LENGTH_MESSAGE: 'This field is required max %s characters long.',

  CHANGED_DOCUMENT_LIST_ID_KEY: '___DOCUMENT_LIST_ID___',

  FILE_SENT_EVENT_NAME: 'FileSentEventName',
  LOCAL_STORAGE_OFFLINE_STATUS_KEY: 'OfflineStatus',
  BEFORE_INSTALL_PROMPT: 'beforeinstallprompt',
  DOCUMENT_SAVE_STATUS: 'document:save:status',
  CHECK_UNSYNCED_DOCUMENTS: 'check_unsynced_documents'
};
