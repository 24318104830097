import { Action } from 'redux';
import { User } from '@formbird/types';

const logger = console;

export const USER_SET_USER = 'USER_SET_USER';


export interface IUserSetUserAction extends Action {
  payload: {
    user: User;
  };
}

export function userSetUser(user: User): IUserSetUserAction {
  return {
    type: USER_SET_USER,
    payload: {
      user
    }
  };
}
