import { Injectable } from '@angular/core';

interface PendingOperationOptions {
  documentId?: string;
  callerName?: string;
}

const config = (<any>window).ftClientConfig;

@Injectable({
  providedIn: 'root'
})
export class PendingOperationService {
    
  private pendingOperations: { [key: string]: PendingOperationOptions } = {};
  private readonly documentSavePendingTimeoutMillis = config.documentSavePendingTimeoutMillis || 10000;

  addPendingOperation(operationName: string, options: PendingOperationOptions): void {
    const operationNameKey = `${operationName} ${options?.documentId ? options.documentId : ''} ${options?.callerName ? options.callerName : ''}`;

    if (this.pendingOperations[operationNameKey]) {
      throw new Error(`Pending operation '${operationNameKey}' already exists.`);
    }

    this.pendingOperations[operationNameKey] = options;
  }

  markOperationComplete(operationName: string, options: PendingOperationOptions): void {
    const operationNameKey = `${operationName} ${options?.documentId ? options.documentId : ''} ${options?.callerName ? options.callerName : ''}`;

    delete this.pendingOperations[operationNameKey];
  }

  waitForPendingOperations(): Promise<void> {
    const pendingOperationKeys = Object.keys(this.pendingOperations);
    let timeout;

    return new Promise<void>((resolve, reject) => {
      if (pendingOperationKeys.length === 0) {
        resolve();
        return;
      }

      const logPendingOperations = () => {
        const pendingOperations = Object.keys(this.pendingOperations);
        console.log(`Waiting for pending operations before save: ${pendingOperations.join(', ')}`);
      };

      logPendingOperations();

      timeout = setTimeout(() => {
        clearTimeout(timeout);
        clearInterval(interval);
        const unfinishedOperations = Object.keys(this.pendingOperations);
        reject(new Error(`Pending operations have not completed in the required time before save: ${unfinishedOperations.join(', ')}`));
      }, this.documentSavePendingTimeoutMillis);

      const interval = setInterval(() => {
        logPendingOperations();
        if (Object.keys(this.pendingOperations).length === 0) {
          clearTimeout(timeout);
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }
}
