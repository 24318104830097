<div id="control-menu" class="control-menu" [ngClass]="'menuClass'">
  <nav id="commandbar-wrapper" class="ft-navbar ft-navbar-default f-flex" role="navigation">

    <div class="objectnavbar-item-left" *ngIf="displayState.showCommandBar">

      <!--Save button-->

      <button type="button" mat-icon-button color="primary"
          (click)="save()"
          class="hidden-print commandbar-item material-icons blink ft-save-button"
          *ngIf="isDirty && !isSaving"
          tooltip-placement="bottom" tooltip="Save" title="Save Document (Ctrl+S)"
          id="buttonSave">
          <mat-icon>check</mat-icon>
      </button>

      <!--Spinner button-->
      <div *ngIf="showSpinner" class="spinner-wrap f-flex f-flex-align-items-center f-flex-align-self-center" id="spinnerIcon">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>



      <div class="commandbar-buttons" >

        <ft-template-name *ngIf="displayState.showTemplateName"></ft-template-name>
        <!--Upload document image button-->
        <ft-command-bar-uploader *ngIf="displayState.showCameraButton"></ft-command-bar-uploader>

        <div class="commandbar-item-others">
          <!--Print button-->
          <ft-print *ngIf="displayState.showPrintButton" class="commandbar-item-others.print">
          </ft-print>

          <!--Create new button-->
          <button type="button" mat-icon-button color="primary"
                  aria-label="Create  New document"
                  (click)="createNewDocument()"
                  class="hidden-print commandbar-item material-icons ft-create-new"
                  *ngIf="displayState.showCreateButton"
                  tooltip-placement="bottom" tooltip="New" title="New document"
                  id="buttonNew">

            <mat-icon>note_add</mat-icon>

          </button>

          <!--Delete button-->
          <ft-delete *ngIf="displayState.showDeleteButton"></ft-delete>
        </div>
      </div>
    </div>

    <!--Summary name-->
    <div [hidden]="!displayState.showSummaryName" class="summaryName-wrapper">
            <span [hidden]="summaryName === 'none'" 
              matTooltip="{{summaryName}}"
              class="summaryName text-ellipsis ft-navbar-text mat-title">
                {{summaryName}}
                <button type="button" mat-icon-button color='primary'
                        title="*PREVIOUS VERSION*"
                        *ngIf="documentData?.document?.systemHeader?.currentVersion === false"
                        (click)="popupVersionMessage(documentData.document)"
                        aria-label='Previous Version'>
                        <mat-icon>date_range</mat-icon>
                </button>
            </span>
    </div>

  </nav>
</div>
