import { Component, OnInit } from '@angular/core';
import { DefaultStateService, LoggedInUserService } from '@formbird/services';

@Component({
  selector: 'ft-default',
  template: ``,
})
export class DefaultComponent implements OnInit {

  constructor(
    private defaultStateService: DefaultStateService,
    private loggedInUserService: LoggedInUserService
  ) { }

  async ngOnInit() {
    let user = this.loggedInUserService.getLoggedInUser();
    if (!user) {
      const loggedUser: any = await this.loggedInUserService.loadLoggedUser();
      user = loggedUser?.user;
    }

    this.defaultStateService.changeToDefaultState('/form', user);
  }

}
