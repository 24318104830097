<ft-top-bar>
    <div class="external-page-wrap">
        <div class="external-page">
        <iframe [src]="url" width="100%"></iframe>
        <style>
            /**
            * Main window scroll will be replaced by iframe scroll
            */
            body {
                overflow: hidden;
            }
        </style>
        </div>
    </div>
</ft-top-bar>