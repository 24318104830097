
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { ConfigService } from '../config/config.service';
import { SearchService } from '../search/search.service';
import { HttpClient} from '@angular/common/http';
import { isEmpty, filter, chunk, nth, orderBy, map, isPlainObject, get, toLower,
  isEqual, isArray, cloneDeep, includes, isString, isFunction  } from 'lodash';
import { SharedConstants } from '@formbird/types';
import { SearchResultsProcessor } from '@formbird/shared';
import { OfflineStatusService } from '../offline-status/offline-status.service';

const logger = console;

export class SclSearchClientConstantService {
  static operator = {
    equals: 'eq',
    notEquals: 'neq',
    contains: 'contains'
  };
}

@Injectable({
  providedIn: 'root'
})
export class SearchClientService {

  contextParams: any;

  constructor(private  httpClient: HttpClient,
              private searchService: SearchService,
              private configService: ConfigService,
              private loggedInUserService: LoggedInUserService,
              private offlineStatusService: OfflineStatusService,
              private dataService: DataService) {

  }


  search(options, searchContext?) {

    return new Promise(async (resolve, reject) => {
      try {

        const offlineMode = this.offlineStatusService.isCachingEnabled();;
        if (options?.searchOfflineOnly && !offlineMode) {
          reject(new Error('Please enable caching before performing a search configured as offline only'));
        } else {
          let isExists: any = false;
          if (!isEmpty(options.queryName) && options.dataSource === 'elastic') {
            try {
              const connected = await this.offlineStatusService.checkServerConnectionStatus();
              if (connected) {
                isExists = await this.isQueryDocumentExists(options.queryName, options.dataSource);
              }
            } catch (err) {
              // the error will be ignored so the filter can be used
            }
          }
          if (!isEmpty(options.queryName) && options.dataSource && isExists) {
            this.executeComponentFunction(options, resolve, reject);
          } else {
            if (!isEmpty(options.queryName) && (options.dataSource === 'dexie' || offlineMode)) {
              options.searchOptions = options.searchOptions ? options.searchOptions : {};
              options.searchOptions.dexieSearchFunction = await this.getDexieSearchFunction(options);
            } else if (options.offlineFilter) {
              options.searchOptions = options.searchOptions ? options.searchOptions : {};
              options.searchOptions.dexieSearchFunction = await this.parseOfflineFilter(options);
            }

            this.searchService.search(options, searchContext).then((results) => {
              if (results.searchProvier === 'dexie') {
                this.processDexieResults(options, results, resolve);
              } else {
                resolve(results);
              }

            }, (err) => {
              reject(err);
            });
          }
        }

      } catch (error) {
        reject(error);
      }

    });
  }

  getDexieSearchFunction(options): Promise<void> {
    const self = this;

    return new Promise((resolve, reject) => {

      this.getDexieQuery(options.queryName, function(err, queryDocument) {

        if (err) {
          reject(err);
        } else if (queryDocument && !isEmpty(queryDocument.filter)) {
          let fun;
          try {
            let funcStr = queryDocument.filter;
            if (options.processHandlebars) {
               funcStr =  options.processHandlebars(queryDocument.filter, null, options.handlebarsParams);
            }

            // tslint:disable-next-line:no-eval
            fun = eval('(' + funcStr + ')');
            resolve(fun);
          } catch (err) {
            reject(err);
          }
        } else {
          resolve();
        }
      });

    });
  }

  processDexieResults = async (options, results, resolve) => {

    let docs = [];
    let filterDocs = results.data;
    if (filterDocs && filterDocs.length > 0) {

      filterDocs = await this.filterResults(options, filterDocs);
      if (filterDocs && filterDocs.length > 0) {
        filterDocs = this.sortResults(options, filterDocs);

        const size = options.size || options.pageSize || SharedConstants.DEFAULT_PAGE_SIZE;
        let pageFrom;
        if (!options.page && options.from && options.from > 0 && options.size > 0) {
          pageFrom = options.from / options.size;
        }
        const page = options.page || pageFrom || SharedConstants.DEFAULT_PAGE;
        const docsArray = chunk(filterDocs, size);
        docs = nth(docsArray, page);
      }
    }

    results.total = filterDocs.length;
    results.data = docs;

    resolve(results);

  }

  executeComponentFunction = async (options, resolve, reject) => {

    const params = {
      functionName: options.functionName ? options.functionName : 'searchByQueryName',
      componentWebServiceName: options.componentWebServiceName ? options.componentWebServiceName : 'ws-search-service',
      functionParameters: [options.queryName, options.dataSource, options.document, options],
      offlineComponentLibraryName: options.offlineComponentLibraryName ? options.offlineComponentLibraryName : options.queryName
    };

    let docs = [];

    let isExists: any = false;
    if (!isEmpty(options.queryName) && options.dataSource === 'elastic') {
      try {
        isExists = await this.isQueryDocumentExists(options.queryName, options.dataSource);
      } catch (err) {
        // the error will be ignored so the filter can be used
      }
    }
    if (!isExists) {
      this.searchService.search(options).then((results) => {
        if (results.searchProvier === 'dexie') {
          this.processDexieResults(options, results, resolve);
        } else {
          resolve(results);
        }

      }, (err) => {
        reject(err);
      });
    }else {
      this.dataService.executeComponentFunction(params)
        .then((results) => {
          let data;
          if (results.data && results.data.hits && results.data.hits.hits) {
            data = results.data.hits.hits;
          } else {
            data = results;
            results.total = data.length;
            results.searchProvier = options.dataSource;
            results.statusCode = 200;
          }


          if (data && data.length > 0) {
            const size = options.size || options.pageSize || SharedConstants.DEFAULT_PAGE_SIZE;
            let pageFrom;
            if (!options.page && options.from && options.from > 0 && options.size > 0) {
              pageFrom = options.from / options.size;
            }
            const page = options.page || pageFrom || SharedConstants.DEFAULT_PAGE;
            const docsArray = chunk(data, size);
            docs = nth(docsArray, page);
          }

          if (results.data && results.data.hits && results.data.hits.hits) {
            results.data.hits.hits = docs;
          } else {
            results.data = docs;
          }

          resolve(results);

        }, (error) => {
          reject(error);
        });
    }

  }


  getDexieQuery(queryName, callback) {
    const offlineMode = this.offlineStatusService.isCachingEnabled();
    if (!offlineMode) {
      callback(new Error("Request for Dexie query without offline enabled. Please enable caching before querying Dexie"));
    } else {
      const queryDocument = async (db, callback) => {
        try {

          await db.documents.where('name').equals(queryName).and(
            (document) => {
              return document.systemHeader.systemType === 'query'
                && toLower(document.searchProvider) === 'dexie'
                && document.systemHeader.currentVersion === true;
            }).toArray(
            (documents) => {
              callback(null, documents);
            })

        } catch (err) {
          logger.error(err);
          callback(err);
        }
      };

      const options = { "searchOptions": { "dexieSearchFunction": queryDocument } };

      this.searchService.search(options).then((results) => {
        let qryDoc;
        if (results && results.data && results.data.length > 0) {
          qryDoc = results.data[0];
        }
        callback(null, qryDoc);

      }, (err) => {
        logger.error(err);
        callback(err);
      });
    }
  }

  private async filterResults(options, documents) {
    const self = this;
    return new Promise(async (resolve) => {
      let query = [];

      let filterObj = options.filter;
      if (typeof filterObj === 'string') {
        filterObj = filterObj.replace(/'/g, '"');
        filterObj = JSON.parse(filterObj);
      }

      if (filterObj && !isEmpty(filterObj.dexieQuery) && isArray(filterObj.dexieQuery) && filterObj.dexieQuery.length > 0) {
        query = cloneDeep(filterObj.dexieQuery);
      }

      const currentVerFilter = { field: 'systemHeader.currentVersion', value: true,
        operator: SclSearchClientConstantService.operator.equals};
      query.push(currentVerFilter);

      const results = await documents.filter(function(document) {
        let isFound = false;

        for (let i = 0; i < query.length; i++ ) {
          const queryObj = query[i];
          if (queryObj && queryObj.field && queryObj.value) {
            const fldValue = get(document, queryObj.field);
            if (isEmpty(queryObj.operator) || queryObj.operator === SclSearchClientConstantService.operator.contains) {
              isFound = includes(toLower(fldValue), queryObj.value);
            } else if (queryObj.operator === SclSearchClientConstantService.operator.equals) {
              isFound = isEqual(fldValue, queryObj.value) || isEqual(toLower(fldValue), queryObj.value);
            } else if (queryObj.operator === SclSearchClientConstantService.operator.notEquals) {
              isFound = !isEqual(toLower(fldValue), queryObj.value);
            }

          }
          if (!isFound) {
            break;
          }
        }

        return isFound;
      });
      resolve(results);

    });

  }

  sortResults(options, documents) {
    let filterObj = options.filter;
    if (typeof filterObj === 'string') {
      filterObj = filterObj.replace(/'/g, '"');
      filterObj = JSON.parse(filterObj);
    }


    if (filterObj && filterObj.dexieSort && isPlainObject(filterObj.dexieSort)) {
      let fieldArr; fieldArr = [];
      let valueArr; valueArr = [];
      map(filterObj.dexieSort, function(value, key) {
        fieldArr.push(key);
        valueArr.push(value);
      });
      documents = orderBy(documents, fieldArr, valueArr);
    }

    return documents;
  }

  getDefaultSearchProvider(context) {
    const offlineMode = this.offlineStatusService.isCachingEnabled();
    const clientConfig = this.configService.clientConfig();
    this.contextParams = {
      document: context.document,
      doc: context.document,
      account: context.account, // the account document from scope
      tpl: context.template,
      template: context.template,
      tplItem: context.componentDefinition, // For BackCompatibility
      componentDefinition: context.componentDefinition // the current components configuration details
    };

    if (!isEmpty(context.componentDefinition?.queryName) && offlineMode && context.componentDefinition?.searchOfflineOnly) {
      return 'dexie';
    } else if (context.componentDefinition?.searchProvider || context.template?.searchProvider) {
      return context.componentDefinition?.searchProvider || context.template?.searchProvider;
    } else if (context.componentDefinition?.defaultSearchProvider || clientConfig?.defaultSearchProvider) {
      return context.componentDefinition?.defaultSearchProvider || clientConfig?.defaultSearchProvider;
    } else if (!isEmpty(context.componentDefinition?.queryName)) {
      return 'elastic';
    } else {
      return null;
    }
  }

  isDexieQuery(context, queryName?) {

    const offlineMode = this.offlineStatusService.isCachingEnabled();

    return ( (!isEmpty(context.componentDefinition.queryName) || queryName) &&
      (offlineMode || context.componentDefinition.searchOfflineOnly ||
        (context.componentDefinition.searchOptions && context.componentDefinition.searchOptions.searchOfflineOnly) ));
  }

  isQueryDocumentExists(queryName, dataSource) {
    return new Promise(async (resolve, reject) => {
      const options = {
        filter: `{"query":{"bool":{"filter":[{"term":{"systemHeader.systemType":"query"}},{"term":{"name":"${queryName}"}},` +
          `{"term":{"searchProvider":{"value": "${dataSource}", "case_insensitive": true}}}]}}}`
      };
      try {
        const results = await this.searchService.search(options);

        SearchResultsProcessor.processSearchResults(results, (itemData, sort) => {

        }, (customData, totalHits, lastSort) => {
          resolve(totalHits > 0);
        });
      } catch (err) {
        logger.error(err);
        resolve(false);
      }
    });
  }

  private parseOfflineFilter(options)  {
    const self = this;

    return new Promise((resolve: any, reject) => {
      let funcStr: any = options.offlineFilter;
      if (!isEmpty(funcStr) && isString(funcStr)) {
        let fun;
        try {
          if (options.processHandlebars) {
            funcStr = options.processHandlebars(funcStr, null, options.handlebarsParams);
          }
          // tslint:disable-next-line:no-eval
          fun = eval('(' + funcStr + ')');

          resolve(fun);
        } catch (err) {
          reject(err);
        }
      } else if (isFunction(funcStr)) {
        resolve(funcStr);
      } else {
        resolve(null);
      }

    });
  }
}
