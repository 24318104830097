import { HttpClient } from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { ComponentDocument, FormDocument, FormTemplate, DocumentData, FormParameters, User, ComponentDefinition, ErrorComponentDefinition, FormCustomElement } from '@formbird/types';
import {TemplateHtmlService} from '../document/template-html.service';
import * as async from 'async';
import {isEmpty, startsWith} from 'lodash';
import { ConfigService } from '../config/config.service';
import * as lodashString from 'lodash/string';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';

import loadjs from 'loadjs';
import { DataService } from '../data/data.service';

@Injectable()
export class ComponentService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private templateHtmlService: TemplateHtmlService,
    private configService: ConfigService,
    private loggedInUserService: LoggedInUserService,
    private offlineStatusService: OfflineStatusService,
    private dataService: DataService
  ) {}

  loadedComponentDocs = {};
  public loadedComponents = {};
  private loadedVendorLibs = {};


  async loadVendorLibs(vendorLibs): Promise<void> {
    const vendorLibraryBasePath = this.configService.clientConfig().vendorLibraryBasePath || '';

    return new Promise((resolve, reject) => {
      async.eachSeries(vendorLibs, (fileName, next) => {
        if (!this.loadedVendorLibs[fileName]) {
          const item = vendorLibs[fileName];

          fileName = fileName.replace('${vendorLibraryBasePath}', vendorLibraryBasePath);

          loadjs([fileName], {
            returnPromise: true
          }).then(() => {
              this.loadedVendorLibs[fileName] = true;
              next(null);
            },
            (err) => {
              next();
              let offlineMsg = "";
              if (this.offlineStatusService.isCachingEnabled()) {
                offlineMsg = ' Please ensure that the library is configured for offline use ' +
                  'by setting offline keys on the vendor library document'
              }

              const msg = `Error loading component vendorLibrary: ${fileName}.${offlineMsg}`;
              console.log(msg);
              this.notificationService.error(msg);
            }
          );
        } else {
          next();
        }
      }, function (errorMsg) {
          if (errorMsg) {
            reject(new Error(errorMsg));
          }

          resolve();
      });
    });
  }

  async loadTemplateVendorLibs(template) {
    if (template && template.vendorLibrariesRel) {
      const templateVendorLibs = this.getFileNamesFromVendorLib(template.vendorLibrariesRel);
      await this.loadVendorLibs(templateVendorLibs);
    }
  }

  async registerComponents(componentDefs,
                           documentData: DocumentData,
                           formParameters: FormParameters,
                           user: User,
                           vcr: ViewContainerRef,
                           dynamicContainerComponent: any) {
    if (!componentDefs) {
      return Promise.resolve();
    }

    const _self = this;
    let key = 0;

    async.eachSeries(componentDefs, (componentDef, next) => {
      const directiveName = componentDef.componentName;
      if (directiveName && !startsWith(directiveName, 'ft-') &&  !startsWith(directiveName, 'cc-')) {
        const componentName = componentDef.componentName;
        const promise = _self.loadComponentDocAndVendorLibs(componentName).then(
          function successFunc() {
            componentDef.componentName = _self.componentDocExists(componentDef.componentName) ?
              componentDef.componentName : 'ft-component-error';
            _self.templateHtmlService.appendComponent(componentDef,
              documentData, formParameters, user, key, vcr, dynamicContainerComponent);
            key++;
            next();
          });
      }
    } , function (errorMsg) {
      if (errorMsg) {
        return Promise.reject(new Error(errorMsg));
      }
      return Promise.resolve();
    });
  }

  private getFileNamesFromVendorLib(vendorLibrariesRel) {
    const fileNames = [];
    if (vendorLibrariesRel && vendorLibrariesRel.length) {
      vendorLibrariesRel.forEach((vendorLib) => {
        if (vendorLib.fileName) {
          const fileName = vendorLib.fileName;
          if (!isEmpty(fileName) && !this.loadedVendorLibs[fileName]) {
            fileNames.push(fileName);
          }
        } else if (vendorLib.fileNames) {
          fileNames.push(...vendorLib.fileNames);
        }
      });
    }

    return fileNames;
  }

  /**
   * Load component document
   * @param directiveName the directive name
   * @returns the promise of component doc
   */
  async loadComponentDoc(directiveName) {
    if (!this.loadedComponentDocs[directiveName]) {
      const componentDoc =  await this.dataService.loadComponentDoc(directiveName);
      this.loadedComponentDocs[directiveName] = componentDoc;
    } 

    return this.loadedComponentDocs[directiveName];
  }

  /**
   * whether the component has been loaded
   * @param directiveName the directive name
   * @returns the result
   */
  componentExists(directiveName) {

    return this.loadedComponents[directiveName];
  }

  /**
   * whether the component doc has been loaded
   * @param directiveName the directive name
   * @returns the result
   */
  componentDocExists(directiveName) {

    return !!this.loadedComponentDocs[directiveName];
  }

  loadComponentDocAndVendorLibs(componentName): Promise<ComponentDocument> {
     const _self = this;
    return new Promise((resolve, reject) => {
      if (!_self.componentDocExists(componentName)) {
        _self.loadComponentDoc(componentName).then(
          function successFunc(componentDoc: ComponentDocument) {
            _self.loadedComponentDocs[componentName] = componentDoc;

            const compVendorLibRels = componentDoc.vendorLibrariesRel;
            if (compVendorLibRels && compVendorLibRels.length) {
              const compVendorLibRel = componentDoc.vendorLibrariesRel;
              const compVendorLibs = _self.getFileNamesFromVendorLib(compVendorLibRel);

              _self.loadVendorLibs(compVendorLibs).then(
                function sFunc() {
                  resolve(componentDoc);
                },
                function eFunc() {
                  resolve(componentDoc);
                }
              );
            } else {
              resolve(componentDoc);
            }
          },
          function error(err) {
            _self.loadedComponentDocs[componentName] = null;
            // do not break the chain in order to load the next component document
            resolve(null);
          }
        );
      } else {
        resolve(_self.loadedComponentDocs[componentName]);
      }
    });
  }

  async createComponent(
    document: FormDocument,
    template: FormTemplate,
    formParameters: FormParameters,
    account,
    compDef: ComponentDefinition | ErrorComponentDefinition,
    key: number,
    isloadedComponentDocAndVendorLibs?
  ): Promise<FormCustomElement> {
    const COMPONENT_ERROR_NAME = 'ft-component-error';

    if (!isloadedComponentDocAndVendorLibs){
      await this.loadComponentDocAndVendorLibs(compDef.componentName);
    }

    let componentName: string;

    if (template.loadComponentDocumentDependencies === false) {
      componentName = compDef.componentName;
    } else {
      const componentDocExists = this.componentDocExists(compDef.componentName);
      if (componentDocExists) {
        componentName = compDef.componentName;
      } else {
        compDef = {
          errorMessage: `Component not found: ${compDef.componentName}`,
          ...compDef
        };
        componentName = COMPONENT_ERROR_NAME;
      }
    }

    const anyWindow: any = window;

    const componentNameCamelCase = lodashString.camelCase(componentName);

    const isFunctionComponent = anyWindow.FormbirdServiceInjector.componentFunctions
      && anyWindow.FormbirdServiceInjector.componentFunctions[componentNameCamelCase];

    let customElement;
    const hasRegistered = customElements.get(componentName);
    if (!hasRegistered && !isFunctionComponent) {
      customElement = window.document.createElement(COMPONENT_ERROR_NAME);
      compDef = {
        errorMessage: `Component ${componentName} was found and loaded but it has not registered a custom element. 
          Components need to register a custom element matching the name of the component in the component document to work. 
          Check that the component package has been installed correctly.`,
        ...compDef
      };
    } else {
      customElement = window.document.createElement(componentName);
    }

    // pass the parameters of the component to the custom element
    customElement.componentDefinition = compDef;
    customElement.document = document;
    // add a class that can be used to identify custom elements that have been rendered by the formbird core from the
    // components array
    customElement.className = "ft-form-component";
    customElement.template = template;
    customElement.formParameters = formParameters;
    customElement.key = key;
    customElement.account = account;
    customElement.fieldName = compDef.name;
    if (document) {
      customElement.fieldValue = document[compDef.name];
    }

    return customElement;
  }
}
