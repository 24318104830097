import { cloneDeep, isEqual } from 'lodash';
import { LatestDocumentService } from '../document/latest-document.service';

export class ProxyEngineService {
  
  private static instance: ProxyEngineService;

  latestDocumentService: LatestDocumentService;

  private constructor() {
    this.latestDocumentService = LatestDocumentService.getInstance();
  }

  public static getInstance() {
    if (!ProxyEngineService.instance) {
      ProxyEngineService.instance = new ProxyEngineService();
    }

    return this.instance;
  }

  setProxyForDocument = (originalDoc, documentContext, ruleEngine) => {
    const _self = this;

    const arrayHandler = function (fldName, oldValue) {

      let shouldIgnoreBuiltinSetting = false;

      return {
        get: function (obj, prop) {

          if (prop === 'length') {
            return obj[prop];
          }

          const newObj = cloneDeep(obj);

          const notifyChanges = function () {
            ruleEngine.attachChangedField(originalDoc, fldName, newObj, oldValue, documentContext);
            console.log(`Setting value for array field: ${fldName} - old value: ${oldValue} - new value: ${newObj}`);
          };

          const func = obj[prop];

          if (typeof func === 'function') {

            if (prop === 'pop') {

              return function (...args) {

                newObj.pop();

                notifyChanges();

                return func.apply(obj, args);
              }
            }

            if (prop === 'shift') {

              return function (...args) {

                newObj.shift();

                notifyChanges();

                shouldIgnoreBuiltinSetting = true;

                const res = func.apply(obj, args);

                shouldIgnoreBuiltinSetting = false;

                return res;
              };
            }

            if (prop === 'unshift') {

              return (...args) => {

                newObj.unshift(...args);

                notifyChanges();

                return func.apply(obj, args);
              }
            }

            if (prop === 'splice') {

              return (...args) => {

                newObj.splice(...args);

                notifyChanges();

                return func.apply(obj, args);
              }
            }
          }

          if (['[object Object]'].indexOf(Object.prototype.toString.call(obj[prop])) > -1) {
            return new Proxy(obj[prop], nestedObjectHandler(fldName, oldValue));
          }
          if (Array.isArray(obj[prop])) {
            return new Proxy(obj[prop], arrayHandler(fldName, oldValue));
          }

          return obj[prop];
        },
        set: function (obj, prop, value) {

          obj[prop] = value;

          if (prop === 'length' || shouldIgnoreBuiltinSetting) {
            return true;  // ignore inside variable of array
          }

          const newValue = originalDoc[fldName];
          if (!isEqual(oldValue, newValue)) {
            ruleEngine.attachChangedField(originalDoc, fldName, newValue, oldValue, documentContext);
            console.log(`Setting value for array field: ${fldName} - value: ${newValue}`);
          }

          return true;
        }
      };
    }

    const nestedObjectHandler = function (fldName, oldValue) {
      return {
        get: function (obj, prop) {
          if (['[object Object]'].indexOf(Object.prototype.toString.call(obj[prop])) > -1) {
            return new Proxy(obj[prop], nestedObjectHandler(fldName, oldValue));
          }
          if (Array.isArray(obj[prop])) {
            return new Proxy(obj[prop], arrayHandler(fldName, oldValue));
          }
          return obj[prop];
        },
        set: function (obj, prop, value) {
          obj[prop] = value;

          const newValue = originalDoc[fldName];
          if (!isEqual(oldValue, newValue)) {
            ruleEngine.attachChangedField(originalDoc, fldName, newValue, oldValue, documentContext);
            console.log(`Setting value for field: ${fldName} - value: ${newValue}`);
          }

          return true;
        }
      };
    };

    const fieldsChangedByRuleset = {
      set: function (document, fldName, newValue) {

        ruleEngine.attachChangedField(document, fldName, newValue, document[fldName], documentContext);

        Reflect.set(document, fldName, newValue);

        console.log(`Setting value for field: ${fldName} - value: ${newValue}`);

        return true;
      },
      deleteProperty(document, fldName) {

        ruleEngine.attachChangedField(document, fldName, undefined, document[fldName], documentContext);

        Reflect.deleteProperty(document, fldName);

        return true;
      },
      defineProperty: function (document, fldName, descriptor) {

        ruleEngine.attachChangedField(document, fldName, descriptor.value, document[fldName], documentContext);

        Reflect.defineProperty(document, fldName, descriptor);

        return document;
      },
      get: function (document, fldName) {

        const doc = _self.latestDocumentService.latestDocuments && _self.latestDocumentService.latestDocuments[originalDoc?.documentId]
          ? _self.latestDocumentService.latestDocuments[originalDoc?.documentId] : originalDoc;
        let oldValue;

        if (doc && doc[fldName]) {
          oldValue = cloneDeep(doc[fldName]);
        }

        if ((['[object Object]'].indexOf(Object.prototype.toString.call(document[fldName])) > -1) && document[fldName] !== null) {

          return new Proxy(document[fldName], nestedObjectHandler(fldName, oldValue));

        } else if ((['[object Array]'].indexOf(Object.prototype.toString.call(document[fldName])) > -1) && document[fldName] !== null) {

          return new Proxy(document[fldName], arrayHandler(fldName, oldValue));

        } else {
          return document[fldName];
        }

      }
    };

    return new Proxy(originalDoc, fieldsChangedByRuleset);

  };

}
