import { Injectable } from '@angular/core';
import {
  ChangedDocumentService,
  ClientAccessService,
  CurrentDocumentService,
  DataService,
  formDeleteDocument,
  ModifiedFieldService,
  NotificationService,
  ValidationService
} from '@formbird/services';
import { DocumentData, SharedConstants } from '@formbird/types';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from '@services/modal/modal.service';
import { UtilDocument } from '@formbird/shared';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DeletionService {

  deleteOptions: any;
  deletionService$: Observable<object>;
  deleteOptionsSubject: Subject<object>;

  documentData: DocumentData;

  constructor(
    private currentDocumentService: CurrentDocumentService,
    private dataService: DataService,
    private clientAccessService: ClientAccessService,
    private modalService: ModalService,
    private router: Router,
    private modifiedFieldService: ModifiedFieldService,
    private notificationService: NotificationService,
    private changedDocumentService: ChangedDocumentService,
    private validationService: ValidationService
  ) {
    this.deleteOptions = {
      isDeletable: false,
      showSpinner: false
    };

    this.deleteOptionsSubject = new Subject<object>();
    this.deletionService$ = this.deleteOptionsSubject.asObservable();

    this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });
  }

  deleteDoc() {
    if (!this.documentData) {
      return;
    }

    const documentId = this.documentData.document.documentId;
    const templateId = this.documentData.template.documentId;
    const disableDeleteConfirmationDialog = this.documentData.template.disableDeleteConfirmationDialog;

    let previousVersionId;


    const doc = this.documentData.document;
    if (doc.systemHeader && doc.systemHeader.versionId) {
      previousVersionId = doc.systemHeader.versionId;
    }

    if (documentId) {
      const doDelete = () => {
        const docContext = {
          documentData: this.documentData,
          template: this.documentData.template,
          templateId: this.documentData.template.documentId,
          formParameters: {
            overrideTemplateId: this.documentData.overrideTemplateId,
            selectedDocumentId: doc.documentId,
            hierarchyInfo: this.documentData.hierarchyInfo,
            isMainDoc: this.documentData.isMainDoc
          }
        };

        this.validationService.preDeleteClient(doc, docContext, (error, result) => {
          if (error) {
            if (error.message && error.message.trim() !== '') {
              this.notificationService.printMessage('Error on deleting: ' + error.message, 'error');
            }
          } else {
            logger.info('Deleting document: ' + documentId);
            this.deleteOptions.showSpinner = true;
            this.deleteOptionsSubject.next(this.deleteOptions);
            this.dataService.destroy(documentId, templateId, previousVersionId).then(
              () => {
                this.changedDocumentService.dispatchAction(formDeleteDocument(this.documentData.documentId));
                this.modifiedFieldService.resetFormDirty();
                this.deleteOptions.showSpinner = false;
                this.deleteOptionsSubject.next(this.deleteOptions);
                this.notificationService.printMessage('Deleted document: ' + documentId, 'success');
                this.router.navigate(['/form', templateId]);
              }, (err) => {
                this.deleteOptions.showSpinner = false;
                this.deleteOptionsSubject.next(this.deleteOptions);
                let message = err ? 'Error deleting document: ' + err.message : 'Error on deleting.'
                this.notificationService.printMessage(message, 'error');
              }
            );
          }
        });
      };

      if (disableDeleteConfirmationDialog) {
        doDelete();
      } else {
        this.modalService.showDialog('Do you wish to delete this document?', 'WARNING', () => {
          doDelete();
        }, () => {
          logger.info('Deletion cancelled by user of document ' + documentId);
        });
      }
    }
  }

  checkingDeleteDoc(document) {
    let deletable = true;
    const isNew = UtilDocument.isNew(document);

    if (!document || !document.documentId || isNew) {
      deletable = false;
    } else {
      const canDelete = this.clientAccessService.checkAccess(document, SharedConstants.OPERATION_TYPE_DELETE);
      if (!canDelete) {
        deletable = false;
      }
    }

    this.deleteOptions.isDeletable = deletable;
    this.deleteOptionsSubject.next(this.deleteOptions);
  }
}
