import { DocumentListService } from '@services/document/document-list.service';
import { Injectable } from '@angular/core';
import {
  BroadcastService,
  SearchService,
  ChangedDocumentService,
  CurrentDocumentService,
  DataService,
  DocumentService,
  DocumentSaveService,
  LocalStorageService,
  NotificationService,
  OfflineUtilService,
  UnsavedDocumentService,
  MandatoryFieldService,
  ModifiedFieldService,
  ClientRulesService,
  ValidationService,
  LoggedInUserService,
  WebSocketService,
  FileProviderService,
  ComponentService,
  FinaliseValueFieldService,
  OfflineStatusService,
  KeyValueStorageService,
  SearchQueriesService,
  SearchClientService,
  NavigationService,
  PendingOperationService
} from '@formbird/services';
import { AuditService } from '../audit/audit.service';
import { ModalService } from '../modal/modal.service';
import { RouterService } from '../../../app-routing/router.service';
import { ImageCompressionService } from '../image-compression.service';
import { ImageGalleryService } from '../image-gallery.service';
import { DocumentModalService } from '../modal/document-modal.service';

@Injectable({
  providedIn: 'root'
})
export class FormbirdInjectorService {

  constructor(
    private searchService: SearchService,
    private changedDocumentService: ChangedDocumentService,
    private broadcastService: BroadcastService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private mandatoryFieldService: MandatoryFieldService,
    private modifiedFieldService: ModifiedFieldService,
    private documentListService: DocumentListService,
    private unsavedDocumentService: UnsavedDocumentService,
    private currentDocumentService: CurrentDocumentService,
    private offlineUtilService: OfflineUtilService,
    private documentService: DocumentService,
    private documentSaveService: DocumentSaveService,
    private clientRulesService: ClientRulesService,
    private modalService: ModalService,
    private routerService: RouterService,
    private validationService: ValidationService,
    private loggedInUserService: LoggedInUserService,
    private webSocketService: WebSocketService,
    private fileProviderService: FileProviderService,
    private imageCompressionService: ImageCompressionService,
    private imageGalleryService: ImageGalleryService,
    private documentModalService: DocumentModalService,
    private componentService: ComponentService,
    private auditService: AuditService,
    private finaliseFieldValueService: FinaliseValueFieldService,
    private offlineStatusService: OfflineStatusService,
    private keyValueStorageService: KeyValueStorageService,
    private searchQueriesService: SearchQueriesService,
    private searchClientService: SearchClientService,
    private navigationService: NavigationService,
    private pendingOperationService: PendingOperationService
  ) {
  }

  /**
   * set up service to be called globally from components and other code external to the Angular app
   */
  setupServices() {
    (<any>window).FormbirdServiceInjector = {
      /**
       * return the service matching the name
       */
      get(serviceName: string) {
        if (serviceName) {
          return this[serviceName];
        }
      },

      SearchService: this.searchService,
      ChangedDocumentService: this.changedDocumentService,
      BroadcastService: this.broadcastService,
      NotificationService: this.notificationService,
      DataService: this.dataService,
      LocalStorageService: this.localStorageService,
      MandatoryFieldService: this.mandatoryFieldService,
      ModifiedFieldService: this.modifiedFieldService,
      DocumentListService: this.documentListService,
      UnsavedDocumentService: this.unsavedDocumentService,
      CurrentDocumentService: this.currentDocumentService,
      OfflineUtilService: this.offlineUtilService,
      OfflineStatusService: this.offlineStatusService,
      DocumentService: this.documentService,
      DocumentSaveService: this.documentSaveService,
      ClientRulesService: this.clientRulesService,
      ModalService: this.modalService,
      RouterService: this.routerService,
      ValidationService: this.validationService,
      LoggedInUserService: this.loggedInUserService,
      WebSocketService: this.webSocketService,
      FileProviderService: this.fileProviderService,
      ImageCompressionService: this.imageCompressionService,
      ImageGalleryService: this.imageGalleryService,
      DocumentModalService: this.documentModalService,
      ComponentService: this.componentService,
      AuditService: this.auditService,
      FinaliseValueFieldService: this.finaliseFieldValueService,
      KeyValueStorageService: this.keyValueStorageService,
      DatatablesQueriesService: this.searchQueriesService,
      SearchQueriesService: this.searchQueriesService,
      SearchClientService: this.searchClientService,
      NavigationService: this.navigationService,
      PendingOperationService: this.pendingOperationService
    };
  }
}
