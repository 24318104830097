import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedUrlRoutes, UtilValidation } from '@formbird/shared';
import { LoggedInUserService, LocalStorageService, LocalStorageKeys, 
  NotificationService, SessionTestService, ClientConstants } from '@formbird/services';
  import { CustomRouteService } from '@services/custom-route.service';

@Component({
  selector: 'ft-public-login',
  template: ``
})
export class PublicLoginComponent implements OnInit, OnDestroy {
  sub: any;
  user: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loggedInUserService: LoggedInUserService,
    private notificationService: NotificationService,
    private sessionTestService: SessionTestService,
    private localStorageService: LocalStorageService,
    private customRouteService: CustomRouteService
  ) { }

  private async authenticatePublicUser(accountPublicURL) {
    const { publicLogin } = SharedUrlRoutes.serverRoutes;
    try {
      const response: any = await this.http.post(publicLogin,
        { context: accountPublicURL }).toPromise();
      const data = response;
      if (data.status === 'ok') {
        this.loggedInUserService.setUser(data.user);
        await this.sessionTestService.testSession();
        return data.user;
      } else {
        throw new Error('User not found!');
      }
    } catch (err) {
      console.log(err);
      throw new Error('Public login failed because the account is not public');
    }
  }

  private async checkPublicUser(accountPublicURL) {
    try {
      this.localStorageService.setItem(LocalStorageKeys.CHECK_PUBLIC_USER, "true");
      this.user = await this.loggedInUserService.loadLoggedUser();
      if (this.user.accountPublicURL === accountPublicURL) {
        return this.user;
      } else {
        await this.authenticatePublicUser(accountPublicURL);
      }
    } catch (err) {
      await this.authenticatePublicUser(accountPublicURL);
    } finally {
      this.localStorageService.removeItem(LocalStorageKeys.CHECK_PUBLIC_USER);
      this.localStorageService.removeItem(ClientConstants.LAST_ENTERED_STATE_URL);
    }
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params: any) => {
      const {
        accountPublicURL,
        paramRoute,
        paramQuery,
        override
      } = params;

      const navExtras = { skipLocationChange: true, replaceUrl: false };

      if (accountPublicURL && accountPublicURL !== '') {
        const firstRoute = accountPublicURL;
        const authRoutes = {
          'login': '/auth/login',
          'forgotpass': '/auth/forgotpass',
          'signup': '/auth/signup',
          'resetpass': '/auth/resetpass',
          'emailRequired': '/auth/emailRequired',
          'activateAccount': '/auth/activateAccount'
        };

        if (Object.keys(authRoutes).indexOf(firstRoute) >= 0) {
          this.router.navigate([authRoutes[firstRoute]], { ...navExtras, queryParamsHandling: 'preserve' } );
        } else if (firstRoute === 'searchNoNavBar') {
          this.router.navigate(['/search/noNavBar', paramRoute], navExtras);
        } else {
          try {
            await this.checkPublicUser(accountPublicURL);
            if (paramRoute === 'form' && override && override !== '') {
              this.router.navigate(['/form', paramQuery, override], navExtras);
            } else if (paramRoute === 'form') {
              this.router.navigate(['/form', paramQuery], navExtras);
            } else if (paramRoute === 'formNoNavBar') {
              this.router.navigate(['/formNoNavBar', paramQuery], navExtras);
            } else if (paramRoute === 'search') {
              this.router.navigate(['/search', paramQuery], navExtras);
            } else if (paramRoute === 'searchNoNavBar') {
              this.router.navigate(['/search/noNavBar', paramQuery], navExtras);
            } else if (paramRoute === 'external') {
              this.router.navigate(['/external?p=', paramQuery], navExtras);
            } else {
              if (this.user.accountPublicURL !== accountPublicURL) {
                const customRoute: any = await this.customRouteService.getURLRoute(accountPublicURL);
                const routeDoc = customRoute?.urlRouteTemplateRel?.[0];
                if (routeDoc) {
                  const customFunc = routeDoc.routeHandler;
                  let routes;
                  if (UtilValidation.hasValue(customFunc)) {
                    const routeHandler = Function(`return (${customFunc})`)();
                    routes = await Promise.resolve(routeHandler(paramRoute));
                  } else {
                    routes = paramRoute ? ['/form', paramRoute, routeDoc.documentId] : ['/form', routeDoc.documentId];
                  }

                  if (customRoute.titlePrefix) {
                    this.localStorageService.setItem(LocalStorageKeys.TITLE_PREFIX, customRoute.titlePrefix);
                  }
                  // don't clutter history. when back if pressed on current route, it'll go to the what's specified in the routeDoc
                  // causing a navigation back to the page where the back button was pressed
                  this.router.navigate(routes, { ...navExtras, replaceUrl: true });
                } else {
                  throw new Error("Invalid urlRoute document: " + customRoute.documentId);
                }
              } else {
                this.router.navigate(['/default'], navExtras);
              }
            }
          } catch (err) {
            this.notificationService.error(err.message);
            this.router.navigate(['/notFound'], navExtras);
          }
        }
      }

    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
