import { Injectable } from '@angular/core';
import * as Dexie from 'dexie';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { PageVisibilityService } from '../page-visibility.service';
import { NotificationService } from '../notification/notification.service';
import { UtilArray } from '@formbird/shared';
import { KeyValueStorageService } from '../key-value-storage/key-value-storage.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { ConfigService } from '../config/config.service';
import { IndexedDBConnector, IndexedDBConstants } from '@formbird/indexed-db';

const logger = console;


// Flags used to indicate if we need to wait for onupgradeneeded function to complete. This is to solve 10049#c33176
// retrieve the db without upgrading it (like when the user refreshes the page after db was created)
const GET_DB_EVENT_DEFAULT = 0;

// create the db, perform the default upgrade function and retrieve the db
const GET_DB_EVENT_INIT = 1;

// perform search index upgrade function and retrieve the db
const GET_DB_EVENT_REG_SEARCH_INDEXES = 2;

let IndexedDBConnectorService$: any;

@Injectable({
  providedIn: 'root'
})
export class IndexedDBConnectorService extends IndexedDBConnector{

  documentIndexes: any[];
  currentVersion: number;

  constructor(
    private configService: ConfigService,
    private loggedInUserService: LoggedInUserService,
    private pageVisibilityService: PageVisibilityService,
    public notificationService: NotificationService,
    private keyValueStorageService: KeyValueStorageService,
    private offlineStatusService: OfflineStatusService
  ) {
    super(keyValueStorageService, notificationService);
    this.pageVisibilityService.registerVisibilityListener((isPageHidden) => {

      if (isPageHidden) {
        return;
      }
      if (this.offlineStatusService.isCachingEnabled()) {
        this.getDatabase().then(() => {
          let trueIdbVersion = this.loggedInUserService.getUserConfigItem('idbCurrentVersion'); // shared accross tabs
          trueIdbVersion = trueIdbVersion == null ? 0 : trueIdbVersion;

          let registeredIdbVersion = this.offlineDatabase.idbVersion;
          registeredIdbVersion = registeredIdbVersion == null ? 0 : registeredIdbVersion;

          // reload since there's a new version of idb due to cache being enabled by another tab, or new search indexes
          // registered by another tab.
          if (typeof window !== 'undefined' && trueIdbVersion !== registeredIdbVersion) {
            window.location.reload();
          }
        }, (error) => {
          logger.error(error);
        });
      }
    });

    IndexedDBConnectorService$ = this;
  }

  getLoggedInUserService() {
    return this.loggedInUserService;
  }

  async getDatabase(): Promise<any> {
    if (!this.offlineStatusService.isCachingEnabled()) {
      this.loggedInUserService.setUserConfigItem('idbCurrentVersion', 0);
      throw new Error('Attempted to access IndexedDB database when offline is not enabled.');
    }

    if (this.offlineDatabase.idb && this.offlineDatabase.idb.isOpen()) {
      return this.offlineDatabase.idb;
    }

    // Lazy-load Dexie
    const persistResult = await this.tryPersistWithoutPromptingUser();

    switch (persistResult) {
      case "never":
        logger.info("Not possible to persist storage");
        break;
      case "persisted":
        logger.info("Successfully persisted storage silently");
        break;
      case "prompt":
        logger.info("Not persisted, but we may prompt user when we want to.");
        const persistent = await navigator.storage.persist();
        if (persistent) {
          logger.info("Not persisted, but we may prompt user when we want to.");
        } else {
          logger.warn("Storage may be cleared by the UA under storage pressure.");
          const message = "If your device is low on storage, the offline database may be cleared\ " +
            "in this browser. Please choose a browser that does not have this limitation";
          this.notificationService.printMessage(message, 'warn', null, { x: 'right', y: 'top' });
        }
        break;
    }

    this.idbName = IndexedDBConstants.FT_DOCUMENTS_DB_NAME_PREFIX + this.loggedInUserService.getUserId();


    const defaultAddons = [
      {
        "fileName": "/vendor/offline/dexie-elasticsearch-addon/dexie-elasticsearch-addon.js",
        "exportName": "ElasticIndexedDBAddon"
      }
    ];

    const addons = [];
    const dexieAddons = this.configService.clientConfig().offline?.dexieAddons || defaultAddons;

    for (const dexieAddon of dexieAddons) {
      try {
        const module = await import(/* webpackIgnore: true */ dexieAddon.fileName);
        addons.push(module[dexieAddon.exportName]);
      } catch (err) {
        //proceed even if not found or any error
        logger.error("Unabe to load addon: " + dexieAddon.fileName + ". Reason: " + err.message);
      }
    }

    const db = new Dexie.default(this.idbName, { addons });

    this.documentIndexes = [...IndexedDBConstants.DOCUMENT_INDEXES];
    let indexes = this.loggedInUserService.getUserConfigItem('searchIndexes');
    indexes = this.getIndexKeyFromAccountGroupConfig(indexes);


    for (let i = 0; i < indexes.length; i++) {
      UtilArray.add(this.documentIndexes, indexes[i], true, null);
    }

    this.currentVersion = this.loggedInUserService.getUserConfigItem('idbCurrentVersion');
    this.currentVersion = (this.currentVersion && this.currentVersion >= IndexedDBConstants.VERSION) ? this.currentVersion : IndexedDBConstants.VERSION;

    // Open the database as a dexie database
    db.version(this.currentVersion).stores({
      // each table has a list of indexes assigned. The first index is the primary key,
      // so systemHeader.versionId
      // is the primary key for documents
      documents: this.documentIndexes.toString(),
      ftProperties: 'key',
      offlineFiles: 'fileNo, status'
    });

    db.open();

    this.offlineDatabase.idb = db;
    this.offlineDatabase.idbVersion = this.currentVersion;

    this.loggedInUserService.setUserConfigItem('searchIndexes', this.documentIndexes);
    this.loggedInUserService.setUserConfigItem('idbCurrentVersion', this.currentVersion);

    return this.offlineDatabase.idb;
  }

  upgradeOfflineDatabase(indexes) {
    const currentVersion = this.loggedInUserService.getUserConfigItem('idbCurrentVersion');
    const nextVersion = Number(currentVersion) + 1;

    const db = this.offlineDatabase.idb;
    if (db) {
      db.close();
      db.version(nextVersion).stores({
          documents: indexes.toString(),
          ftProperties: 'key',
          offlineFiles: 'fileNo, status'
        }
      );

      this.loggedInUserService.setUserConfigItem('searchIndexes', indexes);
      this.loggedInUserService.setUserConfigItem('idbCurrentVersion', nextVersion);
      this.keyValueStorageService.setItem('idbCurrentVersion', nextVersion);
      this.offlineDatabase.idbVersion = nextVersion;
      this.offlineDatabase.idb = db;

      db.open().then(() => {
        logger.info('>>>>>>>>>>>>>>>>>>>>Offline DB has been upgraded to version: ' + nextVersion);
      }).catch((err) => {
        logger.warn(err);
        this.notificationService.warning('Failed to apply the saved "indexedDBIndexes": ' 
          + err.message, 'Warning');
      });
    }
  }

  // /**
  //  THIS METHOD IS NOT USED ANY WHERE. WILL BE CONVERTED TO ANGULAR LATER.
  //  * Generic function for opening
  //  * @param version
  //  * @param requestEvent
  //  * @returns {jQuery.promise|promise|*}
  //  */
  // function openOfflineDatabase(version, requestEvent){
  //   var deferred = $q.defer();
  //
  //   var onsuccessComplete = false;
  //   //we should wait for upgrade in two cases: [1]upon creating the database & [2]upon registering search indexes
  //   var dontWaitForUpgrade = requestEvent === GET_DB_EVENT_DEFAULT;
  //   var onupgradeComplete = dontWaitForUpgrade;//regard as completed if don't wait
  //
  //   /**
  //    * Chrome and Firefox do not behave the same way when it comes to the order/timing which IDBDatabase.onsuccess
  //    * and IDBDatabase.onupgradeneeded functions. resolveOpenRequest will make sure that onupgradeneeded function
  //    * completes before we return the IDBDatabase reference to those who request it via the getDatabase function.
  //    *
  //    * See 10049#c33176
  //    */
  //   var resolveOpenDBRequest = function(IDBDatabase){
  //     if(onsuccessComplete && onupgradeComplete){
  //       deferred.resolve(IDBDatabase);
  //       $rootScope.$digest();
  //       return deferred.promise;
  //     }
  //   };
  //
  //   var idbName = LoggedInUserService.getUserId();
  //
  //   var dbRequest = indexedDB.open(idbName, parseInt(version));
  //
  //   dbRequest.onblocked = function(event) {
  //     logger.info("IndexedDB Event: indexedDB blocked. ");
  //   };
  //
  //   dbRequest.onerror = function (event) {
  //     var msg = "Error opening indexedDB: " + event.target.error.message;
  //     logger.error(msg);
  //     if (event.target.error.name === "VersionError") {
  //
  //       var nextVersion = parseInt(version) + 1;
  //       LoggedInUserService.setUserConfigItem('idbCurrentVersion', nextVersion);
  //       logger.info("Reloading page to open IDB with version: " + nextVersion);
  //
  //       $window.location.reload();
  //
  //     } else if (event.target.error.name === "AbortError") {
  //       logger.error("Error: AbortError.");
  //     } else {
  //       logger.error("Error: " + event.target.error.name);
  //       deferred.reject(event.target.error);
  //     }
  //   };
  //
  //   dbRequest.onsuccess = function (event) {
  //     // only set the database version when the upgrade is successful
  //     LoggedInUserService.setUserConfigItem('idbCurrentVersion', version);
  //
  //     logger.info('IndexedDB Event: ' + indexedDBConstants.DB_NAME + ' storage opened successfully. Version: ' + version);
  //
  //     var database = dbRequest.result;
  //
  //     database.onclose = function (evt) {
  //       logger.info("Closing indexedDB database: " + database.name);
  //       $window.alert("Offline database closed.");
  //     };
  //
  //     database.onabort = function (evt) {
  //       logger.error("IndexedDB abort occured: " + evt.target.error.message);
  //       logger.error(evt);
  //     };
  //
  //     database.onversionchange = function (evt) {//another tab might have upgraded the database
  //       logger.info('IndexedDB Event: database version changed.');
  //
  //       ModalService.showDialog('A new version for the offline database is available. Please reload.',
  //         'WARNING',
  //         function () {
  //           $window.location.reload();
  //         },
  //         function () {
  //
  //         });
  //     };
  //
  //     database.onerror = function(evt){
  //       logger.error('IDBDatabase error occurred: ' + evt.target.error.message);
  //       logger.error(evt);
  //     };
  //
  //     onsuccessComplete = true;
  //     resolveOpenDBRequest(database);
  //
  //   };
  //
  //   var defaultUpgradeFunc =  function (event) {
  //
  //     var database = event.target.result;
  //     //If the an objectStore's structure is modified in a future version, make sure to remove
  //     //the old version of the objectStore (if it exists)
  //
  //     //recreate tables to prep for whatever structural changes in the indexes
  //     var tables = [
  //       {
  //         name: indexedDBConstants.DOCUMENT_TABLE_NAME,
  //         param: { keyPath: "systemHeader.versionId", autoIncrement: false},
  //         indexes : [
  //           {
  //             name:  indexedDBConstants.DOCUMENT_INDEX_VERSION_ID,
  //             path: "systemHeader.versionId",
  //             unique: true
  //           },
  //           {
  //             name: indexedDBConstants.DOCUMENT_INDEX_DOCUMENT_ID,
  //             path: "documentId",
  //             unique: false
  //           },
  //           {
  //             name: indexedDBConstants.DOCUMENT_INDEX_PENDING_OPERATION,
  //             path: "systemHeader.offlineDetails.status",
  //             unique: false
  //           },
  //           {
  //             name: indexedDBConstants.DOCUMENT_INDEX_SYSTEM_TYPE,
  //             path: "systemHeader.systemType",
  //             unique: false
  //           }
  //         ]
  //       },
  //       {
  //         name: indexedDBConstants.FT_PROPERTIES_TABLE_NAME,
  //         param:{ keyPath: "key", autoIncrement: false},
  //         indexes : [
  //           { name: "key", path: "key", unique: true }
  //         ]
  //       },
  //       {
  //         name: indexedDBConstants.OFFLINE_FILE_TABLE_NAME,
  //         param: { keyPath: 'fileNo', autoIncrement : false},
  //         indexes:[
  //           { name: "fileNo", path: "fileNo", unique: true },
  //           { name: "status", path: "status", unique: false }
  //         ]
  //       }
  //     ];
  //
  //     //recreate tables for new structural changes in the indexes
  //     for(var i = 0 ; i < tables.length ; i++){
  //       var tbl = tables[i];
  //       //Do not add recreate already existing objectStores else an ConstraintError error will be thrown.
  //       if(database.objectStoreNames.contains(tbl.name)){
  //         database.deleteObjectStore(tbl.name);
  //       }
  //       var store = database.createObjectStore(tbl.name, tbl.param);
  //       for(var z = 0 ; z < tbl.indexes.length; z++){
  //         var index = tbl.indexes[z];
  //         createIndex(store, index.name, index.path, index.unique);
  //       }
  //     }
  //
  //     logger.info("IndexedDB Event: Default offline database indexes set.");
  //
  //     onupgradeComplete = true;
  //     resolveOpenDBRequest(database);
  //
  //   };
  //
  //   var registerSearchIndexesFunc = function (event) {
  //
  //     var searchIndexes = LoggedInUserService.getUserConfigItem('searchIndexes');
  //     var objectStore = event.target.transaction.objectStore(indexedDBConstants.DOCUMENT_TABLE_NAME);
  //
  //     for(var x = 0 ; x < searchIndexes.length ; x++){
  //       var value = searchIndexes[x].replace(".", "___");
  //       createIndex(objectStore, value, value, false);
  //     }
  //
  //     //record all registered indexes
  //     var registered = LoggedInUserService.getUserConfigItem('registeredSearchIndexes');
  //     LoggedInUserService.setUserConfigItem('registeredSearchIndexes', angular.extend(registered, searchIndexes));
  //
  //     logger.info("Search indexes registered." + searchIndexes);
  //
  //     onupgradeComplete = true;
  //     var database = event.target.result;
  //     resolveOpenDBRequest(database);
  //   };
  //
  //   var regSearchIndexes = requestEvent === GET_DB_EVENT_REG_SEARCH_INDEXES;
  //
  //   dbRequest.onupgradeneeded = regSearchIndexes ? registerSearchIndexesFunc: defaultUpgradeFunc;
  //
  //   return deferred.promise;
  //
  // }

  upgradeIndexedDB(): Promise<any> {

    const searchIndexes = this.loggedInUserService.getUserConfigItem('searchIndexes');
    const registered = this.loggedInUserService.getUserConfigItem('registeredSearchIndexes');

    let hasUnregisteredIndex = false;

    for (let k = 0; k < searchIndexes.length; k++) {

      hasUnregisteredIndex = registered.indexOf(searchIndexes[k]) === -1;

      if (hasUnregisteredIndex) {
        break;
      }
    }

    if (!hasUnregisteredIndex) {
      return Promise.resolve(); // just return a resolved promise
    }

    return this.getDatabase();
  }

  /**
   * open an IndexedDB cursor
   * @param cursorIndexName - the name of the index to open a cursor on
   * @param cursorRange - an IndexedDBKeyRange (part of the IndexedDB api) of the cursor to open
   * @returns promise.promise|*|jQuery.promise the promise
   */
  openCursor(cursorIndexName, cursorRange) {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.getDatabase().then(function(db: any) {

        const tbl = IndexedDBConstants.DOCUMENT_TABLE_NAME;
        const transaction = db.transaction(tbl, 'readwrite');
        const store = transaction.objectStore(tbl);
        const index = store.index(cursorIndexName);

        const request = index.openCursor(cursorRange);

        request.onsuccess = function() {
          const cursor = request.result;
          resolve(cursor);
        };

        request.onerror = function() {
          reject('Error opening indexedDB cursor in table: ' + tbl);
        };

      },(error) => {
        reject(error);
      });
    });

  }

  private createIndex(objectStore, indexName, keyPath, isUnique) {

    if (!UtilArray.contains(objectStore.indexNames, indexName)) {

      objectStore.createIndex(indexName, keyPath, { unique: !!isUnique });

    }
  }

  closeDatabase() {

    if (this.offlineDatabase.idb != null) {

      this.offlineDatabase.idb.delete();
      this.offlineDatabase.idb = null;
      this.offlineDatabase.idbVersion = null;
      this.offlineDatabase.state = 'closed';

    }

    return Promise.resolve();

  }

  getIndexKeyFromAccountGroupConfig(indexes){

    let accountConfig = this.loggedInUserService.getUserAccountGroupConfigDocument();
    if (accountConfig && accountConfig.indexedDBIndexes && accountConfig.indexedDBIndexes.length > 0){
      indexes = UtilArray.union(indexes, accountConfig.indexedDBIndexes);
    }
    return indexes;

  }

}
