import { Injectable } from '@angular/core';
import * as lodashLang from 'lodash/lang';
import { ClientConstants, DocumentSaveService, DocumentService, ModifiedFieldService, UnsavedDocumentService } from '@formbird/services';
import { SharedConstants } from '@formbird/types';
import { DocumentDialogComponent } from '@components/dialog/document-dialog/document-dialog.component';
import { ModalService } from './modal.service';
import { logger } from '@logger';

@Injectable({
  providedIn: 'root'
})
export class DocumentModalService {

  constructor(
    private unsavedDocumentService: UnsavedDocumentService,
    private modifiedFieldService: ModifiedFieldService,
    private documentSaveService: DocumentSaveService,
    private documentService: DocumentService,
    private modalService: ModalService
  ) {
  }

  private documentModel = {};

  getDocumentModel() {
    return this.documentModel;
  }

  setDocumentModel(model) {
    this.documentModel = model;
  }

  async openDocumentInModal(documentId, options) {

    const _self = this;

    await _self.documentService.getDocumentOnly(documentId);

    return new Promise((resolve, reject) => {

      let unsavedDocumentListId;
      let modalTitle;

      const formParameters = {} as any;
      if (options) {
        formParameters.parentDocumentId = options.parentDocumentId;
        formParameters.defaultFields = options.defaultFields;
        formParameters.hierarchyInfo = options.hierarchyInfo;
        unsavedDocumentListId = options.unsavedDocumentListId;
        modalTitle = options.modalTitle;
      }

      if (!unsavedDocumentListId) {
        unsavedDocumentListId = _self.unsavedDocumentService.createUnsavedDocumentList();
      }

      formParameters.unsavedDocumentListId = unsavedDocumentListId;
      formParameters.selectedDocumentId = documentId;
      formParameters.isModal = true;

      const cancelFunc = function() {
        const docId = getUnsavedDocumentId();
        if (docId) {
          _self.modifiedFieldService.removeModifiedDocumentFields(docId);
        }
        resolve(null);
      };

      const discardFunc = function() {
        cancelFunc();
        _self.modalService.closeDialog();
      };

      const closeFunc = function() {
        const docId = getUnsavedDocumentId();

        if (docId) {

          const mFlds = _self.modifiedFieldService.getModifiedFieldsInDoc(docId);

          if (mFlds && mFlds.length > 0) {

            _self.modalService.showDialog('Save Changes?', 'WARNING', saveFunc, cancelFunc, discardFunc);

          } else {
            discardFunc();
          }

        } else {
          discardFunc();
        }

      };

      const saveFunc = async function() {
        await _self.documentSaveService.save(unsavedDocumentListId).then(
          function successFunc(documents) {
            resolve(documents);
            _self.modalService.closeDialog();
          },
          function errorFunc(err) {
            _self.modalService.showDialog(err.message, 'ERROR');
            reject(err);
          }
        );

      };

      function getUnsavedDocumentId() {
        let docId;
        if (!lodashLang.isEmpty(unsavedDocumentListId)) {
          const unsavedDocuments = _self.unsavedDocumentService.getUnsavedDocuments(unsavedDocumentListId);
          if (!lodashLang.isEmpty(unsavedDocuments) && unsavedDocuments[Object.keys(unsavedDocuments)[0]]) {
            docId = unsavedDocuments[Object.keys(unsavedDocuments)[0]].documentId;
          }
        }
        return docId;
      }

      const data = {
        component: DocumentDialogComponent,
        html: `
          <div class="divTemplateDialog">
              <div class="modal-header">
                  <button type="button" class="modelClose" data-dismiss="modal" aria-hidden="true" ng-click="saveModal('cancel')">
                      <i class="material-icons">close</i></button>
                  <h4 class="modal-title">
                      <button type="button" ng-show="formParameters.dirtyClass==='show'?true:false"  class="modelSave"
                              data-dismiss="modal" aria-hidden="true" ng-click="saveModal('save')">
                          <i class="material-icons blink {{formParameters.dirtyClass}}">check</i></button>
                      {{modalTitle}}
                  </h4>
              </div>
              <div class="modal-body">
                  <!--<ft-template form-parameters="formParameters" is-main-document="false"></ft-template>-->
              </div>
              <div class="modal-footer">
              </div>
          </div>`,
        messageType: SharedConstants.STATUS_SUCCESS,
        confirmButton: true,
        showCancelButton: true,
        allowEnterKey: false, // prevent accidental confirm
        title: modalTitle,
        templateId: documentId,
        formParameters: formParameters,
        succFunc: saveFunc,
        errorFunc: closeFunc
      };

      _self.modalService.openDialog({ 
            data, 
            height: 'max-content', 
            width: '80vw',
            panelClass: 'ft-document-dialog'
          });
    });

  };
}
