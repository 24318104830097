import { Action } from 'redux';
import { getDefaultUserState, IUserState } from '../state/user.state';
import { USER_SET_USER, IUserSetUserAction } from '../actions/user.actions';

export function userReducer(state: IUserState = getDefaultUserState(), action: Action): IUserState {
  switch (action.type) {
    case USER_SET_USER: {
      const typedAction = <IUserSetUserAction>action;
      return { ...state, user: typedAction.payload.user };
    }

    default: {
      return state;
    }
  }
}
