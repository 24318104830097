import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedUrlRoutes } from '@formbird/shared';
import { Observable } from 'rxjs';

const serverRoutes = SharedUrlRoutes.serverRoutes;

@Injectable({
  providedIn: 'root'
})
export class SessionTestService {
  constructor(private http: HttpClient) {}

  testSession(): Promise<any> {
    // returns an observable that shows whether the session to the server is connected
    return this.testSessionObservable().toPromise();
  }

  testSessionObservable(): Observable<any> {
    return this.http.get(serverRoutes.sessionTest, { responseType: 'text' });
  }


}
