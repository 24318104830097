import { Injectable } from '@angular/core';
import { SharedUrlRoutes, UtilDocumentId,  UtilFileReference, UtilUploads } from '@formbird/shared';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { IndexedDBService } from '../indexeddb/indexed-db.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { IndexedDBConstants } from '@formbird/indexed-db';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { from } from 'rxjs';
import { cloneDeep } from 'lodash';
import { SharedConstants, DocumentInfo, FormParameters, DocumentChangedInfo, FileReferenceDetails} from '@formbird/types';
import { DataService } from '../data/data.service';
import { ConfigService } from '../config/config.service';
import { ClientAccessService } from '../access/client-access.service';
import { IApplicationState } from '../../redux/state/application.state';
import { formDocumentChanged} from '../../redux/actions/form-new.actions';
import { AppStore } from '../../redux/store/app.store';
import { ModifiedFieldService } from '../document/modified-field.service';
import { CurrentDocumentService } from '../document/current-document.service';
import { ValidationService } from '../validation/validation.service';
import * as  mime from 'mime-types';
import { OpfsCacheService } from '../opfs/opfs-cache.service';

// Get window.URL object
const URL = window.URL; /*|| window.webkitURL;*/
const logger = console;

interface options{
  isOnline?: boolean,
  isCommandBarUpload?: boolean,
  docFieldName?: string
  fileReferenceDocumentId?: string
  fileReferenceTemplateId?: string
}
@Injectable({
  providedIn: 'root'
})
export class FileProviderService {

  constructor(
    private loggedInUserService: LoggedInUserService,
    private indexedDBService: IndexedDBService,
    private offlineStatusService: OfflineStatusService,
    private httpClient: HttpClient,
    private dataService: DataService,
    private configService: ConfigService,
    private clientAccessService: ClientAccessService,
    private appStore: AppStore<IApplicationState>,
    private modifiedFieldService: ModifiedFieldService,
    private opfsCacheService: OpfsCacheService,
    private currentDocumentService: CurrentDocumentService,
    private validationService: ValidationService
  ) {
  }

  /**
   * Retrieves the file from offline storage first, if unsuccessful retrieve from server.
   */
  /**
   * Retrieves the file from offline storage first, if unsuccessful retrieve from server.
   */
  async getFileResources(file: Pick<FileReferenceDetails, 'fileNo'> & Partial<Pick<FileReferenceDetails, 'fileName'>>) {

    const url = `${SharedUrlRoutes.serverRoutes.loadFile}/${file.fileNo}`;
    try{
      const cachedFile = await this.opfsCacheService.getCachedFile(file.fileNo);
      if (cachedFile !== null) {
        const idbFile: any = await this.getFile(file.fileNo);
        const mimeType = idbFile?.mimeDetailsObj?.contentType ?? idbFile.uploadConfig?.mimetype;
        const fileName = idbFile.uploadConfig?.originalname ?? file.fileName ?? file.fileNo;
        const newFile = new File([cachedFile], fileName, { type: mimeType });
        return {
          base64strData: await this.indexedDBService.toBase64Str(newFile),
          url
        }
      } else {
        return {
          url
        }
      }
    } catch(err) {
      logger.error(err.message);
      return {
        base64strData: null,
        url
      }
    }

  }

  private async getFile(fileNo){
    return new Promise(async (resolve, reject) => {
      this.indexedDBService.getFile(fileNo, (file) => {
        resolve(file);
      });
    });
  }

  /**
   * Sends the file directly to server.
   */
  async onlineFileUpload(uploadConfig) {
    if (!uploadConfig) {
      throw new Error('No file details passed in file upload.');
    }

    const fileData: any = uploadConfig;
    const type = fileData.mimetype;
    const file = new File([fileData.file], fileData.originalname, { type: type });
    const options: any = {isOnline: true};
    options.fileReferenceDocumentId = fileData.fileReferenceDocumentId;
    let response: any = await this.uploadFileToServer(fileData.fileNo, file, fileData.documentId, fileData.documentName, options)
      .toPromise();
    const uploaded = response.body;

    uploadConfig.fileNo = uploaded.fileNo;
    uploadConfig.basePath = uploaded.basePath;
    uploadConfig.fileType = uploaded.fileType;

    await this.updateFileReferenceDocument(uploadConfig, uploaded);

    return ({ data: uploaded });
  }

  private uploadFileToServer(fileNo: string, file: File, documentId: string, documentName: string, options: options) {
    const maxUploadSize = parseInt(this.configService.clientConfig().maxUploadSize) || SharedConstants.DEFAULT_MAX_UPLOAD_SIZE;
    if (file && file.size && file.size > (maxUploadSize * 1024 * 1024)){

        const errMsg = 'The file is too large. Allowed maximum size is ' + maxUploadSize + "mb";
        return from(Promise.reject(new Error(errMsg)));

    } else {

      const postUrl = SharedUrlRoutes.serverRoutes.uploadFiles;
      if (!options) {
        options = {};
      }
      if (this.loggedInUserService.isUserOfflineMode() && !options.isOnline) {
        let uploadConfig = {
            fileNo: fileNo, file: file, originalname: file.name, mimetype: file.type
            , documentId: documentId, documentName: documentName
            , isCommandBarUpload: options.isCommandBarUpload
            , docFieldName: options.docFieldName
            , options
        };
        return from(this.offlineFileUpload(uploadConfig));

      } else {

        const myFormData: FormData = new FormData();
        myFormData.append('fileNo', fileNo);
        myFormData.append('file', file);
        myFormData.append('documentId', documentId);
        myFormData.append('documentName', documentName);
        myFormData.append('fileReferenceTemplateId', options?.fileReferenceTemplateId);
        if(options.fileReferenceDocumentId){
          myFormData.append('isCreatedFileReference', 'true');
        }

        const config = new HttpRequest('POST', postUrl, myFormData, {
          reportProgress: true
        });
        return this.httpClient.request(config);

      }
    }
  }

  private createFileReferenceDocument(uploadConfig, fileUploaderConfig){
    return new Promise(async (resolve, reject) => {
      try {

        const fileData = uploadConfig;
        const mimetype = fileData.mimetype;
        const fileType = mime.extension(mimetype).toUpperCase();
        const fileReferenceTemplateId = uploadConfig?.options?.fileReferenceTemplateId || fileUploaderConfig?.defaultTemplateId;

        if (!UtilUploads.whiteListed(fileType, fileUploaderConfig)) {
          throw new Error(`File type not allowed: ${fileType}`);
        }

        if (!fileReferenceTemplateId) {
          throw new Error('Invalid configuration values for generating reference document.');
        }

        const fileName = fileData.originalname;
        const fileNo = fileData.fileNo;


        //prepare data for creating file reference
        const meta: any = {

          file: {
            fileNo,
            fileName: fileName,
            fileType: fileType
          },

          parent: {//parent document id
            documentId: fileData.documentId,
            name: fileData.documentName
          }

        };

        if (mimetype) {
          meta.file.ext = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
          meta.file.mimeDetailsObj = {
            contentType: mimetype
          };
        }


        //create file reference
        const frTemplate = await this.dataService.getDocument(fileReferenceTemplateId);

        UtilFileReference.constructFileReferenceDocument(meta.file, meta.parent,
          uploadConfig?.options?.fileReferenceTemplateId, fileUploaderConfig,
          async (err, fileRefDoc) => {
          if (err){
            reject(err);
          } else {
            if (!fileRefDoc.documentId) {
              fileRefDoc.documentId = UtilDocumentId.generateId();
            }

            this.clientAccessService.writeKeysToDocument(fileRefDoc, frTemplate);
            this.dataService.insert(fileRefDoc)
              .then(function successFunc(data) {
                logger.info('Created reference document ' + data.documentId + ' for ' + fileNo + '.');
                resolve(data);
              }, function errorFunc(err) {
                var msg = 'Failed to create reference document for ' + fileNo + ' to ' + fileData.documentId + '.';
                logger.error(err.message);
                reject(new Error(msg));
              });
          }
        });


      } catch (err){
        reject(err);
      }
    });
  }

  private updateFileReferenceDocument(uploadConfig, uploadedData){

    return new Promise(async (resolve, reject) => {
      try {
        const fileData = uploadConfig;
        //updating offline fileReference document with uploaded fileNo.
        if (fileData.fileReferenceDocumentId) {
          //updating fileReference document with server fileNo .
          this.indexedDBService.getDocumentCurrentVersion(fileData.fileReferenceDocumentId, refDoc => {
            refDoc[fileData.fileUploaderField][0].fileNo = uploadedData.fileNo;
            refDoc[fileData.fileUploaderField][0].basePath = uploadedData.basePath;
            this.indexedDBService.saveEntry(IndexedDBConstants.DOCUMENT_TABLE_NAME, refDoc);
          });

          let originalFRDoc;

          originalFRDoc = await this.dataService.getDocument(fileData.fileReferenceDocumentId);
          if (originalFRDoc) {
            const frDocument = cloneDeep(originalFRDoc);
            frDocument[fileData.fileUploaderField][0].fileNo = uploadedData.fileNo;
            frDocument[fileData.fileUploaderField][0].basePath = uploadedData.basePath;
            await this.dataService.deepDiffUpdate(originalFRDoc, frDocument);
          }

          resolve(true);
        }

      } catch (err){
        logger.error("Error on offline file reference document update: " + JSON.stringify(err));
        reject(err);
      }
    });
  }

  private doPreSaveOffline() {
    if (this.offlineStatusService.isConnected() || !this.offlineStatusService.isOfflineMode()) {
      return;
    }
    
    const { document, template } = this.currentDocumentService.getDocumentData()    
    const docContext = {} as any;
    docContext.template = template;
    docContext.templateId = document.systemHeader.templateId;
    // docContext.unsavedDocumentListId = unsavedDocumentListId;

    return new Promise((resolve, reject) => {
      this.validationService.onPreSaveOfflineDocument(document, docContext, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  /**
   * Saves to offline database. The OfflinePoller sends it to the server later
   */
  private offlineFileUpload(uploadConfig) { //

    const _self = this;

    return new Promise(async (resolve, reject) => {
      try {
          const clientConfig = this.configService.clientConfig();
          const fileUploaderConfig : any =  clientConfig.fileUploader || {};
          await this.doPreSaveOffline();
          const fileRefDoc: any = await this.createFileReferenceDocument(uploadConfig, fileUploaderConfig);
          uploadConfig.fileReferenceDocumentId = fileRefDoc.documentId;
          uploadConfig.fileUploaderField = fileUploaderConfig.fileUploaderField;

          const blob = uploadConfig.file;

          const options  =  uploadConfig.options;
          let fileDetails: any =  cloneDeep(uploadConfig);
          delete fileDetails.options;
          fileDetails = {...fileDetails, ...options};
          delete fileDetails.file;

          await _self.opfsCacheService.cacheFile(uploadConfig, blob);
          
          await _self.indexedDBService.saveFile({
            fileNo: uploadConfig.fileNo,
            uploadConfig: fileDetails,
            status: IndexedDBConstants.OFFLINE_STATUS_PENDING // 'UPLOADED' or 'PENDING'
          });



          resolve({
            body: {fileNo: uploadConfig.fileNo},
            status: 200,
            data: 'OK'
          });


      } catch (err) {
        reject(err);
      }
    });
  }

  downloadFile(file) {

    if (!file.resources.base64strData) {
      window.open(file.resources.url);
      return;
    }

    const objectURL = URL.createObjectURL(this.dataURLtoFile(file.resources.base64strData, file.fileName));

    const anchor = document.createElement('a');
    anchor.download = file.fileName;
    anchor.href = objectURL;
    anchor.click();

    URL.revokeObjectURL(objectURL);
  }

  // helpers
  private dataURLtoFile(dataurl, filename) {

    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  uploadFile(uploadConfig) {

    if (this.loggedInUserService.isUserOfflineMode()) {
      uploadConfig.isOnline = false;
    } else {
      uploadConfig.isOnline = true;
    }

    return this.uploadFileToServer(uploadConfig.fileNo, uploadConfig.file, uploadConfig.documentId, uploadConfig.documentName, uploadConfig);

  }

  private updateFileInfo(document, uploadConfig){
      const _self = this;

    return new Promise(async (resolve, reject) => {
      if (uploadConfig.isCommandBarUpload) {
        const DOCUMENT_FIELD_NAME = SharedConstants.DOCUMENT_FIELD_NAME_UPLOAD_FILE;
        // save as the template's image on navbar
        document.systemHeader[DOCUMENT_FIELD_NAME] = uploadConfig.fileNo;
      } else {
        this.indexedDBService.loadCachedDocByVersion(document.documentId, document.systemHeader.previousVersionId, function(cacheObject) {
          const oldFieldValues = cacheObject ? cacheObject[uploadConfig.docFieldName] : null;
          _self.updateFileInfoFieldValue(document, uploadConfig, oldFieldValues);
          resolve(true);
        });
      }
    });
  }

  private updateFileInfoFieldValue(document, uploadConfig, oldFieldValues?){
    if (uploadConfig.docFieldName){
      const fileRecord: any = {
        fileNo : uploadConfig.fileNo,
        fileName: uploadConfig.originalname,
        fileType: uploadConfig.fileType,
        basePath: uploadConfig.basePath,
      };
      let isExists = false;
      let documentFiles = document[uploadConfig.docFieldName];
      if (documentFiles && documentFiles.length > 0){
        for(let i=0; i<documentFiles.length; i++){
          const item = documentFiles[i];
          if (item.fileNo === uploadConfig.fileNo){
            item.basePath = uploadConfig.basePath;
            if (uploadConfig.fileNo){
              item.fileNo = uploadConfig.fileNo;
              item.fileType = uploadConfig.fileType;
            }
            isExists = true;
          } else if (oldFieldValues) {
            oldFieldValues.map(obj => {
              if (obj.fileNo === item.fileNo) {
                  item.basePath = obj.basePath ? obj.basePath  : item.basePath;
                  item.fileType = obj.fileType ? obj.fileType  : item.fileType;
              }
            });
          }
        }
      }else{
        documentFiles = [];
      }
      if (!isExists){
        document[uploadConfig.docFieldName] =  [...documentFiles, fileRecord];
      }
    }
  }

  updateSentFileToOfflineDoc(docId, processedFiles): Promise<void> {

    const _self = this;

    return new Promise(async (resolve, reject) => {

      const indexValue = docId;
      if (indexValue) {
        try{
          const originalDoc = await this.dataService.getDocument(docId);

          //used for new document
          let currentDoc = cloneDeep(this.appStore.getState().formState.documents[docId]);

          const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;
          const unsavedDocumentListIds = Object.keys(docInfo);
          let unsavedDocListId = null;
          unsavedDocumentListIds.forEach(function(unsavedDocumentListId) {
            if (docInfo[unsavedDocumentListId][docId]) {
              unsavedDocListId = unsavedDocumentListId;
            }
          });

          const cachedObject =  currentDoc ? cloneDeep(currentDoc) : originalDoc;

          let fieldName;
          if (cachedObject) {

            for(let i=0; i<processedFiles.length; i++){
              fieldName = processedFiles[i].uploadConfig.docFieldName;
              await this.updateFileInfo(cachedObject, processedFiles[i].uploadConfig);
            }

            if (unsavedDocListId && this.modifiedFieldService.isDirty()){
              const formParameters = {} as FormParameters;
              formParameters.unsavedDocumentListId = unsavedDocListId;
              const documentChangedInfo: DocumentChangedInfo = {
                documentId: docId,
                fieldName: fieldName,
                newValue: cachedObject[fieldName],
                formParameters: formParameters
              };
              this.appStore.dispatch(formDocumentChanged(documentChangedInfo));
            } else {
              const updatedDoc = await this.dataService.deepDiffUpdate(originalDoc, cachedObject);
              await this.doUpdateCachedObject(updatedDoc);
            }

            resolve();
          } else {
            logger.error("Error on updating send file details to the offline document");
            reject();
          }

        }catch(err){
          logger.error(err);
          logger.error("Error on updating send file details to the offline document");
          reject();
        }

      } else {
        resolve();
      }

    });

  }


  private doUpdateCachedObject(cachedObject): Promise<void> {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.indexedDBService.saveEntry(IndexedDBConstants.DOCUMENT_TABLE_NAME, cachedObject, function (err) {

        if (!err) {
          resolve();

        } else {
          reject();
        }
      });

    });

  }

}
