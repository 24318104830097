import { Injectable } from '@angular/core';
import { UtilDocument, UtilDocumentId } from '@formbird/shared';
import { DocumentInfo } from '@formbird/types';
import cloneDeep from 'lodash.clonedeep';
import {
  formResetDocumentInfo,
  formResetTemplateComponentDefinition,
  formResetToOrgDocument,
  formSetDocument,
  formSetDocumentInfo,
  formSetupDocumentList
} from '../../redux/actions';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { CurrentDocumentInfoService } from './current-document-info.service';

const logger = console;

@Injectable()
export class UnsavedDocumentService {

  constructor(
    private appStore: AppStore<IApplicationState>,
    private currentDocumentInfoService: CurrentDocumentInfoService
  ) {
  }

  /**
   * create a new unsaved document list. The unsaved document list is a list of documents that will be saved in a single
   * operation. This allows saving of documents independently of the nav bar save button. Eg. If you open up a dialog with a
   * template with a child doc then you need to save several documents on dialog close without saving the main document loaded
   * in the form. We can't put parameters in the save function to handle special cases like whether the save is for a dialog
   * because we would have to keep adding more special cases for saving from different locations.
   */
  createUnsavedDocumentList(isNewMainDocList?, existingUnsavedDocumentListId?) {

    // generate an unsaved document list id, this will be used by the caller to keep track of this list. It will be passed
    // to the save function to trigger the save of the documents in that list
    const unsavedDocumentListId = existingUnsavedDocumentListId ? existingUnsavedDocumentListId : UtilDocumentId.generateId();

    this.appStore.dispatch(formSetupDocumentList(isNewMainDocList, unsavedDocumentListId));

    return unsavedDocumentListId;
  }

  getDocumentInfo(documentListId) {

    if (!documentListId) {
      throw new Error('No documentListId provided in getDocumentInfo');
    }

    return cloneDeep(this.appStore.getState().formState.documentInfo[documentListId]);
  }

  getDocumentListInfos(documentId) {
    
    const result = [];

    const storedDocInfo = this.appStore.getState().formState.documentInfo;
    const documentListIds = storedDocInfo ? Object.keys(storedDocInfo) : [];
    documentListIds.forEach(documentListId => {
      
      const storedDocInfoItem = storedDocInfo[documentListId];
      const documentIds = Object.keys(storedDocInfoItem);
      
      documentIds.forEach(docId => {
      
        const item = storedDocInfoItem[docId];
        if (docId === documentId && item.loaded) {
          
          result.push({
            documentListId,
            template: this.appStore.getState().formState.templates[item.templateId]
          });
        }
      
      })
    });

    return result;
  }

  /**
   * Get document information for each document inside a list. The result should be:
  {
    documentId: 'document-id',
    templateId: 'template-id',
    hierarchyInfo: {
      parentDocumentId: 'parent-document-id',
      childDocumentId: 'child-document-id'
    },
    options: {}
  }
   * @param documentListId
   * @param documentId
   * @returns
   */
  getDocumentInfoItem(documentListId, documentId) {

    if (!documentListId) {
      throw new Error('No documentListId or documentId provided in getDocumentInfo item');
    }

    const storedDocInfo = this.appStore.getState().formState.documentInfo[documentListId];
    let documentInfoItem = storedDocInfo ? storedDocInfo[documentId] : null;
    if (!documentInfoItem) {
      documentInfoItem = {
        documentListId,
        documentId,
        hierarchyInfo: {}
      }
    } else {
      documentInfoItem = cloneDeep(documentInfoItem);
    }

    return documentInfoItem;
  }

  getUnsavedDocumentInfo(unsavedDocumentListId) {
    return cloneDeep(this.appStore.getState().formState.documentInfo[unsavedDocumentListId]);
  }

  /**
   * add a document to the list, so it will be included in the list of unsaved documents
   * @param document the document
   * @param unsavedDocumentListId the unsavedDocumentListId
   */
  addDocumentToUnsavedList(document, unsavedDocumentListId) {

    if (!document || !document.documentId) {
      throw new Error('No document or documentId provided in addDocumentToUnsavedList');

    } else if (!unsavedDocumentListId) {
      throw new Error('No unsavedDocumentListId provided in addDocumentToUnsavedList');

    }

    const docInfo: DocumentInfo = {
      documentId: document.documentId,
    };

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));

    this.appStore.dispatch(formSetDocument(document));

  }

  /**
   * add a template to the list
   * @param template the template obj
   * @param unsavedDocumentListId the unsaved list
   * @param orgTemplateId the original template id
   */
  addTemplateToUnsavedList(document, template, unsavedDocumentListId, orgTemplateId) {

    if (!template || !template.documentId) {
      throw new Error('No template provided in addTemplateToUnsavedList');

    } else if (!unsavedDocumentListId) {
      throw new Error('No unsavedDocumentListId provided in addTemplateToUnsavedList');

    }

    const docInfo: DocumentInfo = {
      documentId: document.documentId
    };

    const templateId = template.documentId;

    // in case alt template
    if (orgTemplateId && templateId !== orgTemplateId) {

      docInfo.templateId = orgTemplateId;
      docInfo.altTemplateId = templateId;

    } else {

      docInfo.templateId = templateId;
    }

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));

  }

  /**
   * get the document from the unsaved document list. The document will be set in the document parameter rather than returned
   * so that the document directly references the value in the unsaved list
   * @param documentResult - the document will be placed in the document parameter of documentResult. It needs to be done this
   *                         way because if you assign to an object directly, the reference that the object points at changes
   * @param unsavedDocumentListId the unsaved list Id
   * @param documentId the document Id
   */
  getDocument(documentResult, unsavedDocumentListId, documentId) {

    const formState = this.appStore.getState().formState;
    const documents = formState.documents;
    const docInfo: DocumentInfo = formState.documentInfo;

    if (!documentResult) {
      throw new Error('The documentResult must be passed as an object to getDocument so the document can be set under it');

    } else if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in getDocument');

    } else if (!docInfo[unsavedDocumentListId]) {
      throw new Error('GetDocument: Unsaved document list has not been initialised with id: ' + unsavedDocumentListId);

    } else if (!docInfo[unsavedDocumentListId][documentId]) {
      throw new Error('Unsaved document list does not contain document: ' + documentId);
    }

    // set the document from loaded docs in the document parameter
    documentResult.document = cloneDeep(documents[documentId]);
  }

  /**
   * get the template from the unsaved document list. The template will be set in the template result parameter
   * rather than returned so that the template directly references the value in the unsaved list
   * @param documentResult - the template will be placed in the template parameter of documentResult. It needs to
   * be done this way because if you assign to an object directly, the reference that the object points at changes
   * @param unsavedDocumentListId the unsaved list Id
   * @param templateId the template Id
   */
  getTemplate(documentResult, unsavedDocumentListId, templateId, documentId) {

    const formState = this.appStore.getState().formState;
    const templates = formState.templates;
    const docInfo: DocumentInfo = formState.documentInfo;

    if (!documentResult) {
      throw new Error('The documentResult must be passed as an object to getTemplate so the template can be set under it');

    } else if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in getTemplate');

    } else if (!docInfo[unsavedDocumentListId]) {
      throw new Error('GetTemplate: Unsaved document list has not been initialised with id: ' + unsavedDocumentListId);

    } else if (!docInfo[unsavedDocumentListId][documentId]) {
      throw new Error('Unsaved document list does not contain document: ' + documentId);
    }

    const docInfoItem = docInfo[unsavedDocumentListId][documentId];

    if (docInfoItem.templateId === templateId || docInfoItem.altTemplateId === templateId) {

      // set the template from loaded templates in the document parameter
      documentResult.template = cloneDeep(templates[templateId]);
    }

    if (!templateId) {
      throw new Error('Unsaved document list does not contain template: ' + templateId);
    }

  }

  setDocument(unsavedDocumentListId, document) {

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in setDocument');

    }

    const docInfo: DocumentInfo = {
      documentId: document.documentId
    };

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
    this.appStore.dispatch(formSetDocument(document));
  }


  setTemplate(unsavedDocumentListId, documentId, template) {

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in setTemplate');

    } else if (!template || !template.documentId) {
      throw new Error('Template not provided in setTemplate');

    }

    const docInfo: DocumentInfo = {
      templateId: template.documentId,
      documentId
    };

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
    this.appStore.dispatch(formSetDocument(template));
  }

  /**
   * get the document from a unsaved document list.
   * @param documentId the document Id
   */
  getDocumentOnly(documentId) {

    return cloneDeep(this.appStore.getState().formState.documents[documentId]);
  }

  /**
   * get the template from the unsaved document list.
   * @param templateId the template Id
   */
  getTemplateOnly(templateId) {

    return this.getDocumentOnly(templateId);
  }

  setDocumentListOptions(unsavedDocumentListId, documentId, key, value) {

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in getDocumentOptions');

    }

    const docInfo: DocumentInfo = {
      documentId,
      options: {
        [key]: value
      }
    };

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, docInfo));
  }


  getDocumentListOptions(unsavedDocumentListId, documentId) {

    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not provided in getDocumentOptions');

    } else if (!docInfo[unsavedDocumentListId] || !docInfo[unsavedDocumentListId][documentId] || !docInfo[unsavedDocumentListId][documentId].options) {
      throw new Error(`getDocumentOptions: Unsaved document list has not been initialised with id: ${unsavedDocumentListId} and docId: ${documentId}`);
    }

    return docInfo[unsavedDocumentListId][documentId].options;
  }

  getDocumentOptionKey(unsavedDocumentListId, documentId, optionKey) {

    const options = this.getDocumentListOptions(unsavedDocumentListId, documentId);

    return options[optionKey];
  }

  /**
   * Get the list of pair of document and template for each form. The result should be:
  [{
    document: document1,
    template: template1
  },
  {
    document: document2,
    template: template2
  }
  ]
   * @param unsavedDocumentListId
   * @returns
   */
  getUnsavedDocumentDatas(unsavedDocumentListId) {

    const documentDatas = [];

    const documentInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];
    const documents = this.appStore.getState().formState.documents;
    const templates = this.appStore.getState().formState.templates;

    const documentIds = Object.keys(documentInfo);
    documentIds.forEach(documentId => {

      const documentInfoItem = documentInfo[documentId];

      if (documentInfoItem?.loaded) {

        const document = cloneDeep(documents[documentId]);
    
        const templateId = documentInfoItem.overrideTemplateId ? documentInfoItem.overrideTemplateId : documentInfoItem.templateId;
        const template = cloneDeep(templates[templateId]);
    
        documentDatas.push({
          document,
          template
        });
      }

    });

    return documentDatas;
  }

  /**
   * get all the unsaved documents inside the provided document list
   * @param unsavedDocumentListId - the id of the list to get the loaded docs for
   */
  getUnsavedDocuments(unsavedDocumentListId) {

    const documentInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];

    const documents = this.appStore.getState().formState.documents;

    const unsavedDocuments = [];

    const documentIds = Object.keys(documentInfo);
    documentIds.forEach(documentId => {
      const doc = cloneDeep(documents[documentId]);
      unsavedDocuments.push(doc);
    });

    return unsavedDocuments;
  }

  getUnsavedDocumentId(unsavedDocumentListId) {
    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not specified when getting loaded documents');

    } else if (!docInfo[unsavedDocumentListId]) {
      throw new Error('Unsaved documents not found with id ' + unsavedDocumentListId);
    }

    return this.currentDocumentInfoService.getMainDocumentIdFromList(unsavedDocumentListId);
  }

  /**
   * get all the unsaved templates inside the privided document list
   * @param unsavedDocumentListId - the id of the document list to get all the loaded templates
   */
  getUnsavedTemplates(unsavedDocumentListId) {

    const documentInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];
    const unsavedTemplates = [];
    const templates = this.appStore.getState().formState.templates;
    const documentIds = Object.keys(documentInfo);
    documentIds.forEach(documentId => {
      const documentInfoItem = documentInfo[documentId];
      const templateId = documentInfoItem.templateId;
      const doc = cloneDeep(templates[templateId]);
      unsavedTemplates.push(doc);
    });

    return unsavedTemplates;

  }

  getUnsavedTemplate(documentId, unsavedDocumentListId) {
    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;

    if (!docInfo) {
      console.warn('Document infor in app state seems not initialized yet.');
      return null;
    }

    if (!unsavedDocumentListId) {
      console.warn('Unsaved document list id not specified when getting unsaved template');
      return null;
    }

    if (!documentId) {
      console.warn('Unsaved document id not specified when getting unsaved template');
      return null;
    }

    if (!docInfo[unsavedDocumentListId] || !docInfo[unsavedDocumentListId][documentId]) {
      console.warn(`Unsaved template not found with document list id: ${unsavedDocumentListId} and documentId: ${documentId}`);
      return null;
    }

    const overrideTemplateId = docInfo[unsavedDocumentListId][documentId].overrideTemplateId;
    const templateId = overrideTemplateId ? overrideTemplateId : docInfo[unsavedDocumentListId][documentId].templateId;

    return cloneDeep(this.appStore.getState().formState.templates[templateId]);
  }

  removeDocumentList(unsavedDocumentListId) {

    this.appStore.dispatch(formResetDocumentInfo(unsavedDocumentListId));
  }

  /**
   * Get parent document and template
   * @param unsavedDocumentListId
   * @param childDocumentId
   */
  getParentData(unsavedDocumentListId, childDocumentId) {

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not specified when getting unsaved parent data');
    }

    if (!childDocumentId) {
      throw new Error('Unsaved child document id not specified when getting unsaved parent data');
    }

    const documents = this.appStore.getState().formState.documents;
    const templates = this.appStore.getState().formState.templates;
    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];

    if (docInfo && docInfo[childDocumentId]) {
      const parentDocId = docInfo[childDocumentId].hierarchyInfo?.parentDocumentId;
      if (parentDocId) {
        const parentDoc = cloneDeep(documents[parentDocId]);

        const parentTplId = docInfo[parentDocId].overrideTemplateId ? docInfo[parentDocId].overrideTemplateId : docInfo[parentDocId].templateId;
        const parentTpl = cloneDeep(templates[parentTplId]);

        return {
          parentDoc,
          parentTpl
        } as any;
      }
    }

    return {};
  }

  setParentInfo(documentContext) {
    const hierarchyInfo = documentContext.formParameters.hierarchyInfo;
    const parentDocumentId = hierarchyInfo?.parentDocumentId;
    const parentDocument = this.appStore.getState().formState.documents[parentDocumentId];
    documentContext.parentDocument = parentDocument;
    if (parentDocument) {
      documentContext.parentTemplate = this.appStore.getState().formState.templates[parentDocument.systemHeader.templateId];
    }
  }

  setChildInfo(documentContext) {
    const hierarchyInfo = documentContext.formParameters.hierarchyInfo;
    const childDocumentId = hierarchyInfo?.childDocumentId;
    const childDocument = this.appStore.getState().formState.documents[childDocumentId];
    documentContext.childDocument = childDocument;
    if (childDocument) {
      documentContext.childTemplate = this.appStore.getState().formState.templates[childDocument.systemHeader.templateId];
    }
  }

  /**
   * Get child document and template
   * @param unsavedDocumentListId
   * @param documentId
   */
  getChildData(unsavedDocumentListId, documentId) {

    if (!unsavedDocumentListId) {
      throw new Error('Unsaved document list id not specified when getting unsaved child data');
    }

    if (!documentId) {
      throw new Error('Unsaved child document id not specified when getting unsaved child data');
    }

    const documents = this.appStore.getState().formState.documents;
    const templates = this.appStore.getState().formState.templates;
    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];

    if (docInfo && docInfo[documentId]) {
      const childDocId = docInfo[documentId].hierarchyInfo?.childDocumentId;
      if (childDocId) {
        const childDoc = cloneDeep(documents[childDocId]);

        const childTplId = docInfo[childDocId].overrideTemplateId ? docInfo[childDocId].overrideTemplateId : docInfo[childDocId].templateId;
        const childTpl = cloneDeep(templates[childTplId]);

        return {
          childDoc,
          childTpl
        } as any;

      }
    }

    return {};
  }

  /**
     * store a document hierarchy info to a list. This will be needed when retrieve information to load a child
     * document from a parent one
     * @param hierarchyInfo (parentDocumentId and childDocumentId)
     * @param unsavedDocumentListId the unsaved list Id
     */
  updateDocumentListInfo(documentInfo: DocumentInfo, unsavedDocumentListId) {

    this.appStore.dispatch(formSetDocumentInfo(unsavedDocumentListId, documentInfo));

  }

  clearDocuments() {
    // this.documents = {};
  }

  getAllLoadedDocs(excludedOptions?) {

    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;
    const documents = this.appStore.getState().formState.documents;
    const docIds = Object.keys(documents);

    const loadedDocs = [];

    const unsavedDocumentListIds = Object.keys(docInfo);

    unsavedDocumentListIds.forEach(function (unsavedDocumentListId) {

      const documentIds = Object.keys(docInfo[unsavedDocumentListId]);

      documentIds.forEach(documentId => {
        const options = docInfo[unsavedDocumentListId][documentId].options;

        docIds.forEach(function (docId) {

          const doc = cloneDeep(documents[docId]);
          if (excludedOptions) {
            if (!UtilDocument.hasIntersect(options[docId], excludedOptions)) {
              loadedDocs.push(doc);
            }
          } else {
            loadedDocs.push(doc);
          }

        });
      });

    });

    return loadedDocs;
  }

  documentIsLoaded(documentId) {

    const docInfo: DocumentInfo = this.appStore.getState().formState.documentInfo;
    const documents = this.appStore.getState().formState.documents;

    let result = false;

    const unsavedDocumentListIds = Object.keys(docInfo);

    unsavedDocumentListIds.forEach(function(unsavedDocumentListId) {

      if (documents[documentId] && docInfo[unsavedDocumentListId][documentId] && docInfo[unsavedDocumentListId][documentId].loaded) {

        result = true;
      }
    });

    return result;
  }

  isDocumentsDefined(unsavedDocumentListId) {
    return !!this.appStore.getState().formState.documentInfo[unsavedDocumentListId];
  }

  getHierarchyInfo(unsavedDocumentListId, documentId) {
    const documentInfo = this.appStore.getState().formState.documentInfo;
    if (documentInfo && documentInfo[unsavedDocumentListId] && documentInfo[unsavedDocumentListId][documentId]) {

      return cloneDeep(documentInfo[unsavedDocumentListId][documentId].hierarchyInfo);

    }

    return null;
  }

  resetToOrgDocument(documentId, unsavedDocumentListId?) {
    this.appStore.dispatch(formResetToOrgDocument(documentId, unsavedDocumentListId));
  }

  resetDocument(documentId, unsavedDocumentListId?) {
    this.resetToOrgDocument(documentId, unsavedDocumentListId);
  }

  resetTemplateComponent(templateId, unsavedDocumentListId?) {
    this.appStore.dispatch(formResetTemplateComponentDefinition(templateId, unsavedDocumentListId));
  }

}
