import { AuthenticationService } from './../authentication/authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConstants, LocalStorageService, LocalStorageKeys } from '@formbird/services';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private location: Location,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                // user is authenticated
            },
            error => {
                if (error.status === ClientConstants.HTTP_STATUS_LOGIN_REJECTED) {
                    const authRoutes = [
                        '/login', '/auth/login',
                        '/forgotpass', '/auth/forgotpass',
                        '/signup', '/auth/signup',
                        '/resetpass', '/auth/resetpass',
                        '/emailRequired', '/auth/emailRequired',
                        '/activateAccount', '/auth/activateAccount'
                    ];

                    const checkPublicUser = this.localStorageService.getItem(LocalStorageKeys.CHECK_PUBLIC_USER);
                    if (!authRoutes.find(route => this.location.path().startsWith(route)) && !checkPublicUser) {
                        this.authenticationService.showLogin();
                    }
            }
        }));
    }
}
