import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { VendorLibraryService } from '../vendor-library/vendor-library.service';
import { SearchResultsProcessor } from '@formbird/shared';
import { SharedConstants } from '@formbird/types';
import loadjs from 'loadjs';

/**
 * Angular: Load External JavaScript/Stylesheet File Dynamically
 */
@Injectable({
  providedIn: 'root'
})
export class LazyScriptLoaderService {

  private loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private vendorLibraryService: VendorLibraryService
  ) {}

  loadScript(url: string): Promise<any> {

    const _self = this;

    if (_self.loadedLibraries[url]) {
      return _self.loadedLibraries[url].toPromise();
    }

    _self.loadedLibraries[url] = new ReplaySubject();

    const script = _self.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      _self.loadedLibraries[url].next(undefined);
      _self.loadedLibraries[url].complete();
    };

    _self.document.body.appendChild(script);

    return _self.loadedLibraries[url].toPromise();
  }

  loadStyle(url: string): Promise<any> {

    const _self = this;

    if (_self.loadedLibraries[url]) {
      return _self.loadedLibraries[url].toPromise();
    }

    _self.loadedLibraries[url] = new ReplaySubject();

    const style = _self.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      _self.loadedLibraries[url].next(undefined);
      _self.loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return _self.loadedLibraries[url].toPromise();
  }

  async load(dependencies: any) {
    if (!dependencies || (!dependencies.name && !dependencies.documentId)) {
      return Promise.reject(new Error('Dependencies not specified correctly in lazyLoader.load'));
    }

    let files = [];

    if (!dependencies.files) {
      let vendorLib;
      if (dependencies.documentId) {
        vendorLib = await this.vendorLibraryService.getVendorLibrary(dependencies.documentId);
      } else {
        vendorLib = await this.vendorLibraryService.getVendorLibrary(dependencies.name);
      }

      if (vendorLib) {
        if (vendorLib.fileName) {
          files.push(vendorLib.fileName);
        }

        if (vendorLib.fileNames) {
          files = files.concat(vendorLib.fileNames);
        }
      }
    } else {
      files = dependencies.files;
    }

    if (!files.length) {
      return Promise.reject(
        new Error(`Dependencies specified in lazyLoader.load not found!`));
    }

    return await loadjs(files, {
      returnPromise: true
    });
  }
}
