import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, EventEmitter, Injectable, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import {
  ClientConstants,
  CurrentDocumentService,
  DataService,
  LoggedInUserService,
  NavigationService,
  NotificationService,
  select,
  UrlService
} from '@formbird/services';
import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import { MenuObjectDialogComponent } from '../menu-object/menu-object.component';
import { User } from '@formbird/types';
import { ModalService } from '@services/modal/modal.service';

const logger = console;


interface TemplateTreeNode {
    expandable: boolean;
    children: TemplateTreeNode[];
    name: string;
    address: any;
    description: any;
    icon: any;
    isFieldtecApp: any;
    load: any;
}

/** Flat node with expandable and level information */
interface TemplateFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    address: any;
    description: any;
    icon: any;
    isFieldtecApp: any;
    load: any;
}

/**
 * File node data with nested structure.
 * Each node has a name, id, and a type or a list of children.
 */
export class TreeNode {
    id: string;
    children: TreeNode[];
    name: string;
    type: any;
    address: any;
    description: any;
    icon: any;
    isFieldtecApp: any;
    load: any;
    target: string;
}

/** Flat node with expandable and level information */
export class TreeFlatNode {
    constructor(
        public expandable: boolean,
        public name: string,
        public level: number,
        public type: any,
        public id: string,
        public address: any,
        public description: any,
        public icon: any,
        public isFieldtecApp: any,
        public load: any,
        public target: string,
    ) {}
}

/**
 * The structure tree data in string. The data could be parsed into a Json object
 */

/**
 * Tree database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `TreeNode` with nested
 * structure.
 */
@Injectable()
export class TreeDatabase {
    dataChange = new BehaviorSubject<TreeNode[]>([]);

    get data(): TreeNode[] {
        return this.dataChange.value;
    }

    setData(treeData) {
      if (treeData) {
        const data = this.buildTreeNode(treeData, 0);
        this.dataChange.next(data);
      }
    }

    constructor() {}

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TreeNode`.
     */
    buildTreeNode(obj: { [key: string]: any }, level: number, parentId: string = '0'): TreeNode[] {
        return Object.keys(obj).reduce<TreeNode[]>((accumulator, key, idx) => {
            const value = obj[key];
            const node = new TreeNode();
            node.name = obj[key].name;
            /**
             * Make sure your node has an id so we can properly rearrange the tree during drag'n'drop.
             * By passing parentId to buildTreeNode, it constructs a path of indexes which make
             * it possible find the exact sub-array that the node was grabbed from when dropped.
             */
            node.id = `${parentId}/${idx}`;
            node.address = obj[key].address;
            node.description = obj[key].description;
            node.icon = obj[key].icon;
            node.isFieldtecApp = obj[key].isFieldtecApp;
            node.target = obj[key].target;
            if (value.subMenu && value.subMenu.length > 0) {
                if (typeof value.subMenu === 'object') {
                    node.children = this.buildTreeNode(value.subMenu, level + 1, node.id);
                } else {
                    node.type = value;
                }
            }
            return accumulator.concat(node);
        }, []);
    }
}

@Component({
    selector: 'ft-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    providers: [TreeDatabase, Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy {
    @Output() menuSelect: EventEmitter<any> = new EventEmitter();

    @select(['userState', 'user']) user$: Observable<User>;
    private subs: Subscription = new Subscription();

    treeControl: FlatTreeControl<TreeFlatNode>;
    treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
    dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
    expandedNodeSet = new Set<string>();
    dragging = false;
    expandTimeout: any;
    expandDelay = 1000;
    treeData: any;
    database: any;

    treeControlTemplate: any;
    selectedMenuItem: any;
    dataSourceTemplate: any;
    treeFlattenerTemplate: any;

    menus: any = {};
    user: any;

    options: any;
    mode: string;
    isChanged: boolean;
    originalAccount: any;
    currentItem: any;

    location: Location;
    testCount = 0;
    dialogData: any;

    private _transformerTemplate = (node: TemplateTreeNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            address: node.address,
            description: node.description,
            icon: node.icon,
            isFieldtecApp: node.isFieldtecApp,
            load: node.load,
            level: level
        };
    }

    constructor(
        database: TreeDatabase,
        private modalService: ModalService,
        public dialog: MatDialog,
        private navigationService: NavigationService,
        private loggedInUserService: LoggedInUserService,
        private urlService: UrlService,
        private currentDocumentService: CurrentDocumentService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private router: Router,
        location: Location
    ) {
        // TODO: menus arent showig, loggedinuserservice account is null,
        // user was previously loaded in initialization state

        this.treeFlattenerTemplate = new MatTreeFlattener(
            this._transformerTemplate,
            node => node.level,
            node => node.expandable,
            node => node.children
        );
        this.treeControlTemplate = new FlatTreeControl<TemplateFlatNode>(node => node.level, node => node.expandable);
        this.dataSourceTemplate = new MatTreeFlatDataSource(this.treeControlTemplate, this.treeFlattenerTemplate);

        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.location = location;

        const subUser = this.user$.subscribe(user => this.user = user);
        this.subs.add(subUser);

        this.options = {
            dropped: () => {
                this.isChanged = true;
                this.saveChange();
            }
        };

        this.mode = 'VIEW';
        this.isChanged = false;
        this.currentItem = null;

        this.database = database;
    }

    /**Functions for menu template */
    hasChildTemplate = (_: number, node: TemplateTreeNode) => node.expandable;

    isHasMenuTemplate() {
        this.testCount = this.testCount + 1;
        if (this.testCount > 2) {
            return true;
        } else {
            this.transformMenuTemplateItems();
        }
        return true;
    }

    transformMenuTemplateItems() {
        if (this.menus.template) {
            let strMenusTemplate = JSON.stringify(this.menus.template);
            strMenusTemplate = strMenusTemplate.split('"subMenu":').join('"children":');
            this.dataSourceTemplate.data = JSON.parse(strMenusTemplate);
        }
    }

    /** TreeNode constant code and functions start here*/

    transformer = (node: TreeNode, level: number) => {
        return new TreeFlatNode(
            !!node.children,
            node.name,
            level,
            node.type,
            node.id,
            node.address,
            node.description,
            node.icon,
            node.isFieldtecApp,
            node.load,
            node.target
        );
    }

    private _getLevel = (node: TreeFlatNode) => node.level;
    private _isExpandable = (node: TreeFlatNode) => node.expandable;
    private _getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children);
    hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

    /**
     * This constructs an array of nodes that matches the DOM,
     * and calls rememberExpandedTreeNodes to persist expand state
     */
    visibleNodes(): TreeNode[] {
        this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
        const result = [];

        function addExpandedChildren(node: TreeNode, expanded: Set<string>) {
            result.push(node);
            if (expanded.has(node.id)) {
                node.children.map(child => addExpandedChildren(child, expanded));
            }
        }

        this.dataSource.data.forEach(node => {
            addExpandedChildren(node, this.expandedNodeSet);
        });
        return result;
    }

    /**
     * Handle the drop - here we rearrange the data based on the drop event,
     * then rebuild the tree.
     * */
    drop(event: CdkDragDrop<string[]>) {
        // console.log('origin/destination', event.previousIndex, event.currentIndex);

        // ignore drops outside of the tree
        if (!event.isPointerOverContainer) { return; }

        // construct a list of visible nodes, this will match the DOM.
        // the cdkDragDrop event.currentIndex jives with visible nodes.
        // it calls rememberExpandedTreeNodes to persist expand state
        const visibleNodes = this.visibleNodes();

        // deep clone the data source so we can mutate it
        const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

        // recursive find function to find siblings of node
        function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
            let result, subResult;
            arr.forEach(item => {
                if (item.id === id) {
                    result = arr;
                } else if (item.children) {
                    subResult = findNodeSiblings(item.children, id);
                    if (subResult) { result = subResult; }
                }
            });
            return result;
        }

        // remove the node from its old place
        const node = event.item.data;
        const siblings = findNodeSiblings(changedData, node.id);
        const siblingIndex = siblings.findIndex(n => n.id === node.id);
        const nodeToInsert: TreeNode = siblings.splice(siblingIndex, 1)[0];

        // determine where to insert the node
        const nodeAtDest = visibleNodes[event.currentIndex];
        if (nodeAtDest.id === nodeToInsert.id) { return; }

        // determine drop index relative to destination array
        let relativeIndex = event.currentIndex; // default if no parent
        const nodeAtDestFlatNode = this.treeControl.dataNodes.find(n => nodeAtDest.id === n.id);
        const parent = this.getParentNode(nodeAtDestFlatNode);
        if (parent) {
            const parentIndex = visibleNodes.findIndex(n => n.id === parent.id) + 1;
            relativeIndex = event.currentIndex - parentIndex;
        }
        // insert node
        const newSiblings = findNodeSiblings(changedData, nodeAtDest.id);
        if (!newSiblings) { return; }
        newSiblings.splice(relativeIndex, 0, nodeToInsert);

        // rebuild tree with mutated data
        this.rebuildTreeForData(changedData);

        this.formatMenuItem(changedData);
    }

    /**
     * Experimental - opening tree nodes as you drag over them
     */
    dragStart() {
        this.dragging = true;
    }

    dragEnd() {
        this.dragging = false;
    }

    dragHover(node: TreeFlatNode) {
        if (this.dragging) {
            clearTimeout(this.expandTimeout);
            this.expandTimeout = setTimeout(() => {
                this.treeControl.expand(node);
            }, this.expandDelay);
        }
    }

    dragHoverEnd() {
        if (this.dragging) {
            clearTimeout(this.expandTimeout);
        }
    }

    /**
     * The following methods are for persisting the tree expand state
     * after being rebuilt
     */

    rebuildTreeForData(data: any) {
        this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
        this.dataSource.data = data;
        this.forgetMissingExpandedNodes(this.treeControl, this.expandedNodeSet);
        this.expandNodesById(this.treeControl.dataNodes, Array.from(this.expandedNodeSet));
    }

    private rememberExpandedTreeNodes(treeControl: FlatTreeControl<TreeFlatNode>, expandedNodeSet: Set<string>) {
        if (treeControl.dataNodes) {
            treeControl.dataNodes.forEach(node => {
                if (treeControl.isExpandable(node) && treeControl.isExpanded(node)) {
                    // capture latest expanded state
                    expandedNodeSet.add(node.id);
                }
            });
        }
    }

    private forgetMissingExpandedNodes(treeControl: FlatTreeControl<TreeFlatNode>, expandedNodeSet: Set<string>) {
        if (treeControl.dataNodes) {
            expandedNodeSet.forEach(nodeId => {
                // maintain expanded node state
                if (!treeControl.dataNodes.find(n => n.id === nodeId)) {
                    // if the tree doesn't have the previous node, remove it from the expanded list
                    expandedNodeSet.delete(nodeId);
                }
            });
        }
    }

    private expandNodesById(flatNodes: TreeFlatNode[], ids: string[]) {
        if (!flatNodes || flatNodes.length === 0) { return; }
        const idSet = new Set(ids);
        return flatNodes.forEach(node => {
            if (idSet.has(node.id)) {
                this.treeControl.expand(node);
                let parent = this.getParentNode(node);
                while (parent) {
                    this.treeControl.expand(parent);
                    parent = this.getParentNode(parent);
                }
            }
        });
    }

    private getParentNode(node: TreeFlatNode): TreeFlatNode | null {
        const currentLevel = node.level;
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (currentNode.level < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** End of TreeNode constant code and functions*/

    async ngOnInit() {
        if (!this.user.menu) {
            try {
                await this.loggedInUserService.setLoggedUser();
            } catch (error) {
                console.log(error);
            }
        }

        const sub = this.navigationService.menus$.subscribe(menus => {
            this.menus = menus;
        });

        this.subs.add(sub);

        this.buildMenuItem();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    buildMenuItem() {
        this.database.setData(this.user.menu);
        this.database.dataChange.subscribe(data => this.rebuildTreeForData(data));
    }

    formatMenuItem(dataFromDragnDrop) {
        const DataFromDragnDropCopy = JSON.parse(JSON.stringify(dataFromDragnDrop));

        function formatNewMenuItem(arrObj) {
            let result, subResult;
            arrObj.forEach(item => {
                delete item.id;
                if (item.children) {
                    subResult = formatNewMenuItem(item.children);
                    if (subResult) {
                        if (!item.subMenu) {
                            item.subMenu = [];
                        }
                        item.subMenu = subResult;
                        result = subResult;
                    }
                    delete item.children;
                } else {
                    item.subMenu = [];
                }
                result = arrObj;
            });
            return result;
        }

      this.user.menu = formatNewMenuItem(DataFromDragnDropCopy);
    }

    menuClass(page) {
        const current = this.location.path();
        return page === '' ? 'divider' : page === current ? 'active' : '';
    }

    loadNodeAddress(node) {
        if (node.isFieldtecApp === true && node.address) {
            return this.buildPath(node.address);
        }
        return node.address;
    }

    menuClick(node, event) {
        if (event.shiftKey || event.ctrlKey || event.metaKey) {
            return;
        } else {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.mode === 'EDIT') {
            return;
        }
        if (node.id) {
            this.selectedMenuItem = node.id;
        } else {
            this.selectedMenuItem = node.name;
        }

        if (node && !node.isFieldtecApp) {
            let externalAddress = node.address;
            this.urlService.urls.external = externalAddress;
            externalAddress = externalAddress.replace('=', ClientConstants.URL_EQUAL_REPLACEMENT_STRING);
            if (node.target === '_blank') {
                (<any>window).open(`/external?p=${node.address}`, '_blank');
            } else {
                this.router.navigate(['/external'], { queryParams: { 'p': node.address }} );
            }
        } else {
            if (node.target === '_blank') {
                (<any>window).open(node.address, '_blank');
            } else {
                this.urlService.urls.current = this.buildPath(node.address);
                this.router.navigate([node.address]);
            }
        }

        this.menuSelect.emit(node);
    }

    isActiveMenuItem(node) {
        if (node.id) {
            return this.selectedMenuItem === node.id;
        } else {
            return this.selectedMenuItem === node.name;
        }
    }

    saveChange() {
        if (this.user.account) {
            for (let idx = 0; idx < this.user.menu.length; idx++) {
                this.removeHashKey(this.user.menu[idx]);
            }

            this.user.account.menu = this.user.menu;

            this.dataService.deepDiffUpdate(this.originalAccount, this.user.account).then(
                () => {
                    this.isChanged = false;
                    this.notificationService.success('Menu changes saved.');
                },
                (error) => {
                    logger.error('Error in menu update: ' + error.message);
                    this.notificationService.error('Error in menu update: ' + error.message);
                }
            );
        } else {
            this.notificationService.error('Could not update menus for account');
            logger.error('Could not update menus for account because user is unknown');
        }
    }

    removeItem(node) {
        const dataSourceValueCopy = JSON.parse(JSON.stringify(this.dataSource.data));

        // recursive find function for node to removed
        function findNodeToRemove(arr: Array<any>, id: string): Array<any> {
            let result, subResult;
            arr.forEach((item, index) => {
                if (item.id === id) {
                    arr[index] = { undefined: 'undefined' };
                } else if (item.children) {
                    subResult = findNodeToRemove(item.children, id);
                    if (subResult) { result = subResult; }
                }

                result = arr;
            });
            return result;
        }

        function removeUndefined(str) {
            let resStr = str;
            resStr = resStr.split(',{"undefined":"undefined"},').join(',');
            resStr = resStr.split('{"undefined":"undefined"},').join('');
            resStr = resStr.split(',{"undefined":"undefined"}').join('');
            resStr = resStr.split(',"children":[{"undefined":"undefined"}]').join('');
            return resStr;
        }

        const menuDataValues = findNodeToRemove(dataSourceValueCopy, node.id);

        const succFunc = () => {
            this.isChanged = true;
            const strDataItem = JSON.stringify(findNodeToRemove(menuDataValues, node.id));
            const newFilteredDataMenu = JSON.parse(removeUndefined(strDataItem));
            this.formatMenuItem(newFilteredDataMenu);
            this.buildMenuItem();
        };
        const errFunc = () => {
            logger.info('Removing menu-item: "' + node.name + '" cancelled by current user');
        };
        this.modalService.showDialog('Do you wish to remove menu-item: "' + node.name + '"?', 'WARNING', succFunc, errFunc);
    }

    newSubItem(node) {
        console.group("newSubItem")
        console.log("node", node)
        const menuDataValues = this.user.menu;

        /** format default values */
        let menuData = menuDataValues
        const selectedNodeId = (node.id + '').split('/');
        selectedNodeId.forEach((item, index) => {
            if (index > 0) {
                menuData = index === 1 ? menuData[item] : menuData["subMenu"][item]
            }
        });

        const selectedItem = { ...menuData }
        if (!selectedItem.subMenu) {
            selectedItem.subMenu = [];
        }
        console.groupEnd()
        /** variable selectedItem is the container of cloned item */

        /** Edit the cloned selected item by adding default values */
        selectedItem.name = selectedItem.name + '.' + (selectedItem.subMenu.length + 1);
        selectedItem.description = selectedItem.description + '.' + (selectedItem.subMenu.length + 1);
        selectedItem.id = node.id;
        selectedItem.isFieldtecApp = true;
        selectedItem.address = this.getCurrentPath();
        selectedItem.icon = '';
        selectedItem.target = '_self';
        selectedItem.subMenu = [];
        /** End of Editing the cloned selected..... */

        /** Inserting the edited clone seletectedItem as children of the selectedMenuItem*/
        // tslint:disable-next-line:no-eval
        const selectedMenuItem = menuData
        if (!selectedMenuItem.subMenu) {
            selectedMenuItem.subMenu = [];
        }
        selectedMenuItem.subMenu.push(selectedItem);
        /**Inserting the edited clone..... */

        logger.info('Saving menu....');
        this.isChanged = true;

        this.user.menu = menuDataValues;
        this.buildMenuItem();
    }

    editItem(node) {
        this.currentItem = node;

        this.isChanged = true;

        this.openMenuItemModal(this.currentItem, false, 'edit');
    }

    newItem() {
        this.originalAccount = this.user.account;

        if (!this.user.menu) {
            this.user.menu = [];
        }

        this.currentItem = {
            name: this.getTemplateName(),
            description: '',
            isFieldtecApp: true,
            address: this.getCurrentPath(),
            icon: '',
            target: '_self',
            subMenu: []
        };

        logger.info('Saving menu...');
        // this.isChanged = true;
        // // this.user.menu.push(this.currentItem);
        // // this.buildMenuItem();
        // // this.saveChange();

        this.openMenuItemModal(this.currentItem, false, 'new');
    }

    openMenuItemModal(node, isSubItem, mode) {
        const succFunc = () => {
            const dataFromFields = this.dialogData._containerInstance._config.data;
            const itemFromFields = {
                name: dataFromFields.name, // document.querySelector('.menu-object.modal-body #name-tf')['value'],
                description: dataFromFields.description, // document.querySelector('.menu-object.modal-body #des-tf')['value'],
                isFieldtecApp: dataFromFields.isFieldtecApp, // document.querySelector('#isFtApp-tf-input')['checked'],
                address: dataFromFields.address, // document.querySelector('.menu-object.modal-body #addr-tf')['value'],
                icon: dataFromFields.icon, // document.querySelector('.menu-object.modal-body #icon-tf')['value'],
                target: dataFromFields.target,
                subMenu: []
            };

            if (isSubItem) {
                // this.user.menu.push(itemFromFields);
                this.saveNewSubMenuItem(node, dataFromFields);
            } else {
                if (mode === 'new') {
                    this.isChanged = true;
                    this.user.menu.push(itemFromFields);
                    this.buildMenuItem();
                    this.saveChange();
                } else if (mode === 'edit') {
                    this.saveEditedMenuItem(node, dataFromFields);
                }
            }
        };

        const errFunc = () => {
            logger.info('Cancelled by current user ');
        };

        const dialogConfig = new MatDialogConfig();
        
        dialogConfig.data = {
            name: node.name,
            description: node.description,
            isFieldtecApp: node.isFieldtecApp,
            icon: node.icon,
            target: node.target,
            address: node.address
        };
        dialogConfig.data.title = 'Menu Editor';
        //dialogConfig.minWidth = '500px';
        dialogConfig.panelClass = "dialog-menu-editor"
        dialogConfig.data.succFunc = succFunc;
        dialogConfig.data.errorFunc = errFunc;
        const dialogRef = this.dialog.open(MenuObjectDialogComponent, dialogConfig);
        dialogRef.afterOpened().subscribe(() => {
            this.dialogData = dialogRef;
        });
    }

    saveEditedMenuItem(node, dataFromFields) {
        const dataSourceValueCopy = JSON.parse(JSON.stringify(this.dataSource.data));

        // recursive find function: Edit the node values
        function editNodeValues(arr: Array<any>, id: string): Array<any> {
            let result, subResult;
            arr.forEach((item, index) => {
                if (item.id === id) {
                    arr[index].name = dataFromFields.name;
                    arr[index].description = dataFromFields.description;
                    arr[index].isFieldtecApp = dataFromFields.isFieldtecApp;
                    arr[index].address = dataFromFields.address;
                    arr[index].icon = dataFromFields.icon;
                    arr[index].target = dataFromFields.target || '_self';
                } else if (item.children) {
                    subResult = editNodeValues(item.children, id);
                    if (subResult) { result = subResult; }
                }
                result = arr;
            });
            return result;
        }

        const menuDataValues = editNodeValues(dataSourceValueCopy, node.id);

        logger.info('Saving menu-item : ' + node.name + ' ' + node.id);
        this.isChanged = true;
        this.formatMenuItem(menuDataValues);
        this.buildMenuItem();
    }

    saveNewSubMenuItem(node, dataFromFields) {
        let menuDataValues = this.user.menu;

        /** Cloning selected item and format default values */
        const selectedNodeId = (node.id + '').split('/');
        selectedNodeId.forEach((item, index) => {
            if (index === 1) {
                menuDataValues = menuDataValues[item]
            } else {
                menuDataValues = menuDataValues["subMenu"][item]
            }
        });

        // tslint:disable-next-line:no-eval
        const selectedItem = { ...menuDataValues };
        if (!selectedItem.subMenu) {
            selectedItem.subMenu = [];
        }
        /**End of Cloning selected item. variable selectedItem is the container of cloned item */

        /**Edit the cloned selected item by adding default values */
        selectedItem.name = dataFromFields.name;
        selectedItem.description = dataFromFields.description;
        selectedItem.isFieldtecApp = dataFromFields.isFieldtecApp;
        selectedItem.address = dataFromFields.address;
        selectedItem.icon = dataFromFields.icon;
        selectedItem.target = dataFromFields.target;
        selectedItem.subMenu = [];
        /**End of  Editing the cloned selected..... */

        this.isChanged = true;

        /** Inserting the edited clone seletectedItem as children of the selectedMenuItem*/
        // tslint:disable-next-line:no-eval
        const selectedMenuItem = menuDataValues;
        if (!selectedMenuItem.subMenu) {
            selectedMenuItem.subMenu = [];
        }
        selectedMenuItem.subMenu.push(selectedItem);
        /** Inserting the edited clone..... */

        this.user.menu = menuDataValues;
        this.buildMenuItem();
    }

    removeHashKey(menu) {
        if (menu) {
            delete menu.$$hashKey;
            if (menu.subMenu) {
                for (let sid = 0; sid < menu.subMenu.length; sid++) {
                    this.removeHashKey(menu.subMenu[sid]);
                }
            }
        }
    }

    getRootNodesScope() {
        // return document.getElementById("tree-root-" + this.mode.toLowerCase()).scope();
    }

    changeMode() {
        this.mode = this.mode === 'EDIT' ? 'VIEW' : 'EDIT';
        if (this.mode === 'EDIT') {
            this.originalAccount = this.user.account;
        } else {
            this.saveChange();
        }
    }

    getCurrentPath() {
        let href = window.location.href;
        let index = href.indexOf('//');
        index = index !== -1 ? index : 0;
        href = href.substring(href.indexOf('/', index + 2));
        return href;
    }

    getTemplateName() {
        const template = this.currentDocumentService.getTemplate();
        return template?.name ? template.name : '';
    }

    buildPath(relPath) {
        // remove / from old menu links for it to work with baseUrlPath
        if (relPath.startsWith('/')) {
            return relPath.substring(1, relPath.length);
        }

        return relPath;
    }
}
