<!-- Tree Node Item for menu.template -->
<mat-tree
    *ngIf="menus.template ? isHasMenuTemplate() : false"
    [style.display]="mode == 'VIEW' ? 'block' : 'none'"
    id="matTreeTemplateId"
    class="ft-menu-tree"
    [dataSource]="dataSourceTemplate"
    [treeControl]="treeControlTemplate"
>
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
        class="templateMatTreeNode"
        *matTreeNodeDef="let node"
        [class.active-menu]="isActiveMenuItem(node)"
        matTreeNodeToggle
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
    >
        <div class="mat-tree-node treeMenuContent">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button type="button" mat-icon-button disabled></button>
            <span>
                <a class="menu-link mat-subheading-2" href="{{ loadNodeAddress(node) }}" (click)="menuClick(node, $event)">{{ node.name }}</a>
            </span>
        </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
        class="templateMatTreeNode"
        *matTreeNodeDef="let node; when: hasChildTemplate"
        [class.active-menu]="isActiveMenuItem(node)"
        matTreeNodeToggle
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
    >
        <div class="mat-tree-node treeMenuContent">
            <button type="button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControlTemplate.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            <span>
                <a class="menu-link mat-subheading-2" href="{{ loadNodeAddress(node) }}" (click)="menuClick(node, $event)">{{ node.name }}</a>
            </span>
        </div>
    </mat-tree-node>
</mat-tree>

<!--Tree Node Item for user.menu -->
<mat-tree class="ft-user-menu-tree" [dataSource]="dataSource" [treeControl]="treeControl" id="matTreeMenuId" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        cdkDrag
        [cdkDragData]="node"
        [class.active-menu]="isActiveMenuItem(node)"
        (mouseenter)="dragHover(node)"
        (mouseleave)="dragHoverEnd()"
        (cdkDragStarted)="dragStart()"
        (cdkDragReleased)="dragEnd()"
        [cdkDragDisabled]="!(mode == 'EDIT')"
    >
        <li class="mat-tree-node treeMenuContent">
            <button type="button" hidden="true" mat-icon-button disabled mat-raised-button color="primary"></button>
            <div class="control-wrap">
                <span>
                    <a class="menu-link mat-subheading-2" title="{{ node.description }}" href="{{ loadNodeAddress(node) }}" (click)="menuClick(node, $event)"><mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon> <span>{{ node.name }}</span></a>
                </span>
                

                <div class="control-tools" *ngIf="mode == 'EDIT'">
                    <button type="button" mat-icon-button mat-mini-fab [matMenuTriggerFor]="menu" aria-label="control-tools" data-nodrag>
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" color="primary" mat-menu-item (click)="editItem(node)" data-nodrag>
                            <mat-icon>mode_edit</mat-icon>
                            <span>Edit</span>
                        </button>  

                        <button type="button" mat-menu-item (click)="removeItem(node)" data-nodrag>
                        <mat-icon>delete_forever</mat-icon>    
                        <span>Delete</span>                      
                        </button>
                        <button type="button" mat-menu-item (click)="newSubItem(node)" data-nodrag>
                        <mat-icon>add</mat-icon>
                        <span>Add</span>
                        
                        </button>
                    </mat-menu>
                </div>
            </div>
        </li>
    </mat-tree-node>

    <!--Tree Node Item for user.menu children -->
    <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        cdkDrag
        [cdkDragData]="node"
        class="nested"
        [class.active-menu]="isActiveMenuItem(node)"
        (mouseenter)="dragHover(node)"
        (mouseleave)="dragHoverEnd()"
        (cdkDragStarted)="dragStart()"
        (cdkDragReleased)="dragEnd()"
        [cdkDragDisabled]="!(mode == 'EDIT')"
    >
        <li>
            <div class="mat-tree-node treeMenuContent">
                <button type="button"
                    mat-icon-button
                    matTreeNodeToggle
                    mat-raised-button
                    color="primary"
                    [attr.aria-label]="'toggle ' + node.name"
                >
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                </button>
                <div class="control-wrap">
                    <span>
                        <a class="menu-link mat-subheading-2" href="{{ loadNodeAddress(node) }}" (click)="menuClick(node, $event)">{{
                            node.name
                        }}</a>
                    </span>
                    
                    <div class="control-tools" *ngIf="mode == 'EDIT'">
                        <button type="button" mat-icon-button mat-mini-fab [matMenuTriggerFor]="menu" aria-label="control-tools" data-nodrag>
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button type="button" color="primary" mat-menu-item (click)="editItem(node)" data-nodrag>
                                <mat-icon>mode_edit</mat-icon>
                                <span>Edit</span>
                            </button>  

                            <button type="button" mat-menu-item (click)="removeItem(node)" data-nodrag>
                            <mat-icon>delete_forever</mat-icon>    
                            <span>Delete</span>                      
                            </button>
                            <button type="button" mat-menu-item (click)="newSubItem(node)" data-nodrag>
                            <mat-icon>add</mat-icon>
                            <span>Add</span>
                            
                            </button>
                        </mat-menu>
                    </div>

                    
                </div>
            </div>
        </li>
    </mat-tree-node>
</mat-tree>
<div class="edit-mode-buttons" *ngIf="!user.publicAccount">
    <mat-chip-list aria-label="Fish selection" class="mat-chip-list">
        <mat-chip color="primary" (click)="changeMode()" matTooltip="Change mode"><mat-icon class="mat-icon-rtl-mirror">mode_edit</mat-icon></mat-chip>
        <mat-chip (click)="newItem()" matTooltip="New item"><mat-icon class="mat-icon-rtl-mirror">add_circle</mat-icon></mat-chip>        
      </mat-chip-list>
</div>
