import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientConstants } from '../../constants/ClientConstants';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';

export interface SearchFilter {
  filter: boolean;
  queryToBeFiltered: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  private searchFilter: SearchFilter;
  private searchFilterSubject: Subject<SearchFilter>;
  searchFilter$: Observable<SearchFilter>;

  constructor(private localStorageService: LocalStorageService) {
    this.searchFilter = {
      filter: false,
      queryToBeFiltered: null
    };

    this.searchFilterSubject = new Subject<SearchFilter>();
    this.searchFilter$ = this.searchFilterSubject.asObservable();
  }

  setQueryToBeFiltered(query) {
    this.localStorageService.setItem(ClientConstants.FILTERED_QUERY, query);
    this.localStorageService.setItem(ClientConstants.FILTER, 'true');

    this.searchFilter.filter = true;
    this.searchFilter.queryToBeFiltered = query;

    this.searchFilterSubject.next(this.searchFilter);
  }

  removeFilter() {
    this.searchFilter.filter = false;
    this.searchFilter.queryToBeFiltered = null;

    this.localStorageService.removeItem(ClientConstants.FILTER);
    this.localStorageService.removeItem(ClientConstants.FILTERED_QUERY);

    this.searchFilterSubject.next(this.searchFilter);
  }
}
