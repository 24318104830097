import { Injectable } from '@angular/core';
import { CurrentDocumentService, DocumentSaveService, ModifiedFieldService, UnsavedDocumentService, DocumentService, BroadcastService, ClientConstants, OfflineStatusService } from '@formbird/services';
import { RouterService } from '../../../app-routing/router.service';
import { ModalService } from '@services/modal/modal.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class NewDocumentService {

  constructor(
    private documentSaveService: DocumentSaveService,
    private documentService: DocumentService,
    private currentDocumentService: CurrentDocumentService,
    private modifiedFieldService: ModifiedFieldService,
    private modalService: ModalService,
    private routingService: RouterService,
    private unsavedDocumentService: UnsavedDocumentService,
    private broadcastService: BroadcastService,
    private offlineStatusService: OfflineStatusService
  ) {
  }

  needReloadForm = false;

  createNewDocument() {

    logger.info('Initialising new document');

    const _self = this;

    const mainTemplate = this.currentDocumentService.getTemplate();

    if (mainTemplate) {

      if (this.modifiedFieldService.isDirty()) {

        const succFunc = function () {
          _self.needReloadForm = _self.modifiedFieldService.isDirty();
          
          const unsavedDocumentListIds = _self.modifiedFieldService.getAllChangedDocumentListIds();
          _self.documentSaveService.saveDocumentLists(unsavedDocumentListIds).then(
            function allPromiseSuccessFunc(result) {
              if (result.error) {
                _self.modalService.showDialog(result.error.message, 'ERROR');
              } else {
                _self.resetForm(mainTemplate, true);
              }
            },
            function promiseErrorFunc(err) {
              _self.modalService.showDialog(err.message, 'ERROR');
            }
          );
          
        };

        const discardFunc = function() {
          logger.info('Resetting form on discard of unsaved document');
          _self.needReloadForm = _self.modifiedFieldService.isDirty();
          _self.resetForm(mainTemplate);
        };

        const cancelFunc = function() {
          logger.info('Cancelling navigation due to unsaved changes');
        };

        _self.modalService.showDialog('Save Changes?', 'WARNING', succFunc, cancelFunc, discardFunc);

      } else {

        _self.resetForm(mainTemplate);

      }
    }
  }

  resetForm(targetTemplate, isDocumentSaved?) {

    const templateId = targetTemplate.documentId;

    // should reset template to original version because it might be modified in the rules for component visibility/mandatory/requirement
    this.unsavedDocumentService.resetToOrgDocument(templateId);
    this.unsavedDocumentService.resetTemplateComponent(templateId);

    const currentDocumentData = this.currentDocumentService.getDocumentData();
    const document = currentDocumentData?.document;
    if (!document?.systemHeader?.serverCreatedDate || (isDocumentSaved && !document?.systemHeader?.previousVersionId)) {
      this.broadcastService.broadcast(ClientConstants.DOCUMENT_RENEW, {
        templateId,
        isDocumentSaved
      });

      const offlineMode = this.offlineStatusService.isCachingEnabled();
      // in case the document was saved --> able to back to it
      if (document?.systemHeader?.serverCreatedDate || (offlineMode && (<any>document?.systemHeader)?.offlineDetails)) {
        history.pushState(null, null, `/form/${document?.documentId}`);
      }
    }

    this.modifiedFieldService.resetFormDirty();
    this.modifiedFieldService.resetModifiedFields();
    this.currentDocumentService.resetUndoRedo();

    // 16501 - always go to new template, pushState to force url change, some components use pushState
    this.routingService.navigate(['form', templateId]);
  }

  isNeedReloadForm() {
    return this.needReloadForm;
  }

  resetRenewFlag() {
    this.needReloadForm = false;
  }
}

