import { Injectable } from '@angular/core';
import { DocumentUpdater, SystemHeaderFilter, UtilDocument, UtilHttpStatus, UtilString } from '@formbird/shared';
import { ChangeSourceType, DocumentChangedInfo, FormParameters, SharedConstants } from '@formbird/types';
import { cloneDeep, isEqual } from 'lodash';
import { formSetDocument } from '../../redux/actions';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { BroadcastService } from '../broadcast/broadcast.service';
import { ConfigService } from '../config/config.service';
import { ValidationService } from '../validation/validation.service';
import { DocumentService } from './document.service';
import { ModifiedFieldService } from './modified-field.service';
import { UnsavedDocumentService } from './unsaved-document.service';
import { ClientConstants } from '../../constants/ClientConstants';
import { DataService } from '../data/data.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DocumentUpdateService {

  constructor(
    private dataService: DataService,
    private documentService: DocumentService,
    private modifiedFieldService: ModifiedFieldService,
    private unsavedDocumentService: UnsavedDocumentService,
    private broadcastService: BroadcastService,
    private configService: ConfigService,
    private validationService: ValidationService,
    private appStore: AppStore<IApplicationState>,
    private offlineStatusSerivce: OfflineStatusService
  ) {
  }

  private updatePrevCurrentVersion(currentVersion) {

    const _self = this;

    // in case the provided version is current one so set currentVersion of the previous one to false
    // This case for updating document from pulling documents on reconnection

    const documentId = currentVersion.documentId;
    const loadedDocs = _self.unsavedDocumentService.getAllLoadedDocs();
    const orgDocs = _self.documentService.getOrgDocuments();
    const keys = Object.keys(orgDocs);

    for (let i = 0; i < keys.length; i++) {

      const versionId = keys[i];
      let orgDoc = orgDocs[versionId];

      if (documentId === orgDoc.documentId &&
        versionId !== documentId &&
        versionId !== currentVersion.systemHeader.versionId &&
        orgDoc.systemHeader.currentVersion
      ) {

        orgDoc = cloneDeep(orgDoc);
        orgDoc.systemHeader.currentVersion = false;
        _self.documentService.setOriginalDocumentOnly(orgDoc, orgDoc.systemHeader.versionId);

        loadedDocs.forEach(function(loadedDoc) {

          if (loadedDoc.systemHeader.versionId === orgDoc.systemHeader.versionId) {
            loadedDoc.systemHeader.currentVersion = false;
            _self.documentService.setLoadedDocumentOnly(cloneDeep(loadedDoc), orgDoc.systemHeader.versionId);
          }
        });

        return;
      }
    }
  }

  private executeOnFieldChangeOnPush(oldDocument, newDocument, changedFields) {

    if (!changedFields?.length || !oldDocument) {
      return;
    }

    const defaultExecuteOnFieldChangeOnPush = this.configService.clientConfig().defaultExecuteOnFieldChangeOnPush === true;
    const documentId = oldDocument.documentId;
    const documentListIdInfos = this.unsavedDocumentService.getDocumentListInfos(documentId);

    documentListIdInfos.forEach(documentListIdInfo => {

      const template = documentListIdInfo.template;
      const executeOnFieldChangeOnPush = template.executeOnFieldChangeOnPush === true;
      
      //check if the template has been enabled to execute OnFieldChange on push
      if (defaultExecuteOnFieldChangeOnPush || executeOnFieldChangeOnPush) {
        
        const formParameters: FormParameters = {
          unsavedDocumentListId: documentListIdInfo.documentListId,
          selectedDocumentId: documentId
        };

        changedFields.forEach(changedField => {

          const component = template?.components?.filter(comp => comp?.name === changedField)[0];
          if (component) {

            const fieldName = component.name;
            if (fieldName) {

              const documentChangedInfo: DocumentChangedInfo = {
                documentId: documentId,
                fieldName: fieldName,
                oldValue: oldDocument[fieldName],
                newValue: newDocument[fieldName],
                formParameters: formParameters,
                sourceType: ChangeSourceType.DOCUMENT_PUSH,
                sourceEvent: ChangeSourceType.DOCUMENT_PUSH,
                shouldCompareValues: false
              };

              this.validationService.executeOnFieldChangeWithNoChanges(documentChangedInfo);
            }
          }

        });
      }

    });
      

  }

  private extractChangedFields(updates) {

    const res = [];

    updates.forEach(update => {
      const fieldName = update.path.split('/')[1];
      if (fieldName !== 'systemHeader') {
        res.push(fieldName);
      }
    });

    return res;

  }

  async updateVersions(versions) {

    const _self = this;

    if (versions && versions.length) {

      const currentVersion = versions.filter(version => version.systemHeader.currentVersion)[0];
      if (currentVersion) {

        this.documentService.resetDisableSaveFieldToDoc(currentVersion);

        if (versions.length === 1) {
          _self.updatePrevCurrentVersion(currentVersion);
        }

        const documentId = currentVersion.documentId;

        logger.info('Updating document in memory: ' + documentId);

        const loadedDoc = _self.documentService.getLoadedDocument(documentId);
        if (loadedDoc?.systemHeader?.currentVersion) {
          // use current version to update loaded document on form
          const changedFields = await _self.applyDocumentChanges(loadedDoc, currentVersion);

          // execute OnFieldChange on all the configured fields have been updated from pushed document
          this.executeOnFieldChangeOnPush(loadedDoc, currentVersion, changedFields);

          // update the loaded document in store
          _self.documentService.setLoadedDocumentOnly(loadedDoc);
          // use current version to update org version in store
          _self.documentService.setOriginalDocumentOnly(currentVersion);

          logger.info('Update document on form to latest one.');

          _self.broadcastService.broadcast(ClientConstants.DOCUMENT_UPDATED, [currentVersion]);
        }
      } else {
        _self.broadcastService.broadcast(ClientConstants.DOCUMENT_DELETED, versions[0].documentId);
      }

      const nonCurrentVersions = versions.filter(version => version.systemHeader.currentVersion === false);
      if (nonCurrentVersions.length) {

        // user non current version to update loaded non-current version in store
        nonCurrentVersions.forEach(async function(version) {

          const versionId = version.systemHeader.versionId;
          const loadedVersion = _self.documentService.getLoadedDocument(versionId);

          if (loadedVersion) {
            await _self.applyDocumentChanges(loadedVersion, version);

            // update loaded non-current version on form
            _self.documentService.setLoadedDocumentOnly(cloneDeep(loadedVersion), versionId);
            // use non-current version to update its version in store
            _self.documentService.setOriginalDocumentOnly(version, versionId);
          }

        });
      }

    }
  }

  async applyDocumentChanges(oldOrgDoc, latestDoc, applyAllChanges?) {

    let changedFields = [];

    const documentId = latestDoc.documentId;
    logger.info('Applying document changes for doc: ' + documentId);

    const modifiedFields = this.modifiedFieldService.getModifiedFieldsInDoc(documentId);

    var excludedOptions = {};
    excludedOptions[SharedConstants.MAKE_CURRENT_VERSION_KEY] = true;
    const loadedDocs = this.unsavedDocumentService.getAllLoadedDocs(excludedOptions);

    for (let i = 0; i < loadedDocs.length; i++) {

      const loadedDoc = cloneDeep(loadedDocs[i]);

      if (loadedDoc.documentId === documentId) {

        const updates = await DocumentUpdater.getUpdates(oldOrgDoc, latestDoc);

        if (updates.updates && updates.updates.length) {

          if (modifiedFields && modifiedFields.length) {

            modifiedFields.forEach(function(modifiedField) {

              modifiedField = UtilString.convertToObjectPath(modifiedField, '/');

              updates.updates.forEach(function(update: any, index) {

                const path = UtilString.convertToObjectPath(update.path, '/');

                if (modifiedField === path && !isEqual(loadedDoc[modifiedField], latestDoc[modifiedField])) {

                  logger.warn('Field in pushed document: \'' +
                    modifiedField + '\' has been changed to: ' + JSON.stringify(update.value));

                  delete updates.updates[index];

                }

              });

            });

          }

          changedFields = this.extractChangedFields(updates.updates);

          console.log('Updates: ', updates);
          console.log('ChangedFields: ', changedFields);

          if (changedFields?.length) {
            await DocumentUpdater.applyUpdates(oldOrgDoc, updates);
          }

          if (applyAllChanges) {
            SystemHeaderFilter.applyUpdatesToSystemHeader(oldOrgDoc, latestDoc);
          }
        }
      }
    }

    return changedFields;

  }

  async updateFormDocument(unsavedDocuments, doc) {

    if (unsavedDocuments.length) {

      // just mutate the document if so that references to this object are not cutoff
      // also update loaded docs too
      for (let j = 0; j < unsavedDocuments.length; j++) {
        const unsavedDoc = unsavedDocuments[j];

        if (unsavedDoc.documentId === doc.documentId) {

          const updates = await DocumentUpdater.getUpdates(unsavedDoc, doc);
          await DocumentUpdater.applyUpdates(unsavedDoc, updates);
          this.appStore.dispatch(formSetDocument(unsavedDoc));

          break;
        }
      }

    }
  }

  applyDocumentChangesSimple(unsavedDocument, doc) {
    DocumentUpdater.applyUpdatesSimple(unsavedDocument, doc);
  }

  async checkDocumentsCreated() {

    const _self = this;

    //should not perform checking and updating if caching is not enabled
    if (this.offlineStatusSerivce.isCachingEnabled()) {
      return;
    }

    //load all new documents on form
    const loadedDocuments = _self.unsavedDocumentService.getAllLoadedDocs();

    //check if any documents are new but found from server
    if (loadedDocuments && loadedDocuments.length) {

      loadedDocuments.forEach(async loadedDocument => {

        await this.applyDocumentCreated(loadedDocument);

      });
    }

  }

  async applyDocumentCreated(loadedDocument) {

    const _self = this;

    const isNew = UtilDocument.isNew(loadedDocument);
    if (!isNew) {
      return;
    }

    const documentId = loadedDocument.documentId;

    try {

      const serverDoc = await _self.dataService.getDocumentFromServer(documentId);
      if (serverDoc) {

        //Found from server. Update that new document using the version from server

        this.documentService.resetDisableSaveFieldToDoc(serverDoc);

        logger.info('Updating document in memory: ' + documentId);

        // use current version to update loaded document on form
        await _self.applyDocumentChanges(loadedDocument, serverDoc, true);

        // update the loaded document in store
        _self.documentService.setLoadedDocumentOnly(loadedDocument);
        // use current version to update org version in store
        _self.documentService.setOriginalDocumentOnly(serverDoc);

        logger.info('Update document on form to latest one.');

        _self.broadcastService.broadcast(ClientConstants.DOCUMENT_UPDATED, [serverDoc]);

      }

    } catch (error) {
      console.log(error?.status === UtilHttpStatus.NOT_FOUND ?
        `Document ${documentId} not found from server. Ignore updating on form.` :
        `Can not check document ${documentId} from server`
      );
    }
  }

}
