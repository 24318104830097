import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FtError, LastTileSyncDetails } from "@formbird/types";
import { ConfigService,  OfflineStatusService, LoggedInUserService} from '@formbird/services';
import { logger } from '@logger';
import { isArray } from 'lodash';

const MIN_ZOOM = 1;
const MAX_ZOOM = 14;


@Injectable({
  providedIn: 'root'
})

export class CacheMapTilesService {

  tileServerUrl: any;
  shouldRetryFetch: boolean = false;
  strUserConfigItem = "lastSyncTileServerCacheCount";
  dataBounds: number[];
  urlStyle: string;
  urlConfigBound: string;
  cacheName: string = "map-tiles";
  cacheStorage: any;

  constructor(
    private http: HttpClient,  private configService: ConfigService,
    private  offlineStatusService:  OfflineStatusService,
    private loggedInUserService: LoggedInUserService
  ) {
    const clConfig = this.configService.clientConfig();
    if (clConfig && clConfig.openLayers && clConfig.openLayers.tileServerGL){
      if (clConfig.openLayers.tileServerGL.url) {
        this.tileServerUrl = clConfig.openLayers.tileServerGL.url;
      }
      if (clConfig.openLayers.tileServerGL.urlConfigBound) {
        this.urlConfigBound = clConfig.openLayers.tileServerGL.urlConfigBound;
      }
      if (clConfig.openLayers.tileServerGL.dataBounds) {
        this.dataBounds = clConfig.openLayers.tileServerGL.dataBounds;
      }
      if (clConfig.openLayers.tileServerGL.urlStyle) {
        this.urlStyle = clConfig.openLayers.tileServerGL.urlStyle;
      }
    }

    window.addEventListener('online', () => this.handleNetworkChange());
    window.addEventListener('offline', () => this.handleNetworkChange());
  }

  private longToTile(lon, zoom) {
    let result;

    if (!lon || !zoom) {
      const msg = "No longitude or zoom specified when converting longitude to tile";
      logger.error(msg);
      throw new Error(msg);
    } else {
      result = (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)));
    }

    return result;
  }

  private latToTile(lat, zoom) {
    let result;

    if (!lat || !zoom) {
      const msg = "No latitude or zoom specified when converting latitude to tile";
      logger.error(msg);
      throw new Error(msg);
    } else {
      result = (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 *
        Math.pow(2, zoom)));
    }

    return result;
  }


  private calculateTilesWithinRegion(point1, point2) {
    let arrayCoords = [];

    const lastSyncDetails = this.offlineStatusService.getLastTileSyncDetails();
    const lastSyncX = lastSyncDetails ? lastSyncDetails.lastSyncX : null;
    const lastSyncY = lastSyncDetails ? lastSyncDetails.lastSyncY : null;

    for (let zoom = MIN_ZOOM; zoom <= MAX_ZOOM; zoom++) {
      var point1X = this.longToTile(point1.lon, zoom);
      var point2X = this.longToTile(point2.lon, zoom);
      var point1Y = this.latToTile(point1.lat, zoom);
      var point2Y = this.latToTile(point2.lat, zoom);

      var startX = Math.min(point1X, point2X);
      var endX = Math.max(point1X, point2X);
      var startY = Math.min(point1Y, point2Y);
      var endY = Math.max(point1Y, point2Y);

      startX = lastSyncX && startX < lastSyncX ? lastSyncX : startX;
      startY = lastSyncY && startY < lastSyncY ? lastSyncY : startY;

      for (let x = startX; x <= endX; x++) {
        for (let y = startY; y <= endY; y++) {
          var url = this.tileServerUrl;
          url = url.replace("{z}", zoom);
          url = url.replace("{x}", x);
          url = url.replace("{y}", y);
          arrayCoords.push({url, x, y});
        }
      }
    }

    return arrayCoords;
  }

  private loadTileServerVectorStyle() {

    let url = this.tileServerUrl;
    url = url.replace("data/v3/{z}/{x}/{y}.pbf", 'styles/basic-preview/style.json');
    return fetch(url);

  }

  async loadDataCache(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.cacheStorage = await caches.open(this.cacheName);
      
      if (this.tileServerUrl) {

        if (this.urlStyle) {
          try {
            await fetch(this.urlStyle);
            this.cacheStorage.add(this.urlStyle);
          } catch (err) {
            const errMsg = 'Unable to cache tile serverGL vector style.' + err;
            logger.error(errMsg);
            reject(new Error(errMsg));
            return;
          }
        }

        try {
          await this.loadTileServerGL();
        } catch (err) {
          const errMsg = 'Unable to cache tile serverGL.' + err;
          logger.error(errMsg);
          reject(new Error(errMsg));
          return;
        }

        resolve();
        
      } else {
        resolve();
      }
    });

  }
  private loadTile(coordArray, index) {
    if (!navigator.onLine) {
      logger.info('Network is offline, waiting to resume... at: ' + index + '/' + coordArray.length);
      return new Promise(resolve => {
        const checkOnline = setInterval(() => {
          if (navigator.onLine) {
            clearInterval(checkOnline);
            resolve(this.loadTile(coordArray, index)); // Resume the function when online
          }
        }, 1000); // Check every second
      });
    }

    logger.info('***** loadEach : ' + index + '/' + coordArray.length);

    if (!this.offlineStatusService.offlineStatus.cacheLoading) {
      const errMsg = "Caching TileServerGL terminated due to cache loading cancelled";
      return Promise.reject(new Error(errMsg))
    }

    this.offlineStatusService.offlineStatus.tileServerCacheCount++;
    this.offlineStatusService.setOfflineStatusToLocalStorage();
    this.loggedInUserService.setUserConfigItem(this.strUserConfigItem, 
      this.offlineStatusService.offlineStatus.tileServerCacheCount);

    return new Promise((resolve, reject) => {
      if (coordArray[index]) {
        fetch(coordArray[index].url).then(async (response) => {
          // cache this link in storage
          this.cacheStorage.add(coordArray[index].url);

          this.offlineStatusService.setLastTileSyncDetails({ lastSyncX: coordArray[index].x, lastSyncY: coordArray[index].y });

          resolve(null);
        }).catch(err => {
          this.offlineStatusService.setLastTileSyncDetails({ lastSyncX: coordArray[index].x, lastSyncY: coordArray[index].y });

          reject(err);
        });
      } else {
        resolve(null);
      }
    });
  }

  private async processDataTiles(dataBounds, accountConfig): Promise<any> {
    let cacheBounds = dataBounds;
    if (accountConfig && accountConfig.tileServerGLCacheBounds) {
      cacheBounds = Array.isArray(accountConfig.tileServerGLCacheBounds) ? accountConfig.tileServerGLCacheBounds : dataBounds;
    }
  
    logger.info('Loading tileserver-gl data cache. With bounds: ', cacheBounds);
    let point1 = { lat: cacheBounds[1], lon: cacheBounds[0] };
    let point2 = { lat: cacheBounds[3], lon: cacheBounds[2] };
    const coordArray = this.calculateTilesWithinRegion(point1, point2);
    this.offlineStatusService.offlineStatus.tileServerCacheCount = -1;
    this.offlineStatusService.offlineStatus.tileServerMaxCacheCount = coordArray.length;
  
    const lastSyncTileServerCacheCount = this.loggedInUserService.getUserConfigItem(this.strUserConfigItem);
    const startIndex = 0;
    this.offlineStatusService.offlineStatus.tileServerCacheCount = startIndex - 1;
  
    return this.processRange(startIndex, coordArray.length, index => this.loadTile.call(this, coordArray, index))
      .then(result => {
        this.offlineStatusService.offlineStatus.tileServerCacheCount = 0;
        return result;
      })
      .catch(err => {
        this.offlineStatusService.offlineStatus.tileServerCacheCount = 0;
        throw err;
      });
  }

  private handleNetworkChange() {
    if (navigator.onLine && this.shouldRetryFetch) {
      logger.info('Network is back online, resuming fetch...');
      this.offlineStatusService.setCacheLoadingStatus(true);
      this.loadTileServerGL(); // Retry fetching
    }
  }

  private loadTileServerGL() {
    if (!navigator.onLine) {
      logger.info('Network is offline, waiting to resume...');
      this.shouldRetryFetch = true;
      return Promise.resolve(null); // Exit if there's no network
    }

    let accountConfig = this.loggedInUserService.getUserAccountGroupConfigDocument();

    return new Promise((resolve, reject) => {
      if (this.urlConfigBound) {
        this.http.get(this.urlConfigBound, {responseType: 'json'}).subscribe(
          async res => {
            let data;  data = res;
            resolve(this.processDataTiles(data.bounds, accountConfig));
          },
          err => {
            const msg = 'Error requesting offline tileserver-gl. Status: ' + err.status;
            logger.error(err.message);
            reject(new FtError(msg));
          }
        );
      } else {
        resolve(this.processDataTiles(this.dataBounds, accountConfig));
      }
      
    });
  }

  private processRange(startIndex, endIndex, asyncJob): Promise<any> {
    let sequence = Promise.resolve();

    for (let i = startIndex; i <= endIndex; i++) {
      sequence = sequence.then(function() {
        return asyncJob(i);
      }).catch(err => {
        logger.error(err.message);
        return asyncJob(i);
      });
    }

    return sequence;

  }
}
