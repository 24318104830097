<form name="ftForm" id="ftForm" role="form">
  <ng-container #ngMainCont></ng-container>
</form>

<ng-template #ngMainTpl>
  <ft-template ftFormColor [formParameters]="formParameters" 
              [user]="user"
              style="display: flex; flex-direction: row; flex-wrap: wrap; place-content: flex-start" 
              class="gt-xs">
  </ft-template>
</ng-template>