import { SessionStorageService } from '../storage/session-storage/session-storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControlStateSaveService {

  constructor(
    private sessionStorageService: SessionStorageService
  ) { }

  setControlState(stateName: string, value: any) {
    this.sessionStorageService.setItem(stateName, JSON.stringify(value));
  }

  getControlState<T>(stateName: string): T {
    const value = this.sessionStorageService.getItem(stateName);
    return JSON.parse(value) as T;
  }

  async removeControlState(stateName: string) {
    await this.sessionStorageService.removeItem(stateName);
  }
}
