// type of message sent from a web worker to the main thread
export enum WebWorkerMessageType {
    ADD_TO_MAX_CACHE_COUNT,
    ADD_TO_CURRENT_CACHE_COUNT,
    SET_PAGE_DOWNLOAD_PROGRESS,
    SET_INITIAL_CACHE_QUERY_SUCCESS,
    INITIAL_CACHE_COMPLETED,
    SET_LAST_SYNC_START_TIME,
    PEINDING_DOCUMENT_COUNT,
    PREVIOUS_SYNC_STATUS,
    PRINT_MESSAGE,

}

// a message sent between a web worker and the main thread using postMessage
export interface OfflineWebWorkerMessage {
    messageType: WebWorkerMessageType;
    value: any;
};
