import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@formbird/types';
import { Observable } from 'rxjs';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { ConfigService } from '../config/config.service';
import { select } from '../../redux/decorators/select';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DefaultStateService {

  @select(['userState', 'user']) user$: Observable<User>;
  user: User;

  constructor(
    private loggedInUserService: LoggedInUserService,
    private router: Router,
    private configService: ConfigService
  ) {
    this.user$.subscribe((user: User) => this.user = user);
  }

  isPathAccountDefault() {
    let userBean;
    if (this.user) {
      userBean = this.user.account;
    }

    const pathArray = this.router.url.split('/');
    return userBean && userBean.defaultURL === pathArray[2];
  }

  changeToDefaultState(nextState?, userBean?) {
    logger.info('Changing to default URL');

    const defaultURL = this.configService.clientConfig().defaultURL;
    const user = this.user.account;

    let publicAccount = false;
    let accountPublicURL;

    if (user && user.publicAccount) {
      publicAccount = true;
      accountPublicURL = user.accountPublicURL;
    }

    if (!userBean) {
      userBean = this.user.account;
    }

    let id = '';
    if (defaultURL && defaultURL !== '') {
      id = defaultURL;
    }

    if (userBean && userBean.defaultURL && userBean.defaultURL !== '') {
      id = userBean.defaultURL;
    }

    if (nextState) {
      if (id && id !== '') {
        const locationParams: any = {};
        if (publicAccount) {
          locationParams.replaceUrl = false;
        }

        this.router.navigate([nextState, id], locationParams);
      } else {
        this.router.navigate([nextState]);
      }
    } else {
      if (publicAccount) {
        this.router.navigate([`/${accountPublicURL}`, 'form', id]);
      } else if (id !== '') {
        this.router.navigate(['/form', id]);
      } else {
        this.router.navigate(['/form']);
      }
    }
  }

}
