import { CurrentDocumentService } from '@formbird/services';
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ft-template-name',
  templateUrl: './template-name.html',
  styleUrls: ['./template-name.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TemplateNameComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  templateName = '';

  constructor(
    private currentDocumentService: CurrentDocumentService,
    private cdf: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const template = this.currentDocumentService.getTemplate();
    if (template) {
      this.templateName = template.name || '';
      this.cdf.detectChanges();
    };
    this.sub = this.currentDocumentService.documentData$.subscribe(data => {
      this.templateName = data?.template?.name || '';
      this.cdf.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
