import { Injectable } from '@angular/core';
import { UrlBasePathUtil } from '../../utils/UrlBasePathUtil';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  constructor() {
  }

  openNewDocument(templateId: string, initialData, newTab: string | boolean, callback: Function) {
    if (!callback) {
      callback = function() {
      };
    }

    let target = '_blank';

    if ((newTab && newTab !== null && newTab === 'no') || newTab === false) {
      target = '_self';
    }

    const initialDataKey = 'initialData:' + templateId;
    localStorage.setItem(UrlBasePathUtil.basePath + initialDataKey, JSON.stringify(initialData));

    callback();
    window.open('form/' + templateId, target);
  }
}
