import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogComponent } from '@components/dialog/dialog.component';
import { SharedConstants } from '@formbird/types';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

    dialogRef: MatDialogRef<DialogComponent>;
    swal: any; // TODO: Remove if no longer used in rules/component source
    openModal: any;

    constructor(public dialog: MatDialog) {
        this.openModal = (options: any, message?: string, type?: string) => {
            if (typeof options === 'object') {
                this.openDialog(options);
            } else {
                const data: any = {
                    title: options,
                    html: `<p>${message}</p>`,
                    messageType: type,
                    confirmButton: true
                };

                this.openDialog({ data });
            }
        };

        // swal function have arguments ({title, message, type}, callback(true)) or (title, message, type)
        this.swal = async (swalOptions: any, callback?: any, type?: string) => {
            // deprecated, remove when no longer used
            console.warn('Deprecated: Please use functions openDialog and openModal. '
                + 'SweetAlert(swal) is now replaced with material dialog. The '
                + 'swal(title, message, type) and swal(options) functions will be removed in the future.');

            let message = '';
            if (typeof callback === 'string') {
                message = callback;
            }

            if (typeof swalOptions === 'object') {
                // convert swal options
                // retaining the definite features from
                // https://www.formbird.com/docs/5.Rulesets/060-Ruleset_Functions/#options_1
                const dialogOptions: any = {
                    data: {
                        title: swalOptions.title || swalOptions.titleText,
                        html: swalOptions.html || `<p>${swalOptions.text || ''}</p>`,
                        messageType: swalOptions.type || 'info',
                        ...swalOptions,
                        succFunc: () => {
                            const isConfirmed = true;
                            if (callback) {
                                callback(isConfirmed);
                            }
                        },
                        errorFunc: swalOptions.showCancelButton ? () => {
                            const isConfirmed = false;
                            if (callback) {
                                callback(isConfirmed);
                            }
                        } : null
                    }
                };

                dialogOptions.width = swalOptions.width || '500px';
                dialogOptions.disableClose = swalOptions.allowOutsideClick === false
                    || swalOptions.allowEscapeKey === false;
                dialogOptions.autoFocus = true;

                return this.openDialog(dialogOptions);
            } else {
              this.openModal(swalOptions, message, type);
            }
        };

        this.swal.close = this.openModal.close = () => {
            this.closeDialog();
        };
    }

    openDialog(options: any) {
        if (!options) return Promise.resolve();
        
        if (options.data && options.data.component) {
          this.dialogRef = this.dialog.open(options.data.component, options);
        } else {
          this.dialogRef = this.dialog.open(DialogComponent, options);
        }

        if (options.data.timer) {
            setTimeout(() => {
                this.dialogRef.close();
            }, options.data.timer);
        }

        return this.dialogRef.afterClosed().toPromise();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    showDialog(
        message: string,
        messageType?: string,
        succFunc?: Function,
        errorFunc?: Function,
        discardFunc?: Function,
        closeFunc?: Function): void {

        if (!messageType) {
            messageType = SharedConstants.STATUS_SUCCESS;
        }

        const lowerCaseMessageType = messageType.toLowerCase();
        const data: any = {
            html: `<p>${message}</p>`,
            messageType: lowerCaseMessageType,
            confirmButton: true,
            showCancelButton: true,
            allowEnterKey: false // prevent accidental confirm
        };

        if (lowerCaseMessageType === SharedConstants.STATUS_ERROR) {
            data.title = 'Error';
        } else if (lowerCaseMessageType === SharedConstants.STATUS_SUCCESS) {
            data.title = 'Success';
        } else if (lowerCaseMessageType.toLowerCase() === SharedConstants.STATUS_INFO) {
            data.title = 'Info';

            data.succFunc = succFunc;
            data.errorFunc = errorFunc;
        }  else if (lowerCaseMessageType === SharedConstants.STATUS_WARNING) {
            data.title = 'Warning';
            data.confirmButton = false;

            data.succFunc = succFunc;
            data.errorFunc = errorFunc;
            data.discardFunc = discardFunc;
        }

        this.openDialog({ data, panelClass: 'ft-dialog-component-wrap'  }).then((data) => {
            if (closeFunc) closeFunc();
        });
    }

}
