import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageLinkService } from '@services/image-link.service';
import {
  ConfigService, CurrentDocumentService, ModifiedFieldService,
  NotificationService, FileProviderService, DataService, formSetDocument, AppStore, IApplicationState
} from '@formbird/services';
import { ModalService } from '@services/modal/modal.service';
import { UtilDocumentId } from '@formbird/shared';
import { ImageCompressionService } from '@services/image-compression.service';
import { Subscription } from 'rxjs';
import { SharedConstants, FormDocument } from '@formbird/types';

const DOCUMENT_FIELD_NAME = SharedConstants.DOCUMENT_FIELD_NAME_UPLOAD_FILE; // key used at systemHeader to refer to the uploaded file

@Component({
  selector: 'ft-command-bar-uploader',
  templateUrl: './command-bar-uploader.component.html',
  styleUrls: ['./command-bar-uploader.component.scss']
})

export class CommandBarUploaderComponent implements OnInit, OnDestroy {
  documentData: any;
  doc: any;
  maxImageHeight: number;
  fieldName: any;
  sub: Subscription;
  progress = 0;

  constructor(
    private currentDocumentService: CurrentDocumentService,
    private fileProviderService: FileProviderService,
    private modalService: ModalService,
    private imageLinkService: ImageLinkService,
    private dataService: DataService,
    private notificationService: NotificationService,
    private configService: ConfigService,
    private imageCompressionService: ImageCompressionService,
    private appStore: AppStore<IApplicationState>,
  ) {
  }

  isImageFile(file) {
    const ext = this.getExtension(file.name || file.fileName).toLowerCase();
    return this.filterImage(ext);
  }

  getExtension(fileName) {
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toUpperCase();
    const extName = this.configService.clientConfig().imageViewer.extensionNameMap[ext];
    return extName !== undefined && extName !== null ? extName : ext.toUpperCase();
  }

  filterImage(ext) {
    const exts = this.configService.clientConfig().imageViewer.supportedExtensions;
    return (exts.indexOf(ext.toLowerCase()) > -1);
  }

  onFileSelect(files: File[]) {
    if (!files || files.length == null
      || files.length === 0 || this.documentData?.isNew) {
      return;
    }

    const fileObject = files[0];

    if (!this.isImageFile(fileObject)) {
      this.notificationService.printMessage('Error in Image Upload. Please check if your file is a supported image.', 'error');
      return;
    }

    const document = this.currentDocumentService.getDocument();
    const fileNo = UtilDocumentId.generateId();
    const documentId = document.documentId;
    const documentName = document.systemHeader.summaryName;

    const maxImageHeight = this.configService.clientConfig().commandBarMaxImageHeight || 96;
    this.imageCompressionService
      .resizeImage(fileObject, maxImageHeight).then((dataUrl) => {
      const compressedImage = this.imageCompressionService.dataURLtoFile(dataUrl, fileObject.name);

      const uploadData: any = {};
      uploadData.fileNo =  fileNo;
      uploadData.file =  compressedImage;
      uploadData.documentId =  documentId;
      uploadData.documentName =  documentName;
      uploadData.isCommandBarUpload = true;

      this.fileProviderService.uploadFile(
        uploadData
      ).subscribe(
        (event: any) => {
          if (event.body && event.status === 200) {
            const uploaded = event.body;

            // save as the template's image on navbar
            document.systemHeader[DOCUMENT_FIELD_NAME] = uploaded.fileNo;

            // update the template's current image on navbar
            this.imageLinkService.setImageFileNo96(uploaded.fileNo);

            const orignalDoc = this.currentDocumentService.getDocument();

            this.dataService.deepDiffUpdate(orignalDoc, document).then((data: FormDocument) => {
              this.appStore.dispatch(formSetDocument(data));
              this.notificationService.printMessage('Your image has been uploaded successfully.', 'success');
              this.currentDocumentService.setDocument(data);
            }, (error) => {
              this.notificationService.printMessage('Error on uploading image.', 'error');
            });

            this.progress = 0;
          } else {
            this.progress = (event.loaded / event.total) * 100;
          }
        },
        (err) => {
          this.progress = 0;
          this.modalService.showDialog(err.error || err.message, 'ERROR');
        }
      );
    });
  }

  onFileInput(event) {
    if (event.target.files) {
      this.onFileSelect(event.target.files);
    }
  }

  ngOnInit() {
    this.sub = this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
