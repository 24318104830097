import { ConfigService } from '../config/config.service';
import { DataService } from '../data/data.service';
import { DocumentUpdateService } from '../document/document-update.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { RestDataService } from '../data/rest-data.service';
import { UnsavedDocumentService } from '../document/unsaved-document.service';
import { UrlBasePathUtil } from '../../utils/UrlBasePathUtil';
import { SharedConstants } from '@formbird/types';
import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { BroadcastService } from '../broadcast/broadcast.service';
import { ClientConstants } from '../../constants/ClientConstants';
import { AppStoreSessionService } from '../session/app-store-session.service';
import { VersionService } from '../version/version.service';
import { ClientAccessService } from '../access/client-access.service';

const logger = console;

const urlBasePath = UrlBasePathUtil.basePathWithSlashPrefix;
const path = { path: 'socket.io' };

const WEB_SOCKET_RECONNECT_TIME = 1000;

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private socket;

  constructor(
    private offlineStatusService: OfflineStatusService,
    private unsavedDocumentService: UnsavedDocumentService,
    private dataService: DataService,
    private restDataService: RestDataService,
    private documentUpdateService: DocumentUpdateService,
    private configService: ConfigService,
    private broadcastService: BroadcastService,
    private appStoreSessionService: AppStoreSessionService,
    private clientAccessService: ClientAccessService
  ) {
  }

  private getTransports() {
    if (this.configService.clientConfig().socketIO && this.configService.clientConfig().socketIO.transports) {
      return this.configService.clientConfig().socketIO.transports;
    }
    return null;
  }

  /**
   * cache any documents saved while offline. This can either be when the browser was closed or the device was offline
   */
  private loadDocumentsSavedWhileOffline() {

    const _self = this;

    logger.info('Device has come online or app has been started. Loading any data saved while offline');

    const promise: Promise<any> = _self.dataService.loadDataCache(false);

    promise.then(updateCachedDocuments);

    async function updateCachedDocuments(newDocuments) {

      if (newDocuments) {
        for (let i = 0; i < newDocuments.length; i++) {
          let newDocument = newDocuments[i];
          await _self.documentUpdateService.updateVersions([newDocument]);
        }
      }

      return newDocuments;
    }
  }

  start() {

    logger.info('Starting websocket...');

    const _self = this;

    const options = {
      resource: path,
      path: null,
      transports: ["websocket"]
    };

    const transports = _self.getTransports();
    if (transports) {
      options.transports = transports;
    }

    options.path = urlBasePath + '/socket.io';
    _self.socket = io.connect('/', options);

    function startReconnect() {
      if (!_self.socket.connected) {
        setTimeout(doReconnect, WEB_SOCKET_RECONNECT_TIME);
      }
    }

    function doReconnect() {
      if (!_self.socket.connected) {
        startReconnect();
        _self.socket.connect();
      }
    }

    _self.socket.on(SharedConstants.WEB_SOCKET_MESSAGE_SAVE_DOCUMENT, function (docInfo) {

      if (_self.offlineStatusService.isCachingEnabled() ||
        _self.unsavedDocumentService.documentIsLoaded(docInfo.documentId)) {
        
        return _self.restDataService.loadVersions(docInfo.versionIds).then(async function successFunc(versions) {

          await _self.documentUpdateService.updateVersions(versions);
          
          // if (!_self.appStoreSessionService.isSameAppStoreSession(docInfo?.sourceId)) {
          //   _self.broadcastService.broadcast(ClientConstants.DOCUMENT_RECEIVED, docInfo);
          // }

          if (_self.offlineStatusService.isCachingEnabled() === true) {
            
            const hasOfflineAccess = _self.clientAccessService.checkAccess(docInfo, SharedConstants.OPERATION_TYPE_OFFLINE);
            if (hasOfflineAccess) {
              _self.offlineStatusService.addToMaxCacheCount(1);
            }

            // pass versions in. If it was not fetched already
            _self.dataService.cacheDocument(docInfo, versions).then(
              function successFunc(versions) {
                logger.info("Cached pushed document for offline use: " + docInfo.documentId);
              },
              function errorFunc(err) {
                logger.info("Not found document from received pushed versions: " + docInfo.documentId);
              }
            );
          }
        }, function errorFunc(err) {
          logger.error("Error loading pushed document: " + err.message);
        });
      }
    });

    _self.socket.on('reconnect', function (attempt) {
      _self.loadDocumentsSavedWhileOffline();
    });

    _self.socket.on('connect', function (data) {

      logger.info('Connected to notification socket. Setting status to connected.');

      _self.offlineStatusService.setConnected();
      // _self.utilService.scopeApply(_self.$rootScope);

      _self.loadDocumentsSavedWhileOffline();
      
      VersionService.checkServerVersion();
      _self.documentUpdateService.checkDocumentsCreated();
    });

    _self.socket.on('connect_error', function (data) {

      logger.info('Error in notification socket. Setting status to disconnected');

      _self.offlineStatusService.setDisconnected();
      // _self.utilService.scopeApply(_self.$rootScope);

    });

    _self.socket.on('disconnect', function (data) {

      logger.info('Disconnected from notification socket. Setting status to disconnected');

      _self.offlineStatusService.setDisconnected();

      startReconnect();

    });

    _self.socket.on(SharedConstants.WEB_SOCKET_MESSAGE_PASSWORD_RESET, function (userId) {
      _self.emit( SharedConstants.PASSWORD_RESET, userId);
    });
  }

  /**
   * end the websocket session
   */
  end() {
    // disconnect from the socket. No callback is fired on doing this. A disconnect event is fired but only if the socket is
    // currently connected

    if (this.socket) {
      this.socket.disconnect();
    }

  }

  emit(eventName, data) {
    this.socket.emit(eventName, data);
  }

  addEventListener(eventName, eventHandlerFunc) {
    this.socket.on(eventName, eventHandlerFunc);
  }

  removeEventListener(eventName) {
    this.socket.off(eventName);
  }
}
