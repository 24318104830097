import { OfflineStatus } from '@formbird/types';

export interface IOfflineState {
  offlineStatus?: OfflineStatus;
}

export function getDefaultOfflineState(): IOfflineState {
  return {
    offlineStatus: {
        unsyncedCount: 0,
        connected: true,
        connectionText: '',
        incomingCacheCount: 0,
        maxCacheCount: 0,
        currentCacheCount: 0,
        cacheLoading: false,
        pendingDocumentCount: 0,
        errorCacheCount: 0,
        initialCachingComplete: false,
        cachingApp: false,
        currentStaticResourceCount: 0,
        totalStaticResourceCount: 0,
        tileServerCacheCount: 0,
        tileServerMaxCacheCount: 0,
        errorCacheAttachedFile: [],
        errorOfflineChangesSyncDocs: [],
        maxCacheAttachedFileCount: 0,
        currentCacheAttachedFileCount: 0,
        enabled: false
    }
  };
}
