import { createStore, Store } from 'redux';
import rootReducer from '../reducers/index';

export function getStore(): Store {
  const globalWindow = <any>window;

  if (!globalWindow.formbirdStore) {
    // Create a Redux store holding the state of your app.
    // Store it on the window object so it is accessible from custom elements
    globalWindow.formbirdStore = createStore(
      rootReducer,
        globalWindow.__REDUX_DEVTOOLS_EXTENSION__ ? globalWindow.__REDUX_DEVTOOLS_EXTENSION__() :
        globalWindow.REDUX_DEVTOOLS_EXTENSION ? globalWindow.REDUX_DEVTOOLS_EXTENSION() : f => f
    );

  }

  return globalWindow.formbirdStore;
}
