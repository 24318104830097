import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DexieSharedWorkerService {
    private worker: any;
    private resolveMap: any = {};
    private rejectMap: any = {};
    private pageId: any;
    private pendingQueries: Map<string, Subject<void>> = new Map();

    initWorker() {
        console.log('****** this.worker = ', this.worker);
        if (this.worker) {
            return;
        }
        // Unique identifier for this page/tab
        this.pageId = Math.random().toString(36).substring(2, 15);
        console.log('**** pageId = ', this.pageId);



        if (window.Worker) {
            this.worker = new Worker('dexieWorker.js');
        } else {
            console.error('Does not support Web Worker and Shared Worker');
        }
        console.log('****** DONE initWorker, this.worker = ', this.worker);
        if (this.worker) {
            const messageHandler = (e: any) => {
                console.log(`***** messageHandler: dexie worker service receives message
                \t\t - current page url : ${window.location.href}
                \t\t - e = `, e);
                if (e?.data?.pageId === this.pageId && e?.data?.pageUrl === window.location.href) {
                    const { action, searchId, results, error, query } = e.data;
                    const queryKey = `${this.pageId}-${window.location.href}-${query}`;

                    if (action === 'result') {
                        console.log('***** messageHandler: RESOLVE, result = ', results);
                        if (this.resolveMap[searchId]) {
                            console.log('***** messageHandler: resolveMap, typeof: ' + typeof(this.resolveMap[searchId])
                             + ', searchId = ', searchId);
                            this.resolveMap[searchId](results);
                            delete this.resolveMap[searchId];
                            delete this.rejectMap[searchId];
                        } else {
                            // messageHandler called without resolver
                            // do nothing
                        }

                        // Check for pending queries and resolve them
                        if (this.pendingQueries.has(queryKey)) {
                            const pendingPromise: any = this.pendingQueries.get(queryKey);
                            if (pendingPromise && typeof pendingPromise.resolve == 'function') {
                                console.log('***** messageHandler: pendingPromise');
                                pendingPromise.resolve(results);
                            }
                            this.pendingQueries.delete(queryKey);
                        }
                    } else if (action === 'error') {
                        console.log('***** messageHandler: REJECT, error = ', error);
                        if (this.rejectMap[searchId]) {
                            console.log('***** messageHandler: rejectMap');
                            this.rejectMap[searchId](new Error(error));
                            delete this.resolveMap[searchId];
                            delete this.rejectMap[searchId];
                        } else {
                            // messageHandler called without rejector
                            // throw the error
                            throw new Error(error);
                        }

                        // Check for pending queries and reject them
                        if (this.pendingQueries.has(queryKey)) {
                            const pendingPromise: any = this.pendingQueries.get(queryKey);
                            if (pendingPromise && typeof pendingPromise.reject == 'function') {
                                console.log('***** messageHandler: pendingPromise');
                                pendingPromise.reject(new Error(error));
                            }
                            this.pendingQueries.delete(queryKey);
                        }
                    }

                }
                
            };

            if (this.worker?.port) {
                this.worker.port.onmessage = messageHandler;
            } else {
                this.worker.onmessage = messageHandler;
            }

            window.addEventListener('beforeunload', () => {
                console.log('****** event before unload ************');
                // Send message to Worker to close connection to IndexedDB
                const workerOptions = {
                    action: 'closeDatabase',
                    pageId: this.pageId,
                    pageUrl: window.location.href,
                    searchId: Date.now()
                };
                if (this.worker?.port) {
                    this.worker.port.postMessage(workerOptions);
                } else {
                    this.worker.postMessage(workerOptions);
                }
            });
        }
    }

    search(idbName: string, currentVersion: number, documentIndexes: any
        , searchId: any, options: any): Observable<any> {
        // console.log('**** search, options = ', options);
        const queryKey = `${this.pageId}-${window.location.href}-${JSON.stringify(options.filter)}`;
        console.log(`**** queryKey = ${queryKey}`);

        // If a query with the same key is already pending, return the existing promise
        if (this.pendingQueries.has(queryKey)) {
            this.pendingQueries.get(queryKey).next();
            this.pendingQueries.delete(queryKey);
            console.log('*** Previous search task cancelled for queryKey:', queryKey);
        }

        const cancelSubject = new Subject<void>();
        this.pendingQueries.set(queryKey, cancelSubject);

        const searchObservable = new Observable<any>(observer => {
            try {

                console.log('***** dexie worker service calls search...., this.worker = ' + this.worker);

                this.resolveMap[searchId] = (result: any) => {
                    observer.next(result);
                    observer.complete();
                    this.pendingQueries.delete(queryKey);
                };
                this.rejectMap[searchId] = (error: any) => {
                    observer.error(error);
                    this.pendingQueries.delete(queryKey);
                };
                
                const transferableOptions: any = {};

                Object.entries(options).forEach(([key, value]) => {
                    if (
                    !(value instanceof Error) &&
                    !(value instanceof Function) &&
                    !(value instanceof Node) &&
                    !(value instanceof Window) &&
                    !(typeof value === 'function')
                    ) {
                    transferableOptions[key] = value;
                    }
                });
                
                const defaultAddons = [
                    {
                        "fileName": "/vendor/offline/dexie-elasticsearch-addon/dexie-elasticsearch-addon.js",
                        "exportName": "ElasticIndexedDBAddon"
                    }
                ];

                const clientConfig = globalThis.ftClientConfig;
                const dexieAddons = clientConfig.offline?.dexieAddons || defaultAddons;

                const workerOptions = {
                    action: 'search',
                    pageId: this.pageId,
                    pageUrl: window.location.href,
                    searchId,
                    options: {
                        idbName,
                        currentVersion,
                        documentIndexes: documentIndexes,
                        query: transferableOptions
                    },
                    dexieAddons
                };
                if (this.worker?.port) {
                    this.worker.port.postMessage(workerOptions);
                } else {
                    this.worker.postMessage(workerOptions);
                }
                
            } catch (error) {
                console.error('Can not call web worker, error = ', error);
                observer.error(error);
                this.pendingQueries.delete(queryKey);
            }
        }).pipe(takeUntil(cancelSubject));


        return searchObservable;
    }
    
}