import { Injectable } from '@angular/core';

let StorageEventService$;

@Injectable({
  providedIn: 'root'
})
export class StorageEventService {

  private listeners = [];

  constructor(
  ) {
    StorageEventService$ = this;
  }

  addListener(callback) {
    this.listeners.push(callback);
  }

  private storage(event) {
    for (let i = 0; i < StorageEventService$.listeners.length; i++) {
      StorageEventService$.listeners[i](event);
    }
  };

  start() {
    window.addEventListener('storage', this.storage);
  }

  end() {
    this.listeners = [];
    window.removeEventListener('storage', this.storage);
  }

}
