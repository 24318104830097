import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, OnInit, AfterViewInit, ViewEncapsulation, Renderer2
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClientConstants,
  KeyValueStorageService, LoggedInUserService, SearchFilterService,
  SearchService, select, OfflineStatusService
} from '@formbird/services';
import { SearchResultsProcessor } from '@formbird/shared';
import { SharedConstants, User } from '@formbird/types';
import { Observable, EMPTY } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UtilSearch } from './UtilSearch';

const logger = console;
const searchRoute = '/search/';
const searchElSelector = 'ft-search-box ng-select > div > div > div.ng-input > input[type=text]';

@Component({
  selector: 'ft-search-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent implements OnInit, AfterViewInit  {

  @select(['userState', 'user']) user$: Observable<User>;

  user: User;

  results: any[] = [];
  selectedResult: any;
  totalResults: any = 0;
  filter: any;
  loading = false;
  textUpdated = false;
  holdSelected = null;

  typeahead = new EventEmitter<string>();
  errorMessage = null;

  // search scroll
  page = 0 as number;
  loaded = 0 as number;
  pasteTriggered = false;

  txt: any;
  enetered: boolean = false;

  options = {
    page: SharedConstants.DEFAULT_SEARCH_SELECT_PAGE,
    pageSize: SharedConstants.DEFAULT_SEARCH_SELECT_PAGE_SIZE,
    text: '',
    from: null,
    queryToBeFiltered: null,
    sourceFilter: {
      'include': [
        'documentId',
        'systemHeader.summaryName'
      ]
    },
    searchOptions: {}
  };

  constructor(
    private loggedInUserService: LoggedInUserService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private searchFilterService: SearchFilterService,
    private keyValueStorageService: KeyValueStorageService,
    private titleService: Title,
    private router: Router,
    private cd: ChangeDetectorRef,
    private offlineStatusService: OfflineStatusService,
    private renderer: Renderer2
  ) {
    const vm = this;

    this.getKeyStoreItem();

    vm.searchFilterService.searchFilter$.subscribe(searchFilter => {
      vm.cd.markForCheck();

      vm.filter = searchFilter.filter;
      vm.options.queryToBeFiltered = searchFilter.queryToBeFiltered;
    });

    vm.route.params.forEach(function(param: any) {
      vm.txt = param['txt'];
    });

    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    const vm = this;
    if (this.isSearchRoute()) {
      const text = decodeURI(this.router.url).replace(searchRoute, '');
      this.txt = text;
    }

    let errorMessage = '';

    vm.typeahead
      .pipe(
        debounceTime(SharedConstants.DEFAULT_SEARCH_DELAY),
        switchMap(term => {
          errorMessage = '';

          if (!term?.trim()) {
            this.sortResults();
            return EMPTY;
          }

          vm.page = 0;
          vm.options.page = 0;
          vm.options.from = null;
          vm.loading = true;
          vm.pasteTriggered = false;

          const offlineMode = vm.offlineStatusService.isCachingEnabled();
          if (offlineMode){
            vm.options.searchOptions = UtilSearch.dexieSearchFunction(term, 0);
          }

          if (term && term !== '') {
            vm.options.text = term;
            return vm.searchService.search(vm.options).then(data => {
              return Promise.resolve(data);
            }).catch((err) => {
              errorMessage = err.message;

              const data = null;
              return Promise.resolve(data);
            });
          } else {
            // when error occurs in searchService like blank text,
            // it doesn't work again
            const data = null;
            return Promise.resolve(data);
          }
        })
      ).subscribe((data: any) => {
          if (data) {
          SearchResultsProcessor.processSearchResults(data,
            function elementProcessing(hit) {
            },
            function complete(hits, totalHits) {
              vm.totalResults = totalHits;
              vm.loaded = hits.length;
              vm.results = hits;
              vm.sortResults();
              vm.loading = false;
            });
        } else {
          vm.totalResults = 0;
          vm.loaded = 0;
          vm.results = [];
          vm.cd.markForCheck();
          vm.loading = false;
        }

        vm.errorMessage = errorMessage;
      },
      (err) => {
        logger.log('error', err);
        vm.results = [];
        vm.loading = false;
        vm.errorMessage = err.message;
      });
  }

  sortResults() {
    const sortFunc = (a, b) => {
      if (!this.txt) return 0; 
      
      let ret = 0;
      const lowerTxt = this.txt.toLowerCase();
      const aIndex = a.systemHeader?.summaryName?.toLowerCase().indexOf(lowerTxt);
      const bIndex = b.systemHeader?.summaryName?.toLowerCase().indexOf(lowerTxt);

      if (aIndex > -1 && bIndex > -1) {
        if (aIndex <= bIndex) {
          ret = -1;
        } else {
          ret = 1;
        }
      } else if (aIndex > -1) {
        ret =  -1;
      } else if (bIndex > -1) {
        ret = 1;
      }

      return ret;
    };

    this.results = [...this.results.sort(sortFunc)];
    this.cd.markForCheck();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const searchElement = this.getSearchInputElement();
      if (!searchElement.value && !this.txt) {
        searchElement.placeholder = "Search";
      }
      
      if (this.isSearchRoute() && !searchElement.value) {
        searchElement.value = this.txt;
        searchElement.focus();
      }
    },100);
  }

  async getKeyStoreItem() {
    try {
      const filter = await this.keyValueStorageService.getItem(ClientConstants.FILTER);
      if (filter) { this.filter = filter; }
      const queryToBeFiltered = await this.keyValueStorageService.getItem(ClientConstants.FILTERED_QUERY);
      if (queryToBeFiltered) { this.options.queryToBeFiltered = queryToBeFiltered; }
    } catch (error) {
      console.error(error.message);
    }
  }

  navigateToSearch(searchText) {
    this.router.navigate(['/search', searchText]);
    this.titleService.setTitle(`${searchText} - Search`);

    (document.querySelector('.ng-input input') as HTMLElement).blur();
    this.setTextOnSearchField(searchText);
    this.selectedResult = null;
  }

  navigateToForm(documentId) {
    this.router.navigate(['/form', documentId]);
    (document.querySelector('.ng-input input') as HTMLElement).blur();
  }

  onEnter() {
    this.enetered = true;
    this.navigateToSearch(this.txt);
  }

  onFocusOut() {
    if (this.holdSelected) {
      this.selectedResult = this.holdSelected;
      this.holdSelected = null;
      this.setTextOnSearchField('');
    }

    if (!this.selectedResult 
      || this.textUpdated 
      || (this.txt && this.isSearchRoute())) {
      this.setTextOnSearchField(this.txt);
    }

    console.log(this.txt);
    console.log(this.selectedResult);
    console.log(this.getSearchInputElement().placeholder);
  }

  onFocusIn() {
    if (this.selectedResult) {
      this.setTextOnSearchField(this.txt);
      this.holdSelected = this.selectedResult;
      this.selectedResult = null;
    }
  }

  onKeyPress(event) {
    this.txt = event.target.value;
    this.errorMessage = '';
    this.textUpdated = true;
    this.selectedResult = null;
    this.holdSelected = null;
  }

  getSearchInputElement() { // always get the latest element reference
    return (<any>window).document.querySelector(searchElSelector);
  }

  setTextOnSearchField(text) {
    this.getSearchInputElement().value = text || '';
    
    if ((!text || text === '') && !this.selectedResult) {
      this.getSearchInputElement().placeholder = 'Search';  
    } else {
      this.getSearchInputElement().placeholder = '';    
    }
  }

  private isSearchRoute() {
    const url = decodeURI(this.router.url);
    return url.indexOf(searchRoute) !== -1;
  }

  onChange(selected) {
    this.textUpdated = false;
    this.setTextOnSearchField('');
    this.holdSelected = null;

    setTimeout(() => {
      if (!this.enetered && selected && selected.documentId) {
        this.navigateToForm(selected.documentId);
        this.getSearchInputElement().placeholder = '';
      } else if (this.selectedResult && this.txt) {
        this.navigateToSearch(this.txt);
      }
    }, 500);
  }

  search() {
    if (this.txt) {
      this.navigateToSearch(this.txt);
    }
  }

  removeFilter() {
    this.searchFilterService.removeFilter();
  }

  onPaste() {
    this.pasteTriggered = true;
  }

  onScroll({ end }) {
    if (end >= this.results.length && !this.loading) {
      this.onScrollToEnd();
    }
  }

  onScrollToEnd() {
    const vm = this;

    // stop querying when all results are loaded
    if (vm.loaded === vm.totalResults) {
      return;
    }

    vm.page++;

    vm.options.page = vm.page;
    vm.options.from = vm.page * SharedConstants.DEFAULT_SEARCH_SELECT_PAGE_SIZE;

    vm.cd.markForCheck();
    vm.loading = true;

    const offlineMode = vm.offlineStatusService.isCachingEnabled();
    if (offlineMode){
      vm.options.searchOptions = UtilSearch.dexieSearchFunction(vm.txt, vm.page);
    }

    vm.searchService.search(vm.options)
      .then(function(data) {
        if (data) {
          SearchResultsProcessor.processSearchResults(data,
            function elementProcessing(hit) {
            },
            function complete(hits) {
              vm.loaded = vm.loaded + hits.length;
              vm.results = [...vm.results, ...hits];
            });
        }

        vm.loading = false;
        vm.errorMessage = null;
      }, function(err) {
        logger.log('error' + err);
        vm.loading = false;
        vm.errorMessage = err.message;
      }).finally(() => {
        vm.cd.detectChanges();
      });
  }
}
