import { Injectable } from '@angular/core';
import { UtilSystemHeader } from '@formbird/shared';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class ClientSystemHeaderService {

  constructor(
    private documentService: DocumentService
  ) { }

  private getTemplate(templateId) {
    return this.documentService.getDocumentOnly(templateId);
  };

  updateSystemHeader(bean, template, originalBean) {

    const _self = this;

    return new Promise((resolve, reject) => {

      if (originalBean && originalBean.systemHeader) {

        if (!template) {

          if (!originalBean.systemHeader.templateId) {
            // no template to write system header with, so don't write the summary values
            resolve(bean);

          } else {

            _self.getTemplate(originalBean.systemHeader.templateId).then(

              function successFunc(template) {
                UtilSystemHeader.writeSystemHeaderSummary(bean, template, originalBean);
                resolve(bean);
              },

              function errorFunc(err) {
                resolve(bean);
              }
            );
          }

        } else {

          UtilSystemHeader.writeSystemHeaderSummary(bean, template, originalBean);
          resolve(bean);

        }
      } else {
        resolve(bean);
      }
    });

  };
}
