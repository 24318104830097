import { Component, OnInit } from '@angular/core';
import { VersionService } from '../modules/formbird-services';

@Component({
  selector: 'ft-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  async ngOnInit() {
    await VersionService.checkServerVersion();
  }

}
