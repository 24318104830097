import { DataService } from '../data/data.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { CurrentDocumentService } from './current-document.service';
import { DocumentService } from './document.service';

@Injectable()
export class TemplateService implements OnInit, OnDestroy {

  documentData: any;
  sub: any;

  constructor(
    private currentDocumentService: CurrentDocumentService,
    private documentService: DocumentService,
    private dataService: DataService
  ) {
    this.sub = this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async loadTemplate(doc, unsavedTemplates) {
    let tpl;

    let templateId = this.documentData.overrideTemplateId;

    if (templateId && this.documentData.document.documentId === doc.documentId) {

      tpl = this.documentService.getExistingDocument(templateId);
    
    } else {
    
      templateId = doc.systemHeader.templateId;

      const filteredTemplates = unsavedTemplates?.filter(template => template.documentId === templateId);
      
      if (filteredTemplates.length) {
        tpl = filteredTemplates[0];
      }
    
    }

    if (tpl) {
      return Promise.resolve(tpl);
    }

    // template not loaded. This can happen in circumstances like editing in the template editor.
    // Get the template from the server
    return await this.dataService.getDocument(templateId);
  }
}
