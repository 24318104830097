import { NgModule } from '@angular/core';
import { FormColorDirective } from '../../directives/form-color.directive';
import { BackgroundImageDirective } from '../../directives/background.directive';
import { AuditService } from '@services/audit/audit.service';

@NgModule({
    imports: [],
    declarations: [
        FormColorDirective,
        BackgroundImageDirective,
    ],
    exports: [
        FormColorDirective,
        BackgroundImageDirective,
    ],
    providers: [
        AuditService
    ]
})
export class FormbirdCommonModule {
    constructor() { }

    ngDoBootstrap() { }
}