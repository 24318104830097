import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { InitialisationService } from '@services/initialisation.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ClientConstants, BroadcastService, LocalStorageService, LocalStorageKeys, VersionService } from '@formbird/services';

let isInitialized = false;
let isSaving = false;
let currentUrl;

@Injectable({
  providedIn: 'root',
})
export class CanActivateGuard implements CanActivate {
  constructor(public initialisationService: InitialisationService,
              public broadcastService: BroadcastService,
              private router: Router, private ngZone: NgZone,
              private localStorageService: LocalStorageService,
              private authenticationService: AuthenticationService
              ) {
    this.broadcastService.on(ClientConstants.DOCUMENT_SAVE_STATUS).subscribe((value: any) => {
      isSaving = value?.isSaving;
      currentUrl = value?.url;
    });
  }
  canActivate(
      route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {
      if (this.authenticationService.isLoggedOutOffline()) {
        this.authenticationService.showLogin();
        return false;
      }

      this.initBeforeUnloadEvent();
      this.checkServiceWorkerActivated();
      return this.initializeServices();
  }

  getBaseUrl() {
    return (<any>window).document.getElementsByTagName("base")[0].href.split('/')[3] || "";
  }

  checkServiceWorkerActivated() {
    const baseUrl = this.getBaseUrl();
    const appVersion = this.localStorageService.getItem(LocalStorageKeys.APP_VERSION);
    const key = baseUrl + LocalStorageKeys.SERVICE_WORKER_ACTIVATED;

    const serviceWorkerActivated = JSON.parse(localStorage.getItem(key) || '{}');
    const isActivated = serviceWorkerActivated[appVersion];
    if (isActivated === false) {
      if (VersionService.needsUpdate(appVersion)) {
        localStorage.setItem(key, `{ "${appVersion}": true }`);
        window.location.reload();
      }
    } else if (!VersionService.hasBreakingApiChanges() && isActivated) {
        localStorage.setItem(key, `{ "${appVersion}": false }`);
    }
  }

  async initializeServices(): Promise < boolean > {
    try {
      const curNavigation = this.router.getCurrentNavigation();
      if (curNavigation?.trigger === "popstate" && curNavigation?.previousNavigation?.trigger === "popstate"){
        window.location.reload();
      } else if (isSaving) {
        const isCompleted = await this.waitToCompleteSaving();
        if (isCompleted && currentUrl) {
          this.ngZone.run(() => this.router.navigateByUrl(currentUrl));
        } else if (!isCompleted){
          window.location.reload();
        } else {
          return false;
        }
      } else if (isInitialized){
        return true;
      }else{
        this.initialisationService.setupAppInfo();
        this.initialisationService.setupServices();
        await this.initialisationService.initialise();
        await this.initialisationService.postLoginInitialise();
        isInitialized = true;
        return true;
      }
    } catch (error) {
      if (error.status === 502 || error.status === 504) {
        return true;
      }
    }
  }

  private async waitToCompleteSaving(): Promise < boolean > {
    return new Promise(async (resolve, reject) => {
      const config = (<any>window).ftClientConfig;
      let timeoutCount = 0;
      const inner = async () => {
        if (!isSaving) {
          resolve(true);
        } else {
          if ( config?.saveCompleteTimeout && (timeoutCount * 500) > config?.saveCompleteTimeout){
            resolve(false);
          } else {
            timeoutCount++;
            return setTimeout(inner,500);
          }
        }
      };

      await inner();
    })
  }

  private initBeforeUnloadEvent() {
    async function unloadHandler(event) {
      if (isSaving){
        event.preventDefault();
        return event.returnValue = `Are you sure you want to leave?`;
      }
    }
    window.addEventListener('beforeunload', async function (e) {
      return await unloadHandler(e);
    });

    window.addEventListener("visibilitychange", async function(e)
    {
      if (document.visibilityState == 'hidden') {
        return await unloadHandler(e);
      }
    });
  }
}
