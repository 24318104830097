import { Injectable } from '@angular/core';
import { FormComponent, FormDocument, FormTemplate, ComponentDefinition } from '@formbird/types';

import * as lodashCloneDeep from 'lodash.clonedeep';

export declare const angular: any;
@Injectable({
  providedIn: 'root'
})
export class AngularJSWrapperService {

  constructor() {
  }

  /** register a custom element wrapper that can be used to load an AngularJS component in the Angular
   *  component loader
   */
  registerAngularJSWrapperElement(componentName: string) {
    const displayWaterMark = (<any>window).ftClientConfig.displayAngularJSWatermark;

    const elementClass = class extends HTMLElement implements FormComponent {
      document: FormDocument;
      template: FormTemplate;
      componentDefinition: ComponentDefinition;
      fieldValue: any;
      fieldName: string;
      formParameters: any;
      key: number;
      responsiveLayouts: string;
      componentName: string;

      constructor() {
        // Always call super first in constructor
        super();
      }
      connectedCallback() {
        if (this.componentDefinition && this.template && this.document) {
          this.componentName = this.componentDefinition.componentName;

          // use an element id for the component that includes the documentId so that it will be unique within
          // the form even if there is a child document with a field with the same name
          const elementId = this.document.documentId + '-' + this.key;

          this.innerHTML = `<div id="${elementId}">
                              <div ng-controller="WrapperCtrl">
                                <${this.componentName} doc='doc'
                                field-name='fieldName'
                                field-value='fieldValue'
                                tpl='tpl'
                                key='key'
                                tpl-item='tplItem'
                                is-new='isNew'
                                form-parameters='formParameters'
                                account='user.filteredAccount'
                                responsive-layouts='responsiveLayouts'></${this.componentName}>
                              </div>
                            </div>`;

          const self: any = this;
          // Grab a reference to the part of the DOM you want angular to control
          const aElement = angular.element('#' + elementId);
          // When that DOM element is ready...
          aElement.ready(function () {
            // and the app is done bootstrapping, the run block will fire...
            angular.module('ftApp').controller('WrapperCtrl', ['$scope', 'observeOnScope',
              function ($scope, observeOnScope) {


              $scope.doc = lodashCloneDeep(self.document);
              $scope.tpl = self.template;
              $scope.key = self.key;
              $scope.tplItem = self.componentDefinition;
              $scope.account = self.account;
              $scope.fieldName = self.fieldName;
              $scope.fieldValue = self.fieldValue;
              $scope.isNew = self.isNew;
              $scope.formParameters = self.formParameters;

              $scope.displayWaterMark = displayWaterMark;

              // watch for changes in document
              function monitorFieldChange() {
                // do not watch changes to fields with no fieldName because the value will not be set in the document
                if (self.fieldName) {
                  observeOnScope($scope, watch, true).subscribe(onChange);
                  function watch() {
                    return $scope.doc[$scope.fieldName];
                  }
                  function onChange(change) {
                    if (change.newValue && change.newValue !== change.oldValue) {
                      (<any>window).FormbirdServiceInjector.ChangedDocumentService.valueChanged(self, change.newValue);
                    }
                  }
                }
              }

              monitorFieldChange();
            }]);

            // run the angularjs app
            angular.module('ftApp').run(function () {});

            // Kick off the bootstrap
            angular.bootstrap(aElement, ['ftApp']);
          });
        }
      }
    };

    // Define the new element
    if (componentName && !customElements.get(componentName)) {
      customElements.define(componentName, elementClass);
    }
  }
}
