
export class UtilWebWorker {

   public static initWebWorker(file, options?) {
     return new Promise(async (resolve, reject) => {
       const webWorker = new Worker(file, options);
       await new Promise(resolve => setTimeout(resolve,3000));
       resolve(webWorker)
     });
  }


}
