import {
  ChangeDetectorRef, Component, ElementRef, Injector,
  OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ImageLinkService } from '@services/image-link.service';
import { ImageGalleryService } from '@services/image-gallery.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

import {
  BroadcastService,
  ClientConstants,
  ConfigService,
  CurrentDocumentService,
  ControlStateConstants,
  ControlStateSaveService,
  ModifiedCSSService,
  NavigationService,
  DefaultStateService,
  LoggedInUserService,
  FileProviderService
} from '@formbird/services';
import { SharedUrlRoutes } from '@formbird/shared';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

const { serverRoutes } = SharedUrlRoutes;

const SIDEBAR_OPEN_MIN_WIDTH = 1300;

@Component({
  selector: 'ft-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TopBarComponent implements OnInit, OnDestroy {
  @ViewChild('snav') snav: MatSidenav;

  private subscriptions: Subscription = new Subscription();

  initialized = false;

  hideDelete: boolean;
  alert: any;
  displayUIView: boolean;

  hideTopBar = false;
  fullWindow = false;
  noNavBar = false;
  hideGeneralSearch = true;

  dynamicCSSObj: any = {};
  templateImages: any = {};
  sideBarState: any = {};

  docInfo: any;
  weedLink: any;

  responsiveLayouts: any;
  docHeaderWidth: any;

  homeButtonImage;
  breakpointState;
  fileResources;
  fileResourcesCommandBar;

  defaultCommandBarCSS = {
    background: 'white'
  }

  private _mobileQueryListener: () => void;
  public mobileQuery: MediaQueryList;
  private galleryId = 'formbird-top-bar-gallery';

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private currentDocumentService: CurrentDocumentService,
    private modifiedCSSService: ModifiedCSSService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private defaultStateService: DefaultStateService,
    private imageLinkService: ImageLinkService,
    private imageGalleryService: ImageGalleryService,
    injector: Injector,
    private broadcastService: BroadcastService,
    private controlStateSaveService: ControlStateSaveService,
    private loggedInUserService: LoggedInUserService,
    private breakpointObserver: BreakpointObserver,
    private fileProviderService: FileProviderService
  ) {

    this.hideDelete = true;
    this.alert = null;
    this.displayUIView = true;

    this.docHeaderWidth = {};

    this.docInfo = {
      imageLink: this.imageLinkService.imageLink
    };

    const media = injector.get(MediaMatcher);
    const changeDetectorRef = injector.get(ChangeDetectorRef);

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
  }

  private setCommandBarCSS(commandBarCSS?) {
    this.dynamicCSSObj.commandBarCSS = commandBarCSS || this.configService.clientConfig().commandBarCSS || this.defaultCommandBarCSS;

    if (!this.dynamicCSSObj.commandBarCSS.background && !this.dynamicCSSObj.commandBarCSS['background-color']) {
      this.dynamicCSSObj.commandBarCSS.background = this.defaultCommandBarCSS.background;
    }
  }

  async ngOnInit() {


    const breakPointSub = this.breakpointObserver
      .observe([
        Breakpoints.XSmall
      ])
      .subscribe((state: BreakpointState) => {
        this.breakpointState = state;
        this.setHomeButtonImage();
      });
    this.subscriptions.add(breakPointSub);

    this.dynamicCSSObj.commandBarCSS = this.configService.clientConfig().commandBarCSS || this.defaultCommandBarCSS;

    this.setCommandBarCSS();

    const dataSub = this.route.data.subscribe(data => {
      this.noNavBar = data.noNavBar;
      if (this.loggedInUserService.getLoggedInUser()) {
        this.initialized = true;
      } else {
        this.loggedInUserService.loadLoggedUser().then(user => {
          this.initialized = true;
        }).catch(error => {
          this.initialized = false;
        });
      }
    });

    this.broadcastService.on(ClientConstants.DOCUMENT_LOADED).subscribe((data: any) => {
      if (data && data.documentData && data.documentData.template) {
        const template = data.documentData.template;
        const currentMainTemplate = this.currentDocumentService.getTemplate();

        if (template.documentId === currentMainTemplate?.documentId) {
          this.hideTopBar = template.hideTopBar === true;
          this.fullWindow = template.fullWindow === true;
          this.noNavBar = template.noNavBar === true;
          this.hideGeneralSearch = template.navigationBar?.hideGeneralSearch === true;
        }

        const storedCommandBarCSS = this.modifiedCSSService.getStoredCommandBarCSS(template.documentId);
        this.setCommandBarCSS(storedCommandBarCSS);
      }

      this.initialized = true;
    });

    this.hideGeneralSearch = this.configService.clientConfig().navigationBar?.hideGeneralSearch === true;

    const imagesSub = this.navigationService.images$.subscribe(images => {
      this.templateImages = images;
      
      if (images.homeButtonImage) {
        this.homeButtonImage = images.homeButtonImage
      }
      this.setHomeButtonImage();
    });

    this.subscriptions.add(dataSub);
    this.subscriptions.add(imagesSub);

    this.weedLink = serverRoutes.loadFile;

    const MAX_WIDTH = 51;
    const WIDTH_OFFSET = 9;
    const DOCHEADER_INITIAL_WIDTH = 22;
    let docheaderWidth = DOCHEADER_INITIAL_WIDTH;

    this.imageLinkService.imageLink$.subscribe((imageLink) => {
        this.docInfo.imageLink = imageLink;

        this.imageGalleryService.addImageToGallery(this.galleryId, this.weedLink + '/' + imageLink.imageFileNo96);

        if (this.docInfo.imageLink.imageFileNo96) {
          docheaderWidth = MAX_WIDTH + WIDTH_OFFSET;
        } else {
          docheaderWidth = 0;
        }

        this.docHeaderWidth = {
          'margin-right': docheaderWidth
        };
        this.getCommandBarImage();
    });

    const deviceWidth = (<any>window).innerWidth;
    if (deviceWidth > SIDEBAR_OPEN_MIN_WIDTH) {
      this.sideBarState = await this.controlStateSaveService.getControlState(ControlStateConstants.CONTROL_STATE_SIDEBAR_TOGGLE);
    }

    if (!this.sideBarState) {
      this.sideBarState = {
        leftSideBarOpen: false
      };
    }
  }

  toggleSideNav() {
    this.sideBarState.leftSideBarOpen = !this.sideBarState.leftSideBarOpen;
    this.controlStateSaveService.setControlState(ControlStateConstants.CONTROL_STATE_SIDEBAR_TOGGLE, this.sideBarState);
    this.snav.toggle();
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  changeState() {
    if (this.defaultStateService.isPathAccountDefault()) {
      document.getElementById('ftForm').scrollIntoView(true);
    }
    this.defaultStateService.changeToDefaultState();
  }

  onMenuSelect() {
    const deviceWidth = (<any>window).innerWidth;
    if (deviceWidth <= SIDEBAR_OPEN_MIN_WIDTH) {
      this.toggleSideNav();
    }
  }

  viewImageFile() {
    this.imageGalleryService.open(this.galleryId, this.weedLink + '/' + this.docInfo.imageLink.imageFileNo96);
  }

  setHomeButtonImage() {
    if (this.breakpointState) {
      if (this.breakpointState.matches) {
        this.homeButtonImage = this.templateImages?.homeButtonImages?.breakpoints?.xs
          || this.configService.clientConfig().homeButtonImages?.breakpoints?.xs
          || this.homeButtonImage;
      } else {
        this.homeButtonImage = this.templateImages?.homeButtonImages?.breakpoints?.lg
          || this.configService.clientConfig().homeButtonImages?.breakpoints?.lg
          || this.homeButtonImage;
      }
    }
    this.getHomeButtonImage();
  }

  getHomeButtonImage() {
    if (!this.homeButtonImage) {
      return
    }
    const file =  {fileNo: this.homeButtonImage};
    this.fileProviderService.getFileResources(file).then( (resources: any) => {
        this.fileResources = resources;
    });
  }
  getCommandBarImage() {
    if (this.docInfo?.imageLink?.imageFileNo96) {
      const file = { fileNo: this.docInfo?.imageLink?.imageFileNo96 };
      if (!file.fileNo) {
        return
      }
      this.fileProviderService.getFileResources(file).then((resources: any) => {
        this.fileResourcesCommandBar = resources;
      });
    }
  }
}
