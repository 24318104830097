import { Injectable } from '@angular/core';
import { DocumentIdValidator, UtilArray, UtilConflict, UtilDocument, UtilHttpStatus, UtilType } from '@formbird/shared';
import { UtilSequenceProcess } from '@formbird/shared';
import { SharedConstants } from '@formbird/types';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { formSetDocument } from '../../redux/actions';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { ClientAccessService } from '../access/client-access.service';
import { BroadcastService } from '../broadcast/broadcast.service';
import { ConfigService } from '../config/config.service';
import { DataService } from '../data/data.service';
import { NotificationService } from '../notification/notification.service';
import { ValidationService } from '../validation/validation.service';
import { ClientConstants } from './../../constants/ClientConstants';
import { ChangedDocumentService } from './changed-document.service';
import { ClientSystemHeaderService } from './client-system-header.service';
import { CurrentDocumentService } from './current-document.service';
import { DocumentUpdateService } from './document-update.service';
import { DocumentService } from './document.service';
import { FinaliseValueFieldService } from './finalise-value-field.service';
import { MandatoryFieldService } from './mandatory-field.service';
import { ModifiedFieldService } from './modified-field.service';
import { UnsavedDocumentService } from './unsaved-document.service';
import { PreProcessorFieldService } from '../preprocessor/pre-processor-field.service';
import { KeyValueStorageService } from '../key-value-storage/key-value-storage.service';
import { PendingOperationService } from '../pending-operations.service';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DocumentSaveService {
  private savingEventBus: Subject<any>;
  private savedEventBus: Subject<any>;

  private savedDocs;

  documentData: any = {};

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private documentService: DocumentService,
    private unsavedDocumentService: UnsavedDocumentService,
    private currentDocumentService: CurrentDocumentService,
    private mandatoryFieldService: MandatoryFieldService,
    private modifiedFieldService: ModifiedFieldService,
    private validationService: ValidationService,
    private clientSystemHeaderService: ClientSystemHeaderService,
    private documentUpdateService: DocumentUpdateService,
    private configService: ConfigService,
    private broadcastService: BroadcastService,
    private clientAccessService: ClientAccessService,
    private changedDocumentService: ChangedDocumentService,
    private finaliseValueService: FinaliseValueFieldService,
    private preProcessorFieldService: PreProcessorFieldService,
    private appStore: AppStore<IApplicationState>,
    private keyValueStorageService: KeyValueStorageService,
    private pendingOperationService: PendingOperationService,
    private loggedInUserService: LoggedInUserService,
    private offlineStatusSerivce: OfflineStatusService
  ) {
    this.savingEventBus = new Subject<any>();
    this.savedEventBus = new Subject<any>();
    this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });
  }

  private loadOverrideTemplate(document) {
    if (this.documentData.overrideTemplateId && this.documentData.document?.documentId === document.documentId) {
      return this.documentData.overrideTemplateId;
    }
  }

  private loadTemplate(document, unsavedDocumentListId) {

    return this.unsavedDocumentService.getUnsavedTemplate(document.documentId, unsavedDocumentListId);
  }

  private async doPreSaveData(document, unsavedDocumentListId, preSaveFunc) {

    const docBean = cloneDeep(document);

    const template = this.loadTemplate(docBean, unsavedDocumentListId);

    await this.preProcessorFieldService.preProcess(template, docBean);

    this.finaliseValueService.finaliseFieldValues(docBean, template);

    if (!docBean.systemHeader) {
      docBean.systemHeader = {};
    }

    if (!docBean.systemHeader.systemType) {
      docBean.systemHeader.systemType = SharedConstants.SYSTEM_TYPE_DOCUMENT;
    }

    if (UtilDocument.isNew(docBean) && !docBean.systemHeader.templateId) {
      docBean.systemHeader.templateId = template.documentId;
    }

    this.clientAccessService.writeKeysToDocument(docBean, template);

    const overrideTemplateId = this.loadOverrideTemplate(document);
    if (overrideTemplateId) {
      docBean.systemHeader.createdWith = overrideTemplateId;
    } else {
      docBean.systemHeader.createdWith = document.systemHeader.templateId;
    }

    const docContext = {} as any;
    docContext.template = template;
    docContext.templateId = document.systemHeader.templateId;
    docContext.unsavedDocumentListId = unsavedDocumentListId;

    return preSaveFunc(docBean, docContext);
  }

  private async doPostSaveData(document, unsavedDocumentListId) {
    const docBean = cloneDeep(document);
    const template = this.loadTemplate(docBean, unsavedDocumentListId);

     if (document.systemHeader.systemType === SharedConstants.SYSTEM_TYPE_ACCOUNT) {
        await this.loggedInUserService.setLoggedUser();
    }

     if (!template || !docBean) {
      return;
    }

    const docContext = {} as any;
    docContext.template = template;
    docContext.templateId = document.systemHeader.templateId;
    docContext.unsavedDocumentListId = unsavedDocumentListId;
    return this.postSaveClientValidation(docBean, docContext);
  }

  private postSaveClientValidation(docBean, docContext) {
    return new Promise((resolve, reject) => {
      this.validationService.onPostSaveClientDocument(docBean, docContext, (error, result) => {
        if (error) {
          reject(error);
        } else {
          this.validationService.broadcastValidationEvent();
          const latestDocument = this.documentService.getLoadedDocument(docBean.documentId);
          resolve(latestDocument);
        }
      });
    });
  }

  private shouldApplyFlags(documentListId) {
    // Should apply flag on the document list that was attached to command bar
    return documentListId === this.currentDocumentService.getMainDocumentListId();
  }

  private resetCleanStateDocument(doc, unsavedDocumentListId?, shouldResetStoreChanges?) {

    this.documentService.resetDisableSaveFieldToDoc(doc);

    this.documentService.resetOriginalDocument(doc);

    if (this.shouldApplyFlags(unsavedDocumentListId)) {
      this.currentDocumentService.resetUndoRedo();
    }

    if (shouldResetStoreChanges !== false) {
      this.changedDocumentService.resetChangeDocument();
    }
  }

  private performPostSaveEach(doc, unsavedDocumentListId?) {
    this.doPostSaveData(doc, unsavedDocumentListId);

    this.notificationService.success('Saved successfully');

    if (this.modifiedFieldService.hasSavingChanged(doc.documentId)) {


      this.modifiedFieldService.assignChangedDocumentFields(doc);
    
      const saveChangedDoc = this.modifiedFieldService.extractSavingChangedAsDocument(doc.documentId);

      console.log('Applying the changes were made on the document while pushing it into server: ', saveChangedDoc);
    
      this.documentUpdateService.applyDocumentChangesSimple(doc, saveChangedDoc);
    
      const shouldResetStoreChanges = Object.keys(saveChangedDoc).length === 0;
      this.resetCleanStateDocument(doc, unsavedDocumentListId, shouldResetStoreChanges);
    
    } else {
      this.modifiedFieldService.removeModifiedDocumentFields(doc.documentId);
      this.resetCleanStateDocument(doc, unsavedDocumentListId);
    }

    const key = `FB-CORE-WIP-${doc.documentId}`;
    this.keyValueStorageService.removeItem(key);

    // Put doc into an array due to avoid changing the handler in custom component
    this.broadcastService.broadcast(ClientConstants.DOCUMENT_UPDATED, [doc]);
  }

  private async processSaveEach(modifiedDocument, unsavedDocumentListId) {
    try {
      const result = DocumentIdValidator.validateDocumentId(
        modifiedDocument.documentId,
        this.configService.clientConfig().documentIdRules
      );
  
      if (!result.valid) {
        throw new Error(result.reason);
      }
  
      const newModifiedDoc: any = await this.reloadDocumentAndApplyUpdates(modifiedDocument, unsavedDocumentListId);

      const execPreSave = async (doc) => {
        
        const performPreSaveValidation = (docBean, docContext, runAsOffline?) => {
          return new Promise((resolve, reject) => {
            this.appStore.dispatch(formSetDocument(docBean));

            const validationCallback = (error, result) => {
              if (error) {
                reject(error);
              } else {
                this.validationService.broadcastValidationEvent();
                const latestDocument = this.documentService.getLoadedDocument(docBean.documentId);
                resolve(latestDocument);
              }
            };

            if (runAsOffline) {
             this.validationService.onPreSaveOfflineDocument(docBean, docContext, validationCallback);
            } else {
              this.validationService.onPreSaveDocument(docBean, docContext, validationCallback);
            }
          });
        }

        try {
          let processedDoc = await this.doPreSaveData(doc, unsavedDocumentListId, (docBean, docContext) => {
            return performPreSaveValidation(docBean, docContext);
          });

          // run PreSaveOffline
          if (!this.offlineStatusSerivce.isConnected() && this.offlineStatusSerivce.isOfflineMode()) {
            processedDoc = await this.doPreSaveData(processedDoc, unsavedDocumentListId, (docBean, docContext) => {
              return performPreSaveValidation(docBean, docContext, true);
            });
          }

          if (processedDoc) {
            const savedDoc = await this.doSaveEach(processedDoc, unsavedDocumentListId);
            this.performPostSaveEach(savedDoc, unsavedDocumentListId);
            return savedDoc;
          } else {
            const msg = 'There is an error in PreSave.';
            logger.error(msg);
            throw new Error(msg);
          }
        } catch (error) {
          logger.info('Saving data error...' + JSON.stringify(error));
          throw error;
        }
      };
  
      if (newModifiedDoc.systemHeader?.deleted === true) {
        const docContext = {
          documentData: this.documentData,
          template: this.documentData.template,
          templateId: this.documentData.template.documentId,
          formParameters: {
            overrideTemplateId: this.documentData.overrideTemplateId,
            selectedDocumentId: newModifiedDoc.documentId,
            hierarchyInfo: this.documentData.hierarchyInfo,
            isMainDoc: this.documentData.isMainDoc
          }
        };
  
        return new Promise((resolve, reject) => {
          this.validationService.preDeleteClient(newModifiedDoc, docContext, async (error, result) => {
            if (error) {
              reject(error);
            } else {
              try {
                const savedDoc = await execPreSave(newModifiedDoc);
                resolve(savedDoc);
              } catch (err) {
                reject(err);
              }
            }
          });
        });
      } else {
        return await execPreSave(newModifiedDoc);
      }
    } catch (err) {
      logger.info('Saving data error...' + JSON.stringify(err));
      throw err;
    }
  }
  

  private async doSaveEach(docBean, unsavedDocumentListId) {
    if (docBean) {
      logger.info('Saving document: ' + docBean.documentId);

      const includeDeleted = true;
      const docExists = await this.dataService.documentExists(docBean.documentId, includeDeleted);

      const tpl = this.documentService.getTemplateFromDoc(docBean);
      let originalDoc;

      if (docExists) {
        originalDoc = this.documentService.getExistingDocument(docBean.documentId);

        if (!originalDoc && this.configService.clientConfig().useSaveConflictResolution) {
          originalDoc = this.documentService.getExistingDocument(docBean.systemHeader.previousVersionId);

        } else if (!originalDoc) {
          originalDoc = this.documentService.getExistingDocument(docBean.systemHeader.versionId);
        }
      }

      await this.clientSystemHeaderService.updateSystemHeader(docBean, tpl, originalDoc || docBean);

      this.modifiedFieldService.setPushingData(true, docBean.documentId);

      const isCreationMode = UtilDocument.isNew(docBean) || !docExists; // insert as new if not found
      if (isCreationMode) {
        const savedDoc = await this.dataService.insert(docBean);
        this.savedDocs.push(savedDoc);
        logger.info('Document inserted: ' + savedDoc.documentId);

        this.modifiedFieldService.resetPushingData(docBean.documentId);

        return savedDoc;
      } else {
        if (!originalDoc) { // docExists but no access
          throw new Error('Unable to update! No access to document: ' + docBean.documentId);
        } else {
          const overrideTemplateId = this.loadOverrideTemplate(docBean);

          const options = {
            overrideTemplateId: overrideTemplateId,
            templateId: originalDoc ? originalDoc.systemHeader.templateId : docBean.systemHeader.templateId,
            makeCurrentVersion: this.unsavedDocumentService.getDocumentOptionKey(unsavedDocumentListId,
              docBean.documentId, SharedConstants.MAKE_CURRENT_VERSION_KEY),
            importPreviousVersion: this.unsavedDocumentService.getDocumentOptionKey(unsavedDocumentListId,
              docBean.documentId, SharedConstants.IMPORT_PREVIOUS_VERSION)
          };

          const savedDoc: any = await this.dataService.jsonPatchUpdate(originalDoc, docBean, options);
          this.savedDocs.push(savedDoc);
          logger.info('Document updated: ' + savedDoc.documentId);

          this.modifiedFieldService.resetPushingData(docBean.documentId);

          return savedDoc;
        }
      }
    }
  }

  setFlags(dirtyFlag, spinnerFlag, saveFlag, unsavedDocumentListId?) {

    if (spinnerFlag !== undefined) {
      this.modifiedFieldService.setShowSpinner(spinnerFlag, unsavedDocumentListId);
    }

    if (saveFlag !== undefined) {
      this.modifiedFieldService.setSaveFlag(saveFlag, unsavedDocumentListId);
    }

    if (saveFlag) {
      // Saving means the update on form is allowed,
      // so set updateAllowed flag to true in order to show the dirty again in case saving errors
      this.modifiedFieldService.setUpdateAllowed(true);
    }

    if (dirtyFlag !== undefined) {
      this.modifiedFieldService.setDirty(dirtyFlag, null, unsavedDocumentListId);
    }
  }

  private doSave(unsavedDocumentListId) {

    const _self = this;

    return new Promise((resolve, reject) => {

      try {
        // check mandatory fields in all documents getUnsavedDocuments will throw an exception
        // if the unsavedDocumentListId is unknown
        const unsavedDocumentDatas = _self.unsavedDocumentService.getUnsavedDocumentDatas(unsavedDocumentListId);

        _self.mandatoryFieldService.isMandatoryAnswered(unsavedDocumentDatas).then(
          function successFunc() {

            // 11003: only save modified documents. Don't save the parent if only a child has changed
            const modifiedDocuments = _self.modifiedFieldService.getModifiedDocuments(unsavedDocumentListId);

            if (modifiedDocuments?.length) {

              _self.savedDocs = [];

              // save documents sequentially so that all document saves aren't sent to the server at the
              // same time. See the notes of Mantis 11213 for further details
              UtilSequenceProcess.processList(modifiedDocuments, (modifiedDocument) => {
                return _self.processSaveEach(modifiedDocument, unsavedDocumentListId);
              }).then(
                function allPromiseSuccessFunc() {

                  if (_self.savedDocs && _self.savedDocs.length > 0) {
                    resolve(_self.savedDocs);

                  } else {
                    reject(new Error('No results returned from save'));
                  }
                },
                function errorFunc(err) {
                  logger.info('Saving data got errors...' + JSON.stringify(err));

                  reject(err);
                });

            } else {
              logger.info('Exit saving because there is no modified document.');
              // resolve with no document because there's no save
              resolve(null);
            }

          }, function errorFunc(err) {
            reject(err);
          });

      } catch (e) {
        // pass through the error with no additional messages so that the error is
        // nicely formatted for display
        reject(new Error(e));
      }

    });

  }

  private reloadDocumentAndApplyUpdates(modifiedDoc, unsavedDocumentListId) {

    const _self = this;

    return new Promise(async (resolve, reject) => {

      var isMakingCurVer = _self.unsavedDocumentService.getDocumentOptionKey(
        unsavedDocumentListId, modifiedDoc.documentId, SharedConstants.MAKE_CURRENT_VERSION_KEY);

      if (UtilDocument.isNew(modifiedDoc)) {
        
        //should not perform checking and updating if caching is not enabled
        if (this.offlineStatusSerivce.isCachingEnabled() === false) {
          await _self.documentUpdateService.applyDocumentCreated(modifiedDoc);
        }
        
        resolve(modifiedDoc);

      } else if (UtilDocument.isPreviousVersion(modifiedDoc) || isMakingCurVer) {

        resolve(modifiedDoc);

      } else {

        const documentId = modifiedDoc.documentId;

        let oldOriginalDoc = _self.documentService.getExistingDocument(documentId);
        if (!oldOriginalDoc) {
          oldOriginalDoc = _self.documentService.getExistingDocument(modifiedDoc.systemHeader.versionId);
        }

        if (oldOriginalDoc) {

          // load the latest version from DB first
          _self.dataService.getDocument(documentId).then(
            function successFunc(latestDoc) {

              if (oldOriginalDoc.systemHeader.versionId !== latestDoc.systemHeader.versionId) {
                let diffs = UtilConflict.getDiffs(oldOriginalDoc, latestDoc);
                if (diffs.length) {
                  // get the changed fields by rules and user on form
                  diffs = UtilConflict.getDiffs(oldOriginalDoc, modifiedDoc);

                  // update the latest version to current original version
                  _self.documentService.resetOriginalDocument(latestDoc);

                  // create a new copied version from the latest one
                  const newModifiedDoc = cloneDeep(latestDoc);

                  // apply the changes by rules and user on form to the new document
                  if (diffs.length) {
                    diffs.forEach(function (diff) {
                      newModifiedDoc[diff.fieldName] = modifiedDoc[diff.fieldName];
                    });
                  }

                  const template = _self.loadTemplate(newModifiedDoc, unsavedDocumentListId);
                  if (template && template.components && template.components.length) {

                    template.components.forEach(function (component) {

                      if (!UtilType.isDefined(newModifiedDoc[component.name])) {

                        if (UtilType.isDefined(component.defaultValue)) {

                          newModifiedDoc[component.name] = component.defaultValue;
                        }
                      }
                    });
                  }

                  // set the new changed version to unsaved list
                  _self.unsavedDocumentService.setDocument(unsavedDocumentListId, newModifiedDoc);

                  resolve(newModifiedDoc);
                } else {
                  resolve(modifiedDoc);
                }
              } else {
                resolve(modifiedDoc);
              }
            },
            function errorFunc(err) {
              reject(err);
            }
          );
        } else {
          resolve(modifiedDoc);
        }
      }
    });

  }

  public save(unsavedDocumentListId) {

    const _self = this;

    return new Promise(async (resolve, reject) => {

      if (!unsavedDocumentListId) {
        reject(new Error('Unsaved document list id not provided in save'));

      } else if (_self.modifiedFieldService.isSaving()) {
        reject(new Error('Save is already in progress'));

      } else {

        _self.setFlags(false, true, true, unsavedDocumentListId);

        try {
          await _self.pendingOperationService.waitForPendingOperations();
        } catch (e) {
          _self.setFlags(true, false, false, unsavedDocumentListId);
          reject(e);
          return;
        }

        _self.doSave(unsavedDocumentListId).then(
          async function doSaveSuccess(docs: []) {
            // reset temporary array of saved documents
            _self.savedDocs = null;

            if (docs) {
              for (let id = 0; id < docs.length; id++) {
                const doc: any = cloneDeep(docs[id]);

                // Remove offline info before applying the changes
                if (doc.systemHeader) {
                  delete doc.systemHeader.offlineDetails;
                }

                const unsavedDocuments = _self.modifiedFieldService.getModifiedDocuments(unsavedDocumentListId);

                await _self.documentUpdateService.updateFormDocument(unsavedDocuments, doc);
              }
            }

            if (_self.modifiedFieldService.hasAnySavingChanged()) {
              _self.setFlags(true, false, false, unsavedDocumentListId);
              _self.modifiedFieldService.removeAllSavingChangedDocumentFields();
            } else {
              _self.setFlags(false, false, false, unsavedDocumentListId);
            }

            resolve(docs);
          },
          function errorFunc(err) {
            _self.setFlags(true, false, false, unsavedDocumentListId);

            reject(err);
          });
      }
    });

  }

  async saveDocumentLists(documentListIds) {

    const result = {
      data: null,
      error: null
    };

    const _self = this;

    this.broadcastSaving(true);

    _self.broadcastService.broadcast(ClientConstants.VALIDATE_FORM_FIELDS);

    try {

      const unsavedDocumentListIds = _self.modifiedFieldService.getAllChangedDocumentListIds();

      // save documents sequentially so that all document saves aren't sent to the server at the
      // same time. See the notes of Mantis 11213 for further details
      function asyncJob(documentListId) {
        if (documentListId) {

          if (UtilArray.contains(unsavedDocumentListIds, documentListId)) {
            return _self.save(documentListId);
          }

        }
      }

      result.data = await UtilSequenceProcess.processList(documentListIds, asyncJob);
      if (result.data) {
        this.broadcastDocumentSaved(result.data.map(documentObj => documentObj?.documentId));
      }
    } catch (err) {
      result.error = err;
    } finally {
      this.broadcastSaving(false);

    }

    return result;

  }

  private broadcastSaving(data) {
    this.savingEventBus.next(data);
  }

  onDocumentSaving() {
    return this.savingEventBus.asObservable();
  }

  private broadcastDocumentSaved(documentIds) {
    this.savedEventBus.next(documentIds);
  }

  onDocumentSaved() {
    return this.savedEventBus.asObservable();
  }

}
