import { Injectable, HostListener } from '@angular/core';
import { DocumentData, DocumentInfo, FormDocument, FormTemplate } from '@formbird/types';
import { select} from '../../redux/decorators/select';
import { IApplicationState } from '../../redux/state/application.state';
import { Observable, Subject } from 'rxjs';
import cloneDeep from 'lodash.clonedeep';
import { formResetDocumentInfo, resetUndoRedoDocument, formResetInitDocumentData } from '../../redux/actions/form-new.actions';
import { CurrentDocumentInfoService } from './current-document-info.service';
import { DocumentService } from './document.service';
import { AppStore } from '../../redux/store/app.store';

/**
 * This service stores the document data for the document on the attached main template.
 * It will be retrieved to navigate to document after creating from template, command bar buttons or left/right sidebar visibility...
 */

@Injectable({
  providedIn: 'root'
})
export class CurrentDocumentService {

  @select(['formState', 'documents']) documents$: Observable<any>;
  @select(['formState', 'documentInfo']) documentInfo$: Observable<any>;

  private documentData: DocumentData;
  private documentDataSubject: Subject<DocumentData>;
  public documentData$: Observable<DocumentData>;

  private mainUnsavedDocumentListId: string;
  private documentId: string;

  constructor(
    private appStore: AppStore<IApplicationState>,
    private currentDocumentInfoService: CurrentDocumentInfoService,
    private documentService: DocumentService
  ) {

    this.documentData = {
      document: null,
      template: null
    };
    this.documentDataSubject = new Subject<DocumentData>();
    this.documentData$ = this.documentDataSubject.asObservable();

    this.documents$.subscribe(documents => {

      this.updateDocumentData();

    });

    this.documentInfo$.subscribe(documentInfos => {

      this.updateDocumentData();

    });
  }

  private updateDocumentData() {

    let changed = false;

    const mainDocInfo = this.appStore.getState().formState.documentInfo[this.mainUnsavedDocumentListId];
    if (mainDocInfo && mainDocInfo[this.documentId]) {
      const docInfo = mainDocInfo[this.documentId];

      this.documentData.documentId = docInfo.documentId;
      this.documentData.templateId = docInfo.templateId;
      this.documentData.options = docInfo.options;
      this.documentData.overrideTemplateId = docInfo.overrideTemplateId;
      this.documentData.showFormAfterCreated = docInfo.showFormAfterCreated;
      this.documentData.hierarchyInfo = docInfo.hierarchyInfo;
      this.documentData.altTemplateId = docInfo.altTemplateId;
      this.documentData.isNew = docInfo.isNew;
      this.documentData.isMainDoc = docInfo.isMainDoc;

      changed = true;

    }

    const documents = this.appStore.getState().formState.documents;
    const templates = this.appStore.getState().formState.templates;

    if (documents && Object.keys(documents)) {
      const document = documents[this.documentData.documentId];
      if (document) {
        this.documentData.document = cloneDeep(document);
        changed = true;
      }

      const template = templates[this.documentData.templateId];
      if (template) {
        this.documentData.template = cloneDeep(template);
        changed = true;
      }
    }

    if (changed) {
      this.documentDataSubject.next(this.documentData);
    }

  }

  /**
   * Set main document list and the attached main document
   * @param documentListId
   * @param documentId
   */
  public setMainDocumentListId(documentListId, documentId) {

    this.mainUnsavedDocumentListId = documentListId;
    this.documentId = documentId;

  }

  public getMainDocumentListId() {
    return this.mainUnsavedDocumentListId;
  }

  public getMainDocumentId() {
    return this.documentId;
  }

  public getDocumentData(): DocumentData {
    return this.currentDocumentInfoService.getDocumentData(this.mainUnsavedDocumentListId, this.documentId);
  }

  /**
   * get the document from the main document - which is the document that the object nav bar is linked to
   */
  public getDocument(): FormDocument {
    return this.getDocumentData().document;
  }

  public setDocument(document) {
    this.documentService.setLoadedDocumentOnly(document);
  }

  /**
   * get the template from the main document - which is the document that the object nav bar is linked to
   */
  public getTemplate(): FormTemplate {
    return this.getDocumentData()?.template;
  }

  public setTemplate(template) {

    this.documentService.setLoadedDocumentOnly(template);
  }

  public resetDocumentData(unsavedDocumentListId) {

    this.appStore.dispatch(formResetDocumentInfo(unsavedDocumentListId));
  }

  public resetUndoRedo() {
    this.appStore.dispatch(resetUndoRedoDocument());
  }

  public resetInitDocumentData() {
    this.appStore.dispatch(formResetInitDocumentData(this.mainUnsavedDocumentListId, this.documentId));
  }

}
