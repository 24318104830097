import { UrlBasePathService } from '../../utils/UrlBasePathUtil';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { IndexedDBKeyValueStorage } from '@formbird/indexed-db';
import * as Dexie from 'dexie';

const logger = console;

export class KeyValueStorageService extends IndexedDBKeyValueStorage{
  private urlBasePathService: UrlBasePathService;
  private localStorageService: LocalStorageService;

  constructor() {
    super();
    this.urlBasePathService = new UrlBasePathService();
    this.localStorageService = new LocalStorageService();
  }

  public setItem(key, value) {
    key = this.urlBasePathService.getBasePath() + key;
    const self = this;

    if(self.openFailedError) {
      self.localStorageService.setItem(key, value);
      return Promise.resolve();
    }

    return new Promise<any>((resolve, reject) => {

      super.setItem(key, value).then(result => {
        resolve(result);
      }, e => {
        if (e.name === 'OpenFailedError') {
          logger.error('Error adding entry to IndexedDB, using localStorage instead.');
          self.localStorageService.setItem(key, value);
          resolve(value);
        } else {
          reject(e);
        }
      });
    });
  }

  public getItem(key) {
    key = this.urlBasePathService.getBasePath() + key;
    const self = this;

    if (self.openFailedError) {
      const value = self.localStorageService.getItem(key);
      return Promise.resolve(value);
    }

    return new Promise<any>((resolve, reject) => {
      this.getDatabase().then(function success(db) {
        let value;

        db[self.objectName].where('key').equals(key).each(results => {
          value = results.value;
        }).then(() => {
          resolve(value);
        }).catch(function(error) {
          if (error.name === 'OpenFailedError') {
            logger.error('Error getting dexie entries, using localStorage instead.');
            const value = self.localStorageService.getItem(key);
            self.openFailedError = true;
            resolve(value);
          } else {
            logger.error('Error getting dexie entries: ' + error.message);
            reject(error);
          }
        });
      });
    });
  }


  public removeItem(key) {
    key = this.urlBasePathService.getBasePath() + key;
    const self = this;

    if (self.openFailedError) {
      self.localStorageService.removeItem(key);
      return Promise.resolve(1);
    }

    return new Promise<any>((resolve, reject) => {
      super.removeItem(key).then(deleteCount =>{
        resolve(deleteCount);
      }, error => {
        if (error.name === 'OpenFailedError') {
          self.localStorageService.removeItem(key);
          resolve(1);
        } else {
          logger.error('Error removing dexie entries: ' + error.message);
          reject(error);
        }
      });
    });
  }



}
