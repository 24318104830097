import {Injectable} from '@angular/core';
import {createCustomElement} from '@angular/elements';

@Injectable({
  providedIn: 'root'
})

export class CustomElementService {

  constructor() {}

  convertToCustomElement = (component, selector, injector, version?, options?) => {

    if (!version) {
      version = 'NOT-SET';
    }

    if (!customElements.get(selector)) {
      const scComponent = createCustomElement(component, { injector: injector });

      const connectedCallback = scComponent.prototype.connectedCallback || function () {};
      scComponent.prototype.connectedCallback = function() {
        this.setAttribute('fb-component-version', version);
        if (options) {
          this.setAttribute('is-static-panel', options.isStaticPanel);
          this.setAttribute('is-collapsible-panel', options.isCollapsiblePanel);
        }
        connectedCallback.call(this);
      };

      customElements.define(selector, scComponent);
    }
  }
}
