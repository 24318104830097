import { DocumentListService } from './services/document/document-list.service';
import { StateChangeService } from '@services/state-change/state-change.service';
import { MainTemplateComponent } from '@components/main-template/main-template.component';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormbirdServicesModule } from '@formbird/services';
import { AppRoutingModule } from '../app-routing/app-routing.module';
import { AppComponent } from '@components/app.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { AngularJSWrapperService } from '@services/angular-js-wrapper/angular-js-wrapper.service';
import { FormbirdInjectorService } from '@services/injector/formbird-injector.service';
import { CacheLoadService } from '@services/cache-load.service';
import { ModalService } from '@services/modal/modal.service';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from '@components/dialog/dialog.component';
import { NewDocumentService } from '@services/document/new-document.service';
import { ImageLinkService } from '@services/image-link.service';
import { ImageGalleryService } from '@services/image-gallery.service';
import { InitialisationService } from '@services/initialisation.service';
import { CustomCSSService } from '@services/custom-css.service';
import { AuthInterceptorService } from '@services/interceptor/auth-interceptor.service';
import { GatewayErrorInterceptorService } from '@services/interceptor/gateway-error-interceptor.service';
import { KeyboardShortcutService } from '@services/keyboard-shortcut.service';
import { DocumentDialogComponent } from '@components/dialog/document-dialog/document-dialog.component';
import { PublicLoginComponent } from '@components/public-login/public-login.component';
import { ExternalViewComponent } from '@components/external-view/external-view.component';
import { TemplateViewComponent } from '@components/template/template-view.component';
import { DefaultComponent } from '@components/default/default.component';
import { DocumentModalService } from '@services/modal/document-modal.service';
import { CustomElementService } from '@services/custom-elements/custom-element.service';
import { TemplateComponent } from '@components/template/template.component';
import { DynamicContainerComponent } from '@components/template/dynamic-container/dynamic-container.component';
import { ComponentErrorComponent } from '@components/component-error/component-error.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { FormbirdCommonModule } from './modules/common/formbird-common.module';
import { BlankContainerComponent } from '@components/template/blank-container/blank-container.component';
import { ListenServiceWorkerService } from '@services/listen-service-worker';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        GalleryModule,
        LightboxModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        A11yModule,
        PortalModule,
        ScrollingModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        FormbirdServicesModule,
        AppRoutingModule,
        FormbirdCommonModule,
    ],
    declarations: [
        MainTemplateComponent,
        NotFoundComponent,
        AppComponent,
        DocumentDialogComponent,
        DropZoneDirective,
        PublicLoginComponent,
        ExternalViewComponent,
        TemplateViewComponent,
        DefaultComponent,
        TemplateComponent,
        ComponentErrorComponent,
        DynamicContainerComponent,
        DialogComponent,
        BlankContainerComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AngularJSWrapperService,
        CustomElementService,
        FormbirdInjectorService,
        ModalService,
        CanDeactivateGuard,
        // turn off http throttling until issue with errors on save, in https://mantis.formbird.com/view.php?id=14769#c55039 ,is solved
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HttpRequestThrottleInterceptor,
        //   multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GatewayErrorInterceptorService,
            multi: true
        },
        ImageLinkService,
        ImageGalleryService,
        DocumentListService,
        NewDocumentService,
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        CacheLoadService,
        InitialisationService,
        CustomCSSService,
        KeyboardShortcutService,
        DocumentModalService,
        StateChangeService,
        ListenServiceWorkerService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private initialisationService: InitialisationService,
    private customElementService: CustomElementService,
    private injector: Injector,
    private customCSSService: CustomCSSService
  ) {

    // register any components as custom elements that need to be rendered as custom elements at runtime
    this.customElementService.convertToCustomElement(TemplateComponent, 'ft-formbird-template', this.injector);
    this.customElementService.convertToCustomElement(ComponentErrorComponent, 'ft-component-error', this.injector);
    this.customElementService.convertToCustomElement(ComponentErrorComponent, 'ft-component-not-found', this.injector);

    // append custom css to head
    this.customCSSService.loadCustomCSS();
  }

}
