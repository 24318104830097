import { Injectable } from '@angular/core';
import { Gallery, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

@Injectable({
    providedIn: 'root'
})
export class ImageGalleryService {

    galleryId = 'formbird-gallery';
    images: { [p:string]: string[] } = {};


    constructor(private gallery: Gallery, private lightbox: Lightbox) {}

    public addImageToGallery(galleryId: string = this.galleryId, imageSrc: string) {
        const galleryRef = this.gallery.ref(galleryId);
        const images = this.images[galleryId];
        if (images) {
          this.images[galleryId].push(imageSrc)
        } else {
          this.images[galleryId] = [imageSrc]
        }

        galleryRef.load(this.images[galleryId].map(src => new ImageItem({ src: src, thumb: src })));
    }

    open(galleryId: string = this.galleryId, imageSrc) {
        const index = this.images[galleryId].indexOf(imageSrc);
        this.lightbox.open(index, galleryId)
    }
}
