
<div class="ft-modal-header document-dialog">
  <button type="button" mat-icon-button *ngIf="dirtyClass==='show'?true:false"
    class="modelSave" data-dismiss="modal" aria-hidden="true" (click)="ok()"> <mat-icon>check</mat-icon>
  </button> 
  <div *ngIf="showSpinner" class="spinner-wrap f-flex f-flex-align-items-center f-flex-align-self-center"
    id="spinnerIcon">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
  
  <h1 mat-dialog-title *ngIf="data.title">{{data.title}} </h1> 

  <button type="button" mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="cancel()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>

  <form mat-dialog-content
    [formGroup]="formGroup" name="ftForm" role="form">
    <ft-template
        [formGroup]="formGroup"
        [formParameters]="data.formParameters"
        [user]="user"
        style="display: flex; flex-direction: row; flex-wrap: wrap; place-content: flex-start"        
    ></ft-template>
  </form>


