import {Injectable} from '@angular/core';
import { isArray, isObject, includes } from 'lodash';


const operator = {
    equals: 'eq',
    notEquals: 'neq',
    contains: 'contains'
  };


@Injectable({
  providedIn: 'root'
})
export class SearchQueriesService {

  constructor() {  }

  getElasticQuery(scope, filter, search, columns, order, dtcolumns) {

    let filterObj = filter;

    if (typeof filter === 'string') {
      filterObj = filter.replace(/'/g, '"');
      // filterObj = filterObj.replace(/ /g, "\\\\ ");
      filterObj = JSON.parse(filterObj);
    }

    if (filterObj.query.bool.should === undefined) {
      filterObj.query.bool.should = [];
    }
    if (filterObj.query.bool.must === undefined) {
      filterObj.query.bool.must = [];
    }

    // tslint:disable-next-line:variable-name
    let indexSBoxFilter = -1;
    const arr = filterObj.query.bool.must;
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i].query_string !== 'undefined') {
        if (typeof arr[i].query_string.allow_leading_wildcard !== 'undefined') {
          indexSBoxFilter = i;
          break;
        }
      }
    }
    if (indexSBoxFilter !== -1) {
      filterObj.query.bool.must.splice(indexSBoxFilter, 1);
    }

    if (search.value) {
      let searchTerm = search.value.trim();
      if( !scope.searchBoxAnyCase ){
        searchTerm = search.value.toLowerCase().trim();
      }

      // eslint-disable-next-line max-len
      let sBoxFilter = `{'query_string':{'query': '*{{{search}}}*','analyze_wildcard':true, 'allow_leading_wildcard': true, default_operator: 'AND', 'analyzer: 'formbird_text_search'}}`;
      // 17334
      if( scope.componentDefinition.searchBoxFilter ){
        sBoxFilter = scope.componentDefinition.searchBoxFilter;
      }
      // end 17334
      const sBoxFilterStr = sBoxFilter.replace('{{{search}}}', searchTerm.replace(/\//g, '//')).replace(/'/g, '"');
      const sBoxFilterObj = JSON.parse(sBoxFilterStr);

      const excludeTypes = ['date', 'boolean', 'number', 'object'];
      const excludeFilters = ['dateFilter', 'numericFilter'];

      const systemTextSearch = ['systemHeader.summaryName', 'systemHeader.summaryDescription', 'appTags'];
      let hasSystemSearch = false;

      // Assign Default Fields if no fields set in the sBox filter
      if (!sBoxFilterObj.query_string.fields) {
        const fields = [];
        dtcolumns.map((col) => {
          if( !includes(excludeTypes, col.type) && !includes(excludeFilters, col.filter)){
            let fieldName = col.data;
            if ( !scope.searchBoxAnyCase && !systemTextSearch.includes(col.data) ){
              fieldName = col.data + '.lcase';
            }

            if ( systemTextSearch.includes(col.data) ){
              hasSystemSearch = true;
            }else{
            fields.push(fieldName);
          }

          }
        });

        if( hasSystemSearch ){
          fields.push('system_text_search');
        }

        sBoxFilterObj.query_string.fields = fields;
      }
      // column
      filterObj.query.bool.must.push(sBoxFilterObj);

    }

    for (let i = 0; i < columns.length; i++) {

      const s = columns[i];
      let fieldTerm = s.name;
      if (fieldTerm.match(/\.\d+\./g)) {
        fieldTerm = fieldTerm.replace(/\.\d+\./g, '.');
      }

      // tslint:disable-next-line:variable-name
      let indexCBoxFilter = -1;
      // tslint:disable-next-line:no-shadowed-variable
      const arr = filterObj.query.bool.must;
      for (let j = 0; j < arr.length; j++) {
        if (typeof arr[j].wildcard !== 'undefined') {
          if (typeof arr[j].wildcard[fieldTerm + '.lcase'] !== 'undefined') {
            if (typeof arr[j].wildcard[fieldTerm + '.lcase'].value !== 'undefined') {
              indexCBoxFilter = j;
              break;
            }
          }
        }
      }
      if (indexCBoxFilter !== -1) {
        filterObj.query.bool.must.splice(indexCBoxFilter, 1);
      }

      if (s.search.value) {
        switch (dtcolumns[i].type) {
          default:
            const wc = {};
            wc[fieldTerm + '.lcase'] = {value: '*' + s.search.value.replace(/\//g, '//').toLowerCase() + '*'};
            filterObj.query.bool.must.push({wildcard: wc});
            break;
        }
      }
    }

    // Secure the sort object to be empty, avoid adding duplicate sort fields
    if (filterObj.sort === undefined || filterObj.sort.length > 0) {
      filterObj.sort = [];
    }

    order.forEach((val, i) => {
      if (val.dir !== '') {
        const sortObj = {};
        sortObj[scope.componentDefinition.gridColumns[val.column].field.replace(/\.\d+/g, '')] = val.dir;
        filterObj.sort.push(sortObj);
      }
    });

    return filterObj;

  }

  getMongoQuery(scope, filter, search, columns, order, dtcolumns) {

    let filterObj;
    let queryData;
    if (typeof filter === 'string') {
      filterObj = filter.replace(/'/g, '"');
      filterObj = JSON.parse(filterObj);
    } else {
      filterObj = filter;
    }
    queryData = filterObj.query ? filterObj.query : filterObj;

    if (search.value) {
      const andArray = queryData.$and;
      const regexObj = {$regex: search.value.replace(/\//g, '//').replace(/'/g, '"').toLowerCase(), $options: 'i'};
      if (andArray === undefined || !isArray(andArray)) {
        queryData['systemHeader.summaryName'] = regexObj;
      } else {
        queryData.$and.push({'systemHeader.summaryName': regexObj});
      }

    }

    for (let i = 0; i < columns.length; i++) {
      const s = columns[i];
      let fieldTerm = s.name;
      if (fieldTerm.match(/\.\d+\./g)) {
        fieldTerm = fieldTerm.replace(/\.\d+\./g, '.');
      }

      if (s.search.value) {
        switch (dtcolumns[i].type) {
          default:
            const andArray = filterObj.$and;
            const regexObj = {$regex: s.search.value.replace(/\//g, '//').toLowerCase().toLowerCase(), $options: 'i'};
            if (andArray === undefined || !isArray(andArray)) {
              queryData[fieldTerm] = regexObj;
            } else {
              const fldObj = {};
              fldObj[fieldTerm] = regexObj;
              queryData.$and.push(fldObj);
            }
            break;
        }
      }
    }

    if (filterObj.query) {
      filterObj.query = queryData;
    } else {
      filterObj = {query: queryData};
    }
    // Secure the sort object to be empty, avoid adding duplicate sort fields
    if (filterObj.sort === undefined || !isObject(filterObj.sort)) {
      filterObj.sort = {};
    }


    order.forEach((val, i) => {
      if (val.dir !== '') {
        filterObj.sort[scope.componentDefinition.gridColumns[val.column].field.replace(/\.\d+/g, '')] = val.dir === 'asc' ? 1 : -1;
      }
    });


    return filterObj;

  }

  getDexieQuery(scope, filter, search, columns, order, dtcolumns) {

    let filterObj;
    filterObj = {dexieQuery: []} ;

    if (search.value) {
      filterObj.dexieQuery.push({ field : 'systemHeader.summaryName',
        value: search.value.replace(/\//g, '//').replace(/'/g, '"').toLowerCase(),
        operator: operator.contains });
    }

    for (let i = 0; i < columns.length; i++) {
      const s = columns[i];
      let fieldTerm = s.name;
      if (fieldTerm.match(/\.\d+\./g)) {
        fieldTerm = fieldTerm.replace(/\.\d+\./g, '.');
      }

      if (s.search.value) {
        switch (dtcolumns[i].type) {
          default:
            const fldObj = {field: fieldTerm, value: s.search.value.replace(/\//g, '//').toLowerCase(),
              operator: operator.contains };
            filterObj.dexieQuery.push(fldObj);
            break;
        }
      }
    }

    // Secure the sort object to be empty, avoid adding duplicate sort fields
    if (filterObj.dexieSort === undefined || !isObject(filterObj.dexieSort)) {
      filterObj.dexieSort = {};
    }

    order.forEach((val, i) => {
      if (val.dir !== '') {
        filterObj.dexieSort[scope.componentDefinition.gridColumns[val.column].field] = val.dir;
      }
    });

    return filterObj;

  }

}

@Injectable({
  providedIn: 'root'
})
export class DatatablesQueriesService extends SearchQueriesService {}
