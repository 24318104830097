import { Injectable } from '@angular/core';
declare const angular: any;

@Injectable({
  providedIn: 'root'
})
export class FormbirdInjectorService {
  injector: any;

  constructor() {
    // get the injector. If running with an AngularJS core the injector will be the AngularJS injector. If running with an
    // Angular core, the injector is exposed globally in a window.FormbirdServiceInjector object. Once we have moved all customers
    // to an Angular core, we can remove the searching for an AngularJS injector

    // first try getting the global formbird service injector which will be defined if running with an Angular only core
    this.injector = (window as any).FormbirdServiceInjector;

  }

  get(serviceName: string) {
    // first try getting the global formbird service injector which will be defined if running with an Angular only core
    return this.injector.get(serviceName);
  }

}
