export class LatestDocumentService {

  private static instance: LatestDocumentService;

  public latestDocuments: any = {};

  constructor() { }

  public static getInstance(): LatestDocumentService {
    if (!LatestDocumentService.instance) {
      LatestDocumentService.instance = new LatestDocumentService();
    }

    return LatestDocumentService.instance;

  }

  public getLatestDocument(documentId: string) {
    return this.latestDocuments[documentId];
  }

}
