import { IFormSetAltTemplateInfoAction, FORM_SET_ALT_TEMPLATE_INFO } from './../actions/form-new.actions';
import { ChangeSourceInfo, SharedConstants, StateChangeInfo } from '@formbird/types';
import { isEqual, cloneDeep } from 'lodash';
import { Action } from 'redux';
import {
  FORM_CHANGE_DOCUMENT,
  FORM_CLEAR_DOCUMENT,
  FORM_DELETE_DOCUMENT,
  FORM_REDO,
  FORM_RESET_CHANGE_DOCUMENT,
  FORM_RESET_DOCUMENT_INFO,
  FORM_RESET_TO_ORG_DOCUMENT,
  FORM_RESET_UNDO_REDO,
  FORM_UPDATE_COMPONENT_DEFINITION,
  FORM_SET_COMPONENT_FLAG_TEMPLATE,
  FORM_SET_COMPONENT_PROPS_TEMPLATE,
  FORM_SET_LIST_COMPONENTS_FLAG_TEMPLATE,
  FORM_SET_DISABLE_SAVE_FIELD_DOCUMENT,
  FORM_SET_DOCUMENT,
  FORM_SET_TEMPLATE,
  FORM_SET_DOCUMENT_INFO,
  FORM_SET_ORG_DOCUMENT,
  FORM_SETUP_DOCUMENT_LIST,
  FORM_UNDO,
  FORM_SET_DOCUMENT_SESSION,
  IFormDeleteDocumentAction,
  IFormDocumentChangedAction,
  IFormResetDocumentInfoAction,
  IFormResetToOrgDocumentAction,
  IFormUpdateComponentDefinitionAction,
  IFormSetComponentFlagAction,
  IFormSetComponentPropsAction,
  IFormSetDocumentAction,
  IFormSetDocumentInfoAction,
  IFormSetupDocumentListAction,
  IFormSetDisabledSaveAction,
  IFormSetDocumentSessionAction,
  IFormSetListComponentsFlagAction,
  FORM_UPDATE_INIT_DOCUMENT_DATA,
  FORM_RESET_INIT_DOCUMENT_DATA,
  IFormUpdateInitDocumentDataAction,
  IFormResetInitDocumentDataAction,
  FORM_RESET_TEMPLATE_COMPONENT_DEFINITION,
  IFormResetTemplateComponentDefinitionAction,
  FORM_DOCUMENT_LOADED,
  IFormDocumentLoadedAction,
  FORM_SET_RULES_STATUS,
  IFormSetRulesStatusAction,
  FORM_INIT_APP_STORE_SESSION,
  IFormInitAppStoreSessionAction
} from '../actions';
import { getDefaultFormNewState, IFormNewState } from '../state/form-new.state';

export function formDocumentReducer(state: IFormNewState = getDefaultFormNewState(), action: Action): IFormNewState {

  // let shouldRecordPatches = false;
  // set the changes to state from reducer so if any changes they should be subscribed instead of listening whole state.
  const recordedPatches: StateChangeInfo = {
    patches: null
  };
  // let recordedUndoRedo = false;
  const changedSourceInfo: ChangeSourceInfo = {};


  switch (action.type) {

    case FORM_INIT_APP_STORE_SESSION: {
      const typedAction = <IFormInitAppStoreSessionAction>action;
      return { ...state, storeSessionId: typedAction.payload.appStoreId };
    }

    case FORM_SETUP_DOCUMENT_LIST: {
      const typedAction = <IFormSetupDocumentListAction>action;
      const documentListId = typedAction.payload.documentListId;
      const isNewMainDocList = typedAction.payload.isNewMainDocList;

      const documentInfo = { ...state.documentInfo };
      if (isNewMainDocList) {

        // Remove the old main document that was attached to navbar and other places
        let oldMainDocumentListId;

        const documentListIds = Object.keys(documentInfo);

        for (let i = 0; i < documentListIds.length; i++) {

          const documentListId = documentListIds[i];

          const documentIds = Object.keys(documentInfo[documentListId]);

          for (let j = 0; j < documentIds.length && !oldMainDocumentListId; j++) {

            const documentId = documentIds[j];

            if (documentInfo[documentListId][documentId].isMainDoc) {
              oldMainDocumentListId = documentListId;
            }
          }
        }

        if (oldMainDocumentListId) {
          delete documentInfo[oldMainDocumentListId];
        }
      }

      if (!documentInfo[documentListId]) {
        documentInfo[documentListId] = {};
      }

      return { ...state, documentInfo: documentInfo };
    }

    case FORM_SET_ORG_DOCUMENT: {
      const typedAction = <IFormSetDocumentAction>action;
      const document = typedAction.payload.document;

      const orgDocuments = { ...state.orgDocuments };
      orgDocuments[document.documentId] = cloneDeep(document);
      return { ...state, orgDocuments: orgDocuments };
    }

    case FORM_SET_DOCUMENT: {
      const typedAction = <IFormSetDocumentAction>action;
      const document = typedAction.payload.document;

      if (typedAction.payload.versionId || document) {
        const key = typedAction.payload.versionId ? typedAction.payload.versionId : document.documentId;

        const documents = { ...state.documents };
        documents[key] = document;

        changedSourceInfo.documentId = key;
        changedSourceInfo.resetDocument = true;
        recordedPatches.changedSourceInfo = changedSourceInfo;
        state = { ...state, recordedStateChanges: recordedPatches };

        return { ...state, documents: documents, recordedStateChanges: recordedPatches };
      }

      return state;
    }
      
    case FORM_SET_ALT_TEMPLATE_INFO: {

      const typedAction = <IFormSetAltTemplateInfoAction>action;
      const documentId = typedAction.payload.documentId;
      const templateId = typedAction.payload.templateId;

      const stateAltTemplateInfo = { ...state.altTemplateInfo };
      stateAltTemplateInfo[documentId] = templateId;

      return { ...state, altTemplateInfo: stateAltTemplateInfo };
    }

    case FORM_SET_TEMPLATE: {
      const typedAction = <IFormSetDocumentAction>action;
      const document = typedAction.payload.document;

      if (typedAction.payload.versionId || document) {
        const key = typedAction.payload.versionId ? typedAction.payload.versionId : document.documentId;

        const templates = { ...state.templates };
        templates[key] = document;

        return { ...state, templates: templates };
      }

      return state;
    }

    case FORM_UPDATE_COMPONENT_DEFINITION: {
      const typedAction = <IFormUpdateComponentDefinitionAction>action;
      const templateId = typedAction.payload.templateId;
      const fieldName = typedAction.payload.fieldName;
      const key = typedAction.payload.key;
      const compDef = typedAction.payload.componentDef;

      const templates = { ...state.templates };
      const components = templates[templateId].components;

      const recordedComponentTemplateChanges = { ...state.recordedComponentTemplateChanges };

      if (!recordedComponentTemplateChanges[templateId]) {
        recordedComponentTemplateChanges[templateId] = {};
      }

      if (!recordedComponentTemplateChanges[templateId][fieldName]) {
        recordedComponentTemplateChanges[templateId][fieldName] = {};
      }

      let hasChanged = false;
      if (components[key] && components[key].name && components[key].name === fieldName) {
        components[key] = compDef;

        recordedComponentTemplateChanges[templateId][fieldName].key = key;
        recordedComponentTemplateChanges[templateId][fieldName].componentDef = compDef;

        hasChanged = true;
      }

      // shouldRecordPatches = true;

      return hasChanged ? { ...state, templates: templates, recordedComponentTemplateChanges: recordedComponentTemplateChanges } : state;
    }

    case FORM_SET_COMPONENT_PROPS_TEMPLATE: {
      const typedAction = <IFormSetComponentPropsAction>action;
      const fieldName = typedAction.payload.fieldName;
      const componentName = typedAction.payload.componentName;
      const propertyValue = typedAction.payload.propertyValue;
      const templateId = typedAction.payload.templateId;
      const templates = { ...state.templates };

      const recordedComponentTemplateChanges = { ...state.recordedComponentTemplateChanges };
      recordedComponentTemplateChanges[templateId] = {};
      recordedComponentTemplateChanges[templateId][fieldName] = {};
      
      let hasChanged = false;
      templates[templateId].components.forEach((tplItem, idx) => {
        if (tplItem.name && tplItem.name === fieldName && (!componentName || tplItem.componentName === componentName)) {
          const keys = Object.keys(propertyValue);
          keys.forEach(key => {
            tplItem[key] = propertyValue[key];
          });
          tplItem = { ...tplItem, ...propertyValue };
          recordedComponentTemplateChanges[templateId][fieldName].componentDef = tplItem;
          recordedComponentTemplateChanges[templateId][fieldName].key = idx;
          recordedComponentTemplateChanges[templateId][fieldName].propertyChanges = propertyValue;
          if (componentName) {
            recordedComponentTemplateChanges[templateId][fieldName].componentName = componentName;
          }
          hasChanged = true;
        }
        
      });

      return hasChanged ? { ...state, recordedComponentTemplateChanges: recordedComponentTemplateChanges, templates: templates } : state;
    }

    case FORM_SET_COMPONENT_FLAG_TEMPLATE: {
      const typedAction = <IFormSetComponentFlagAction>action;
      const flagName = typedAction.payload.flagName;
      const flagValue = typedAction.payload.flagValue;
      const fieldName = typedAction.payload.fieldName;
      const templateId = typedAction.payload.templateId;
      const templates = { ...state.templates };

      const recordedComponentTemplateChanges = {};
      recordedComponentTemplateChanges[templateId] = {};
      recordedComponentTemplateChanges[templateId][fieldName] = {};

      let hasChanged = false;
      let isProcessed = false;
      templates[templateId].components.forEach((tplItem, idx) => {
        // 18883: sc-collapsible-panel not able to stay open when subject to showField --
        // added isProcessed as the component with the same name with wrapActon is closed.
        if (tplItem.name && tplItem.name === fieldName && !isProcessed) {
          isProcessed = true;
          if (!isEqual(tplItem[flagName], flagValue)) {
            tplItem[flagName] = flagValue;
            recordedComponentTemplateChanges[templateId][fieldName][flagName] = flagValue;
            // shouldRecordPatches = true;
            hasChanged = true;
          }
          recordedComponentTemplateChanges[templateId][fieldName].componentDef = tplItem;
          recordedComponentTemplateChanges[templateId][fieldName].key = idx;
        }

      });

      return hasChanged ? { ...state, recordedComponentTemplateChanges: recordedComponentTemplateChanges, templates: templates} : state;
    }

    case FORM_SET_LIST_COMPONENTS_FLAG_TEMPLATE: {
      const typedAction = <IFormSetListComponentsFlagAction>action;
      const fieldNames = typedAction.payload.fieldNames;
      const flagName = typedAction.payload.flagName;
      const flagValue = typedAction.payload.flagValue;
      const templateId = typedAction.payload.templateId;

      const templates = state.templates;
      const template = templates[templateId];

      const recordedComponentTemplateChanges = { ...state.recordedComponentTemplateChanges };
      if (!recordedComponentTemplateChanges[templateId]) {
        recordedComponentTemplateChanges[templateId] = {};
      }

      let hasChanged = false;
      template.components.forEach((tplItem, idx) => {

        const fieldName = tplItem.name;
        if (fieldNames.indexOf(fieldName) !== -1) {

          tplItem[flagName] = flagValue;

          if (!recordedComponentTemplateChanges[templateId][fieldName]) {
            recordedComponentTemplateChanges[templateId][fieldName] = {};
          }

          if (!isEqual(recordedComponentTemplateChanges[templateId][fieldName][flagName], flagValue)) {
            recordedComponentTemplateChanges[templateId][fieldName][flagName] = flagValue;
            hasChanged = true;
          }
          recordedComponentTemplateChanges[templateId][fieldName].componentDef = tplItem;
          recordedComponentTemplateChanges[templateId][fieldName].key = idx;
        }

      });

      return hasChanged ? { ...state, recordedComponentTemplateChanges: recordedComponentTemplateChanges } : state;
    }

    case FORM_RESET_TEMPLATE_COMPONENT_DEFINITION: {
      const typedAction = <IFormResetTemplateComponentDefinitionAction>action;
      const templateId = typedAction.payload.templateId;

      const recordedComponentTemplateChanges = { ...state.recordedComponentTemplateChanges };
      recordedComponentTemplateChanges[templateId] = {};

      return { ...state, recordedComponentTemplateChanges: recordedComponentTemplateChanges };
    }

    case FORM_CLEAR_DOCUMENT: {
      const documentIds = Object.keys(state.documents);
      const documents = { ...state.documents };
      const templates = { ...state.templates };

      documentIds.forEach(docId => {
        // remove document from store
        delete documents[docId];

        if (documents[docId].systemHeader.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE) {
          // remove templates from store
          delete templates[docId];
        }
      });

      return { ...state, documents: documents, templates: templates };
    }

    case FORM_DELETE_DOCUMENT: {
      const typedAction = <IFormDeleteDocumentAction>action;
      const documentId = typedAction.payload.documentId;

      const documents = { ...state.documents };
      const templates = { ...state.templates };
      const orgDocuments = { ...state.orgDocuments };
      const document = state.orgDocuments[documentId];
      delete documents[documentId];
      delete templates[document?.systemHeader?.templateId];
      delete orgDocuments[documentId];

      return { ...state, documents: documents, templates: templates, orgDocuments: orgDocuments };
    }

    case FORM_RESET_TO_ORG_DOCUMENT: {
      const typedAction = <IFormResetToOrgDocumentAction>action;
      const documentId = typedAction.payload.documentId;
      const document = state.orgDocuments[documentId];

      const documents = { ...state.documents };
      if (documents[documentId]) {
        documents[documentId] = document;
        state = { ...state, documents: documents };
      }

      if (document?.systemHeader?.systemType === SharedConstants.SYSTEM_TYPE_TEMPLATE) {
        const templates = { ...state.templates };
        templates[documentId] = document;
        state = { ...state, templates: templates };
      }

      return state;
    }

    case FORM_SET_DOCUMENT_INFO: {

      // DocumentInfo Item Data Structure
      // state.documentInfo[documentListId] = {
      // isMainDoc: isMainDoc;
      // documentId = null;
      // templateId = null;
      // altTemplateId = null;
      // parentDocumentId = null;
      // childDocumentId = null;
      // options = {};
      // initDocData = {
      //   processedFields: [], // processedFields: stores processed fields from components
      //   completedFields: []  // stores the fields have been initialized. It will be set once the RESET action is dispatched.
      //   // It will be used to compare to the processedFields in order to trigger Onload
      // }
      // };

      const typedAction = <IFormSetDocumentInfoAction>action;

      const documentInfo = typedAction.payload.documentInfo;
      const unsavedDocListId = typedAction.payload.unsavedDocumentListId;
      const documentId = documentInfo.documentId;

      const documentInfor = { ...state.documentInfo };
      const storeDocumentInfo = documentInfor[unsavedDocListId];

      if (!storeDocumentInfo[documentId]) {
        storeDocumentInfo[documentId] = {};
      }

      const documentInfoItem = storeDocumentInfo[documentId];

      if (!documentInfoItem.options) {
        documentInfoItem.options = {};
      }
      if (!documentInfoItem.hierarchyInfo) {
        documentInfoItem.hierarchyInfo = {};
      }

      const keys = Object.keys(documentInfo);
      keys.forEach(key => {

        if (key === 'options') {

          const optKeys = Object.keys(documentInfo.options);
          optKeys.forEach(key2 => {
            documentInfoItem.options[key2] = documentInfo.options[key2];
          });

        } else {
          documentInfoItem[key] = documentInfo[key];
        }
      });

      const parentDocumentId = documentInfoItem.hierarchyInfo.parentDocumentId;
      if (parentDocumentId) {
        const parentDocInfo = storeDocumentInfo[parentDocumentId];
        parentDocInfo.hierarchyInfo.childDocumentId = documentId;
      }

      return { ...state, documentInfo: documentInfor };
    }

    case FORM_RESET_DOCUMENT_INFO: {
      const typedAction = <IFormResetDocumentInfoAction>action;

      const documentInfo = { ...state.documentInfo };
      documentInfo[typedAction.payload.unsavedDocumentListId] = {};

      return { ...state, documentInfo: documentInfo };
    }

    case FORM_UPDATE_INIT_DOCUMENT_DATA: {
      const typedAction = <IFormUpdateInitDocumentDataAction>action;
      const unsavedDocListId = typedAction.payload.unsavedDocumentListId;
      const documentId = typedAction.payload.documentId;

      if (unsavedDocListId) {

        const fieldName = typedAction.payload.fieldName;
        const fieldValue = typedAction.payload.fieldValue;

        let hasChanged = false;

        const documentInfo = { ...state.documentInfo };
        const documentInfoItem = documentInfo[unsavedDocListId][documentId];
        if (!documentInfoItem.initDocData) {
          documentInfoItem.initDocData = {
            processedFields: [],
            completedFields: []
          };
          hasChanged = true;
        }

        const initDocData = documentInfoItem.initDocData;
        if (!initDocData.processedFields.includes(fieldName)) {
          initDocData.processedFields.push(fieldName);
          hasChanged = true;
        }

        if (hasChanged) {
          state = { ...state, documentInfo: documentInfo };
        }

        const documents = { ...state.documents };
        const document = documents[documentInfoItem.documentId];
        if (fieldValue == null) {
          delete document[fieldName];
        } else {
          document[fieldName] = fieldValue;
        }
        state = { ...state, documents: documents };
      }

      return state;
    }

    case FORM_RESET_INIT_DOCUMENT_DATA: {
      const typedAction = <IFormResetInitDocumentDataAction>action;
      const unsavedDocListId = typedAction.payload.unsavedDocumentListId;
      const documentId = typedAction.payload.documentId;

      let hasChanged = false;
      const documentInfo = { ...state.documentInfo };
      const documentInfoItem = documentInfo[unsavedDocListId][documentId];
      if (!documentInfoItem.initDocData) {
        documentInfoItem.initDocData = {
          processedFields: [],
          completedFields: []
        };
        hasChanged = true;
      }

      const initDocData = documentInfoItem.initDocData;
      if (initDocData.processedFields.length) {
        initDocData.completedFields = [...initDocData.processedFields];
        initDocData.processedFields = [];
        hasChanged = true;
      }

      return hasChanged ? { ...state, documentInfo: documentInfo } : state;
    }

    case FORM_DOCUMENT_LOADED: {

      const typedAction = <IFormDocumentLoadedAction>action;
      const documentId = typedAction.payload.documentId;
      const unsavedDocListId = typedAction.payload.unsavedDocumentListId;
      const isDocumentLoaded = typedAction.payload.isLoaded;

      const documentInfo = { ...state.documentInfo };
      const docInfo = documentInfo[unsavedDocListId];
      if (docInfo && docInfo[documentId]) {
        if (!isDocumentLoaded) {
          delete docInfo[documentId];
        } else {
          docInfo[documentId].loaded = isDocumentLoaded;
        }
      }

      state = { ...state, documentInfo: documentInfo };

      if (!isDocumentLoaded) {
        const documents = { ...state.documents };
        const orgDocuments = { ...state.orgDocuments };
        // const document = documents[documentId];
        delete documents[documentId];
        // delete orgDocuments[documentId];
      
        state = { ...state, documents: documents, orgDocuments: orgDocuments };
       
      }

      return state;
    }

    case FORM_CHANGE_DOCUMENT: {
      const typedAction = <IFormDocumentChangedAction>action;
      const changedInfo = typedAction.payload.documentChangedInfo;

      const documentId = changedInfo.documentId;
      const documents = { ...state.documents };
      const document = documents[documentId] || {};
      const fieldName = changedInfo.fieldName;

      changedInfo.oldValue = changedInfo.oldValue !== undefined ? changedInfo.oldValue : document[fieldName];
      if (!isEqual(document[fieldName], changedInfo.newValue)) {
        if (document[fieldName] !== undefined && changedInfo.newValue === undefined) {
          delete document[fieldName];
        } else {
          document[fieldName] = changedInfo.newValue;
        }
        state = { ...state, documents: documents };
      }

      let documentChangedInfo = { ...state.documentChangedInfo };
      if (!changedInfo.isInitValue) {
        documentChangedInfo = changedInfo;
        state = { ...state, documentChangedInfo: documentChangedInfo };
      }

      changedSourceInfo.documentId = documentId;
      changedSourceInfo.fieldName = fieldName;
      changedSourceInfo.type = changedInfo.sourceType;
      changedSourceInfo.controlName = changedInfo.controlName;
      recordedPatches.changedSourceInfo = changedSourceInfo;
      state = { ...state, recordedStateChanges: recordedPatches };

      const recordedDisableSaveFieldValues = { ...state.recordedDisableSaveFieldValues };
      const template = state.templates[document?.systemHeader?.templateId];
      let hasDisableSave = false;
      if (template && template.components) {
        const tplItem = template.components.filter(item => {
          return item.name && item.name === fieldName;
        })[0];

        if (tplItem?.disableSave === true) {
          if (!recordedDisableSaveFieldValues[documentId]) {
            recordedDisableSaveFieldValues[documentId] = {};
          }
          recordedDisableSaveFieldValues[documentId][fieldName] = document[fieldName];
          hasDisableSave = true;
        }
      }
      if (hasDisableSave) {
        state = { ...state, recordedDisableSaveFieldValues: recordedDisableSaveFieldValues };
      }


      return state;
    }

    case FORM_RESET_CHANGE_DOCUMENT: {
      return { ...state, documentChangedInfo: undefined };
    }

    case FORM_UNDO: {

      // shouldRecordPatches = true;
      // recordedUndoRedo = true;

      // const newState = <IFormNewState> applyPatches(state, changes[currentVersion--].undo);

      // return produce(
      //   newState,
      //   newDraft => {
      //     newstate.canUndo = changes.hasOwnProperty(currentVersion);
      //     newstate.canRedo = true;
      //   }
      // );
      return state;
    }

    case FORM_REDO: {

      // shouldRecordPatches = true;
      // recordedUndoRedo = true;

      // const newState = <IFormNewState> applyPatches(state, changes[++currentVersion].redo);
      // return produce(
      //   newState,
      //   newDraft => {
      //     newstate.canUndo = true;
      //     newstate.canRedo = changes.hasOwnProperty(currentVersion + 1);
      //   }
      // );

      return state;

    }

    case FORM_RESET_UNDO_REDO: {

      // currentVersion = -1;
      // changes = {};
      state.canRedo = false;
      state.canUndo = false;

      return state;
    }

    case FORM_SET_DISABLE_SAVE_FIELD_DOCUMENT: {
      const typedAction = <IFormSetDisabledSaveAction>action;
      const documentId = typedAction.payload.documentId;
      const value = typedAction.payload.disabledFields;

      const recordedDisableSaveFieldValues = { ...state.recordedDisableSaveFieldValues };
      if (Object.keys(value).length) {
        recordedDisableSaveFieldValues[documentId] = value;
      } else {
        delete recordedDisableSaveFieldValues[documentId];
      }

      return { ...state, recordedDisableSaveFieldValues: recordedDisableSaveFieldValues };
    }

    case FORM_SET_DOCUMENT_SESSION: {
      const typedAction = <IFormSetDocumentSessionAction>action;
      const document = typedAction.payload.documentSession;
      const key = typedAction.payload.documentId;

      const documentSession = { ...state.documentSession };
      documentSession[key] = document;

      return { ...state, documentSession: documentSession };
    }

    case FORM_SET_RULES_STATUS: {


      const typedAction = <IFormSetRulesStatusAction>action;
      const templateId = typedAction.payload.templateId;
      const status = typedAction.payload.status;

      const rulesProcessingData = { ...state.rulesProcessingData };
      if (!rulesProcessingData[templateId]) {
        rulesProcessingData[templateId] = {};
      }

      rulesProcessingData[templateId].status = status;

      return { ...state, rulesProcessingData: rulesProcessingData };
    }

    default: {
      return state;
    }
  }

}
