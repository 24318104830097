import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedUrlRoutes } from '@formbird/shared';

@Injectable({
  providedIn: 'root'
})
export class ClientResourceService {

  constructor(
    private http: HttpClient
  ) {
  }

  getClientResources() {

    return this.http.get(SharedUrlRoutes.serverRoutes.clientResources).toPromise();
  }

  getCachedFilesCount() {

    return Promise.resolve(0);

    // Will be checked later because caches object is undefined
    // return new Promise((resolve, reject) => {
    //   if (caches) {
    //     caches.keys().then(keyList => {
    //
    //       const promises = [];
    //       for (let i = 0; i < keyList.length - 1; i++) {
    //         const key = keyList[i];
    //         promises.push(caches.open(key));
    //       }
    //
    //       return Promise.all(promises);
    //
    //     }).then(returnedCaches => {
    //       const promises = [];
    //       for (let i = 0; i < returnedCaches.length; i++) {
    //         const cache = returnedCaches[i];
    //         promises.push(cache.keys());
    //       }
    //
    //       return Promise.all(promises);
    //
    //     }).then((cachedFilesArray) => {
    //       let totalCount = 0;
    //       for (let i = 0; i < cachedFilesArray.length; i++) {
    //         totalCount += cachedFilesArray[i].length;
    //       }
    //
    //       resolve(totalCount);
    //     });
    //   } else {
    //     resolve(0);
    //   }
    // });
  }
}
