<mat-card *ngIf="errorMsg && !isOffline" class="ft-alert-notfound ft-alert">{{errorMsg}}</mat-card>

<mat-card *ngIf="!isOffline && showNetworkMsg" class="ft-alert ft-connection-lost">

  <mat-card-header>    
    <mat-card-title>Network Connection Lost</mat-card-title>    
  </mat-card-header>  
  <mat-card-content>
    <p>
      Sorry, the network connection has been lost and caching has not been enabled.
    </p>
  </mat-card-content>
  
</mat-card>







<div class="divContainer"  [@fade]="{value: state, params: {bgImageUrl: animationBGImageUrl}}" *ngIf="!isDeleted">
<ng-container #ngCont></ng-container>
</div>
<ng-template #ngTpl let-item>
  <div ft-dynamic-container [id]="'divParent' + components[item.key].name + item.key"
       [user]="user"
       [tplItem]="components[item.key]"
       [components]="components"
       [documentData]="documentData"
       [formParameters]="formParameters"
       [key]=item.key
       [endKey]=item.endKey
       [ngStyle]="{'display': components[item.key].visible === false ? 'none' : 'flex' }"
       class="
       ft-component-wrap
       {{!components[item.key].wrapAction && components[item.key].enabled === false ? 'disabled readonly fb-label-disabled' : ''}} 
       {{components[item.key].wrapAction && components[item.key].enabled === false ? 'disabled-panel readonly-panel fb-label-disabled' : ''}} 
       {{!components[item.key].wrapAction && components[item.key].enabled !== false ? 'fb-label-enabled' : ''}}
       {{components[item.key].wrapAction && components[item.key].enabled !== false ? 'fb-label-enabled' : ''}}
       {{!components[item.key].wrapAction && components[item.key].mandatory === true ? 'mandatory' : ''}}
       {{components[item.key].wrapAction && components[item.key].mandatory === true ? 'mandatory-panel' : ''}}
       "
       [ngClass]="(components[item.key].fullWidth)?'ft-dynamic-container-full-width':'ft-dynamic-container'"
       [attr.aria-disabled]="!components[item.key].wrapAction && components[item.key].enabled === false ? 'true' : null"
  >
  </div>
</ng-template>
