import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';

@Injectable({
  providedIn: 'root'
})
export class ModifiedCSSService {

  dynamicCSSObj = {
    commandBarCSS: null
  };

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  setCommandBarCSS(templateId, commandBarCSS){
    this.dynamicCSSObj.commandBarCSS = commandBarCSS;

    const fromStorage = this.localStorageService.getItem(LocalStorageKeys.COMMAND_BAR_CSS);
    const entry = fromStorage ? JSON.parse(fromStorage) : {};

    entry[templateId] = commandBarCSS;

    this.localStorageService.setItem('commandBarCSS', JSON.stringify(entry));
  }

  getStoredCommandBarCSS(templateId) {
    const fromStorage = this.localStorageService.getItem(LocalStorageKeys.COMMAND_BAR_CSS);
    const entry = fromStorage ? JSON.parse(fromStorage) : {};

    return entry[templateId];
  }
}
