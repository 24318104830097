import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormColorService {

  color$: Observable<any>;
  private colorSubject: Subject<any>;

  constructor() {
    this.colorSubject = new Subject<any>();
    this.color$ = this.colorSubject.asObservable();
  }

  static COLOR_WHITE = '#ffffff';

  color = {
    background: ''
  };

  setColor(formColor) {
    this.color.background = formColor;
    this.colorSubject.next(this.color);
  }

  setBackgroundColor() {
    this.setColor(FormColorService.COLOR_WHITE);
  }

}
