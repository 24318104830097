import { Injectable, OnDestroy } from '@angular/core';
import { UtilConflict, UtilError, UtilRules, UtilValidation } from '@formbird/shared';
import { UtilSequenceProcess } from '@formbird/shared';
import { ChangeSourceType, DocumentChangedInfo, SharedConstants, User } from '@formbird/types';
import { cloneDeep, includes, isEqual } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ClientConstants } from '../../constants/ClientConstants';
import { formSetDocument, formSetRulesStatus } from '../../redux/actions';
import { select } from '../../redux/decorators/select';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { ClientAccessService } from '../access/client-access.service';
import { BroadcastService } from '../broadcast/broadcast.service';
import { ConfigService } from '../config/config.service';
import { CurrentDocumentService } from '../document/current-document.service';
import { DocumentService } from '../document/document.service';
import { ModifiedFieldService } from '../document/modified-field.service';
import { UnsavedDocumentService } from '../document/unsaved-document.service';
import { NotificationService } from '../notification/notification.service';
import { RuleContext } from '../rules/client-rule-context';
import { RuleEngineService } from '../rules/rule-engine.service';
import { TabService } from '../tab/tab.service';
import { KeyValueStorageService } from '../key-value-storage/key-value-storage.service';
import { LoggedInUserService } from '../user/logged-in-user.service';

const logger = console;

export interface RulesData {
  eventName: string;
  document: any;
  documentContext: any;
  callback?: any;
  error?: any;
  results?: any;
  rulesetCount?: number;
  shouldRun?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ValidationService implements OnDestroy {
  @select(['formState', 'documentChangedInfo']) documentChangedInfo$: Observable<DocumentChangedInfo>;
  @select(['userState', 'user']) user$: Observable<User>;

  private subs: Subscription = new Subscription();

  documentData: any;
  user: User;

  /***------------------- Exported data -------------------------***/
  public validationCtrlValues = {};

  /***------------------- Local data ----------------------------***/
  public eventList = {};

  private pendingSave = null;

  private queue = new Subject<RulesData>();

  constructor(
    private documentService: DocumentService,
    private unsavedDocumentService: UnsavedDocumentService,
    private notificationService: NotificationService,
    private modifiedFieldService: ModifiedFieldService,
    private ruleEngineService: RuleEngineService,
    private loggedInUserService: LoggedInUserService,
    private currentDocumentService: CurrentDocumentService,
    private configService: ConfigService,
    private broadcastService: BroadcastService,
    private tabService: TabService, // do not remove. Used in ClientRuleContext
    private clientAccessService: ClientAccessService,  // do not remove. Used in ClientRuleContext
    private appStore: AppStore<IApplicationState>,
    private keyValueStorageService: KeyValueStorageService
  ) {

    const subDocData = this.currentDocumentService.documentData$.subscribe(data => {
      this.documentData = data;
    });

    const subUser = this.user$.subscribe((user: User) => this.user = user);

    const documentChangedSub = this.documentChangedInfo$.subscribe((documentChangedData: DocumentChangedInfo) => {
        if (documentChangedData && documentChangedData.sourceEvent === undefined) {
          documentChangedData.sourceEvent = ChangeSourceType.COMPONENT;
        }
      
        this.handleOnFieldChange(documentChangedData);
      });

    const results = this.queue.pipe(
      concatMap((rulesData: RulesData) => {
        return this.executeDocumentEventPromise(rulesData);
      }));
    const ruleSub = results.subscribe((data: RulesData) => {
      if (data.rulesetCount) {
        logger.info(`%c${data?.rulesetCount} ${data?.eventName} rules done for document: ${data?.document?.documentId}`,
          `color:green`);
      }

      this.setRuleStatusDone(data?.documentContext?.templateId);

      data.callback(data.error, data.results);
    });

    // const subReceive = this.broadcastService.on(ClientConstants.DOCUMENT_RECEIVED).subscribe(async (docInfo: any) => {
      // Temporary disable document received event for 
      // https://mantis.formbird.com/view.php?id=12666#c94304

      // try {
      //   const document = await this.documentService.getDocumentOnly(docInfo.documentId);
          
      //   var docContext: any = {};
      //   docContext.formParameters = {};

      //   if (document.systemHeader && document.systemHeader.templateId) {
      //     docContext.templateId = document.systemHeader.templateId;
      //     docContext.template = await this.documentService.getDocumentOnly(docContext.templateId);
      //     this.onReceiveDocument(document, docContext, (error, result) => {
      //       if (error) {
      //         logger.error(error);
      //       } else {
      //         this.broadcastValidationEvent();
      //       }
      //     });
      //   }
      // } catch (e) {
      //   console.info('Can not run OnReceive rules due to: ', e ? e.message : e);
      // }
    // });

    this.subs.add(subDocData);
    this.subs.add(subUser);
    this.subs.add(documentChangedSub);
    this.subs.add(ruleSub);
    // this.subs.add(subReceive);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /***------------------- Exported functions --------------------***/
  loadValidators(tplItem, fieldName) {

    const validators = [];

    const required = {
      'name': ClientConstants.VALIDATION_REQUIRED_KEY,
      'text': ClientConstants.VALIDATION_REQUIRED_MESSAGE
    };
    validators.push(required);

    const minLength = {
      'name': ClientConstants.VALIDATION_MIN_LENGTH_KEY,
      'text': ClientConstants.VALIDATION_MIN_LENGTH_MESSAGE.replace(/%s/g, tplItem.minLength)
    };
    validators.push(minLength);

    const maxLength = {
      'name': ClientConstants.VALIDATION_MAX_LENGTH_KEY,
      'text': ClientConstants.VALIDATION_MAX_LENGTH_MESSAGE.replace(/%s/g, tplItem.maxLength)
    };
    validators.push(maxLength);

    return validators;
  }

  waitPendingEvents(save) {

    logger.info('ModifiedFieldService.formDirty.validating ' + this.modifiedFieldService.formDirty.validating);

    if (this.modifiedFieldService.formDirty.validating) {

      this.modifiedFieldService.setShowSpinner(true);
      this.modifiedFieldService.setDirty(false);

      this.pendingSave = save;

    } else {
      save();
    }
  }

  setRuleStatusDone(templateId) {
    this.appStore.dispatch(formSetRulesStatus(templateId, ClientConstants.RULES_STATUS_DONE));
  }

  private updateDocumentToStore(prevDocument, document, ruleContext) {
    const diffs = UtilConflict.getDiffs(prevDocument, document);

    if (diffs && diffs.length) {
      this.dispatchStoreAction(formSetDocument(document));
    }
  }

  private handleOnFieldChange(documentChangedData: DocumentChangedInfo) {

    if (documentChangedData) {
      const state = this.appStore.getState().formState;
      const documentId = documentChangedData.documentId;
      const document = cloneDeep(state.documents[documentId]);
      const fieldName = documentChangedData.fieldName;
      const formParameters = documentChangedData.formParameters;
      const template = this.documentService.getloadedTemplateForDoc(document, formParameters)
      const component = template?.components?.filter(comp => comp.name === fieldName)[0] || {};

      if (documentChangedData.shouldCompareValues !== false) {

        if (isEqual(documentChangedData.newValue, documentChangedData.oldValue) ||
          (!UtilValidation.hasValue(documentChangedData.oldValue) &&
            !UtilValidation.hasValue(documentChangedData.newValue))) { // first run.
          return;
        }

        const isChanged = this.modifiedFieldService.isChangedField(documentId, fieldName, formParameters.unsavedDocumentListId);
        if (!isChanged && isEqual(documentChangedData.newValue, documentChangedData.oldValue)) {
          return;
        }
      }

      if (Object.keys(component).length && !component.disableSave && !component.disableDirty && documentChangedData.sourceEvent !== ChangeSourceType.DOCUMENT_PUSH) {
        this.modifiedFieldService.addModifiedField(documentId, fieldName, formParameters.unsavedDocumentListId);
      }

      const orgValue = this.appStore.getState().formState.orgDocuments[documentId][fieldName];

      const docContext = {} as any;
      docContext.fieldChanged = fieldName;
      docContext.originValue = orgValue;
      docContext.newValue = cloneDeep(documentChangedData.newValue);
      docContext.oldValue = cloneDeep(documentChangedData.oldValue);
      docContext.templateId = template.documentId;
      docContext.formParameters = formParameters;
      docContext.template = template;
      docContext.databaseMode = this.configService.clientConfig().defaultSearchProvider;
      docContext.changeSourceType = documentChangedData.sourceType;
      docContext.changeSourceEvent = documentChangedData.sourceEvent;
      docContext.changedFields = documentChangedData.changedFields;

      this.validateChangedField(component, document, template, documentChangedData.newValue, docContext);
    }
  }

  executeOnFieldChangeWithNoChanges(documentChangedInfo: DocumentChangedInfo) {
    this.handleOnFieldChange(documentChangedInfo);
  }

  private executeOnFieldChange(document, docContext) {

    if (this.modifiedFieldService.formDirty.dirty) {
      const key = `FB-CORE-WIP-${document.documentId}`;
      this.keyValueStorageService.setItem(key, { document, updated: new Date() });
    }

    const _self = this;

    const currentValidating = new Date();
    _self.modifiedFieldService.formDirty.validating = currentValidating;

    _self.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.ON_FIELD_CHANGE_EVENT_NAME.name, document, docContext,
      function (error, result) {

        if (result) {

          _self.broadcastValidationEvent();

        }

        if (_self.modifiedFieldService.formDirty.validating === currentValidating) {
          if (_self.pendingSave !== null) {
            _self.pendingSave();
          }

          _self.modifiedFieldService.formDirty.validating = null;
          _self.pendingSave = null;
        }
      });
  }

  private validateChangedField(component, document, template, newValue, docContext) {

    const fieldName = component.name;

    this.initFieldValidator(document, template, component, fieldName, newValue);

    this.executeOnFieldChange(document, docContext);
  }

  validateChangedFieldValues(scope, originValue, newValue, oldValue) {

    const tplField = scope.tplItem;
    const fieldName = scope.fieldName;

    const document = scope.doc;
    const template = scope.tpl;

    this.initFieldValidator(document, template, tplField, fieldName, newValue);

    const docContext = {} as any;
    docContext.fieldChanged = fieldName;
    docContext.originValue = originValue;
    docContext.newValue = newValue;
    docContext.oldValue = oldValue;
    docContext.templateId = scope.tplId;
    docContext.formParameters = scope.formParameters;
    docContext.template = scope.tpl;
    docContext.databaseMode = this.configService.clientConfig().defaultSearchProvider;


    this.executeOnFieldChange(scope.doc, docContext);
  }

  preRenderDocument(document, docContext, callback) {
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.PRERENDER_EVENT_NAME.name, document, docContext, callback);
  }

  preDeleteClient(document, docContext, callback) {
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.PREDELETE_CLIENT_EVENT_NAME.name, document, docContext, callback);
  }

  onLoadDocument(document, docContext, callback) {

    const template = docContext.template;

    // reset validation values due to template/document OnLoad
    this.validationCtrlValues[document.documentId] = {
      templateId: template.documentId
    };

    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.ONLOAD_EVENT_NAME.name, document, docContext, callback);
  }

  private doAuthRules(ruleName, document, docContext, callback) {
    docContext = docContext || {};
    const templateId = document?.systemHeader?.templateId;
    if (!templateId) {
      callback(ruleName + " no template for " + document.documentId);
    }

    docContext.templateId = templateId;
    docContext.formParameters = { unsavedDocumentListId : this.unsavedDocumentService.createUnsavedDocumentList() };

    this.documentService.getDocumentOnly(document?.systemHeader?.templateId).then(template => {
      docContext.template = template;
      this.executeDocumentEvent(ruleName, document, docContext, callback);
    }).catch(error => {
      if (error.name === UtilError.FORBIDDEN_ERROR) {
        logger.warn('Current user doesn\'t have access to account template to execute login rules. Will skip execution.');
        callback();
        return;
      }

      if (error.name === UtilError.NOT_FOUND_ERROR) {
        logger.warn(`Template ${document?.systemHeader?.templateId} not found to execute login rules. Will skip execution.`);
        callback();
        return;
      }

      callback("Unable to run " + ruleName + " due to error: " + error.message);
    });
  }

  doPostLoginClient(document, docContext, callback) {
    this.doAuthRules(SharedConstants.RULESET_RELATIONSHIPS.POSTLOGIN_CLIENT_EVENT_NAME.name, document, docContext, callback);
  }

  doPreLogoutClient(document, docContext, callback) {
    this.doAuthRules(SharedConstants.RULESET_RELATIONSHIPS.PRELOGOUT_CLIENT_EVENT_NAME.name, document, docContext, callback);
  }

  private prepareFormParameters(document, docContext) {
    if (!docContext.formParameters) {
      const unsavedDocumentListId = docContext.unsavedDocumentListId;
      const hierarchyInfo = this.unsavedDocumentService.getHierarchyInfo(unsavedDocumentListId, document.documentId);

      docContext.formParameters = {
        hierarchyInfo,
        unsavedDocumentListId,
      };
    }
  }

  onPreSaveDocument(document, docContext, callback) {
    this.prepareFormParameters(document, docContext);
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.PRESAVE_EVENT_NAME.name, document, docContext, callback);
  }

  onPreSaveOfflineDocument(document, docContext, callback) {
    this.prepareFormParameters(document, docContext);
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.PRESAVE_OFFLINE_EVENT_NAME.name, document, docContext, callback);
  }

  onPostSaveClientDocument(document, docContext, callback) {
    this.prepareFormParameters(document, docContext);
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.POSTSAVE_CLIENT_EVENT_NAME.name, document, docContext, callback);
  }

  onReceiveDocument = function (document, docContext, callback) {
    this.executeDocumentEvent(SharedConstants.RULESET_RELATIONSHIPS.ON_RECEIVE_EVENT_NAME.name, document, docContext, callback);
  }

  broadcastValidationEvent() {
    this.broadcastEvent(SharedConstants.EVENT_VALIDATION_VALUES_NAME, this.validationCtrlValues);
  }

  broadcastEvent(eventName, eventValue?) {
    this.broadcastService.broadcast(eventName, eventValue);
  }

  dispatchStoreAction(action) {
    this.appStore.dispatch(action);
  }

  getTemplateFromStore(templateId) {
    return cloneDeep(this.appStore.getState().formState.templates[templateId]);
  }

  getDocumentFromStore(documentId) {
    return cloneDeep(this.appStore.getState().formState.documents[documentId]);
  }

  /***---------------------- Helper functions -----------------***/
  private initFieldValidator(document, template, tplField, fieldName, fieldValue) {

    this.initValidationValue(document, template, fieldName);

    if (tplField.mandatory === true) {
      this.setValidationValue(document, fieldName, 'required', !UtilValidation.hasValue(fieldValue));
    }

    if (tplField.minLength) {
      this.setValidationValue(document, fieldName, 'hasMinLength',
        !UtilValidation.validateMinLength(fieldValue, tplField.minLength));
    }

    if (tplField.maxLength) {
      this.setValidationValue(document, fieldName, 'hasMaxLength',
        !UtilValidation.validateMaxLength(fieldValue, tplField.maxLength));
    }
  }

  async executeRuleSet(eventName, processDoc, documentContext, eventRule) {

    const _self = this;

    return new Promise((resolve, reject) =>
    {

      documentContext.eventName = eventName;

      const ruleContext = new RuleContext(_self, eventName, processDoc, documentContext);

      _self.ruleEngineService.processRuleFlow(processDoc, documentContext, eventRule, ruleContext, function (result) {

        const msg = result ? result.message : null;
        const status = result ? result.status : SharedConstants.STATUS_SUCCESS;
        if (msg) {
          _self.notificationService.printMessage(msg, status, null, 'toast-top-right', false);
        }

        if (status && status.toLowerCase() === SharedConstants.STATUS_ERROR) {

          reject(result);

        } else {

          switch (eventName) {

            case SharedConstants.RULESET_RELATIONSHIPS.ONLOAD_EVENT_NAME.name:
              _self.broadcastEvent(SharedConstants.ONLOAD_DONE_RULES_EVENT_NAME, true);
              break;

          }

          // _self.updateDocumentToStore(prevDocument, processDoc, documentContext);

          resolve(result);
        }
      });

    });

  }

  private shouldRunRules(rulesData: RulesData) {

    const _self = this;
    const eventName = rulesData.eventName;

    const documentContext = rulesData.documentContext;
    if (SharedConstants.RULESET_RELATIONSHIPS.ON_FIELD_CHANGE_EVENT_NAME.name === eventName && !this.isExistsChangedFieldInRulesetTriggeredOn(eventName, documentContext)) {
      return false;
    }

    // bypass loadedDocument check
    if (rulesData.shouldRun) {
      return true;
    }

    const document = rulesData.document;
    const loadedDocument = _self.documentService.getLoadedDocument(document.documentId);
    if (!loadedDocument) {

      const msg = `%cDocument: ${document.documentId} has been unloaded on form. Terminate processing ${eventName} rules.`;
      logger.info(msg, `color:green`);

      return false;
    }

    return true;
  }

  executeDocumentEventPromise(rulesData: RulesData) {
    const _self = this;

    const eventName = rulesData.eventName;
    let document = rulesData.document ? rulesData.document : {};
    const documentContext = rulesData.documentContext;
    const changedField = documentContext.fieldChanged;
    const newValue = documentContext.newValue;
    const template = documentContext.template;
    const templateId = documentContext.templateId;

    if (documentContext.isNew === undefined) {
      const unsavedDocumentListId = documentContext.unsavedDocumentListId ? documentContext.unsavedDocumentListId : documentContext.formParameters?.unsavedDocumentListId;
      if (document?.systemHeader?.serverUpdatedDate && eventName !== SharedConstants.RULESET_RELATIONSHIPS.POSTSAVE_CLIENT_EVENT_NAME.name) {
        documentContext.isNew = false;
      } else if (unsavedDocumentListId) {
        const documentInfoItem = _self.unsavedDocumentService.getDocumentInfoItem(unsavedDocumentListId, document?.documentId);
        documentContext.isNew = documentInfoItem?.isNew === true;
      } else if (documentContext.documentData) {
        documentContext.isNew = documentContext.documentData.isNew;
      }
    }

    const changedFieldMsg = ` - changed field: ${changedField} - old value: ${documentContext.oldValue} - new value: ${newValue}`;
    const msg = `%cChecking for and processing ${eventName} rules for document: ${document?.documentId} ${changedField ? changedFieldMsg : ''}`;
    logger.info(msg, `color:green`);

    return new Promise((resolve) => {

      if (_self.shouldRunRules(rulesData) === false) {
        resolve(rulesData);
        return;
      }

      this.appStore.dispatch(formSetRulesStatus(templateId, ClientConstants.RULES_STATUS_STARTED));

      _self.loadRule(eventName, template).then(
        function successFunc(rulesets: []) {
          rulesData.rulesetCount = rulesets.length;

          if (!rulesets.length) {
            resolve(rulesData);
            return;
          }

          function processEvent(user, accountControl) {

            documentContext.user = user ? user : {};
            documentContext.accountControl = accountControl;

            _self.unsavedDocumentService.setParentInfo(documentContext);
            _self.unsavedDocumentService.setChildInfo(documentContext);

            function asyncJob(ruleset) {
              if (ruleset) {

                const state = _self.appStore.getState().formState;
                document = state.documents[document?.documentId] ? state.documents[document?.documentId] : document;

                return _self.executeRuleSet(eventName, document, documentContext, ruleset);

              }
            }

            UtilSequenceProcess.processList(rulesets, asyncJob).then(
              function allPromiseSuccessFunc(results) {

                rulesData.results = results;
                resolve(rulesData);

              },
              function errorFunc(err) {

                rulesData.error = err;
                resolve(rulesData);

                if (eventName === SharedConstants.RULESET_RELATIONSHIPS.PRESAVE_EVENT_NAME.name) {

                  _self.broadcastEvent(ClientConstants.PRESAVE_DOCUMENT_FORM_RESET, {
                    document: document,
                    unsavedDocumentListId: documentContext.unsavedDocumentListId
                  });
                }

              }
            );
          }

          processEvent(_self.user.account, _self.user.accountControlDocument);
        },
        function errorFunc(err) {

          logger.error('Load rule error: ' + err);
          rulesData.error = err;
          resolve(rulesData);

        }
      );
    });

  }

  executeDocumentEvent(eventName, document, documentContext, callback) {

    documentContext.isChildDoc = !!documentContext.formParameters?.hierarchyInfo?.parentDocumentId;

    const rulesData: RulesData = {
      eventName,
      document,
      documentContext,
      callback
    };

    this.queue.next(rulesData);
  }

  private setParentTplDoc(documentContext, document) {

    let unsavedDocumentListId = documentContext.formParameters.unsavedDocumentListId;
    if (!unsavedDocumentListId) {
      unsavedDocumentListId = documentContext.unsavedDocumentListId;
    }
    const result = this.unsavedDocumentService.getParentData(unsavedDocumentListId, document.documentId);
    documentContext.parentDocument = result.parentDoc;
    documentContext.parentTemplate = result.parentTpl;
  }

  private setChildTplDoc(documentContext, document) {

    let unsavedDocumentListId = documentContext.formParameters.unsavedDocumentListId;
    if (!unsavedDocumentListId) {
      unsavedDocumentListId = documentContext.unsavedDocumentListId;
    }
    const result = this.unsavedDocumentService.getChildData(unsavedDocumentListId, document.documentId);

    documentContext.childDocument = result.childDoc;
    documentContext.childTemplate = result.childTpl;
  }

  private loadRulesetById(docId, ruleName) {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.documentService.getDocumentOnly(docId).then(
        function successFunc(doc) {

          resolve({
            rulesetId: docId,
            ruleSetDSL: doc.ruleSetDSL
          });

        },
        function errorFunc(err) {

          let msg = ruleName + ' ruleset: ' + docId + ' not found due to error: ';
          msg += err && err.message ? err.message : err;

          if (ruleName === SharedConstants.RULESET_RELATIONSHIPS.ONLOAD_EVENT_NAME.name ||
              ruleName === SharedConstants.RULESET_RELATIONSHIPS.PRESAVE_EVENT_NAME.name) {

            reject(new Error(msg));

          } else {

            _self.notificationService.printMessage(msg, SharedConstants.STATUS_WARNING, null, { x: 'right', y: 'top' });

            // do not break the chain in order to load the next ruleset document
            resolve(true);
          }

        }
      );

    });

  }

  private loadRule(ruleName, template) {

    const _self = this;

    return new Promise((resolve, reject) => {

      if (template) {

        const relName = UtilRules.getRelatedName(ruleName);

        const rulesetRelValues = template[relName];
        if (rulesetRelValues) {

          logger.info('Using number of related rulesets: ' + rulesetRelValues.length);

          const promises = [];

          rulesetRelValues.forEach(function (obj) {
            promises.push(_self.loadRulesetById(obj.documentId, ruleName));
          });

          Promise.all(promises).then(
            function successFunc(ruleFlows) {

              logger.info('Found related rulesets: ' + ruleFlows.length);

              resolve(ruleFlows);

            },
            function errorFunc(err) {

              logger.warn('Not found related rulesets.');

              reject(err);

            }
          );

        } else {

          const rules = template.rules;
          const foundRuleset = [];

          if (rules && rules.length) {

            for (let rId = 0; rId < rules.length; rId++) {

              if (rules[rId].name === ruleName) {
                logger.warn('DEPRECATED: please use the ruleset related document structure ' +
                  'in the rules definition instead of having the ruleFlow script directly ' +
                  'in template: ' + template.documentId);

                foundRuleset.push(rules[rId].ruleFlow);

              }

            }
          }

          resolve(foundRuleset);
        }
      } else {
        reject("No template provided for loadRule.");
      }

    });
  }

  initValidationValue(document, template, field) {

    const affectedDocId = document.documentId;

    if (!this.validationCtrlValues[affectedDocId]) {

      this.validationCtrlValues[affectedDocId] = {
        templateId: template.documentId
      };

    }

    if (!this.validationCtrlValues[affectedDocId][field]) {

      this.validationCtrlValues[affectedDocId][field] = {};

    }
  }

  private setValidationValue(affectedDoc, field, name, flag) {

    if (flag !== undefined) {

      // use string values of "yes" and "true" so that they can be used inside the rules.
      const result = (flag === 'yes' || flag === 'true' || flag === true);

      if (this.validationCtrlValues[affectedDoc.documentId] &&
        this.validationCtrlValues[affectedDoc.documentId][field]) {

        if (this.validationCtrlValues[affectedDoc.documentId][field][name] !== result) {
          this.validationCtrlValues[affectedDoc.documentId][field][name] = result;

          this.validateFormFields({
            documentId: affectedDoc.documentId,
            fieldName: field,
            validateName: name
          });
        }

      }
    }
  }

  private isExistsChangedFieldInRulesetTriggeredOn(eventName, documentContext) {
    let isChangedFieldFound = false;
    if (SharedConstants.RULESET_RELATIONSHIPS.ON_FIELD_CHANGE_EVENT_NAME.name === eventName) {
      const rulesetRelValues = documentContext.template[SharedConstants.RULESET_RELATIONSHIPS.ON_FIELD_CHANGE_EVENT_NAME.relName]
      if (rulesetRelValues && rulesetRelValues.length > 0) {
        for (let i = 0; i < rulesetRelValues.length; i++) {
          const ruleObj = rulesetRelValues[i];
          isChangedFieldFound = !ruleObj.triggeredOn || includes(ruleObj.triggeredOn, documentContext.fieldChanged);
          if (isChangedFieldFound) {
            break;
          }
        }
      }
    }
    return isChangedFieldFound;
  }

  /***------------------- End Rule context -------------------****/

  validateFormFields = (value?: any) => {

    this.broadcastEvent(ClientConstants.VALIDATE_FORM_FIELDS, value);

  }
}
