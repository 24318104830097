import { Component, OnInit } from '@angular/core';
import { DeletionService } from '@services/deletion/deletion.service';

@Component({
    selector: 'ft-delete',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss']
})

export class DeleteComponent implements OnInit {

    deleteOptions: any = {};

    constructor(
        private deletionService: DeletionService
    ) {}

    ngOnInit() {
        this.deletionService.deletionService$.subscribe((deleteOptions) => {
            this.deleteOptions = deleteOptions;
        });
    }

    deleteDoc() {
        this.deletionService.deleteDoc();
    }
}

