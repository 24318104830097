import { ChangeSourceType, ComponentDefinition, DocumentChangedInfo, DocumentInfo, FormDocument } from '@formbird/types';
import { Action } from 'redux';

export const FORM_SETUP_DOCUMENT_LIST = 'FORM_SETUP_DOCUMENT_LIST';

export const FORM_CHANGE_DOCUMENT = 'FORM_CHANGE_DOCUMENT';
export const FORM_RESET_CHANGE_DOCUMENT = 'FORM_RESET_CHANGE_DOCUMENT';
export const FORM_SET_DOCUMENT = 'FORM_SET_DOCUMENT';
export const FORM_SET_TEMPLATE = 'FORM_SET_TEMPLATE';
export const FORM_DELETE_DOCUMENT = 'FORM_DELETE_DOCUMENT';
export const FORM_UPDATE_COMPONENT_DEFINITION = 'FORM_UPDATE_COMPONENT_DEFINITION';
export const FORM_RESET_TEMPLATE_COMPONENT_DEFINITION = 'FORM_RESET_TEMPLATE_COMPONENT_DEFINITION';
export const FORM_SET_COMPONENT_FLAG_TEMPLATE = 'FORM_SET_COMPONENT_FLAG_TEMPLATE';
export const FORM_SET_COMPONENT_PROPS_TEMPLATE = 'FORM_SET_COMPONENT_PROPS_TEMPLATE';
export const FORM_SET_LIST_COMPONENTS_FLAG_TEMPLATE = 'FORM_SET_LIST_COMPONENTS_FLAG_TEMPLATE';
export const FORM_CLEAR_DOCUMENT = 'FORM_CLEAR_DOCUMENT';
export const FORM_RESET_TO_ORG_DOCUMENT = 'FORM_RESET_TO_ORG_DOCUMENT';
export const FORM_DOCUMENT_LOADED = 'FORM_DOCUMENT_LOADED';

export const FORM_SET_ORG_DOCUMENT = 'FORM_SET_ORG_DOCUMENT';

export const FORM_SET_ALT_TEMPLATE_INFO = 'FORM_SET_ALT_TEMPLATE';

export const FORM_SET_DOCUMENT_INFO = 'FORM_SET_DOCUMENT_INFO';
export const FORM_RESET_DOCUMENT_INFO = 'FORM_RESET_DOCUMENT_INFO';

export const FORM_UPDATE_INIT_DOCUMENT_DATA = 'FORM_UPDATE_INIT_DOCUMENT_DATA';
export const FORM_RESET_INIT_DOCUMENT_DATA = 'FORM_RESET_INIT_DOCUMENT_DATA';

export const FORM_UNDO = 'FORM_UNDO';
export const FORM_REDO = 'FORM_REDO';
export const FORM_RESET_UNDO_REDO = 'FORM_RESET_UNDO_REDO';

export const FORM_SET_DISABLE_SAVE_FIELD_DOCUMENT = 'FORM_SET_DISABLE_SAVE_FIELD_DOCUMENT';
export const FORM_SET_DOCUMENT_SESSION = 'FORM_SET_DOCUMENT_SESSION';
export const FORM_INIT_APP_STORE_SESSION = 'FORM_INIT_APP_STORE_SESSION';

export const FORM_SET_RULES_STATUS = 'FORM_SET_RULES_STATUS';

/**
 * Document List section
 */
export interface IFormSetupDocumentListAction extends Action {
  payload: {
    isNewMainDocList?: boolean,
    documentListId?: string
  };
}

export function formSetupDocumentList(isNewMainDocList?: boolean, documentListId?: string): IFormSetupDocumentListAction {
  return {
    type: FORM_SETUP_DOCUMENT_LIST,
    payload: {
      isNewMainDocList,
      documentListId
    }
  };
}

/**
 * Change document section
 */
export interface IFormDocumentChangedAction extends Action {
  payload: {
    documentChangedInfo: DocumentChangedInfo
  };
}

export function formDocumentChanged(documentChangedInfo: DocumentChangedInfo): IFormDocumentChangedAction {
  return {
    type: FORM_CHANGE_DOCUMENT,
    payload: {
      documentChangedInfo
    }
  };
}

export interface IFormResetDocumentChangedAction extends Action {
}

export function formResetDocumentChanged(): IFormResetDocumentChangedAction {
  return {
    type: FORM_RESET_CHANGE_DOCUMENT
  };
}

/**
 * Clear document section
 */
export interface IFormClearDocumentsAction extends Action {
}

export function formClearDocuments(): IFormClearDocumentsAction {
  return {
    type: FORM_CLEAR_DOCUMENT
  };
}

/**
 * Set document section
 */
export interface IFormSetDocumentAction extends Action {
  payload: {
    document: FormDocument;
    versionId?: string;
    source?: ChangeSourceType;
  };
}

export interface IFormDeleteDocumentAction extends Action {
  payload: {
    documentId: string;
  };
}

export interface IFormUpdateComponentDefinitionAction extends Action {
  payload: {
    templateId: string;
    fieldName: string;
    key: number;
    componentDef: ComponentDefinition;
  };
}

export interface IFormResetTemplateComponentDefinitionAction extends Action {
  payload: {
    templateId: string;
  };
}

export interface IFormSetComponentFlagAction extends Action {
  payload: {
    templateId: string;
    fieldName: string;
    flagName: string;
    flagValue: any;
    source?: ChangeSourceType
  };
}

export interface IFormSetComponentPropsAction extends Action {
  payload: {
    templateId: string;
    fieldName: string;
    componentName: string;
    propertyValue: any;
    source?: ChangeSourceType
  };
}

export interface IFormSetListComponentsFlagAction extends Action {
  payload: {
    templateId: string;
    fieldNames: any;
    flagName: string;
    flagValue: any;
    source?: ChangeSourceType
  };
}

export interface IFormResetToOrgDocumentAction extends Action {
  payload: {
    documentId: string
  }
}

export function formSetDocument(document: FormDocument, versionId?: string): IFormSetDocumentAction {
  return {
    type: FORM_SET_DOCUMENT,
    payload: {
      document,
      versionId
    }
  };
}

export function formSetTemplate(document: FormDocument, versionId?: string): IFormSetDocumentAction {
  return {
    type: FORM_SET_TEMPLATE,
    payload: {
      document,
      versionId
    }
  };
}

export function formUpdateComponentDefinition(templateId, fieldName, key, componentDef): IFormUpdateComponentDefinitionAction {
  return {
    type: FORM_UPDATE_COMPONENT_DEFINITION,
    payload: {
      templateId,
      fieldName,
      key,
      componentDef
    }
  };
}

export function formResetTemplateComponentDefinition(templateId, unsavedDocumentListId?: string):
  IFormResetTemplateComponentDefinitionAction {
  return {
    type: FORM_RESET_TEMPLATE_COMPONENT_DEFINITION,
    payload: {
      templateId
    }
  };
}

export function formSetComponentPropsTemplate(templateId, fieldName, componentName, propertyValue): IFormSetComponentPropsAction {
  return {
    type: FORM_SET_COMPONENT_PROPS_TEMPLATE,
    payload: {
      templateId,
      fieldName,
      componentName,
      propertyValue
    }
  };
}

export function formSetComponentFlagTemplate(templateId, fieldName, flagName, flagValue): IFormSetComponentFlagAction {
  return {
    type: FORM_SET_COMPONENT_FLAG_TEMPLATE,
    payload: {
      templateId,
      fieldName,
      flagName,
      flagValue
    }
  };
}

export function formSetListComponentsFlagTemplate(templateId, fieldNames, flagName, flagValue): IFormSetListComponentsFlagAction {
  return {
    type: FORM_SET_LIST_COMPONENTS_FLAG_TEMPLATE,
    payload: {
      templateId,
      fieldNames,
      flagName,
      flagValue
    }
  };
}

export function formSetOrgDocument(document: FormDocument, versionId?: string): IFormSetDocumentAction {
  return {
    type: FORM_SET_ORG_DOCUMENT,
    payload: {
      document,
      versionId
    }
  };
}

export function formDeleteDocument(documentId: string): IFormDeleteDocumentAction {
  return {
    type: FORM_DELETE_DOCUMENT,
    payload: {
      documentId
    }
  };
}

export function formResetToOrgDocument(documentId: string, unsavedDocumentListId?: string): IFormResetToOrgDocumentAction {
  return {
    type: FORM_RESET_TO_ORG_DOCUMENT,
    payload: {
      documentId
    }
  };
}

/**
 * Document info section
 */
export interface IFormSetDocumentInfoAction extends Action {
  payload: {
    unsavedDocumentListId: string,
    documentInfo: DocumentInfo
  }
}

export function formSetDocumentInfo(documentListId: string, documentInfo: DocumentInfo): IFormSetDocumentInfoAction {
  return {
    type: FORM_SET_DOCUMENT_INFO,
    payload: {
      documentInfo,
      unsavedDocumentListId: documentListId
    }
  };
}

export interface IFormResetDocumentInfoAction extends Action {
  payload: {
    unsavedDocumentListId: string
  }
}

export function formResetDocumentInfo(unsavedDocumentListId: string): IFormResetDocumentInfoAction {
  return {
    type: FORM_RESET_DOCUMENT_INFO,
    payload: {
      unsavedDocumentListId
    }
  };
}

export interface IFormUpdateInitDocumentDataAction extends Action {
  payload: {
    unsavedDocumentListId: string,
    documentId: string,
    fieldName: string,
    fieldValue: any
  }
}

export function formUpdateInitDocumentData(unsavedDocumentListId: string, documentId: string, fieldName: string, fieldValue: any): IFormUpdateInitDocumentDataAction {
  return {
    type: FORM_UPDATE_INIT_DOCUMENT_DATA,
    payload: {
      unsavedDocumentListId,
      documentId,
      fieldName,
      fieldValue
    }
  };
}

export interface IFormResetInitDocumentDataAction extends Action {
  payload: {
    unsavedDocumentListId: string,
    documentId: string
  }
}

export function formResetInitDocumentData(unsavedDocumentListId: string, documentId: string): IFormResetInitDocumentDataAction {
  return {
    type: FORM_RESET_INIT_DOCUMENT_DATA,
    payload: {
      unsavedDocumentListId,
      documentId
    }
  };
}

export interface IFormDocumentLoadedAction extends Action {
  payload: {
    documentId: string,
    unsavedDocumentListId: string,
    isLoaded: boolean
  }
}

export function formDocumentLoaded(documentId: string, unsavedDocumentListId: string, isLoaded: boolean): IFormDocumentLoadedAction {
  return {
    type: FORM_DOCUMENT_LOADED,
    payload: {
      documentId,
      unsavedDocumentListId,
      isLoaded
    }
  };
}

/**
 * Undo/Redo document section
 */

export interface IFormUndoDocumentAction extends Action {
}

export interface IFormRedoDocumentAction extends Action {
}

export interface IFormResetUnRedoDocumentAction extends Action {
}

export function formUndoDocument(): IFormUndoDocumentAction {
  return {
    type: FORM_UNDO
  };
}

export function formRedoDocument(): IFormRedoDocumentAction {
  return {
    type: FORM_REDO
  };
}

export function resetUndoRedoDocument(): IFormResetUnRedoDocumentAction {
  return {
    type: FORM_RESET_UNDO_REDO
  };
}

export interface IFormSetDisabledSaveAction extends Action {
  payload: {
    documentId: string;
    disabledFields: any;
  };
}

export function formSetDisableSaveFieldDocument(documentId: string, disabledFields: any): IFormSetDisabledSaveAction {
  return {
    type: FORM_SET_DISABLE_SAVE_FIELD_DOCUMENT,
    payload: {
      documentId,
      disabledFields
    }
  };
}


export interface IFormSetDocumentSessionAction extends Action {
  payload: {
    documentSession: any;
    documentId: string;
  };
}

export function formSetDocumentSession(documentSession: any, documentId: string): IFormSetDocumentSessionAction {
  return {
    type: FORM_SET_DOCUMENT_SESSION,
    payload: {
      documentSession,
      documentId
    }
  };
}

export interface IFormInitAppStoreSessionAction extends Action { 
  payload: {
    appStoreId: string
  }
}

export function formInitAppStoreSession(appStoreId: string): IFormInitAppStoreSessionAction {
  return {
    type: FORM_INIT_APP_STORE_SESSION,
    payload: { appStoreId }
  };
}

export interface IFormSetRulesStatusAction extends Action {
  payload: {
    templateId: string;
    status: string;
  };
}

export function formSetRulesStatus(templateId: string, status: string): IFormSetRulesStatusAction {
  return {
    type: FORM_SET_RULES_STATUS,
    payload: {
      templateId,
      status
    }
  };
}

export interface IFormSetAltTemplateInfoAction extends Action {
  payload: {
    documentId: string,
    templateId: string
  }
}

export function formSetAltTemplateInfo(documentId: string, templateId: string): IFormSetAltTemplateInfoAction {
  return {
    type: FORM_SET_ALT_TEMPLATE_INFO,
    payload: {
      documentId,
      templateId
    }
  }
}