<div class="ft-offline-status" *ngIf="
  (offlineStatus?.cachingApp && offlineStatus?.currentStaticResourceCount > 0)
  || offlineStatus?.cacheLoading > 0 
  || offlineStatus?.cacheLoading > 0
  || offlineStatus?.maxCacheCount > 0
  || offlineStatus?.pendingDocumentCount > 0
  || offlineStatus?.tileServerCacheCount > 0
  || offlineStatus?.errorCacheAttachedFile.length > 0">

    <button type="button" id="btn-cache-details" class="cacheItems" mat-icon-button [matMenuTriggerFor]="cacheItems" aria-label="Caching info"
            [style.color]="offlineStatus?.errorCacheAttachedFile.length > 0 ? 'red' : null">
        <mat-icon >cached</mat-icon>
    </button>

    <mat-menu #cacheItems="matMenu" class="cache-menu-wrap" backdropClass="cache-menu-wrap">

        <div mat-menu-item class="f-flex" *ngIf="offlineStatus?.cachingApp && offlineStatus?.currentStaticResourceCount > 0">
            <div class="f-flex f-flex-align-items-center">
                <mat-progress-spinner
                    class="cache-appValue"
                    [diameter] = "40"
                    [mode]="mode"
                    [value]="appValue">
                </mat-progress-spinner>
                <div class="f-flex f-flex-column f-flex-align-items-center">
                    <p id="spnStaticCacheCount">Caching App : </p>
                    <div id="offline-static-count" class="f-flex" style="margin-bottom: 10px;">
                      <mat-chip color="primary">{{ offlineStatus?.currentStaticResourceCount }} of {{ offlineStatus?.totalStaticResourceCount }} </mat-chip>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider *ngIf="(offlineStatus?.cacheLoading || offlineStatus?.currentCacheCount > 0)"></mat-divider>
        <div id="offline-cache-count" mat-menu-item class="f-flex"
             *ngIf="(offlineStatus?.cacheLoading || offlineStatus?.currentCacheCount > 0)
             && !(offlineStatus?.currentCacheCount === offlineStatus?.maxCacheCount || offlineStatus?.currentCacheCount < 1)">
            <div class="f-flex f-flex-align-items-center">
                <mat-progress-spinner
                    class="cache-dataValue"
                    [diameter] = "40"
                    [mode]="mode"
                    [value]="dataValue">
                </mat-progress-spinner>
                <div id="spnCurrentCacheCount" class="f-flex f-flex-column f-flex-align-items-center">
                    <p>Caching Documents : </p>
                    <div class="f-flex" style="margin-bottom: 10px;" *ngIf="!(offlineStatus?.currentCacheCount === offlineStatus?.maxCacheCount || offlineStatus?.currentCacheCount < 1)">
                        <mat-chip>{{ offlineStatus?.currentCacheCount }} of {{ offlineStatus?.maxCacheCount }} </mat-chip>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider *ngIf="(offlineStatus?.cacheLoading || offlineStatus?.currentCacheAttachedFileCount > 0)"></mat-divider>
        <div id="offline-cache-attached-file-count" mat-menu-item class="f-flex"
             *ngIf="(offlineStatus?.cacheLoading || offlineStatus?.currentCacheAttachedFileCount > 0)
             && !(offlineStatus?.currentCacheAttachedFileCount === offlineStatus?.maxCacheAttachedFileCount)">
          <div class="f-flex f-flex-align-items-center">
            <mat-progress-spinner
              class="cache-fileValue"
              [diameter] = "40"
              [mode]="mode"
              [value]="fileValue">
            </mat-progress-spinner>
            <div id="spnCurrentCacheAttachedFileCount" class="f-flex f-flex-column f-flex-align-items-center">
              <p>Caching Attached Files : </p>
              <div class="f-flex" *ngIf="!(offlineStatus?.currentCacheAttachedFileCount < 1)">
                <mat-chip>{{ offlineStatus?.currentCacheAttachedFileCount }} of {{ offlineStatus?.maxCacheAttachedFileCount }} </mat-chip>
              </div>
            </div>
          </div>
        </div>

        <div mat-menu-item class="f-flex" *ngIf="offlineStatus?.pendingDocumentCount > 0">
            <div class="f-flex f-flex-align-items-center">
                <div class="f-flex f-flex-column f-flex-align-items-center">
                    <p>Uploading offline documents...</p>
                    <mat-chip>{{ offlineStatus?.pendingDocumentCount }}</mat-chip>
                </div>
            </div>
        </div>

        <div mat-menu-item class="f-flex" *ngIf="offlineStatus?.maxCacheCount > 0">
          <div class="f-flex f-flex-align-items-center">
              <div class="f-flex f-flex-column f-flex-align-items-center">
                  <mat-progress-spinner
                    class="cache-appValue"
                    [diameter] = "40"
                    [mode]="mode"
                    [value]="(offlineStatus?.currentCacheCount / offlineStatus?.maxCacheCount) * 100">
                  </mat-progress-spinner>
                  <div class="f-flex f-flex-column f-flex-align-items-center">
                    <p>Downloading offline documents...</p>
                    <div class="f-flex">
                      <mat-chip>{{ offlineStatus?.currentCacheCount }} of {{ offlineStatus?.maxCacheCount }}</mat-chip>
                    </div>
                  </div>
              </div>
          </div>
      </div>

        <mat-divider *ngIf="!(offlineStatus?.tileServerCacheCount === offlineStatus?.tileServerMaxCacheCount ||
        offlineStatus?.tileServerCacheCount < 1)"></mat-divider>
        <div mat-menu-item class="f-flex"
             *ngIf="!(offlineStatus?.tileServerCacheCount === offlineStatus?.tileServerMaxCacheCount ||
                     offlineStatus?.tileServerCacheCount < 1)">
            <div class="f-flex f-flex-align-items-center">
                <mat-progress-spinner
                    class="cache-mapValue"
                    [diameter] = "40"
                    [mode]="mode"
                    [value]="mapValue">
                </mat-progress-spinner>

                <div class="f-flex f-flex-column f-flex-align-items-center">
                    <p>Caching map tiles : </p>
                    <div class="f-flex">
                        <mat-chip> {{ offlineStatus?.tileServerCacheCount }} of {{ offlineStatus?.tileServerMaxCacheCount }}</mat-chip>
                    </div>
                </div>
            </div>
        </div>

      <mat-divider *ngIf="(offlineStatus?.errorCacheAttachedFile.length > 0)"></mat-divider>
        <div mat-menu-item class="f-flex"  *ngIf="offlineStatus?.errorCacheAttachedFile.length > 0 ">
            <div mat-icon-button [matMenuTriggerFor]="cacheErrors"  (menuOpened)="errorMenuClick(true);" (menuClosed)="errorMenuClick(false);"
               (click)="$event.stopPropagation();$event.preventDefault();" style="">
              <mat-icon style="margin-right: 0px; color: red" title="{{isMenuOpened ? 'collapse' : 'expand'}} error details.">
                {{isMenuOpened ? 'remove' : 'add' }}</mat-icon>
              <a style="color: red">Errors on caching attached files</a>

              <mat-icon style="margin-right: 0px;"
                        (click)="$event.stopPropagation();$event.preventDefault();clearErrorCachingAttachedFiles();"
                        title="Clear errors"> clear</mat-icon>
              </div>

        </div>

        <mat-divider class="f-flex" *ngIf="(offlineStatus?.errorOfflineChangesSyncDocs.length > 0)"></mat-divider>
        <div mat-menu-item  *ngIf="offlineStatus?.errorOfflineChangesSyncDocs.length > 0 ">
          <div mat-icon-button [matMenuTriggerFor]="syncErrors"  (menuOpened)="errorMenuClick(true, 'syncDoc');" (menuClosed)="errorMenuClick(false, 'syncDoc');"
             (click)="$event.stopPropagation();$event.preventDefault();" style="">
            <mat-icon style="margin-right: 0px; color: red" title="{{isErrorSyncMenuOpened ? 'collapse' : 'expand'}} error details.">
              {{isErrorSyncMenuOpened ? 'remove' : 'add' }}</mat-icon>
            <a style="color: red">Errors on syncing offline changes</a>

            <mat-icon style="margin-right: 0px;"
                      (click)="$event.stopPropagation();$event.preventDefault();clearErrorOfflineChangesSyncDocs();"
                      title="Clear errors"> clear</mat-icon>
            </div>

        </div>

    </mat-menu>


    <mat-menu #cacheErrors="matMenu" class="cache-menu-cache-errors-wrap">
      <div *ngFor="let err of offlineStatus?.errorCacheAttachedFile; let i = index" style="white-space: nowrap;">
        {{i+1}}: {{ err }}
      </div>
    </mat-menu>

    <mat-menu #syncErrors="matMenu" class="cache-menu-sync-errors-wrap">
      <div *ngFor="let err of offlineStatus?.errorOfflineChangesSyncDocs; let i = index" style="white-space: nowrap;">
        {{i+1}}: {{ err }}
      </div>
    </mat-menu>
</div>
<mat-card id="swUpdateProgressIndicator">
    <button mat-icon-button class="close-button" (click)="closeProgress();">
        <span class="material-icons">close</span>
    </button>
    <div class="cache-icon-wrap f-flex f-flex-column f-flex-align-items-center">
        <div class="sw-update-label f-flex f-flex-align-items-center">
            <span class="material-icons">system_update </span>
            <h5>App is being installed...</h5>  
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</mat-card>


