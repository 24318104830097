import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SharedUrlRoutes,
  UtilArray,
  UtilError,
  UtilHttpStatus,
  UtilType
} from '@formbird/shared';
import { FtError, SharedConstants } from '@formbird/types';
import { DocumentSessionService } from '../document/document-session.service';
import { IndexedDBService } from '../indexeddb/indexed-db.service';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { OfflineWebWorkerOptions } from '../offline/offline-web-worker-options.type';
import { WebWorkerMessageType } from '../offline/offline-web-worker-message.type';
import { LoggedInUserService } from '../user/logged-in-user.service';
import { UtilSequenceProcess } from "@formbird/shared";
import { ConfigService } from '../config/config.service';
import { UtilWebWorker } from '../../utils/UtilWebWorker';
import { AppStoreSessionService } from '../session/app-store-session.service';
import { lastValueFrom } from 'rxjs';
import { IndexedDBConstants } from '@formbird/indexed-db';

const serverRoutes = SharedUrlRoutes.serverRoutes;

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class RestDataService {

  constructor(
    private http: HttpClient,
    private loggedInUserService: LoggedInUserService,
    private offlineStatusService: OfflineStatusService,
    private indexedDBService: IndexedDBService,
    private documentSessionService: DocumentSessionService,
    private configService: ConfigService,
    private appStoreSessionService: AppStoreSessionService
  ) {
  }

  public async checkDocumentExists(url, id, includeDeleted?) {
    try {
      let fullUrl = url;
      if (id) {
        fullUrl += '/' + id;
      }

      if (includeDeleted === true) {
        fullUrl += '/' + includeDeleted;
      }

      return await this.http.get(fullUrl).toPromise();
    } catch (err) {
      const msg = err ? err.message : 'Something went wrong when checking document exists.';
      const error = new FtError(msg);
      if (err) {
        error.status = err.status;
        error.statusText = err.statusText;
        error.message = err.message;
      }

      throw error;
    }
  }

   getTemplateAndDocument = async (id: string, templateId?: string): Promise<any> => {
    try {
      let url = SharedUrlRoutes.serverRoutes.templateAndDocument + '/' + id;
      if (templateId) {
        url = url + '/' + templateId;
      }

      return await this.http.get(url).toPromise();
    } catch (err) {
      const msg = err ? err.message : 'Something went wrong when getting template and document.';
      const error = new FtError(msg);

      if (err) {
        error.status = err.status;
        error.statusText = err.statusText;
        error.message = err.error;

        if (err.status === UtilHttpStatus.SERVER_UNRESPONSIVE) {
          error.message = 'Network connection lost. Could not retrieve.';
        }
      }

      throw error;
    }
  }

  public getDocumentObservable(id, expectedDocumentType?) {
    let fullUrl = serverRoutes.document + '/' + id;
    if(expectedDocumentType){
      fullUrl += "?expectedDocumentType=" + expectedDocumentType
    }
    return this.http.get(fullUrl);
  }

  public async getDocument(id, expectedDocumentType?) {
    try {
      return await this.getDocumentObservable(id, expectedDocumentType).toPromise();
    } catch (err) {
      let msg = err ? err.message : 'Something went wrong when getting document.';
      if (err.error instanceof ProgressEvent) {
        msg = 'Network Error.'
      }

      const error = new FtError(msg);

      if (err && err.status) {
        error.status = err.status;
        error.statusText = err.statusText;
        error.message = err.error;
      }

      throw error;
    }
  }

  public workerLoadDataCache(syncStartTime, isInitialize?) {
    const _self = this;
    return new Promise(async (resolve, reject) => {


      const workerOptions: OfflineWebWorkerOptions = {
        syncStartTime,
        syncPageSize: 0,
        currentCacheCount: 0,
        isInitialize: isInitialize || true,
        cachingEnableDate: _self.loggedInUserService.getUserConfigItem('cachingEnableDate')
      };

      const offlineUserConfig: any = _self.configService.clientConfig().offline;

      let syncWorker: any = await UtilWebWorker.initWebWorker('./assets/offline-sync-rs.worker.js', { type: 'module' });
      workerOptions.syncPageSize = offlineUserConfig?.syncPageSize || 10000;
      workerOptions.currentCacheCount = _self.loggedInUserService.getUserConfigItem('currentCacheCount');

      syncWorker.postMessage(workerOptions);
      syncWorker.onmessage = onMessage;

      function onMessage({ data }) {
        logger.log(`page got message: ${WebWorkerMessageType[data?.messageType]}`);
         if (!_self.offlineStatusService.isCachingEnabled()) {
           syncWorker.terminate();
           syncWorker = undefined;
           reject("Error on caching and terminating initial documents sync.");
         }
        if (data?.messageType === WebWorkerMessageType.ADD_TO_MAX_CACHE_COUNT) {
          // count is sent in data.value by OfflineStatusWebWorkerService
          _self.offlineStatusService.setMaxCacheCount(data.value);
        }
        if (data?.messageType === WebWorkerMessageType.ADD_TO_CURRENT_CACHE_COUNT) {
          _self.offlineStatusService.offlineStatus.currentCacheCount += (data.value);
          _self.loggedInUserService.setUserConfigItem('currentCacheCount', _self.offlineStatusService.offlineStatus.currentCacheCount);
        }
        if (data?.messageType === WebWorkerMessageType.SET_PAGE_DOWNLOAD_PROGRESS) {
          if (data.value && data.value.loaded){
            _self.offlineStatusService.pageDownloadProgress.loaded = data.value.loaded;
          }
          if (data.value && data.value.total){
            _self.offlineStatusService.pageDownloadProgress.total = data.value.total;
          }
          if (data.value && data.value.pageNumber){
            _self.offlineStatusService.pageDownloadProgress.pageNumber = data.value.pageNumber;
          }
        }
        if (data?.messageType === WebWorkerMessageType.SET_INITIAL_CACHE_QUERY_SUCCESS) {
          _self.offlineStatusService.initialCacheQuerySuccess(data.value);
        }
        if (data?.messageType === WebWorkerMessageType.SET_LAST_SYNC_START_TIME) {
           _self.offlineStatusService.setLastSyncDate(data.value);
        }
        if (data?.messageType === WebWorkerMessageType.INITIAL_CACHE_COMPLETED) {
          _self.loggedInUserService.setUserConfigItem('cachingEnableDate', 0);
          if (data.value.error){
            reject(data.value.error);
          } else{
            resolve(data.value.result);
          }

        }
    };




    });
  }

  private processCachingDocuments(documents, isInitialize?, updateOfflineStatus?) {

    const _self = this;

    return new Promise((resolve, reject) => {

      const promises = [];
      if (isInitialize === true){
        promises.push(_self.indexedDBService.doCacheBulkDocuments(documents, updateOfflineStatus));

      }else {
        for (let i = 0; i < documents.length; i++) {

          const document = documents[i];
          if (isInitialize === false) {
            promises.push(_self.indexedDBService.cachePushedDocument(document, [document], updateOfflineStatus));
          } else {
            promises.push(_self.indexedDBService.doCacheDocument(document, updateOfflineStatus));
          }
        }

      }

      Promise.all(promises).then(
        function successFunction(results) {
          resolve(results);
        },
        function errorFunction(err) {
          _self.indexedDBService.checkQuotaExceededError(err);
          reject(new Error(err.message));
        }
      );

    });
  }

  public async getIndexedDBIndexes(): Promise<string[]> {
    const options = {
      filter: {
        query: {
          bool: {
            must: [
              {
                term: {
                  'systemHeader.systemType': SharedConstants.SYSTEM_TYPE_OFFLINE_INDEX
                }
              }
            ]
          }
        }
      },
      sourceFilter: 'indexedDBIndexes'
    };

    const results: any = await this.search(options);
    const hits = results.data?.hits?.hits;

    if (hits && hits.length > 0) {
      if (hits.length > 1) {
        throw new Error('There can only be one offline index document per user.');
      }

      const offlineIndexDoc = hits[0]._source;
      return offlineIndexDoc.indexedDBIndexes;
    }

    return [...IndexedDBConstants.DOCUMENT_INDEXES];
  }

  // get full rest url from a url without the host and webapp section, so a url of 'cases' will become
  // {hostname}:{port}/{webappname}/cases. eg localhost:8090/focus-rest/cases
  private getFullRestURL(url, id?) {
    let fullUrl = url;

    if (UtilType.hasValue(id)) {
      fullUrl += '/' + id;
    }

    return fullUrl;
  }

  /**
   * As per 10539, we send the new document directly to the server and let it run jsonPatch.
   * For simplicity, other parameters from the old deep Update was
   * removed because the server can resolve them with just the newDocument value.
   *
   * @param newDocument the new document to update
   * @returns promise|*|jQuery.promise the promise
   */
  public async jsonPatchUpdate(newDocument, options) {
    const throwError = (err) => {
      console.log(err);
      let errorMessage = err.error || 'Something went wrong when updating document.'
      if (err.error instanceof ProgressEvent) {
        errorMessage = 'Could not save due to lost network connection in save.'
      }
      
      throw new Error(errorMessage);
    };

    const updateUrl = SharedUrlRoutes.serverRoutes.documentDeepDiffUpdate + '/' + newDocument.documentId;

    try {
      
      let body;
      
      if (options.updates?.updates?.length > 0) {

        // only send updates if defined
        body = this.setupRequestBody(null, options);

      } else {
        body = this.setupRequestBody(newDocument, options);
      }

      return await this.http.put(updateUrl, body).toPromise();
    
    } catch (err) {
      if (err && err.status === 422) {  // Retry with updated body
      
        try {
      
          let body = this.setupRequestBody(newDocument, options);
      
          return await this.http.put(updateUrl, body).toPromise();
      
        } catch (err) {
          throwError(err);
        }
      
      } else {
        throwError(err);
      }
    }
  }

  /*
   insert function
   parameters:
   bean - the bean to insert
   url - the url for the restful web service. This will also be used as a key in the database
   successFunction - a function to execute on success to do post save steps
   cachingRequired - whether to save the record to the local database. Records that never need
   to be loaded in the app on their own, such as events, can have this set to false
   */
  public async insert(bean, url) {
    try {
      const fullUrl = this.getFullRestURL(url, bean.id);
      const body = this.setupRequestBody(bean);

      return await this.http.post(fullUrl, body).toPromise();
    } catch (err) {
      let errorMessage = err.error || 'Something went wrong when inserting document.'
      if (err.error instanceof ProgressEvent) {
        errorMessage = 'Could not save due to lost network connection in save.'
      }
      
      throw new Error(errorMessage);
    }
  }

  private setupRequestBody(document, options?) {
    if (!options) {
      options = {};
    }
    if (!options.documentSession) {
      options.documentSession = this.documentSessionService.getDocumentSession(document.documentId);
    }
    if (!options.sourceId) {
      options.sourceId = this.appStoreSessionService.getAppStoreSessionId();
    }

    return {
      document,
      options
    };
  }

  /*
   destroy function
   parameters:
   id - the id of the bean to delete
   url - the url for the restful web service
   cachingRequired - whether to save the record to the local database. Records that never need
   templateId - template used when deleting document
   versionId - version of document during deletion
   to be loaded in the app on their own, such as events, can have this set to false
   */
  public async destroy(id, url, cachingRequired, templateId, previousVersionId) {
    try {
      const fullUrl = this.getFullRestURL(url, id) + '/' + templateId + '/' + previousVersionId;

      const data = {
        body: this.setupRequestBody({documentId: id})
      }

      return await this.http.delete(fullUrl, data).toPromise();
    } catch (err) {
      console.log(err);
      const error = err ? new Error(err.error) : new Error('Something went wrong when destroying document.');
      throw error;
    }
  }

  public async generatePit() {
    return await lastValueFrom(this.http.get('api/generatePit'));
  }

  public async aggregate(query) {
    try {
      return await this.http.post(serverRoutes.aggregate, { query }, {
        headers: { 'Content-Type': 'application/json' }
      }).toPromise();
    } catch (err) {
      throw new Error('Error on aggregate: ' + err.error || err);
    }
  }

  public async search(options) {
    try {
      return await this.http.post('api/documentSearch', options, {
        headers: { 'Content-Type': 'application/json' }
      }).toPromise();
    } catch (err) {
      let msg;
      if (err && err.error) {
        msg = err.error || err.message;
      } else {
        msg = 'Something went wrong when searching document. Status: ' + err.status;
      }

      if (err && err.status === UtilHttpStatus.SERVER_UNRESPONSIVE) {
        throw UtilError.createError(msg, UtilError.SERVER_UNRESPONSE_ERROR);
      } else {
        throw new Error(msg);
      }
    }
  }

  public async loadVersions(versionIds): Promise<any> {
    if (!versionIds || !versionIds.length) {
      return null;
    }

    try {
      const url = SharedUrlRoutes.serverRoutes.versionFetch;
      const options = {
        versionIds: versionIds,
        'includeAllVersions': true
      };

      return await this.http.post(url, options).toPromise();
    } catch (err) {
      const msg = 'Error requesting versions. Status: ' + err.status;
      logger.error(err.message);
      throw new Error(msg);
    }
  }

  /*
   undestroy function
   parameters:
   id - the id of the bean to undelete
   url - the url for the restful web service
   */
  public async undestroy(id, url) {
    const fullUrl = this.getFullRestURL(url, id);
    try {
      return await this.http.put(fullUrl, null).toPromise();
    } catch (err) {
      const error = err ? new Error(err.error) : new Error('Something went wrong when undestroying document.');
      throw error;
    }
  }

  /*
  bulkUploadDocuments function
  parameters:
  documents - the array of documents to upload.
  url - the url for the restful web service
  */
  public async bulkUploadDocuments(documents, url) {
    try {
      const fullUrl = this.getFullRestURL(url);
      return await this.http.post(fullUrl, documents).toPromise();
    } catch (err) {
      const error = err ? new Error(err) : new Error('Something went wrong when uploading documents.');
      throw error;
    }
  }


  public async executeComponentFunction(options) {
    try {
      return await this.http.post('api/execute', options,
        { headers: { 'Content-Type': 'application/json' } }).toPromise();
    } catch (err) {
      let msg;
      logger.error(err);
      if (err && err.error){
        msg = err.error;
      } else if (err && err.message) {
        msg = 'executing component function failed with error: ';
        msg += err.message;
      } else {
        msg = 'Something went wrong when executing component function. Status: ' + err.status;
      }

      if (err && err.status === UtilHttpStatus.SERVER_UNRESPONSIVE) {
        throw UtilError.createError(msg, UtilError.SERVER_UNRESPONSE_ERROR);
      } else {
        throw new Error(msg);
      }
    }
  }

  async loadComponentDoc(componentName) {
    const url = 'api/loadComponentDoc/' + componentName;

    try {
      const result = await lastValueFrom(this.http.get(url));
      if (result === 404) {
        throw new Error(`Component ${componentName} not found.`);
      }

      return result;
    } catch (err) {
      throw new Error(err.message || 'Something went wrong when getting custom component document.');
    }
  }

  loadFile(fileNo: string) {
    const resp = this.http.get(`${serverRoutes.loadFile}/${fileNo}`, { responseType: 'blob'});
    return lastValueFrom(resp)
  }

}
