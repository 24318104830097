import { UtilDocumentId } from '@formbird/shared';
import { Injectable } from '@angular/core';
import { OfflineStatusService } from '../offline-status/offline-status.service';
import { WebWorkerMessageType } from '../offline/offline-web-worker-message.type';
import { ConfigService } from '../config/config.service';
import { ClientConstants} from '../../constants/ClientConstants';
import { BroadcastService } from '../broadcast/broadcast.service';
import { UtilWebWorker } from '../../utils/UtilWebWorker';
import { NotificationService } from '../notification/notification.service';

const logger = console;
@Injectable({
  providedIn: 'root'
})
export class OfflineUtilService {
  private isPendingPreviousSync = false;

  constructor(
    private offlineStatusService: OfflineStatusService,
    private broadcastService: BroadcastService,
    private configService: ConfigService,
    private notificationService: NotificationService) { }

  getNextId(url?) {
    return UtilDocumentId.generateId();
  }

  async startOfflinePoller() {
      const _self = this;

      let offlinePollerWorker: any ;

      const userAgent = navigator.userAgent
      if (/android/i.test(userAgent) && (/chrome/i.test(userAgent)))  {
        offlinePollerWorker = await UtilWebWorker.initWebWorker('OfflinePoller.js');
      } else {
        offlinePollerWorker = await new SharedWorker('OfflinePoller.js').port;
        offlinePollerWorker.start();
      }


      const pollerOptions: any = {};
      pollerOptions.offlinePollerStart = true;
      pollerOptions.unsyncedRecordCheckTime = this.configService.clientConfig().unsyncedRecordCheckTime;

      setTimeout(function() {
        offlinePollerWorker.postMessage(pollerOptions);
      }, 5000);
      _self.broadcastService.on(ClientConstants.CHECK_UNSYNCED_DOCUMENTS).subscribe(data => {
        if (!_self.isPendingPreviousSync) {
          pollerOptions.checkUnsyncedDocuments = true;
          offlinePollerWorker.postMessage(pollerOptions);
          _self.isPendingPreviousSync = true;
        }

      });

      offlinePollerWorker.onmessage = onMessage;
      function onMessage ({ data })  {
        if (data?.messageType === WebWorkerMessageType.PEINDING_DOCUMENT_COUNT) {
          _self.offlineStatusService.setPendingDocumentCount(data.value);
        } else if (data?.messageType === WebWorkerMessageType.PREVIOUS_SYNC_STATUS) {
          _self.isPendingPreviousSync = false;
        } else if (data?.messageType === WebWorkerMessageType.PRINT_MESSAGE) {
           const args = data.value;
          _self.notificationService.printMessage( args.message, args.options, args.title, args.position,
            args.preventDuplicate, args.displayDesktopNotification, args.onClickFunction,
            args.timeOut, args.isPermanent, args.customClassName );
        }
      }

  }

}
