import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { IApplicationState } from './redux/state/application.state';
import { AppStore } from './redux/store/app.store';
import { ReduxStoreService } from './redux/store/redux-store.service';
import { RootStore } from './redux/store/root.store';
import { getStore } from './redux/store/store';
import { ClientAccessService } from './services/access/client-access.service';
import { BroadcastService } from './services/broadcast/broadcast.service';
import { ComponentService } from './services/component/component.service';
import { DataService } from './services/data/data.service';
import { RestDataService } from './services/data/rest-data.service';
import { ChangedDocumentService } from './services/document/changed-document.service';
import { ClientSystemHeaderService } from './services/document/client-system-header.service';
import { CurrentChildDocumentService } from './services/document/current-child-document.service';
import { CurrentDocumentInfoService } from './services/document/current-document-info.service';
import { CurrentDocumentService } from './services/document/current-document.service';
import { DocumentSaveService } from './services/document/document-save.service';
import { DocumentUpdateService } from './services/document/document-update.service';
import { DocumentService } from './services/document/document.service';
import { FinaliseValueFieldService } from './services/document/finalise-value-field.service';
import { MandatoryFieldService } from './services/document/mandatory-field.service';
import { ModifiedFieldService } from './services/document/modified-field.service';
import { TemplateHtmlService } from './services/document/template-html.service';
import { TemplateService } from './services/document/template.service';
import { UnsavedDocumentService } from './services/document/unsaved-document.service';
import { StorageEventService } from './services/event/storage-event.service';
import { FileProviderService } from './services/file/file-provider.service';
import { DexieSearchService } from './services/indexeddb/dexie-search.service';
import { IndexedDBService } from './services/indexeddb/indexed-db.service';
import { IndexedDBConnectorService } from './services/indexeddb/indexed-dbconnector.service';
import { IndexedDBPropertiesService } from './services/indexeddb/indexed-dbproperties.service';
import { FormColorService } from './services/layout/form-color.service';
import { ModifiedCSSService } from './services/layout/modified-css.service';
import { LazyScriptLoaderService } from './services/lazyload/lazy-script-loader.service';
import { DefaultStateService } from './services/navigation/default-state.service';
import { NavigationService } from './services/navigation/navigation.service';
import { NotificationService } from './services/notification/notification.service';
import { OfflineStatusService } from './services/offline-status/offline-status.service';
import { OfflineUtilService } from './services/offline/offline-util.service';
import { PageVisibilityService } from './services/page-visibility.service';
import { CustomFieldPreprocessorService } from './services/preprocessor/custom-field/custom-field-preprocessor.service';
import { PreProcessorFieldService } from './services/preprocessor/pre-processor-field.service';
import { DatatablesQueriesService, SearchQueriesService } from './services/queries/search-queries.service';
import { ClientResourceService } from './services/resource/client-resource.service';
import { RouterService } from './services/router/router.service';
import { ClientIncludeRuleSetService } from './services/rules/client-include-rule-set.service';
import { ClientRulesService } from './services/rules/client-rules.service';
import { IncludeDocumentDataService } from './services/rules/include-document-data.service';
import { RuleCacheService } from './services/rules/rule-cache.service';
import { RuleEngineService } from './services/rules/rule-engine.service';
import { SearchClientService } from './services/search/search-client.service';
import { SearchFilterService } from './services/search/search-filter.service';
import { SearchService } from './services/search/search.service';
import { SessionTestService } from './services/session/session-test.service';
import { ControlStateSaveService } from './services/state/control-state-save.service';
import { LocalStorageService } from './services/storage/local-storage/local-storage.service';
import { SessionStorageService } from './services/storage/session-storage/session-storage.service';
import { TabService } from './services/tab/tab.service';
import { UrlService } from './services/url/url.service';
import { LoggedInUserService } from './services/user/logged-in-user.service';
import { UserSettingsService } from './services/user/user-settings.service';
import { ValidationService } from './services/validation/validation.service';
import { VendorLibraryService } from './services/vendor-library/vendor-library.service';
import { UrlBasePathService } from './utils/UrlBasePathUtil';
import { KeyValueStorageService } from './services/key-value-storage/key-value-storage.service';
import { PendingOperationService } from './services/pending-operations.service';
import { AppStoreSessionService } from './services/session/app-store-session.service';

// TODO: add dialog to entry files in app.module.ts

export function appStoreFactory() {
  return new RootStore();
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  exports: [],
  providers: [
    { provide: AppStore, useFactory: appStoreFactory },
    LoggedInUserService,
    { provide: LocalStorageService, useClass: LocalStorageService },
    { provide: SessionStorageService, useClass: SessionStorageService },
    { provide: KeyValueStorageService, useClass: KeyValueStorageService },
    DataService,
    ReduxStoreService,
    RestDataService,
    ClientAccessService,
    { provide: BroadcastService, useClass: BroadcastService },
    DocumentService,
    TemplateService,
    ComponentService,
    CurrentDocumentService,
    CurrentChildDocumentService,
    CurrentDocumentInfoService,
    UnsavedDocumentService,
    ChangedDocumentService,
    ClientSystemHeaderService,
    SearchFilterService,
    OfflineStatusService,
    SearchService,
    ControlStateSaveService,
    SessionTestService,
    LazyScriptLoaderService,
    IndexedDBPropertiesService,
    IndexedDBService,
    IndexedDBConnectorService,
    DexieSearchService,
    PageVisibilityService,
    UserSettingsService,
    FileProviderService,
    OfflineUtilService,
    FormColorService,
    ModifiedCSSService,
    DefaultStateService,
    NavigationService,
    ValidationService,
    CustomFieldPreprocessorService,
    PreProcessorFieldService,
    ModifiedFieldService,
    TabService,
    RuleCacheService,
    ClientRulesService,
    ClientIncludeRuleSetService,
    RuleEngineService,
    { provide: NotificationService, useClass: NotificationService },
    DocumentSaveService,
    DocumentUpdateService,
    MandatoryFieldService,
    TemplateHtmlService,
    UrlService,
    ClientResourceService,
    StorageEventService,
    { provide: UrlBasePathService, useClass: UrlBasePathService },
    VendorLibraryService,
    FinaliseValueFieldService,
    IncludeDocumentDataService,
    RouterService,
    DatatablesQueriesService,
    SearchQueriesService,
    SearchClientService,
    PendingOperationService,
    AppStoreSessionService
  ]
})
export class FormbirdServicesModule {
  constructor(appStore: AppStore<IApplicationState>) {
    const store = getStore();
    appStore.provideStore(store);
  }
}
