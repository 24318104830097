import { Component, OnInit } from '@angular/core';
import {LegacyProgressSpinnerMode as ProgressSpinnerMode} from '@angular/material/legacy-progress-spinner';


@Component({
  selector: 'ft-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {

  mode: ProgressSpinnerMode = 'indeterminate';

  constructor() { }

  ngOnInit() {
  }

}

