import { Injectable } from '@angular/core';
import { formSetDocumentSession } from '../../redux/actions';
import { AppStore } from '../../redux/store/app.store';
import { IApplicationState } from '../../redux/state/application.state';
import { select } from '../../redux/decorators/select';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class DocumentSessionService {
  @select(['formState', 'documentSession']) documentSession$: Observable<any>;
  private subs: Subscription = new Subscription();
  private documentSession : any;
  constructor(private appStore: AppStore<IApplicationState>) {
    this.documentSession = {};
    if (this.documentSession$){
      this.documentSession$.subscribe(documentSession => {
        // logger.info("Changed documentSession value : " + JSON.stringify(documentSession));
      });
    }

  }

  initialize(documentId){
    this.setDocumentSession(documentId, {})
  }

  setDocumentSession(documentId, document?) {
    this.appStore.dispatch(formSetDocumentSession(document, documentId));
  }

  getDocumentSession(documentId: string) {
    this.documentSession = cloneDeep(this.appStore.getState().formState.documentSession[documentId]);
    return this.documentSession ? this.documentSession : {};
  }

}
