import { Injectable } from '@angular/core';
import { IApplicationState } from '../../redux/state/application.state';
import { DocumentData, DocumentInfo, FormParameters } from '@formbird/types';
import { cloneDeep } from 'lodash';
import { AppStore } from '../../redux/store/app.store';

@Injectable({
  providedIn: 'root'
})
export class CurrentDocumentInfoService {

  constructor(
    private appStore: AppStore<IApplicationState>
  ) {
  }

  getStoreDocumentInfo(): DocumentInfo {
    return this.appStore.getState().formState.documentInfo;
  }

  getDocumentData(unsavedDocumentListId, selectedDocumentId, overrideTemplateId?): DocumentData {

    const documentData: DocumentData = {};

    if (unsavedDocumentListId) {

      const storeDocInfo = this.getStoreDocumentInfo();
      const documentInfo = storeDocInfo[unsavedDocumentListId] ? storeDocInfo[unsavedDocumentListId] : {};
    
      const documentInfoItem = documentInfo[selectedDocumentId];
      if (documentInfoItem) {

        documentData.documentId = selectedDocumentId;
        documentData.document = cloneDeep(this.appStore.getState().formState.documents[selectedDocumentId]);
        const templateId = overrideTemplateId ? overrideTemplateId : documentInfoItem.templateId;
        documentData.templateId = templateId;
        documentData.template = cloneDeep(this.appStore.getState().formState.templates[templateId]);
        documentData.isNew = documentInfoItem.isNew;
        documentData.isMainDoc = documentInfoItem.isMainDoc;
      }
    }
    
    return documentData;
  }

  getMainDocumentListId(): string {
    
    const documentInfo = this.appStore.getState().formState.documentInfo;
    
    const unsavedDocumentListIds = Object.keys(documentInfo);
    
    return unsavedDocumentListIds.filter((unsavedDocumentListId: string) => {
      return this.getMainDocumentIdFromList(unsavedDocumentListId) !== null;
    })[0];
  }

  getMainDocumentId() {
    const mainDocumentListId = this.getMainDocumentId();
    return this.getMainDocumentIdFromList(mainDocumentListId);
  }

  getMainDocumentIdFromList(unsavedDocumentListId) {
    
    const documentListInfo = this.appStore.getState().formState.documentInfo[unsavedDocumentListId];
    
    if (documentListInfo) {
      const documentIds = Object.keys(documentListInfo);
      const filteredIds = documentIds.filter((documentId: any) => {
        return documentListInfo[documentId]?.isMainDoc;
      });
      if (filteredIds.length) {
        return filteredIds[0];
      }
    }

    return null;
  }


}
