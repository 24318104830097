import { Injectable } from '@angular/core';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<any> {
    canDeactivate(component: any,
        // don't remove unused params
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot) {
    return component.canDeactivate(nextState);
  }
}
