import { Injectable } from '@angular/core';
import { KeyboardShortcutService } from './keyboard-shortcut.service';
import { FormbirdInjectorService } from './injector/formbird-injector.service';
import { ModalService } from '@services/modal/modal.service';
import { ListenServiceWorkerService } from '@services/listen-service-worker';
import {
  ClientRulesService,
  DataService,
  LoggedInUserService,
  LocalStorageService,
  LocalStorageKeys,
  NotificationService,
  OfflineStatusService,
  SessionTestService,
  StorageEventService,
  WebSocketService,
  PageVisibilityService,
  ClientConstants,
  OfflineUtilService, select,
  AppStoreSessionService
} from '@formbird/services';
import { StateChangeService } from './state-change/state-change.service';
import { CacheLoadService } from './cache-load.service';
import { AppVersion } from '../AppVersion';
import { AuthenticationService } from './authentication/authentication.service';
import { SharedConstants, User } from '@formbird/types';
import { Observable } from 'rxjs';
import { CacheMapTilesService } from './tileserver-gl/cache-map-tiles.service'

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class InitialisationService {
  @select(['userState', 'user']) user$: Observable<User>;
  user: any;

  constructor(
    private clientRulesService: ClientRulesService,
    private webSocketService: WebSocketService,
    private storageEventService: StorageEventService,
    private notificationService: NotificationService,
    private keyboardShortcutService: KeyboardShortcutService,
    private modalService: ModalService,
    private dataService: DataService,
    private offlineStatusService: OfflineStatusService,
    private stateChangeService: StateChangeService,
    private loggedInUserService: LoggedInUserService,
    private localStorageService: LocalStorageService,
    private cacheLoadService: CacheLoadService,
    private sessionTestService: SessionTestService,
    private formbirdInjectorService: FormbirdInjectorService,
    private pageVisibilityService: PageVisibilityService,
    private authenticationService: AuthenticationService,
    private listenServiceWorkerService: ListenServiceWorkerService,
    private offlineUtilService: OfflineUtilService,
    private appStoreSessionService: AppStoreSessionService,
    private cacheMapTilesService: CacheMapTilesService
  ) {
    this.user$.subscribe((user: User) => this.user = user);
  }

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.listenServiceWorkerService.listenServiceWorkerMessage();
      this.sessionTestService
        .testSession()
        .then(
          res => {
            //mantis #17831 - if logout when online/offline when caching enabled, we need to display login form
            // instead of form is displayed when Network connection is lost and because the network request can't be made.
            if (res==="" && !this.localStorageService.getItem(LocalStorageKeys.LOGGED_IN_USER_ID)){
              this.authenticationService.showLogin();
              reject('Unauthorized');
            } else {
              // set status to connected as soon as the connection has been successfully shown to be online so that the online
              // indicator will not start as red and then change to green
              this.appStoreSessionService.initialize();
              this.offlineStatusService.setConnected();
              resolve();
            }
          },
          err => {
            // an error of 401 is expected if the user is not logged in
            if (err.status === 401) {
              // this indicates the user needs to be logged in, so they will be taken to the login screen on
              // initialisation
              reject('Unauthorized');
            } else {
              reject(err);
            }
          }
        );
    });
  }

  private setupDefaultServices() {
    // start the websocket service so that it can connect on regaining the connection if offline
    this.storageEventService.start();
    // this.notificationService.setupAlert(this.$rootScope, this.configService.clientConfig().maxAlertMessages);
    this.keyboardShortcutService.initialise(); // gets unbound on scope destroy
    this.pageVisibilityService.startListeningForPageVisibility();
  }

  terminateDefaultServices() {
    this.webSocketService.end();
    this.storageEventService.end();
    this.notificationService.reverseSetupAlert();
    // note that InitialisationCtrl initialised DataService
    this.dataService.reset();
    this.pageVisibilityService.stopListeningForPageVisibility();
  }

  async postLoginInitialise() {
    this.offlineStatusService.setConnected();

    try {
      await this.loggedInUserService.setLoggedUser();

      await this.dataService.initialise();
      await this.stateChangeService.handlePostLoginSuccess();

      try{
        const connected = await this.offlineStatusService.checkServerConnectionStatus();
        if (connected) {
          this.dataService.loadDataCache(false);
          if (!this.loggedInUserService.getUserConfigItem('initialCacheCompleted') && this.offlineStatusService.isCachingEnabled()) {
            this.cacheMapTilesService.loadDataCache();
            this.cacheLoadService.cacheStaticAssets();
          }
        }
      } catch (err){
          logger.error('Error loading data cache: ' + err);
      }
      this.webSocketService.start();
      if (this.loggedInUserService.getUserConfigItem('toEnableCache') === true) {
        this.offlineInitialise();
        this.enableCaching();
        // caching now enabled
        this.loggedInUserService.setUserConfigItem('toEnableCache', false);
      } else if (this.offlineStatusService.isCachingEnabled()) {
        //let offlineInitialise start the services and call handlePostLoginSuccess
        this.offlineInitialise();
        this.offlineStatusService.offlineStatus.errorCacheAttachedFile =
          this.loggedInUserService.getUserConfigItem('ErrorCachingAttachedFiles') ?
            this.loggedInUserService.getUserConfigItem('ErrorCachingAttachedFiles') : [];

        this.offlineStatusService.offlineStatus.errorOfflineChangesSyncDocs =
          this.loggedInUserService.getUserConfigItem('ErrorOfflineChangesSyncDocs') ?
            this.loggedInUserService.getUserConfigItem('ErrorOfflineChangesSyncDocs') : [];

        const pendingCount = this.loggedInUserService.getUserConfigItem('offlinePendingDocumentsCount') ?
          this.loggedInUserService.getUserConfigItem('offlinePendingDocumentsCount') : 0;
        this.offlineStatusService.setPendingDocumentCount(pendingCount);
        this.offlineStatusService.setCachingEnabledStatus(true);

        this.offlineUtilService.startOfflinePoller();

      } else {
        this.setupDefaultServices();
      }

      const lastEnteredUrl = this.localStorageService.getItem(ClientConstants.LAST_ENTERED_STATE_URL);
      if (lastEnteredUrl) {
        logger.info("Last entered state: " + lastEnteredUrl);
        this.localStorageService.removeItem(ClientConstants.LAST_ENTERED_STATE_URL);
        window.location.href = lastEnteredUrl;
      }

      this.webSocketService.addEventListener(SharedConstants.WEB_SOCKET_MESSAGE_SIGN_OUT_ALL_DEVICE, ( userId) => {
        if (userId === this.user?.account?.documentId) {
          this.notificationService.info('You have been signed out of all devices.');
          this.authenticationService.showLogin();
        }
      });

    } catch (err) {
      const message = 'Error on post login initialization : ' + err.message;
      logger.error(message);
      this.notificationService.error(message);
      const reload = () => window.location.reload();
      this.modalService.showDialog(message + ' <br/> Reload the page?', 'Warning', reload, null, null, reload);
    }
  }

  async offlineInitialise() {//Use this all the time to enable caching.
    try {
      await this.cacheLoadService.restoreOfflineUserCredentials();
      logger.info('User credentials successfully restored. Setting up default services...');
      this.setupDefaultServices();
      // the app needs to behave as though logged in. If the user's session is no longer valid
      // when they come online they will need to login again to be able to sync the documents
      // saved offline and continue to use the app
      await this.stateChangeService.handlePostLoginSuccess();
    } catch (err) {
      logger.error(err);
    }
  }

  async enableCaching() {
    const controlDoc = this.loggedInUserService.getUserControlDocument();
    const hasOfflineAccess = controlDoc.accessKeys
      .some(key => key.rights.includes(SharedConstants.OPERATION_TYPE_OFFLINE));
      
    if (hasOfflineAccess) {
      try {
        await this.cacheLoadService.enableCaching();
        const msg = 'Loaded offline data';
        logger.info(msg);
        return msg;
      } catch (err) {
        logger.error('Error loading offline data: ' + err);
        logger.error(err);
        throw err;
      }
    } else {
      throw new Error('You do not have any documents configured for offline. Caching cannot be enabled.');
    }
  }

  /**
   * set up service to be called globally from components and other code external to the Angular app
   */
  setupServices() {
    this.formbirdInjectorService.setupServices();
    this.clientRulesService.setModalService(this.modalService);
  }

  async setupAppInfo() {
    const appVersion = AppVersion.FIELDTEC_APP_VERSION;
    this.localStorageService.setItem(LocalStorageKeys.APP_VERSION, appVersion);
  }
  
}
