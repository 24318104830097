import { LoggedInUserService } from '../user/logged-in-user.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { merge } from 'lodash';
import { ClientConstants } from '../../constants/ClientConstants';
import { OfflineStatus, PageDownloadProgress, LastTileSyncDetails } from '@formbird/types';
import { IApplicationState } from '../../redux/state/application.state';
import { AppStore } from '../../redux/store/app.store';
import { offlineSetOfflineStatus } from '../../redux/actions/offline.actions';
import { BroadcastService } from '../broadcast/broadcast.service';
import { KeyValueStorageService} from '../key-value-storage/key-value-storage.service';
import { SessionTestService } from '../session/session-test.service';

@Injectable()
export class OfflineStatusService {
  public offlineStatus: OfflineStatus;
  private offlineStatusSubject: Subject<OfflineStatus>;
  offlineStatus$: Observable<OfflineStatus>;
  pageDownloadProgress: PageDownloadProgress;

  constructor(
    private loggedInUserService: LoggedInUserService,
    private appStore: AppStore<IApplicationState>,
    private broadcastService: BroadcastService,
    private keyValueStorageService: KeyValueStorageService,
    private sessionTestService: SessionTestService
  ) {

    this.offlineStatus = {
      unsyncedCount: 0,
      connected: true,
      incomingCacheCount: 0,
      maxCacheCount: 0,
      currentCacheCount: 0,
      cacheLoading: false,
      pendingDocumentCount: 0,
      errorCacheCount: 0,
      cachingApp: false,
      initialCachingComplete: true,
      connectionText: null,
      currentStaticResourceCount: 0,
      totalStaticResourceCount: 0,
      tileServerCacheCount: 0,
      tileServerMaxCacheCount: 0,
      errorCacheAttachedFile: [],
      errorOfflineChangesSyncDocs: [],
      maxCacheAttachedFileCount: 0,
      currentCacheAttachedFileCount: 0,
      enabled: null
    };

    this.offlineStatusSubject = new Subject<OfflineStatus>();
    this.offlineStatus$ = this.offlineStatusSubject.asObservable();

    this.pageDownloadProgress = {
      pageNumber: 0,
      loaded: 0,
      total: 0
    };

  }

  isInitialCachingComplete() {
    return this.offlineStatus.initialCachingComplete;
  }

  isOfflineMode() {
    return this.isCachingEnabled() && this.isInitialCachingComplete();
  }

  getLastSyncDate(): number {
    // initial value is zero see this.loggedInUserService;
    // fetch directly from the User settings so it will still work across logins
    return this.loggedInUserService.getUserConfigItem('lastSyncDate');
  }

  setLastSyncDate(document) {
    // update the last sync date if the date is later than the stored value. This will be used to sync new objects
    // when data is saved or the app is loaded again. The sync will only happen from the last sync date value
    if (document && document.systemHeader && document.systemHeader.serverUpdatedDate) {
      // use the number of values since the epoch (1/1/1970) in milliseconds, as returned by the valueOf() function,
      // so it can be used to easily compare
      const syncDate = new Date(document.systemHeader.serverUpdatedDate).valueOf();
      if (syncDate > this.getLastSyncDate()) {
        this.loggedInUserService.setUserConfigItem('lastSyncDate', syncDate);
      }
    }
  }

  async setOfflineStatusToLocalStorage() {
    await this.keyValueStorageService.setItem(ClientConstants.LOCAL_STORAGE_OFFLINE_STATUS_KEY,
      JSON.stringify(this.offlineStatus));
    // publish offline status subject.
    // This can be changed to publish the subject in other tabs when the data changes in localStorage
    this.offlineStatusSubject.next(this.offlineStatus);
    this.appStore.dispatch(offlineSetOfflineStatus(this.offlineStatus));
  }

  /**
   * Displays the loading cache status in the page
   * @param isCacheLoading
   * returns true if all pending documents had been cached.
   */
  setCacheLoadingStatus(isCacheLoading) {
    this.offlineStatus.cacheLoading = isCacheLoading;
    this.setOfflineStatusToLocalStorage();
    return this.offlineStatus.currentCacheCount === this.offlineStatus.maxCacheCount;
  }

  setConnected() {
    this.offlineStatus.connected = true;
    this.offlineStatus.connectionText = 'Online';
    this.setOfflineStatusToLocalStorage();
  }

  setDisconnected() {
    this.offlineStatus.connected = false;
    this.offlineStatus.connectionText = 'Offline';
    this.setOfflineStatusToLocalStorage();
  }

  addToMaxCacheCount(count) {
    if (count) {
      this.offlineStatus.maxCacheCount += count;
      this.setOfflineStatusToLocalStorage();
    }
  }

  ignoreCacheDocument(doc) {
    this.offlineStatus.currentCacheCount++;
    this.offlineStatus.errorCacheCount++;

    this.setOfflineStatusToLocalStorage();
  }

  getErrorCacheCount() {
    return this.offlineStatus.errorCacheCount;
  }

  initialCacheQuerySuccess(document) {
    this.offlineStatus.currentCacheCount++;
    if (this.offlineStatus.currentCacheCount >= this.offlineStatus.maxCacheCount) {
      this.offlineStatus.currentCacheCount = 0;
      this.offlineStatus.maxCacheCount = 0;
    }
    this.setOfflineStatusToLocalStorage();

    this.setLastSyncDate(document);
  }

  setInitialCachingComplete(complete) {
    this.offlineStatus.initialCachingComplete = (complete === true);
    this.setOfflineStatusToLocalStorage();
  }

  setPendingDocumentCount(pendingCount) {

    this.offlineStatus.pendingDocumentCount = pendingCount;
    this.loggedInUserService.setUserConfigItem('offlinePendingDocumentsCount', pendingCount);
    this.setOfflineStatusToLocalStorage();
  }

  incPendingDocumentCount() {

    this.offlineStatus.pendingDocumentCount++;
    this.loggedInUserService.setUserConfigItem('offlinePendingDocumentsCount', this.offlineStatus.pendingDocumentCount);
    this.setOfflineStatusToLocalStorage();
  }

  decPendingDocumentCount() {

    this.offlineStatus.pendingDocumentCount--;
    this.loggedInUserService.setUserConfigItem('offlinePendingDocumentsCount', this.offlineStatus.pendingDocumentCount);
    this.setOfflineStatusToLocalStorage();
  }

  isConnected() {
    return this.offlineStatus.connected;
  }

  setCachingAppStatus(isCachingApp) {
    this.offlineStatus.cachingApp = isCachingApp;
    this.setOfflineStatusToLocalStorage();
  }

  setCurrentStaticResourceCount(count: number) {
    this.offlineStatus.currentStaticResourceCount = count;
    this.setOfflineStatusToLocalStorage();
  }

  setTotalStaticResourceCount(count: number) {
    this.offlineStatus.totalStaticResourceCount = count;
    this.setOfflineStatusToLocalStorage();
  }

  mergeOfflineStatusData(offlineStatus) {
    merge(this.offlineStatus, offlineStatus);
    this.setOfflineStatusToLocalStorage();
  }
  
  checkServerConnectionStatus(){
    const _self = this;
     return new Promise<any>((resolve, reject) => {
      this.sessionTestService
        .testSession()
        .then(
          res => {
            if (res===""){
              resolve(false);
            } else {
              resolve(true);
            }

          },
          err => {
            if (err.status === 401) {
              resolve(true);
            }
          }
        );
    });
  }

  getOfflineLoginConnectionPollTime() {
    const config = (<any>window).ftClientConfig;
    return config.offlineLoginConnectionPollTime || 1000;
  }


  setMaxCacheCount(count) {
    if (count) {
      this.offlineStatus.maxCacheCount = count;
      this.setOfflineStatusToLocalStorage();
    }
  }

  setCachingEnabledStatus(enabled: boolean) {
    this.offlineStatus.enabled = enabled;
    this.loggedInUserService.setUserConfigItem('cachingEnabled', enabled);
  }

  isCachingEnabled() {
    if (this.offlineStatus.enabled === null) { // enabled status is null, checking localstorage
      this.offlineStatus.enabled = this.loggedInUserService.getUserConfigItem('cachingEnabled') === true;
    }

    return this.offlineStatus.enabled;
  }

  publishOfflineStatus() {
    // publish offline status subject.
    // This can be changed to publish the subject in other tabs when the data changes in localStorage
    this.offlineStatusSubject.next(this.offlineStatus);
    this.appStore.dispatch(offlineSetOfflineStatus(this.offlineStatus));
  }
  getOfflineStatusFromKeyValueStorage() {
    return  this.keyValueStorageService.getItem(ClientConstants.LOCAL_STORAGE_OFFLINE_STATUS_KEY);
  }

  setLastTileSyncDetails(lastSyncDetails: LastTileSyncDetails) {
    this.loggedInUserService.setUserConfigItem('lastSyncTileDetails', lastSyncDetails);
  }

  getLastTileSyncDetails(): LastTileSyncDetails {
      return this.loggedInUserService.getUserConfigItem('lastSyncTileDetails') || { lastSyncX: 0, lastSyncY: 0 };
  }
}
