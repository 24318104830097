import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VendorLibraryService {

  constructor() {}

  async getVendorLibrary(name) {
    const clientResourcesReq = new Request('api/loadVendorLibrary/' + name, { credentials: 'include' });
    try {
      const response = await fetch(clientResourcesReq);
      return response.json();
    } catch (err) {
      return null;
    }
  }

  async getVendorLibs() {
    const clientResourcesReq = new Request('api/loadVendorLibraries', { credentials: 'include' });
    try {
      const response = await fetch(clientResourcesReq);
      return response.json();
    } catch (err) {
      return null;
    }
  }

}
