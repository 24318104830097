import {Injectable} from '@angular/core';
import {FormbirdInjectorService} from '../injector/formbird-injector.service';
import {ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private service: any;

  constructor(private injector: FormbirdInjectorService) {
    this.service = injector.get('RouterService');
  }
  getActivatedRouteSnapshot(): ActivatedRouteSnapshot {
    return this.service.getActivatedRouteSnapshot()
  }

  navigate(params: any[]): Promise<boolean> {
    return this.service.navigate(params)
  }

  navigateByUrl(url: string, target?: string) {
    return this.service.navigateByUrl(url, target)
  }
}
