<div class="search-wrap">
    <mat-form-field appearance="standard">
        <input matInput hidden/>


    <ng-select
            class="ft-search-box"
            #box
            [items]="results"
            bindLabel="systemHeader.summaryName"
            (change)="onChange($event)"
            (keyup.enter)="onEnter()"
            (keyup)="onKeyPress($event)"
            (scroll)="onScroll($event)"
            (scrollToEnd)="onScrollToEnd()"
            [loading]="loading"
            (blur)="onFocusOut()"
            (focus)="onFocusIn()"
            (paste)="onPaste()"
            [virtualScroll]="true"
            [typeahead]="typeahead"
            [(ngModel)]="selectedResult">
                  <ng-template ng-footer-tmp>
                      <small class="search-text-status">
                          Loaded&nbsp;<b>{{loaded}}&nbsp;</b> of&nbsp;<b>{{totalResults}}</b>
                      </small>
                  </ng-template>
                  <ng-template ng-header-tmp>
                      <small class="search-text-status" *ngIf="!errorMessage">
                          Searching for:&nbsp;<b class="text-ellipsis">{{txt}}</b>
                      </small>
                      <small class="search-text-status-error" *ngIf="errorMessage">
                          {{errorMessage}}
                      </small>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <span [ftOptionHighlight]="search" *ngIf="item">
                        {{ (item.systemHeader && item.systemHeader.summaryName) ?
                            item.systemHeader.summaryName : 'Document ' + item.documentId }}
                      </span>
                </ng-template>
          </ng-select>
          <button type="button" style="display:flex; justify-content: center;" mat-icon-button matSuffix  class="searchFilter" *ngIf="filter" (click)="removeFilter()">
              <mat-icon>filter_list</mat-icon>
          </button>
          <button type="button" style="display:flex; align-content: center;" mat-icon-button matSuffix class="searchFilter search" (click)="search()">
              <mat-icon>search</mat-icon>
          </button>

        </mat-form-field>
  </div>
