<ft-loading *ngIf="!initialized"></ft-loading>
<div *ngIf="initialized" id="ft-navbar-wrap">
  <mat-toolbar *ngIf="!noNavBar && !fullWindow && !hideTopBar" class="ft-navbar" color="primary">
    <mat-toolbar-row class="flex-mat-toolbar-row">
      <div class="ft-nav-buttons">
        <button type="button" id="top-bar-menu-btn" mat-icon-button (click)="toggleSideNav()" matTooltip="Menu">
          <mat-icon>menu</mat-icon></button>
        <button type="button" id="top-bar-home-btn" mat-icon-button (click)="changeState()" matTooltip="Home">
          <mat-icon *ngIf="!homeButtonImage">home</mat-icon>
          <img *ngIf="homeButtonImage" class="homeButtonImage" [src]="(fileResources ?
          (fileResources.base64strData || fileResources.url) : null)"/>
        </button>
      </div>

      <div class="ft-nav-search" id="navigationSearch">
        <ft-search-box *ngIf='hideGeneralSearch === false' search="true" style="display:flex; flex:100%;"></ft-search-box>
      </div>

      <div class="ft-offline-status" >
        <ft-offline-status style="display:flex;align-items: center;position: relative;"></ft-offline-status>
      </div>
      <div class="ft-user" style="display:flex; flex:auto; justify-content: flex-end; align-content: flex-start;">
        <ft-user></ft-user>
      </div>      
      <picture *ngIf="docInfo.imageLink.imageFileNo96" class="docHeader img-print-position">        
        <img (click)="viewImageFile()" class="img-responsive img-thumbnail" [src]="(fileResourcesCommandBar ?
          (fileResourcesCommandBar.base64strData || fileResourcesCommandBar.url) : null)"
          onerror="this.src='images/ft-uploader/ft-uploader-default-gallery-placeholder.png';"/>
      </picture>
      
    </mat-toolbar-row>

    <mat-toolbar-row [ngStyle]="dynamicCSSObj.commandBarCSS" style="padding: 0!important; display:flex; flex-flow: row wrap;
                     justify-content: flex-start; align-content: center">
      <ft-command-bar style="width: 100%;" ></ft-command-bar>
    </mat-toolbar-row>

  </mat-toolbar>

</div>
<mat-sidenav-container id="ft-wrapper"
                       [style.marginTop.px]="mobileQuery.matches ? 56 : 0">

      <mat-sidenav [opened]='!noNavBar && sideBarState.leftSideBarOpen === true' class="ft-sidebar-wrap hidden-print" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <div *ngIf="initialized && !noNavBar" class="sidebar-content">
              <ft-menu (menuSelect)="onMenuSelect()"></ft-menu>
            </div>
      </mat-sidenav>

      <mat-sidenav-content id="ft-sidenav-content">
            <router-outlet></router-outlet>
      </mat-sidenav-content>

</mat-sidenav-container>
