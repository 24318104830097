import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';
import { LocalStorageService, LocalStorageKeys } from '@formbird/services';

const currentYear = new Date().getFullYear();

@Injectable()
export class AboutDialogService {

  constructor(
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) {
  }

  showAboutDialog(): void {

    const appVersion = this.localStorageService.getItem(LocalStorageKeys.APP_VERSION);

    this.modalService.openDialog({
      data: {
        html: `<div class="ft-about-us"><p><img src="images/formbird-logo-makes-it-possible.png"/></p>
                      <p>
                        <a href="https://www.formbird.com/" title="Formbird – Formbird makes it possible">
                        Formbird.com</a> version ${appVersion}
                      </p>
                      <p>&copy; ${currentYear} Formbird Pty Ltd. All rights reserved.</p>
                 </div>`,
        confirmButton: true,
        messageType: 'ft-about-us'
      },
      panelClass: 'ft-about-us-wrap'
    });
  }
}
