import { RouterService } from './router.service';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { DefaultComponent } from '../app/components/default/default.component';
import { NotFoundComponent } from '../app/components/not-found/not-found.component';
import { TemplateViewComponent } from '../app/components/template/template-view.component';
import { PublicLoginComponent } from '../app/components/public-login/public-login.component';
import { ExternalViewComponent } from '../app/components/external-view/external-view.component';
import { CanDeactivateGuard } from '../app/guards/can-deactivate.guard';
import { CanActivateGuard } from '../app/guards/can-activate.guards';
import { TopBarModule } from '../app/modules/top-bar/top-bar.module';
import { TopBarComponent } from '../app/components/top-bar/top-bar.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/default',
        pathMatch: 'full'
    },
    {
        path: 'default',
        component: DefaultComponent,
        canActivate: [CanActivateGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'audit',
        canActivate: [CanActivateGuard],
        loadChildren: () => import('../app/modules/audit/audit.module').then(m => m.AuditModule)

    },
    {
        path: 'search',
        loadChildren: () => import('../app/modules/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'form',
        component: TopBarComponent,
        children: [
            {
                path: '',
                component: NotFoundComponent,
                pathMatch: 'full'
            },
            {
                path: ':id',
                component: TemplateViewComponent,
                canDeactivate: [ CanDeactivateGuard ],
                canActivate: [CanActivateGuard]
            },
            {
                path: ':id/:templateId',
                component: TemplateViewComponent,
                canDeactivate: [ CanDeactivateGuard ],
                canActivate: [CanActivateGuard]
            }
        ]
    },
    {
        path: 'formNoNavBar/:id',
        component: TemplateViewComponent,
        data: { noNavBar: true },
        canDeactivate: [ CanDeactivateGuard ],
        canActivate: [CanActivateGuard]
    },
    {
        path: 'notFound',
        component: NotFoundComponent
    },
    {
        path: 'external',
        component: TopBarComponent,
        children: [
            {
                path: '',
                component: ExternalViewComponent,
                pathMatch: 'full'
            }
        ]
    },
    {
        path: ':accountPublicURL',
        component: PublicLoginComponent
    },
    {
        path: ':accountPublicURL/:paramRoute/:paramQuery',
        component: PublicLoginComponent
    },
    {
        path: ':accountPublicURL/:paramRoute',
        component: PublicLoginComponent
    },
    {
        path: ':accountPublicURL/:paramRoute/:paramQuery/:override',
        component: PublicLoginComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        TopBarModule,
        RouterModule.forRoot(routes, { 
            enableTracing: false, 
            preloadingStrategy: PreloadAllModules 
        })
    ],
    exports: [RouterModule],
    declarations: [],
    providers: [
        RouterService,
        CanDeactivateGuard
    ]
})
export class AppRoutingModule { }
