import { Inject, Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ComponentDefinition, DocumentData, FormParameters, User } from '@formbird/types';

const logger = console;

@Injectable()
export class TemplateHtmlService {

  factoryResolver;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

      /**
       * add the component to the view as a custom element
       */
      appendComponent(componentDefinition: ComponentDefinition,
                      documentData: DocumentData,
                      formParameters: FormParameters,
                      user: User,
                      key: number,
                      vcr: ViewContainerRef,
                      dynamicContainerComponent: any) {
      if (componentDefinition.componentName) {

        const factory = this.factoryResolver.resolveComponentFactory(dynamicContainerComponent);
        const component = factory.create(vcr.parentInjector);

        component.instance.formParameters = formParameters;
        component.instance.tplItem = componentDefinition;
        component.instance.documentData = documentData;
        component.instance.key = key;
        component.instance.user = user.filteredAccount;
        vcr.insert(component.hostView);

        // render a not found component if the component does not exist
        // const componentName = componentDefinition.componentName

        // const customElement = renderer.createElement(componentName);
        //
        // // pass the parameters of the component to the custom element
        // customElement.componentDefinition = componentDefinition;
        // customElement.document = documentData.document;
        // customElement.template = documentData.template;
        // customElement.formParameters = formParameters;
        // customElement.key = key;
        // customElement.account = user.filteredAccount;
        // customElement.fieldName = componentDefinition.name;
        // if (documentData.document) {
        //     customElement.fieldValue = documentData.document[componentDefinition.name];
        // }
        //
        // // insertBefore element to container
        // renderer.appendChild(el.nativeElement, customElement);
      }
    }
}
