import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, NotificationService } from '@formbird/services';

@Injectable()
export class AuditService {
    constructor(private http: HttpClient,
                private dataService: DataService,
                private notificationService: NotificationService) {
    }

    navigateToFormUrl(id) {
        location.assign('form/' + id);
    }

    async fetch(versions, cbxVersionOne, cbxVersionTwo) {
        const versionOne = cbxVersionOne;
        const versionTwo = cbxVersionTwo;

        const object1 = await this.findObjectByVersionId(versions, versionOne);
        const object2 = await this.findObjectByVersionId(versions, versionTwo);

        if (object1 === null || object2 === null) {
            return null;
        }

        const outputObject = [];
        outputObject.push(object1);
        outputObject.push(object2);
        outputObject.push(versionOne);
        outputObject.push(versionTwo);

        return outputObject;
    }

    formatVersionId(object) {
        return object.systemHeader.versionId + ' as of ' +
            new Date(Date.parse(object.systemHeader.createdDate)).toLocaleTimeString('en-US',
                {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                });
    }

    async processDataDiff (index, max, versions, versionIds) {
        const vm = this;

        const versionOne = versionIds[index];
        const versionTwo = versionIds[index >= max ? index : index + 1];

        const cbxVersionOne = versionOne ? versionOne.versionId : null;
        const cbxVersionTwo = versionTwo ? versionTwo.versionId : null;
        return await vm.fetch(versions, cbxVersionOne, cbxVersionTwo);
    }

    private findObjectByVersionId = function (versions, versionId) {

        const _self = this;

        return new Promise((resolve, reject) => {
            const version = versions[versionId];
            if (!version) {
                _self.dataService.getDocument(versionId).then((versionDoc) => {
                    versions[versionId] = versionDoc;
                    resolve(versionDoc);
                }).catch(err => reject(err));
            } else {
                resolve(versions[versionId]);
            }
        });
    };

    async getDiff(baseTextRaw, newTextRaw, baseVersion, nextVersion) {
        return new Promise((resolve, reject) => {
          import('../../scripts/jsdifflib').then((difflib) => {
               // build the diff view and return a DOM node
               const view = difflib.buildView({
                   baseText: baseTextRaw,
                   newText: newTextRaw,
                   // set the display titles for each resource
                   baseTextName: 'Version ' + baseVersion,
                   newTextName: 'Version ' + nextVersion,
                   contextSize: 10,
                   // set inine to true if you want inline
                   // rather than side by side diff
                   inline: false
               });

               resolve(view);
            }).catch(error => {
                reject('An error occurred while loading the audit view: ' + error.message);
            });
        });
    }

    getVersions(documentId): any {
      const _self = this;
      return new Promise((resolve, reject) => {
            _self.http.get('api/documentAudit', {params: { id: documentId }}).subscribe((returnData) => {
                const tmpData = [];
                tmpData.push(returnData);
                // results.data = tmpData;

                resolve(returnData);
            }, function (err) {
                // do something
                _self.notificationService.printMessage(err, 'error');
                reject(err);
            });
        });
    }
}
