import { Injectable } from '@angular/core';
import { FileReferenceDetails, FileReferenceDocument } from '@formbird/types';
import { LoggedInUserService } from '../user/logged-in-user.service';

@Injectable({
  providedIn: 'root'
})
export class OpfsCacheService {

  constructor( public loggedInUserService: LoggedInUserService) { }

  async cacheFile(file: Pick<FileReferenceDetails, 'fileNo'>, blob: Blob) {
    const opfsRoot = await navigator.storage.getDirectory();
    const dirName = this.getDirectoryName();
    const filesDir = await opfsRoot.getDirectoryHandle(dirName, { create: true });
    if (await this.getCachedFile(file.fileNo) !== null) {
      console.warn('File already exists in cache, overwriting', file.fileNo)
    }
    const fileHandle = await filesDir.getFileHandle(`${file.fileNo}`, { create: true });
    const writeable =  await fileHandle.createWritable();
    await writeable.write(blob);
    await writeable.close();
  }

  async clearData() {
    const opfsRoot = await navigator.storage.getDirectory();
    const dirName = this.getDirectoryName();
    await opfsRoot.removeEntry(dirName, { recursive: true });
  }

  async getCachedFile(fileNo: string) {
    const opfsRoot = await navigator.storage.getDirectory();
    try {
      const dirName = this.getDirectoryName();
      const filesDir = await opfsRoot.getDirectoryHandle(dirName, { create: false });
      const fileHandle = await filesDir.getFileHandle(fileNo);
      return await fileHandle.getFile();
    } catch (e) {
      if (e.name === 'NotFoundError') {
        return null;
      } else {
        throw e;
      }
    }
  }

  getDirectoryName =() => {
    return `files-${this.loggedInUserService.getUserId()}`;
  }

}
