import { Injectable } from '@angular/core';
import { SharedConstants } from '@formbird/types';
import { CustomFieldPreprocessorService } from './custom-field/custom-field-preprocessor.service';
import { RelatedDocumentPreprocessor } from './related-document.preprocessor';

@Injectable({
  providedIn: 'root'
})
export class PreProcessorFieldService {

  constructor(
    private customFieldPreprocessorService: CustomFieldPreprocessorService
  ) { }

  preProcessOneField(field, document) {
    this.customFieldPreprocessorService.preProcess(field, document);
  }

  preProcessField(template, document) {
    if (template && template.components) {
      for (let i = 0; i < template.components.length; i++) {
        const field = template.components[i];
        this.preProcessOneField(field, document);
      }
    }
  }

  private isCustomPreProcessor(component) {
    return component.componentName && !component.componentName.startsWith('ft-');
  }

  preProcess(template, document) {

    const _self = this;

    const promises = [];

    return new Promise((resolve, reject) => {

      if (template && template.components) {

        template.components.forEach(function (component) {
          if (_self.isCustomPreProcessor(component)) {
            promises.push(_self.customFieldPreprocessorService.preProcess(component, document));
          }
        });

        if (promises.length > 0) {

          Promise.all(promises).then(
            function successFunc() {
              resolve(promises.length);
            },
            function errorFunc(err) {
              reject(err);
            }
          );

        } else {
          resolve(0);
        }

      } else {
        resolve(0);
      }
    });

  }

}
