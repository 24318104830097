import { SharedConstants } from '@formbird/types';
import { UtilHttpStatus } from '@formbird/shared';
import { Injectable } from '@angular/core';
import {
    LoggedInUserService, LocalStorageService, LocalStorageKeys,
    NotificationService, SessionTestService, SearchService
} from '@formbird/services';

const logger = console;

@Injectable({
    providedIn: 'root'
})
export class CustomRouteService {

    constructor(private searchService: SearchService, private loggedInUserService: LoggedInUserService) { }

    async getURLRoute(routeName) {
        try {
            const query = {
                "query": {
                    "bool": {
                        "must": [
                            { "term": { "systemHeader.systemType": SharedConstants.SYSTEM_TYPE_URL_ROUTE } },
                            { "term": { "name": routeName } }
                        ]
                    }
                }
            };

            const options: any = {
                page: SharedConstants.DEFAULT_PAGE,
                pageSize: SharedConstants.DEFAULT_PAGE_SIZE
            };

            const offlineMode = this.loggedInUserService.getUserConfigItem('cachingEnabled') === true;
            if (offlineMode) {
                options.searchOptions = {
                    dexieSearchFunction: function (db, callback) {
                        try {
                            db.documents.where({ 'name': routeName, 'systemHeader.systemType': SharedConstants.SYSTEM_TYPE_URL_ROUTE }).toArray(function (documents) {
                                callback(null, {
                                    total: documents.length,
                                    statusCode: UtilHttpStatus.OK,
                                    data: documents,
                                    searchProvier: 'dexie'
                                });
                            })
                        } catch (err) {
                            callback(err);
                        }
                    }
                };
            } else {
                options.filter = query;
            }

            const result = await this.searchService.search(options);
            if (result?.data?.hits?.hits?.length) {
                return result.data.hits.hits[0]._source;
            } else {
                throw new Error(`Route document for ${routeName} not found!`);
            }
        } catch (err) {
            throw new Error(err.error || err.message);
        }
    }


}