import { Injectable } from '@angular/core';
import { ComponentService } from '../../component/component.service';
import { UtilValidation } from '@formbird/shared';
import { OfflineStatusService } from '../../offline-status/offline-status.service';
import { IndexedDBService } from '../../indexeddb/indexed-db.service';

const logger = console;

@Injectable({
  providedIn: 'root'
})
export class CustomFieldPreprocessorService {

  constructor(
    private componentService: ComponentService,
    private offlineStatusService: OfflineStatusService,
    private indexedDBService: IndexedDBService
  ) { }

  preProcess(field, document) {

    const _self = this;

    return new Promise((resolve, reject) => {

      _self.componentService.loadComponentDoc(field.componentName).then(
        async function foundFunc(component: any) {

          try {

            const customFunc = component.clientPreprocessorFunction;
            if (UtilValidation.hasValue(customFunc)) {

              const clientPreprocessorFunc = Function('return (' + customFunc + ')')();
              if (_self.offlineStatusService.isCachingEnabled()) {
                await _self.setSaveFlattenedValues(field, document);
              }
              if (component.preprocessorIsAsynchronous) {

                clientPreprocessorFunc(field, document).then(
                  function (retVal) {
                    resolve(retVal);
                  },
                  function (error) {
                    reject(error);
                  }
                );

              } else {

                const retVal = clientPreprocessorFunc(field, document);
                resolve(retVal);
              }

            } else {
              resolve(document);
            }

          } catch (err) {
            const errMsg = ('Error in running custom component preprocessor function : ' + err);
            logger.debug(errMsg);
            reject(errMsg);
          }
        },

        function notFoundFunc(err) {
          reject(err);
        }
      );
    });

  }

  private setSaveFlattenedValues(field, document){
    return new Promise(async (resolve, reject) => {
        const indexes: any  = await this.indexedDBService.getIndexes();
        if (indexes){
          const index = indexes.find(o => o.name === `${field.name}____documentId`);
          if (index) {
            field.saveFlattenedValues = true;
          }
        }
        resolve(true);
    })
  }
  
}
