import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { DefaultStateService } from '@formbird/services';

@Component({
  selector: 'ft-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NotFoundComponent implements OnInit {
  constructor(
    private defaultStateService: DefaultStateService
  ) {
  }
  changeState() {
    this.defaultStateService.changeToDefaultState();
  }

  ngOnInit() {
  }
}
