import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const anyWindow = <any>window;
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private previousUrl : string;
  constructor(private router: Router) {}

  getActivatedRouteSnapshot() {

    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.snapshot;
  }

  navigate(params) {
    this.rewriteUrl(params.join('/'));
    this.router.navigate(params);
  }

  navigateByUrl(url, target) {
    if (!target && url && (url.startsWith('/form') || url.startsWith('form/'))) {
      this.rewriteUrl(url);
      this.router.navigate([url]);
    } else {
      anyWindow.open(url, target || '_self');
    }
  }

  getCurrentUrl() {
    return this.router.url;
  }

  rewriteUrl(url: string, title?: string) {
    if (this.previousUrl !== url){
      this.previousUrl = url;
      anyWindow.history.pushState({}, title, url);
    }
  }

}
