import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BroadcastService, ClientConstants, ConfigService, DefaultStateService, LoggedInUserService,
  DocumentSaveService } from '@formbird/services';
import { filter } from 'rxjs';

const logger = console;

const INITIALISATION_STATE = 'initialisation';

const POST_LOGIN_STATE = 'post_login';
let isSaving = false;
@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  private previousUrl: string;

  nextState = ''; // the next state. This will be visited once it is confirmed that the user is logged in
  nextStateParams = null;

  firstStateChange = true;
  currentUrl;

  constructor(
    private configService: ConfigService,
    private defaultStateService: DefaultStateService,
    private authenticationService: AuthenticationService,
    private loggedInUserService: LoggedInUserService,
    private location: Location,
    private router: Router,
    private broadcastService: BroadcastService,
    private documentSaveService: DocumentSaveService
  ) {
    this.initialise();
  }

  initialise() {
    // listen for changes in routes
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
      logger.info('Navigating from url: ' + this.currentUrl + ' to ' + ev.url);
      this.broadcastService.broadcast(ClientConstants.DOCUMENT_SAVE_STATUS, { isSaving: isSaving, url: this.currentUrl });
      this.broadcastService.broadcast(ClientConstants.URL_CLICKED, ev.url);
      this.currentUrl = ev.url;
    });

    this.documentSaveService.onDocumentSaving().subscribe((isDocSaving: boolean) => {
      isSaving = isDocSaving;
      this.broadcastService.broadcast(ClientConstants.DOCUMENT_SAVE_STATUS, { isSaving: isSaving });
    });
  }

  goBack() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    }
  }

  changeToPostLoginState() {
    this.defaultStateService.changeToDefaultState();
  }

  changeState(userBean?) {
    // if (this.nextState) {
    //  var lastEnteredUrl = LocalStorageService.getItem(ClientConstants.LAST_ENTERED_STATE_URL);
    //  if (lastEnteredUrl) {
    //    logger.info("Last entered state: " + lastEnteredUrl);
    //    LocalStorageService.removeItem(ClientConstants.LAST_ENTERED_STATE_URL);
    //    this.location.go(lastEnteredUrl);
    //  } else if (this.nextState === 'default') {
    //     this.nextState = 'menu.form';
    //     if (userBean) {
    //       this.defaultStateService.changeToDefaultState(this.nextState, userBean.user);
    //     } else if (this.loggedInUserService.user.account) {
    //       this.defaultStateService.changeToDefaultState(this.nextState,
    //         this.loggedInUserService.user.account);
    //     } else {
    //       this.loggedInUserService.loadLoggedUser().then((newUserBean: any) =>
    //         this.defaultStateService.changeToDefaultState(this.nextState, newUserBean.user));
    //     }
    //   } else if (this.nextState === 'menu.form' && !this.nextStateParams.id) {
    //     this.location.go('menu.error');
    //   } else {
    //     if (!this.loggedInUserService.user.account) {
    //       this.loggedInUserService.setLoggedUser().then(() => {
    //         const locationParams: any = {};
    //         const isPublicAccount = this.loggedInUserService.user.publicAccount;
    //         if (isPublicAccount) {
    //           locationParams.location = false;
    //         }
    //         this.location.go(this.nextState, this.nextStateParams, locationParams);
    //       });
    //     } else {
    //       const locationParams: any = {};
    //       const isPublicAccount = this.loggedInUserService.user.publicAccount;
    //       if (isPublicAccount) {
    //         locationParams.location = false;
    //       }
    //       this.location.go(this.nextState, this.nextStateParams, locationParams);
    //     }
    //   }
    //   localStorage.removeItem(ClientConstants.LAST_ENTERED_STATE_URL);
    // } else {
    //     const stateUrl = ClientUrlRoutes.defaultState;
    //     this.location.go(stateUrl);
    // }
  }

  getUser(callback) {
    this.loggedInUserService.loadLoggedUser()
      .then((userBean: any) => {
        if (userBean.user) {
          callback(userBean);
          this.changeState(userBean);
        } else {
          this.changeToState('login');
        }
      })
      .catch(err => {
        logger.error('Error loading logged in user: ' + err);
      });
  }

  changeToState(state, options?) {
    logger.info('Navigating to state - ' + state);
    this.location.go(state, options);
  }

  isNotInitialisationState(stateName) {
    return stateName && stateName !== INITIALISATION_STATE && stateName !== POST_LOGIN_STATE;
  }

  handlePostLoginSuccess() {
    if (this.location.path() === '/login' || this.location.path() === '/auth/login') {
      this.location.go('default');
    } else {
      this.changeState();
    }
  }

  handleRouteChange(event, toState, toParams, fromState, fromParams, callback) {

    if (!toState.data || !toState.data.isAuthView) {

      if (this.firstStateChange) {
        this.nextState = toState.name;
        this.nextStateParams = toParams;

        logger.info('Navigating to initialisation state');

        this.firstStateChange = false;
        this.location.go(INITIALISATION_STATE);

      } else if (this.isNotInitialisationState(toState.name)) {

        logger.info('Navigating to state - ' + toState.name);

        this.nextState = toState.name;
        this.nextStateParams = toParams;

        this.getUser(callback);
      }
    }
  }

  doChangeState(state, options) {
    this.changeToState(state, options);
  }
}
